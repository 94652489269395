import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './NewPasswordModal.scss';
import TextInput from '@components/inputs/TextInput/TextInput';
import Button from '@components/inputs/Button/Button';
import Modal from '@components/feedback/Modal/Modal';
import Form from '@components/inputs/Form/Form';
import { connect } from 'react-redux';
import TextLink from '@components/navigation/TextLink/TextLink';
import { openModal } from '@actions/modal';
import { verifySmsCode, changePasswordSms } from '@actions/user';
import { FORGOT_PASSWORD_MODAL } from '@constants/modal';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';

const t = mova.ns('containers.NewPasswordModal');
const errorsT = mova.ns('errors.validation');

function NewPasswordModal({ sendCode, updatePasswords, goBack }) {
  const [codeValid, setCodeValid] = useState(false);

  const submitForm = (model) => {
    if (codeValid) {
      updatePasswords(model);
    } else {
      sendCode(model, () => setCodeValid(true));
    }
  };

  return (
    <Modal title={t('title')} className='new-password-modal'>
      <div className='new-password-modal__text'>{t('text')}</div>
      <Form onValidSubmit={submitForm}>
        <div className='new-password-modal__row'>
          <TextInput
            name='code'
            placeholder={t('code')}
            fullWidth
            fontSize={14}
            validationErrors={{
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        {codeValid && (
          <>
            <div className='new-password-modal__row'>
              <TextInput
                name='newPassword'
                placeholder={t('newPassword')}
                type='password'
                fullWidth
                fontSize={14}
                validations={{
                  minLength: 4,
                }}
                validationErrors={{
                  minLength: errorsT(`minLength.${getPluralsFactor(4)}`, { symbols: 4 }),
                  isDefaultRequiredValue: errorsT('required')
                }}
                required
              />
            </div>
            <div className='new-password-modal__row'>
              <TextInput
                name='repeatPassword'
                placeholder={t('repeatPassword')}
                type='password'
                fullWidth
                fontSize={14}
                validations={{
                  repeatPassword: (values, value) => values.newPassword === value
                }}
                validationErrors={{
                  repeatPassword: errorsT('repeatPassword'),
                  isDefaultRequiredValue: errorsT('required')
                }}
                required
              />
            </div>
          </>
        )}
        <div className='new-password-modal__row'>
          <Button fullWidth size='medium' behavior='submit'>{t('send')}</Button>
        </div>
      </Form>
      <div className='new-password-modal__back'>
        <TextLink onClick={goBack} size='small' type='success'>{t('back')}</TextLink>
      </div>
    </Modal>
  );
}

NewPasswordModal.propTypes = {
  sendCode: PropTypes.func.isRequired,
  updatePasswords: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

NewPasswordModal.defaultProps = {};

const mapDispatchToProps = {
  sendCode: verifySmsCode,
  updatePasswords: changePasswordSms,
  goBack: () => openModal(FORGOT_PASSWORD_MODAL),
};

export default memo(connect(null, mapDispatchToProps)(NewPasswordModal));
