import React, { Fragment } from 'react';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import PropTypes from 'prop-types';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import mova from 'mova';
import {
  STATUS_ACCEPTED,
  STATUS_REJECTED,
  STATUS_CANCELLED,
  STATUS_REQUESTED,
  STATUS_FINISHED,
  STATUS_SKIPPED
} from '@constants/reservation';
import { connect } from 'react-redux';
import { openModal } from '@actions/modal';
import TextLink from '@components/navigation/TextLink/TextLink';
import { PLACE_FEEDBACK_MODAL } from '@constants/modal';
import { isDefined } from '@utils/lo/lo';
import moment from 'moment';

const t = mova.ns('pages.Notifications.reservation');

const statusIcons = {
  [STATUS_ACCEPTED]: 'confirm',
  [STATUS_CANCELLED]: 'info',
  [STATUS_REQUESTED]: 'info',
  [STATUS_REJECTED]: 'cancel',
  [STATUS_FINISHED]: 'info',
  [STATUS_SKIPPED]: 'cancel',
};

const getStatusMessage = status => {
  switch (status) {
    case STATUS_ACCEPTED:
      return t('confirmed');
    case STATUS_REJECTED:
      return t('rejected');
    case STATUS_CANCELLED:
      return t('cancelled');
    case STATUS_REQUESTED:
      return t('awaiting');
    case STATUS_FINISHED:
      return t('finished');
    case STATUS_SKIPPED:
      return t('skipped');
    default:
      return t('awaiting');
  }
};

const getFeedbackMessage = note => {
  if (note.data.new.feedback && note.data.new.status !== STATUS_CANCELLED) {
    return t('message', { message: note.data.new.feedback });
  }

  return '';
};

export const getMessageText = (note, timezone) => {
  const reservationDate = moment.tz(note.data.new.from, timezone).format('DD.MM.YYYY');
  const reservationTime = moment.tz(note.data.new.from, timezone).format('HH:mm');
  return `${t('reserv')} ${note.place.name} 
                ${t('atDate')} ${reservationTime} ${reservationDate}  
                ${t('for')} ${note.data.new.seats} 
                ${t(`persons.${getPluralsFactor(+note.data.new.seats)}`)} 
                ${getStatusMessage(note.data.new.status)}${getFeedbackMessage(note)}`;
};

function ReservationNotification({ note, leaveFeedback, timezone }) {
  const text = getMessageText(note, timezone);

  const allowLeaveFeedback = note.data.new.status === STATUS_FINISHED && !isDefined(note.place.currentReview);
  const leaveFeedbackText = (
    <Fragment>
      &nbsp;
      <span>{t('didYouLike')}</span>&nbsp;
      <TextLink onClick={() => leaveFeedback(note.place)}>{t('leaveFeedback')}</TextLink>
    </Fragment>
  );

  return (
    <div className='notification-item__container'>
      <div className='notification-item__icon'>
        <SvgIcon type={statusIcons[note.data.new.status]} />
      </div>
      <div className='notification-item__text'>
        {text}
        {allowLeaveFeedback && leaveFeedbackText}
      </div>
    </div>
  );
}

ReservationNotification.propTypes = {
  note: PropTypes.object.isRequired,
  leaveFeedback: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired
};

const mapStateToProps = ({ app }) => ({
  timezone: app.time.data.DEFAULT_TZ
});

const mapDispatchToProps = {
  leaveFeedback: (place) => openModal(PLACE_FEEDBACK_MODAL, place),
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationNotification);
