import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import TextLink from '@components/navigation/TextLink/TextLink';
import { get, isFunction } from '@utils/lo/lo';

const trimProtocol = ref => ref.replace(/^http[s]?:\/\//, '');
const trimWww = ref => ref.replace(/^www./, '');
const trimLastSlash = ref => ref.replace(/\/$/, '');

const types = {
  FACEBOOK: {
    icon: 'facebook',
    link: true,
    trimRef: ref => trimLastSlash(trimWww(trimProtocol(ref)).replace(/^(m.)?facebook.com\//, ''))
  },
  INSTAGRAM: {
    icon: 'instagram',
    link: true,
    trimRef: ref => `@${trimLastSlash(trimWww(trimProtocol(ref))).replace(/^instagram.com\//, '')}`
  },
  EMAIL: {
    icon: 'email',
    mailto: true,
    trimRef: ref => ref
  },
  PHONE: {
    icon: 'phone',
    phone: true,
    trimRef: ref => ref
  },
  WEB: {
    icon: 'web',
    link: true,
    trimRef: ref => trimLastSlash(trimWww(trimProtocol(ref)))
  },
};

const getReference = (type, ref, className) => {
  const trimmedRef = isFunction(get(types[type], 'trimRef')) ? types[type].trimRef(ref) : ref;

  if (get(types[type], 'link')) {
    return <TextLink size={14} to={ref} external newPage className={className} title={ref}>{trimmedRef}</TextLink>;
  }

  if (get(types[type], 'mailto')) {
    return <TextLink size={14} to={`mailto:${ref}`} external className={className} title={ref}>{trimmedRef}</TextLink>;
  }

  if (get(types[type], 'phone')) {
    return <TextLink size={14} to={`tel:${ref}`} external className={className} title={ref}>{trimmedRef}</TextLink>;
  }

  return <span className={className} title={ref}>{ref}</span>;
};

function ContactItem(props) {
  const { contact } = props;
  const { reference, type } = contact;

  const referenceItem = getReference(type, reference, 'contact-item__reference');

  return (
    <div className='contact-item text-overflow-ellipsis'>
      <SvgIcon className='contact-item__icon' type={get(types[type], 'icon')} />
      {referenceItem}
    </div>
  );
}

ContactItem.propTypes = {
  contact: PropTypes.object.isRequired
};

ContactItem.defaultProps = {};

export default memo(ContactItem);
