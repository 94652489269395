import LocalStorageService from '@services/LocalStorage.service';
import { ACTIVE_PLACE_PROMO } from '../constants';
import { isDefined } from '@utils/lo/lo';
import moment from 'moment';

const activatePromo = (place, channelId) => {
  const newPromo = {
    uri: place.uri,
    channelId,
    validUntil: moment().add(1, 'days').format('x')
  };

  LocalStorageService.set(ACTIVE_PLACE_PROMO, newPromo);
};

const hasActivePromo = () => {
  const activePromo = LocalStorageService.get(ACTIVE_PLACE_PROMO);
  return isDefined(activePromo) && moment().isBefore(moment(parseInt(activePromo.validUntil, 10)));
};

const clearPromo = () => {
  LocalStorageService.delete(ACTIVE_PLACE_PROMO);
};

const getPromoData = () => {
  const activePromo = LocalStorageService.get(ACTIVE_PLACE_PROMO);
  if (!isDefined(activePromo)) {
    return null;
  }

  return {
    uri: activePromo.uri,
    channelId: activePromo.channelId
  };
};

export default {
  activatePromo,
  hasActivePromo,
  clearPromo,
  getPromoData
};
