/* eslint-disable */

export default `<p><strong> Общие положения</strong></p>
<p><span>Настоящая политика обработки персональных данных составлена в соответствии с требованиями закона Украины от 01.06.2010. №2297 &laquo;Про захист персональних даних&raquo; и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных Reservble (далее &ndash; Оператор).</span></p>
<ol>
<li><span>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</span></li>
<li><span>Настоящая политика Оператора в отношении обработки персональных данных (далее &ndash; Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://reservble.com.</span></li>
</ol>
<p><strong>Основные понятия, используемые в Политике</strong></p>
<ol>
<li><span>Автоматизированная обработка персональных данных &ndash; обработка персональных данных с помощью средств вычислительной техники;</span></li>
<li><span>Блокирование персональных данных &ndash; временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</span></li>
<li><span>Веб-сайт &ndash; совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://reservble.com;</span></li>
<li><span>Информационная система персональных данных &mdash; совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;</span></li>
<li><span>Обезличивание персональных данных &mdash; действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</span></li>
<li><span>Обработка персональных данных &ndash; любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</span></li>
<li><span>Оператор &ndash; государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;</span></li>
<li><span>Персональные данные &ndash; любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://reservble.com;</span></li>
<li><span>Пользователь &ndash; любой посетитель веб-сайта https://reservble.com;</span></li>
<li><span>Предоставление персональных данных &ndash; действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</span></li>
<li><span>Распространение персональных данных &ndash; любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</span></li>
<li><span>Трансграничная передача персональных данных &ndash; передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</span></li>
<li><span>Уничтожение персональных данных &ndash; любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.</span>&nbsp;</li>
</ol>
<p><strong> Оператор может обрабатывать следующие персональные данные Пользователя</strong></p>
<ol>
<li>Фамилия, имя, отчество;</li>
<li>Электронный адрес;</li>
<li>Номера телефонов;</li>
<li>Год, месяц, дата и место рождения;</li>
<li>Фотографии;</li>
<li>Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов &laquo;cookie&raquo;) с помощью сервисов интернет-статистики (Гугл Аналитика и других).</li>
<li>Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</li>
</ol>
<p><strong> Цели обработки персональных данных</strong></p>
<ol>
<li>Цель обработки персональных данных Пользователя &mdash; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте.</li>
<li>Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты support@reservble.com с пометкой &laquo;Отказ от уведомлениях о новых продуктах и услугах и специальных предложениях&raquo;.</li>
<li>Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</li>
</ol>
<p><strong> Правовые основания обработки персональных данных</strong></p>
<ol>
<li><span>Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте https://reservble.com. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</span></li>
<li><span>Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов &laquo;cookie&raquo; и использование технологии JavaScript).</span></li>
</ol>
<p><span>&nbsp;</span></p>
<p><strong> Порядок сбора, хранения, передачи и других видов обработки персональных данных</strong></p>
<p><span>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</span></p>
<ol>
<li><span>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</span></li>
<li><span>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.</span></li>
<li><span>В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора support@reservble.com с пометкой &laquo;Актуализация персональных данных&raquo;.</span></li>
<li><span>Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора support@reservble.com с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.</span></li>
</ol>
<p><span>&nbsp;</span></p>
<p><strong> Трансграничная передача персональных данных</strong></p>
<ol>
<li>Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.</li>
<li>Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.</li>
</ol>
<p><span>&nbsp;</span></p>
<p><strong> Заключительные положения</strong></p>
<ol>
<li>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты support@reservble.com.</li>
<li>В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</li>
<li>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://reservble.com/privacy-policy.</li>
</ol>`;
