import React from 'react';
import Colors from '@data/enums/Color.enum';

export function tags({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.325 18.523L20.189 9.65664C20.2289 9.61679 20.2476 9.56289 20.2429 9.50898L19.6453 2.44492C19.6289 2.26211 19.4859 2.11914 19.3031 2.10273L12.239 1.50742C12.1851 1.50273 12.1289 1.52148 12.0914 1.56133L3.22732 10.4254C3.19243 10.4606 3.17285 10.5082 3.17285 10.5578C3.17285 10.6074 3.19243 10.655 3.22732 10.6902L11.0601 18.523C11.1328 18.598 11.2523 18.598 11.325 18.523ZM12.7922 3.24883L18.0562 3.69414L18.5015 8.9582L11.1914 16.266L5.48201 10.559L12.7922 3.24883ZM14.2022 7.61359C14.3066 7.71805 14.4307 7.80092 14.5672 7.85744C14.7037 7.91397 14.85 7.94306 14.9977 7.94305C15.1455 7.94304 15.2918 7.91393 15.4282 7.85738C15.5647 7.80083 15.6888 7.71795 15.7932 7.61347C15.8977 7.509 15.9805 7.38497 16.0371 7.24847C16.0936 7.11196 16.1227 6.96567 16.1227 6.81792C16.1227 6.67018 16.0936 6.52388 16.037 6.38739C15.9805 6.2509 15.8976 6.12688 15.7931 6.02242C15.6886 5.91796 15.5646 5.8351 15.4281 5.77857C15.2916 5.72204 15.1453 5.69295 14.9976 5.69296C14.8498 5.69297 14.7035 5.72208 14.567 5.77863C14.4305 5.83518 14.3065 5.91806 14.202 6.02254C14.0976 6.12701 14.0147 6.25104 13.9582 6.38755C13.9017 6.52405 13.8726 6.67035 13.8726 6.81809C13.8726 6.96583 13.9017 7.11213 13.9583 7.24862C14.0148 7.38511 14.0977 7.50913 14.2022 7.61359ZM20.8523 12.652L19.9242 11.7262C19.889 11.6913 19.8414 11.6717 19.7918 11.6717C19.7422 11.6717 19.6946 11.6913 19.6594 11.7262L11.175 20.1941L5.60623 14.6395C5.57099 14.6046 5.5234 14.585 5.47381 14.585C5.42421 14.585 5.37662 14.6046 5.34138 14.6395L4.41326 15.5652C4.37836 15.6005 4.35879 15.6481 4.35879 15.6977C4.35879 15.7472 4.37836 15.7948 4.41326 15.8301L10.1133 21.5207L11.0414 22.4465C11.114 22.5191 11.2336 22.5191 11.3062 22.4465L20.8523 12.9168C20.925 12.8441 20.925 12.7246 20.8523 12.652Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
