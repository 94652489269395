import clients from './common/clients';
import parseError from './common/errors';
import user from './user.api';
import app from './app.api';
import places from './places.api';
import reservations from './reservations.api';
import notifications from './notification.api';
import chatbot from './chatbot.api';
import billing from './billing.api';

function setToken(token) {
  Object.values(clients).forEach((client) => {
    client.setToken(token);
  });
}

export default {
  parseError,
  user,
  app,
  places,
  reservations,
  setToken,
  notifications,
  chatbot,
  billing,
};
