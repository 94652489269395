import { connect } from 'react-redux';
import { toggleView } from '@actions/app';
import ViewSelector from './ViewSelector';

const mapStateToProps = ({ app }) => ({
  view: app.view
});

const mapDispatchToProps = {
  toggleView
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSelector);
