import React from 'react';
import Colors from '@data/enums/Color.enum';

export function logout({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.4276 11.8509L19.1018 9.22587C18.9776 9.12743 18.7971 9.21649 18.7971 9.37353V11.1548H11.4377C11.3346 11.1548 11.2502 11.2392 11.2502 11.3423V12.6548C11.2502 12.7579 11.3346 12.8423 11.4377 12.8423H18.7971V14.6235C18.7971 14.7806 18.9799 14.8696 19.1018 14.7712L22.4276 12.1462C22.45 12.1286 22.4681 12.1062 22.4806 12.0806C22.493 12.0551 22.4995 12.027 22.4995 11.9985C22.4995 11.9701 22.493 11.942 22.4806 11.9164C22.4681 11.8908 22.45 11.8684 22.4276 11.8509Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
      <path
        d='M12.0096 20.2954C10.8869 20.2954 9.79943 20.0751 8.77755 19.6438C7.79043 19.2271 6.89343 18.623 6.13615 17.8649C5.37751 17.1095 4.77259 16.214 4.3549 15.2282C3.9213 14.2063 3.70333 13.1212 3.70333 11.9985C3.70333 10.8759 3.92365 9.79071 4.3549 8.76884C4.77208 7.78212 5.37208 6.89384 6.13615 6.13212C6.90021 5.3704 7.78849 4.7704 8.77755 4.35321C9.79943 3.92196 10.8869 3.70165 12.0096 3.70165L11.9838 1.9204C6.36818 1.93446 1.86583 6.49306 1.92208 12.1017C1.97833 17.6212 6.47365 22.0767 12.0096 22.0767V20.2954Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
