import React from 'react';

export function userMessage() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='#90C29A' />
      <path
        d='M20 10C17.2474 10 15 12.2474 15 15C15 17.7526 17.2474 20 20 20C22.7526 20 25 17.7526 25 15C25 12.2474 22.7526 10 20 10ZM20 11.5C21.9427 11.5 23.5 13.0573 23.5 15C23.5 16.9427 21.9427 18.5 20 18.5C18.0573 18.5 16.5 16.9427 16.5 15C16.5 13.0573 18.0573 11.5 20 11.5ZM13.9896 22C12.8984 22 12 22.8984 12 23.9896V24.75C12 26.5521 13.1406 27.9453 14.6536 28.7812C16.1667 29.6172 18.0833 30 20 30C21.9167 30 23.8333 29.6172 25.3464 28.7812C26.638 28.0703 27.6094 26.9323 27.8802 25.5H28V23.9896C28 22.8984 27.1016 22 26.0104 22H13.9896ZM13.9896 23.5H26.0104C26.2891 23.5 26.5 23.7109 26.5 23.9896V24.75C26.5 25.9479 25.8281 26.8047 24.6224 27.4688C23.4167 28.1328 21.7083 28.5 20 28.5C18.2917 28.5 16.5833 28.1328 15.3776 27.4688C14.1719 26.8047 13.5 25.9479 13.5 24.75V23.9896C13.5 23.7109 13.7109 23.5 13.9896 23.5Z'
        fill='white'
      />
    </svg>
  );
}
