import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './PlaceLocationModal.scss';
import Modal from '@components/feedback/Modal/Modal';
import Map from '@components/display/Map/Map';

function PlaceLocationModal(props) {
  const { place } = props;

  return (
    <Modal className='place-location-modal'>
      <Map places={[place]} fullHeight placePage />
    </Modal>
  );
}

PlaceLocationModal.propTypes = {
  place: PropTypes.object.isRequired
};

PlaceLocationModal.defaultProps = {};

export default memo(PlaceLocationModal);
