import React, { memo } from 'react';
import './ReservationDetails.scss';
import PropTypes from 'prop-types';
import mova from 'mova';
import { format } from 'date-fns';
import moment from 'moment';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import { connect } from 'react-redux';

const t = mova.ns('components.ReservationDetails');

const getReservationDate = (from) => format(new Date(from), 'dd.MM.yyyy');
const getReservationTime = (from, timezone) => moment.tz(from, timezone).format('HH:mm');

function ReservationDetails({ reservation, timezone, place }) {
  return (
    <div className='reservation-details'>
      <div className='reservation-details__item'>
        <SvgIcon className='reservation-details__icon' type='team' />
        {reservation.seats} {t('persons')}
      </div>
      <div className='reservation-details__item'>
        <SvgIcon className='reservation-details__icon' type='calendar' />
        {getReservationDate(reservation.from)}
      </div>
      <div className='reservation-details__item'>
        <SvgIcon className='reservation-details__icon' type='time' />
        {getReservationTime(reservation.from, timezone)}
        {!place.reservationAllDay && `-${getReservationTime(reservation.to, timezone)}`}
      </div>
    </div>
  );
}

ReservationDetails.propTypes = {
  reservation: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  place: PropTypes.object.isRequired,
};

ReservationDetails.defaultProps = {};

const mapStateToProps = ({ app }) => ({
  timezone: app.time.data.DEFAULT_TZ
});

export default memo(connect(mapStateToProps)(ReservationDetails));
