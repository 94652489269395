import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './ForgotPasswordModal.scss';
import TextInput from '@components/inputs/TextInput/TextInput';
import Button from '@components/inputs/Button/Button';
import Modal from '@components/feedback/Modal/Modal';
import Form from '@components/inputs/Form/Form';
import { connect } from 'react-redux';
import TextLink from '@components/navigation/TextLink/TextLink';
import { openModal } from '@actions/modal';
import { sendForgotPasswordRequest, sendForgotPasswordSms } from '@actions/user';
import { LOGIN_MODAL, NEW_PASSWORD_MODAL } from '@constants/modal';
import PhoneInput from '@components/inputs/PhoneInput/PhoneInput';
import { phoneRegexp } from '@utils/validation/validationUtils';
import Timer from '@components/feedback/Timer/Timer';

const t = mova.ns('containers.ForgotPasswordModal');
const errorsT = mova.ns('errors.validation');

function ForgotPasswordModal({ sendEmail, sendSms, showNewPasswordModal, showLoginModal }) {
  const [smsSent, setSmsSent] = useState(false);
  const [smsTimeout, setSmsTimeout] = useState(false);

  const submitPhone = (model) => {
    setSmsSent(true);
    setSmsTimeout(true);
    sendSms(model);
  };

  return (
    <Modal title={t('title')} className='forgot-password-modal'>
      <div className='forgot-password-modal__text'>{t('text')}</div>
      <Form onValidSubmit={sendEmail}>
        <div className='forgot-password-modal__row'>
          <TextInput
            name='email'
            placeholder={t('email')}
            fullWidth
            fontSize={14}
            validations='isEmail'
            validationErrors={{
              isEmail: errorsT('email'),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='forgot-password-modal__row'>
          <Button fullWidth size='medium' behavior='submit'>{t('send')}</Button>
        </div>
      </Form>
      <div className='forgot-password-modal__row forgot-password-modal__or'>
        {t('or')}
      </div>
      <Form onValidSubmit={submitPhone}>
        <div className='forgot-password-modal__text'>{t('textSms')}</div>
        <div className='forgot-password-modal__row'>
          <PhoneInput
            name='phone'
            fullWidth
            required
            validations={{
              matchRegexp: phoneRegexp
            }}
            validationErrors={{
              matchRegexp: errorsT('phone'),
              isDefaultRequiredValue: errorsT('required')
            }}
          />
        </div>
        <div className='forgot-password-modal__row'>
          <Button fullWidth size='medium' behavior='submit' disabled={smsSent && smsTimeout}>
            <>
              {smsSent ? t('sendAgain') : t('send')}
              {
                smsSent && smsTimeout &&
                <span>&nbsp;(<Timer millis={60000} onFinish={() => setSmsTimeout(false)} />)</span>
              }
            </>
          </Button>
        </div>
      </Form>
      <div className='forgot-password-modal__row'>
        <Button type='flat' fullWidth onClick={showNewPasswordModal}>
          {t('codeReceived')}
        </Button>
      </div>
      <div className='forgot-password-modal__back'>
        <TextLink onClick={showLoginModal} size='small' type='success'>{t('back')}</TextLink>
      </div>
    </Modal>
  );
}

ForgotPasswordModal.propTypes = {
  sendEmail: PropTypes.func.isRequired,
  sendSms: PropTypes.func.isRequired,
  showLoginModal: PropTypes.func.isRequired,
  showNewPasswordModal: PropTypes.func.isRequired,
};

ForgotPasswordModal.defaultProps = {};

const mapDispatchToProps = {
  sendEmail: sendForgotPasswordRequest,
  sendSms: sendForgotPasswordSms,
  showLoginModal: () => openModal(LOGIN_MODAL),
  showNewPasswordModal: () => openModal(NEW_PASSWORD_MODAL),
};

export default memo(connect(null, mapDispatchToProps)(ForgotPasswordModal));
