import { loadData } from '@utils/redux/actionUtils';
import { get, isDefined } from '@utils/lo/lo';
import api from '@api';
import {
  RESERVATION_LOADING,
  SAVE_QUICK_DATE,
  SAVE_QUICK_SEATS,
  SAVE_RESERVATION,
  SELECT_NEXT_DAY,
  ACTIVE_RESERVATIONS_LOADING,
  OLD_RESERVATIONS_LOADING,
  SAVE_ACTIVE_RESERVATIONS,
  SAVE_OLD_RESERVATIONS,
} from '@constants/reservation';
import { getAvailableSlots } from './place';
import history from '@store/History';
import store from '@store/Store';
import moment from 'moment';
import { closeModal, openModal } from './modal';
import { baseUrlPath } from '@utils/url/urlUtils';
import queryString from 'query-string';
import { RESERVATION_SUCCESS_MODAL } from '@constants/modal';

export const addReservation = (placeUri, time, spots) => () => {
  const currentState = store.getState();
  const { seats, date } = currentState.reservation.quick;
  const selectedTime = `${moment(date).format('YYYY-MM-DD ')}${time}`;

  const apiRequest = get(api, 'reservations.add');
  const body = {
    from: selectedTime,
    seats,
  };

  if (spots) {
    body.spots = spots.map(spot => ({ id: spot.id }));
    body.hallId = spots[0].hallId;

    if (spots.some(spot => spot.selectedDepositType)) {
      body.depositUpdated = true;
      body.depositOrder = spots
        .filter(spot => spot.selectedDepositType)
        .map(spot => ({ count: 1, depositId: spot.selectedDeposit.id, depositTypeId: spot.selectedDepositType.id }));
    }
  }

  apiRequest(placeUri, body).then(response => {
    history.push(`${baseUrlPath()}/finish/${placeUri}/${response.data.id}`);
  });
};

export const getReservation = (placeUri, reservationId) => (
  loadData('reservations.getOne', { placeUri, reservationId })(RESERVATION_LOADING, SAVE_RESERVATION)
);

export const getActiveReservations = () => {
  const { lat, lng } = get(store.getState(), 'user.location.data');

  const params = {};

  if (lat > 0) {
    params.lat = lat;
    params.lng = lng;
  }

  return loadData('reservations.active', params)(ACTIVE_RESERVATIONS_LOADING, SAVE_ACTIVE_RESERVATIONS);
};

export const countActiveReservations = () => loadData('reservations.countActive')(ACTIVE_RESERVATIONS_LOADING, SAVE_ACTIVE_RESERVATIONS);

export const getOldReservations = () => {
  const { lat, lng } = store.getState().user.location.data;

  const params = {};

  if (lat > 0) {
    params.lat = lat;
    params.lng = lng;
  }

  return loadData('reservations.old', params)(OLD_RESERVATIONS_LOADING, SAVE_OLD_RESERVATIONS);
};

export const confirmReservation = (placeUri, reservation, formData) => (dispatch) => {
  const currentState = store.getState();
  const isAuthenticated = isDefined(currentState.user.details.data?.user);
  const apiRequest = get(api, 'reservations.confirm');

  apiRequest(placeUri, reservation.id, formData)
    .then(() => {
      if (isAuthenticated) {
        history.push(`${baseUrlPath()}/reservations`);
      } else {
        history.push(`${baseUrlPath()}/places/${placeUri}`);
        dispatch(openModal(RESERVATION_SUCCESS_MODAL));
      }
    });
  if (process.env.NODE_ENV === 'production') {
    window.dataLayer.push({
      event: 'reservation',
      place: placeUri
    });
  }
};

export const saveQuickSeats = (seats, id) => dispatch => {
  dispatch({ type: SAVE_QUICK_SEATS, payload: seats });
  dispatch(getAvailableSlots(id));
};

export const saveQuickDate = (date, id) => dispatch => {
  dispatch({ type: SAVE_QUICK_DATE, payload: date });
  dispatch(getAvailableSlots(id));
};

export const selectNextDay = (id) => dispatch => {
  dispatch({ type: SELECT_NEXT_DAY });
  dispatch(getAvailableSlots(id));
};

export const cancelReservation = (placeUri, reservationId, model) => dispatch => {
  const { rows } = store.getState().reservation.active.data;

  const apiRequest = get(api, 'reservations.cancel');
  apiRequest(placeUri, reservationId, model)
    .then(() => {
      dispatch({
        type: SAVE_ACTIVE_RESERVATIONS,
        payload: {
          rows: rows.filter(r => r.id !== reservationId)
        }
      });
      dispatch(closeModal());
    });
};

export const updateQuickReservationParams = () => dispatch => {
  const params = queryString.parse(window.location.search);

  if (params.seats) {
    dispatch({ type: SAVE_QUICK_SEATS, payload: Number(params.seats) });
  }

  if (params.time) {
    dispatch({ type: SAVE_QUICK_DATE, payload: new Date(params.time) });
  }
};
