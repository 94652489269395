import { connect } from 'react-redux';
import MyReservations from './MyReservations';
import { getActiveReservations } from '@actions/reservation';
import { get } from '@utils/lo/lo';

const mapStateToProps = ({ reservation }) => ({
  reservations: get(reservation, 'active.data.rows') || [],
  loading: reservation.active.isLoading || (!reservation.active.data && !reservation.active.error)
});

const mapDispatchToProps = {
  getActiveReservations
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReservations);
