import { createApiClient } from './utils';

export const v1 = createApiClient({
  baseURL: '/api/v1',
});

export const chatbotV1 = createApiClient({
  baseURL: '/chatbot/v1',
});

export default {
  v1,
  chatbotV1,
};
