import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import store from '@store/Store';
import history from '@store/History';
import PagesRouter from '@pages/Router';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import '@theme/styles/index.scss';
import { DEFAULT_LANG } from '@constants/app';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import seo from './utils/seo';

const registerIncolorScript = () => {
  setTimeout(() => {
    const script = document.createElement('script');
    script.src = '//messengers.incolor.agency/messengers.php?c=113&lang=ua';
    script.async = true;
    document.body.appendChild(script);
  }, 5000);
};

(function main() {
  let lang;

  if (window.location.pathname.startsWith('/ru')) {
    lang = 'ru';
  } else if (window.location.pathname.startsWith('/en')) {
    lang = 'en';
  } else if (window.location.pathname.startsWith('/es')) {
    lang = 'es';
  } else {
    lang = DEFAULT_LANG;
  }

  i18n.init(lang);

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],
    environment: window.location.hostname,
  });

  ReactDOM.render(
    <Router history={history}>
      <Provider store={store}>
        <Helmet>
          <title>{seo.title[lang]}</title>
        </Helmet>
        <PagesRouter />
      </Provider>
    </Router>,
    document.getElementById('root'),
  );
  registerIncolorScript();
}());

serviceWorker.unregister();
