import React, { memo } from 'react';
import mova from 'mova';
import Button from '../../components/inputs/Button/Button';
import { baseUrlPath } from '@utils/url/urlUtils';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import failIcon from '../../theme/icons/payment/fail.svg';

const t = mova.ns('pages.Payment');

const PaymentLinkExpired = ({ place, history }) => {
  const goToPlace = () => {
    history.push(`${baseUrlPath()}/places/${place?.uri}`);
  };

  return (
    <div className='link-expired'>
      <img src={failIcon} width={80} height={80} alt='' />
      <div>{t('linkExpired')}</div>
      <div>{t('tryAgain')}</div>
      {place && <Button size='large' onClick={goToPlace}>{t('tryAgainButton')}</Button>}
    </div>
  );
};

PaymentLinkExpired.propTypes = {
  place: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default memo(withRouter(PaymentLinkExpired));
