import React from 'react';
import Colors from '@data/enums/Color.enum';

export function info() {
  return (
    <svg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='7' cy='7' r='7' fill={Colors.COLOR_LINK_MAIN} />
      <path
        d='M6.25 10.4219C6.25 10.6208 6.32902 10.8116 6.46967 10.9522C6.61032 11.0929 6.80109 11.1719 7 11.1719C7.19891 11.1719 7.38968 11.0929 7.53033 10.9522C7.67098 10.8116 7.75 10.6208 7.75 10.4219C7.75 10.223 7.67098 10.0322 7.53033 9.89155C7.38968 9.75089 7.19891 9.67188 7 9.67188C6.80109 9.67188 6.61032 9.75089 6.46967 9.89155C6.32902 10.0322 6.25 10.223 6.25 10.4219ZM6.625 8.45312H7.375C7.42656 8.45312 7.46875 8.41094 7.46875 8.35938V2.92188C7.46875 2.87031 7.42656 2.82812 7.375 2.82812H6.625C6.57344 2.82812 6.53125 2.87031 6.53125 2.92188V8.35938C6.53125 8.41094 6.57344 8.45312 6.625 8.45312Z'
        fill='white'
      />
    </svg>
  );
}
