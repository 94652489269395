import { connect } from 'react-redux';
import CancelReservationModal from './CancelReservationModal';
import { cancelReservation } from '@actions/reservation';
import { get } from '@utils/lo/lo';

const mapStateToProps = ({ modal }) => ({
  reservationId: get(modal, 'params.reservationId'),
  placeUri: get(modal, 'params.placeUri')
});

const mapDispatchToProps = {
  confirmCancelReservation: (placeUri, reservationId, model) => cancelReservation(placeUri, reservationId, model)
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelReservationModal);
