import mova from 'mova';
import { DEFAULT_LANG } from '@data/store/constants/app';
import en from './en.i18n';
import ru from './ru.i18n';
import ua from './ua.i18n';
import es from './es.i18n';

const langOptions = {
  ru,
  en,
  ua,
  es,
};

export default {
  init(lang) {
    mova.setLanguage(langOptions[lang] || langOptions[DEFAULT_LANG]);
  },
};
