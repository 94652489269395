import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';

const t = mova.ns('components.TimeTags');

function TimeTag(props) {
  const { time, onClick, more } = props;

  return (
    <div
      className={`time-tag ${more ? 'time-tag--more' : ''}`}
      onClick={onClick}
      onKeyPress={onClick}
      role='button'
      tabIndex={-1}
    >
      {more ? t('more') : time}
    </div>
  );
}

TimeTag.propTypes = {
  time: PropTypes.string,
  more: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

TimeTag.defaultProps = {
  time: undefined,
  more: false
};

export default memo(TimeTag);
