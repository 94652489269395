import Immutable from '@utils/immutable/Immutable';
import { SAVE_REGISTRATION_DATA } from '@constants/user';

const initialState = {
  data: {}
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SAVE_REGISTRATION_DATA: {
      return Immutable({ ...state, data: action.payload });
    }
    default:
      return state;
  }
};
