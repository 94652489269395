export default {
  name: 'ru',
  base: {
    cancel: 'Отменить',
    signIn: 'Войти',
    signUp: 'Регистрация',
    signOut: 'Выход',
    submitReservationForm: 'Забронировано для <=seats>',
    submitFilterForm: 'Поиск...',
    save: 'Сохранить изменения',
    anycafe: 'RESERVBLE',
    book: 'Забронировать',
    selectTime: 'Выберите время',
    or: 'Или',
    createAccount: 'Создать аккаунт',
    back: 'Назад',
    ok: 'Ок',
    total: 'Общая сумма',
  },
  cities: {
    odessa: 'Одесса',
    kyiv: 'Киев',
    ternopil: 'Тернополь',
    dnipro: 'Днепр',
    lviv: 'Львов',
    kharkiv: 'Харьков',
    uzhhorod: 'Ужгород',
    bilatserkv: 'Белая Церковь',
    chernihiv: 'Чернигов',
  },
  currencies: {
    uah: 'грн'
  },
  types: {
    likes: {
      add: 'Вы добавили <=place> в избранное',
      delete: 'Вы убрали <=place> из избранного',
      addDish: 'Блюдо добавлено в избранное',
      deleteDish: 'Блюдо удалено из избранного',
    },
    user: {
      phone: 'Телефон',
      phoneLower: 'телефон',
      email: 'Е-мейл',
      emailLower: 'е-мейл',
      password: 'Пароль',
      newPassword: 'Новый пароль',
      repeatPassword: 'Повторите пароль',
      name: 'Имя',
      surname: 'Фамилия',
      rememberMe: 'Запомнить меня',
    },
    reservation: {
      '@extends': 'types.user',
      button: 'Забронировать',
      comment: 'У Вас есть пожелания?',
      date: 'Дата/Время',
      status: {
        REQUESTED: 'В процессе',
        ACCEPTED: 'Подтверждено',
        FINISHED: 'Завершено',
        CANCELED: 'Отменено',
        SKIPPED: 'Пропущено',
        REJECTED: 'Отказано'
      }
    },
    filters: {
      city: 'Выберите город',
    },
    feedback: {
      thankYou: 'Спасибо за Ваш отзыв',
    },
    join: {
      thankYou: 'Спасибо за Ваш запрос. Наш администратор свяжется с Вами в ближайшее время',
    }
  },

  errors: {
    '@root': 'Произошла ошибка',
    '@any': '<.>',
    error: 'Ошибка',
    server: 'На сервере произошла неизвестная ошибка',
    validation: {
      '@root': 'Неправильный формат',
      '@any': '<.>',
      required: 'Поле обязательно для заполнения',
      email: 'Введите корректный <types.user.emailLower>',
      phone: 'Введите корректный <types.user.phoneLower>',
      minLength: {
        1: 'Минимум <=symbols> символ',
        2: 'Минимум <=symbols> символа',
        5: 'Минимум <=symbols> символов',
      },
      maxLength: {
        1: 'Максимум <=symbols> символ',
        2: 'Максимум <=symbols> символа',
        5: 'Максимум <=symbols> символов',
      },
      minNumber: 'Минимальное значение <=min>',
      maxNumber: 'Максимальное значение <=max>',
      repeatPassword: 'Пароли не совпадают'
    },
    fields: {
      email: '<types.user.emailLower>ом',
      phone: 'телефоном',
      notUniq: 'Пользователь с таким <=field> существует в системе'
    },
    common: {
      unauthorized: 'Данной действие может выполнить только авторизованный пользователь',
      wrongAuth: 'Указан неправильный телефон или пароль. Проверьте корректность введенных данных',
      notFound: 'Запрашиваемый объект не найден',
      oldPasswordIsInvalid: 'Текущий пароль указан неправильно. Попробуйте еще раз',
      justVisitorCanLeaveAComment: 'Комментарий можно оставить только после окончания подтвержденного резерва',
      userWithSpecifiedEmailNotFound: 'Пользователь с указанным адресом не найден',
    },
    join: 'На сервере возникла неизвестная ошибка. Отправьте, пожалуйста, сообщение нашему администратору на телефон, указанный внизу страниці',
  },

  containers: {
    SearchBar: {
      placeholder: 'Название, кухня, тип, адрес',
      search: 'Поиск',
      useAi: 'Использовать магию AI',
    },
    Header: {
      signIn: '<base.signIn>',
      signUp: '<base.signUp>',
      signOut: '<base.signOut>',
      placeOwner: 'Рестораторам',
      join: 'Подключить заведение',
    },

    SortResults: {
      sortBy: 'Сортировать по:',
      sort: {
        alphabet: 'Алфавиту',
        rating: 'Рейтингу',
        nearest: 'Ближайшие',
        expensive: 'Самые дорогие',
        cheap: 'Самые дешевые',
        free: 'Больше всего свободных мест',
      }
    },

    UserFeedbacks: {
      empty: 'У Вас пока нет отзывов',
      moreReviews: 'Показать еще <=pageSize> отзывов'
    },

    MailingPreferences: {
      notifications: 'Присылать мне на почту уведомления',
      discounts: 'Присылать мне на почту новости о скидках',
      selections: 'Присылать мне на почту подборки ресторанов',
      save: '<base.save>'
    },

    UserPreferences: {
      city: 'Основной город',
      cityPlaceholder: 'Ваш основной город проживания',
      allergy: 'Непереносимости / Аллергии',
      allergyPlaceholder: 'Укажите любые непереносимости или аллергии',
      favoriteCuisine: 'Любимые кухни',
      favoriteCuisinePlaceholder: 'Расскажите какие кухни Вы любите',
      favoriteDishes: 'Любимые блюда',
      favoriteDishesPlaceholder: 'Расскажите о Ваших любимых блюдах',
      unliked: 'Нелюбимые ингредиенты / блюда',
      unlikedPlaceholder: 'Расскажите что Вам не нравится',
      save: '<base.save>'
    },

    UserProfile: {
      photo: {
        delete: 'Удалить фото',
        new: 'Новое фото',
      },
      info: 'Инфо',
      stats: {
        cities: {
          1: 'город',
          2: 'города',
          5: 'городов'
        },
        visits: {
          1: 'посещение',
          2: 'посещения',
          5: 'посещений'
        },
        rates: {
          1: 'оценка',
          2: 'оценки',
          5: 'оценок'
        },
        feedbacks: {
          1: 'отзыв',
          2: 'отзыва',
          5: 'отзывов'
        }
      },
      achievement: {
        rates: 'оценок на <base.anycafe>',
        bookings: 'бронирований с <base.anycafe>'
      },
      data: {
        name: '<types.user.name>',
        namePlaceholder: 'Введите Ваше имя',
        surname: '<types.user.surname>',
        surnamePlaceholder: 'Введите Вашу фамилию',
        phone: '<types.user.phone>',
        email: '<types.user.email>',
        emailPlaceholder: 'Введите Ваш <types.user.emailLower>',
        password: '<types.user.password>',
        passwordPlaceholder: 'Введите Ваш пароль',
        confirmPassword: 'Подтверждение пароля',
        confirmPasswordPlaceholder: 'Подтвердите Ваш пароль',
        newPassword: '<types.user.newPassword>',
        newPasswordPlaceholder: '<types.user.newPassword>',
      },
      social: {
        link: 'Подключить соцсети',
        linked: 'подключен',
        notLinked: 'не подключен',
        linkButton: 'Подключить',
        unlinkButton: 'Отключить'
      },
      save: '<base.save>'
    },

    QuickReservation: {
      book: '<base.book>',
      selectTime: '<base.selectTime>',
      noOptions: 'К сожалению, на выбранное время свободных мест нет',
      nextDay: 'Проверить следующий день'
    },

    TopUserMenu: {
      notifications: 'Уведомления',
      currentReserve: 'Текущая бронь',
      history: 'История',
      favorites: 'Сохраненные',
      profile: 'Профиль',
      logout: '<base.signOut>'
    },

    FinishRegistrationModal: {
      lastStep: 'Остался один шаг для завершения регистрации :)',
      password: '<types.user.password>',
      repeatPassword: '<types.user.repeatPassword>',
      rememberMe: '<types.user.rememberMe>',
      createAccount: '<base.createAccount>'
    },

    AskPhoneModal: {
      lastStep: 'Остался один шаг для завершения Вашего резерва :)',
      text: 'Укажите Ваш телефон, чтобы администратор смог с Вами связаться в случае необходимости',
      continue: 'Продолжить'
    },

    LoginRegisterModal: {
      signInTitle: 'Пожалуйста, войдите в <base.anycafe>',
      signUpTitle: 'Добро пожаловать в <base.anycafe>',
      or: '<base.or>',
      name: '<types.user.name>',
      surname: '<types.user.surname>',
      email: '<types.user.email>',
      password: '<types.user.password>',
      repeatPassword: '<types.user.repeatPassword>',
      rememberMe: '<types.user.rememberMe>',
      forgotPassword: 'Забыли пароль?',
      signIn: '<base.signIn>',
      signUp: '<base.createAccount>',
      newUserQuestion: 'Вы новенький?',
      haveAccountQuestion: 'Уже зарегистрированы?',
      signUpLink: 'Регистрация',
      signInLink: 'Логин'
    },

    PromoSuccessModal: {
      congrats: 'Поздравляем!',
      defaultMessage: 'Вы принимаете участие в промо-акции'
    },

    CancelReservationModal: {
      title: 'Отмена бронирования',
      why: 'Пожалуйста, укажите, почему Вы хотите отменить бронирование',
      changeOfPlans: 'Изменились планы',
      mistake: 'Бронирование было создано по ошибке',
      late: 'Не успеваю на указанное время',
      changeTime: 'Хочу выбрать другое время',
      other: 'Прочее',
      cancel: '<base.cancel>'
    },

    AllowGeolocationModal: {
      title: 'Доступ к данным о геолокации',
      text: 'Чтобы иметь возможность показывать Вам более релевантные результаты поиска и расстояние до ресторана, предоставьте, пожалуйста, приложению доступ к Вашей геолокации',
      ok: '<base.ok>',
    },

    FeedbackForm: {
      whatAbout: 'Как Вам?',
      comment: 'Ваш комментарий:',
      commentPlaceholder: 'Как вам заведение? (не обязательно)',
      cancel: '<base.cancel>',
      send: 'Отправить',
    },

    ForgotPasswordModal: {
      title: 'Восстановить пароль',
      text: 'Введите Ваш <types.user.emailLower>',
      textSms: 'Введите Ваш <types.user.phone>',
      email: '<types.user.email>',
      send: 'Отправить',
      sendAgain: 'Отправить снова',
      back: '<base.back>',
      or: '<base.or>',
      codeReceived: 'Я получил SMS с кодом',
    },

    NewPasswordModal: {
      title: 'Изменение пароля',
      text: 'Введите код из SMS',
      back: '<base.back>',
      code: 'Код из SMS',
      send: 'Отправить',
      newPassword: '<types.user.newPassword>',
      repeatPassword: '<types.user.repeatPassword>',
      successPasswordChange: 'Пароль был успешно изменен',
    },

    ChangeCityPopup: {
      yourCity: 'Ваш город',
      yes: 'Да',
      no: 'Нет, оставить',
      cities: {
        '@extends': 'cities'
      },
    },

    ReservationSuccessModal: {
      title: 'Поздравляем!',
      p1: 'Место забронировано! После подтверждения администратором вы получите SMS уведомление.',
      p1noSms: 'Место было успешно забронирован! Если у нас возникнут какие-либо вопросы – мы Вам позвоним.',
      p2: 'Зарегистрируйтесь, чтобы получать больше преимуществ от Reservble.',
      register: 'Зарегистрироваться',
    },

    CartModal: {
      title: 'Ваш заказ',
      total: '<base.total>',
      uah: '<currencies.uah>',
      empty: 'Вы пока не выбрали ни одного блюда',
      clear: 'Очистить заказ',
    },

    Footer: {
      privacyPolicy: '<pages.Policy.privacy>',
      cookiePolicy: '<pages.Policy.cookies>',
      paymentsPolicy: '<pages.Policy.payments>',
      nearMe: 'Рестораны возле меня',
      openNow: 'Рестораны открытые сейчас',
      topRated: 'Популярные рестораны',
      areYouRestaurant: 'Вы владелец ресторана?',
      pricing: 'Тарифы и цены',
      requestDemo: 'Запросить демо',
    },

    SelectSpotModal: {
      title: 'Выбор места',
      book: '<base.book>',
      skip: 'Пропустить',
      uah: '<currencies.uah>',
      selectDepositType: 'Выберите тип',
    },
  },

  components: {
    Feedback: {
      review: 'Ваш отзыв',
      alreadyLeft: 'Вы уже оставили отзыв',
      empty: 'Об этом заведении еще не оставляли отзывы'
    },
    Success: {
      successMsg: 'Успешно!',
    },
    InfoTags: {
      more: 'Еще'
    },
    TimeTags: {
      more: 'Еще'
    },
    CookieFooter: {
      message: 'Мы используем файлы cookie для обеспечения наилучшей работы нашего сервиса. Используя этот сайт, Вы принимаете использования файлов cookie.',
      learnMore: 'Узнать больше',
      okText: 'Ок'
    },
    ViewSelector: {
      map: 'Карта',
      tiles: 'Плитка',
    },
    Distance: {
      m: 'м',
      km: 'км'
    },
    PlaceCard: {
      cancelReservation: 'Снять бронь',
      reservationsToday: {
        1: 'человек забронировал сегодня',
        2: 'человека забронировали сегодня',
        5: 'человек забронировали сегодня',
      },
    },
    QueryResult: {
      results: {
        1: 'заведение найдено',
        2: 'заведения найдено',
        5: 'заведений найдено',
      },
    },
    SocialLoginButton: {
      text: 'Войти через <=provider>',
      google: 'Google',
      facebook: 'Facebook'
    },
    PlaceContacts: {
      address: 'Адрес:',
      contacts: 'Контакты:',
      webSite: 'Веб-сайт:'
    },
    DetailedRating: {
      heading: 'Рейтинг заведения',
      kitchen: 'Кухня',
      interior: 'Интерьер',
      service: 'Сервис',
      atmosphere: 'Атмосфера',
      noFeedbacks: '<components.Feedback.empty>',
      leaveFeedback: '<pages.PlaceInfo.leaveFeedback>',
      beTheFirst: '. Будьте первыми!'
    },
    PersonsSelector: {
      placeholder: 'Человек',
      suffix: 'чел.',
      guests: 'Гости',
    },
    ReservationDetails: {
      persons: 'чел.'
    },
    DishCard: {
      uah: '<currencies.uah>',
      like: 'Мне нравится',
    },
    ViewOrder: {
      view: 'Просмотреть заказ',
      uah: '<currencies.uah>'
    },
    Calendar: {
      date: 'Дата',
    },
    TimeSelector: {
      time: 'Время',
    },
    CertificatesModal: {
      title: 'Сертификаты',
      buy: 'Купить',
      uah: '<currencies.uah>',
      subscription: 'Абонемент',
      expand: 'Развернуть',
      days: {
        1: 'день',
        2: 'дня',
        5: 'дней',
      },
      visits: {
        1: 'посещение',
        2: 'посещения',
        5: 'посещений',
      },
    },
  },

  pages: {
    Search: {
      noResults: 'По вашему запросу не найдено ресторанов',
      topLabel: 'Один поиск, множество возможностей',
    },

    Favorites: {
      empty: 'У Вас пока нет любимых мест',
      favorites: 'Вы добавили',
      places: {
        1: 'заведение в избранное',
        2: 'заведения в избранное',
        5: 'заведений в избранное',
      }
    },

    Notifications: {
      youHave: 'У Вас',
      notifications: {
        1: 'новое уведомление',
        2: 'новых уведомления',
        5: 'новых уведомлений'
      },
      new: 'Новые',
      read: 'Прочитанные',
      reservation: {
        reserv: 'Ваша бронь в',
        atDate: 'на',
        for: 'для',
        persons: {
          1: 'человека',
          2: 'человек',
          5: 'человек',
        },
        confirmed: 'потверждена',
        rejected: 'отклонена',
        awaiting: 'ожидает подтверждения',
        cancelled: 'была отменена',
        finished: 'была успешно завершена. Ждем Вас снова!',
        skipped: 'была отмечена как пропущенная',
        message: '. Сообщение от администратора: "<=message>"',
        didYouLike: 'Понравился ресторан?',
        leaveFeedback: 'Оставьте отзыв!'
      }
    },

    DemoStub: {
      message: '<base.anycafe> бета-тестирование',
      open: 'Официальное открытие',
      openDate: '1 февраля 2020 года',
      countdown: {
        days: 'дней',
        hours: 'часов',
        minutes: 'минут',
        seconds: 'секунд'
      }
    },

    Error: {
      error404: 'Извините, запрашиваемая страница не найдена',
      error500: {
        message: 'Вы поломали интернет!',
        details: 'Это шутка, похоже у нас небольшая неполадка на сервере, повторите Ваш запрос через несколько минут'
      },
      entity404: 'Запрашиваемый <=entity> не найден',
      reservationNotDraft: 'Данное бронирование уже было оформлено',
      goToReservations: 'Перейти на страницу "<containers.TopUserMenu.currentReserve>"',
      goHome: 'Вернуться на главную страницу',
    },

    Profile: {
      profile: 'Мои данные',
      preferences: 'Предпочтения',
      feedback: 'Мои отзывы',
      mailing: 'Рассылки',
      update: 'Данные успешно обновлены'
    },

    FinishReservation: {
      almost: 'Почти закончили!',
      timer: {
        prefix1: 'Мы удерживаем место для Вас',
        prefix2: 'в течение',
        suffix: 'минут'
      },
      or: '<base.or>',
      name: '<types.user.name>',
      surname: '<types.user.surname>',
      email: '<types.user.email>',
      selectHall: 'Вы можете выбрать зал:',
      depositOptions: 'Дополнительные опции:',
      depositsRequired: 'Необходимо выбрать хотя бы одну дополнительную опцию для создания бронирования',
      certificate: 'Сертификат',
      certificateId: 'ID сертификата/абонемента',
      certificateNotFound: 'Сертификат с таким номером не найден',
      uah: '<currencies.uah>',
      total: '<base.total>',
      comment: 'Ваш комментарий',
      subscribe: 'Подписаться на рассылку',
      notifications: 'Получать напоминания',
      buttonName: '<types.reservation.button>',
      reservation: 'резерв',
      back: 'Назад к заведению',
      submit: '<types.reservation.button>',
      selectedHall: 'Выбранный зал',
      selectedSpots: 'Выбранные места',
      deposits: 'Депозиты',
    },

    Payment: {
      reservation: 'резерв',
      title: 'Оплата депозита',
      uah: '<currencies.uah>',
      success: 'Оплата успешна!',
      back: 'Назад к заведению',
      linkExpired: 'Ваша ссылка на оплату просрочена.',
      tryAgain: 'Попробуйте забронировать снова',
      tryAgainButton: 'Забронировать снова',
      orderNumber: 'Номер заказа',
      paymentTime: 'Время оплаты',
      amount: 'Сумма',
      waitFormLoad: 'Дождитесь загрузки формы оплаты не перезагружая страницу',
      payWithPortmone: 'Оплатить через Portmone.com',
    },

    PlaceInfo: {
      cuisine: 'Кухня',
      additional: 'Дополнительно',
      payment: 'Возможности оплаты',
      certificates: 'Сертификаты и абонементы',
      view: 'Просмотреть',
      info: 'Інформация',
      menu: 'Меню',
      likedCategory: 'Любимое',
      description: 'Описание',
      comments: 'Комментарии',
      status: {
        open: 'Открыто',
        closed: 'Закрыто'
      },
      closing: 'Закрывается:',
      opening: 'Открывается:',
      averageBill: 'Средний чек',
      place: 'ресторан',
      leaveFeedback: 'Оставить отзыв',
      moreReviews: 'Показать еще <=pageSize> отзывов',
      paidServices: 'Дополнительные услуги',
      viewPaidServices: 'Просмотреть услуги',
    },

    PlaceFeedback: {
      leaveFeedback: 'Оставьте отзыв для',
      place: 'ресторан',
      user: 'пользователь',
    },

    MyReservations: {
      summary: {
        booked: {
          1: 'Забронирован',
          2: 'Забронировано',
          5: 'Забронировано',
        },
        tables: {
          1: 'место',
          2: 'места',
          5: 'мест',
        },
        pending: {
          1: ', ожидает подтверждения',
          2: ', ожидают подтверждения',
          5: ', ожидают подтверждения'
        }
      },
      inProcess: 'Ожидает подтверждения',
      confirmed: 'Подтверждено',
      empty: 'Активных бронирований не найдено'
    },

    ReservationHistory: {
      summary: {
        visits: 'Вы были в',
        reservations: {
          1: 'заведении',
          2: 'заведениях',
          5: 'заведениях',
        }
      },
      empty: 'Предыдущих бронирований не найдено'
    },

    Policy: {
      privacy: 'Политика конфиденциальности',
      privacyGeneric: 'Политикой конфиденциальности',
      cookies: 'Политика использования файлов куки',
      cookiesGeneric: 'Политикой использования файлов куки',
      payments: 'Политика проведения оплат',
      meetings: 'Договор оферта встречи',
    },

    GptPlaceSearch: {
      back: 'На главную',
      title: 'Используем магию AI',
      firstMessage: 'Привет, я чат Reservble AI. Напиши блюдо, кухню, напиток или впечатления, которые хочешь получить',
      placeholderMessages: 'Спроси меня: "Где поесть стейк?"',
    },

    BuyCertificate: {
      title: 'Выбор сертификата',
      checkPhone: 'Это поле является обязательным. Внимательно проверьте указанный номер, мы отправляем Вам сертификаты с помощью СМС',
      payWithPortmone: 'Оплатить через Portmone.com',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
    }
  },

  actions: {
    user: {
      forgotPasswordSent: 'Ссылка для восстановления пароля успешно отправлена. Проверьте Ваш <types.user.emailLower>',
      forgotSmsSent: 'Мы отправили Вам SMS сообщение с кодом',
      passwordChanged: 'Пароль был успешно изменен',
    }
  },

  legal: {
    heading: '<pages.Policy.privacy>',
    confirmation: {
      '@extends': 'pages.Policy',
      confirm1: 'Нажимая на кнопку <=buttonName>, Вы соглашаетесь с',
      confirm2: ', а также нашей',
      confirm3: ', включая',
    }
  }
};
