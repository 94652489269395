import React from 'react';

export function pin() {
  return (
    <svg viewBox='0 0 25 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M25 12.5C24.3421 24.3421 13.898 22.6974 12.5 29.852C11.4309 22.6974 0 24.0132 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z' fill='url(#paint0_linear_357_3732)' />
      <path d='M15.7083 20.3737C18.8124 19.1076 21 16.0593 21 12.5C21 7.80558 17.1944 4 12.5 4C7.80558 4 4 7.80558 4 12.5C4 15.8814 5.97445 18.8016 8.83333 20.1707V14.9856C8.31571 14.8785 7.85049 14.5936 7.5164 14.1791C7.18232 13.7645 6.99989 13.2457 7 12.7106V9.46429C7 9.34115 7.04829 9.22306 7.13424 9.13599C7.2202 9.04892 7.33678 9 7.45833 9C7.57989 9 7.69647 9.04892 7.78242 9.13599C7.86838 9.22306 7.91667 9.34115 7.91667 9.46429V12.7106C7.91652 12.9987 8.0046 13.2798 8.16878 13.5152C8.33295 13.7505 8.56514 13.9284 8.83333 14.0245V9.46429C8.83333 9.34115 8.88162 9.22306 8.96758 9.13599C9.05353 9.04892 9.17011 9 9.29167 9C9.41322 9 9.5298 9.04892 9.61576 9.13599C9.70171 9.22306 9.75 9.34115 9.75 9.46429V14.0245C10.0182 13.9284 10.2504 13.7505 10.4146 13.5152C10.5787 13.2798 10.6668 12.9987 10.6667 12.7106V9.46429C10.6667 9.34115 10.715 9.22306 10.8009 9.13599C10.8869 9.04892 11.0034 9 11.125 9C11.2466 9 11.3631 9.04892 11.4491 9.13599C11.535 9.22306 11.5833 9.34115 11.5833 9.46429V12.7106C11.5834 13.2457 11.401 13.7645 11.0669 14.1791C10.7328 14.5936 10.2676 14.8785 9.75 14.9856V20.5453C10.6126 20.8401 11.5376 21 12.5 21C13.294 21 14.0626 20.8911 14.7917 20.6875V16.3775C13.4909 16.0822 12.5 14.5547 12.5 12.7143C12.5 10.6621 13.7311 9 15.25 9C16.7689 9 18 10.6621 18 12.7143C18 14.5547 17.0091 16.0822 15.7083 16.3775V20.3737Z' fill='white' />
      <path d='M17.0833 12.7143C17.0833 14.3021 16.196 15.5 15.25 15.5C14.304 15.5 13.4167 14.3021 13.4167 12.7143C13.4167 11.1264 14.304 9.92857 15.25 9.92857C16.196 9.92857 17.0833 11.1264 17.0833 12.7143Z' fill='white' />
      <defs>
        <linearGradient id='paint0_linear_357_3732' x1='12.5' y1='0' x2='12.5' y2='29.852' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#8EC245' />
          <stop offset='1' stopColor='#6F9736' />
        </linearGradient>
      </defs>
    </svg>
  );
}
