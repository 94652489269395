/* eslint-disable */

export default `
<p><strong>Esta página web utiliza archivos &laquo;cookies&raquo;</strong></p>
<p><span>Utilizamos archivos cookies para personalizar el contenido y la publicidad, integrar funciones de redes sociales y analizar nuestro tráfico. También compartimos información sobre su uso de nuestro sitio con nuestros socios de redes sociales, publicidad y análisis. Pueden combinarla con otra información que usted les haya proporcionado o que hayan recopilado de su uso de sus servicios. Usted acepta nuestro uso de archivos cookies si continúa utilizando este sitio web.</span></p>

<p><strong>Información general sobre archivos &laquo;cookies&raquo;</strong></p>
<p><span>Los archivos cookies son pequeños archivos de texto que las páginas web pueden utilizar para hacer más eficiente la experiencia del usuario.</span></p>
<p><span>Según la ley, podemos almacenar archivos cookies en su dispositivo si son estrictamente necesarios para el funcionamiento de esta página web. Para todos los demás tipos de cookies necesitamos su permiso.</span></p>
<p><span>Esta página web utiliza diferentes tipos de cookies. Algunos cookies son colocados por servicios de terceros que aparecen en nuestras páginas.</span></p>
<p><span>Usted puede cambiar o retirar su consentimiento de la Política de cookies en nuestro sitio web en cualquier momento.</span></p>
<p><span>Obtenga más información sobre nosotros, nuestra información de contacto y cómo procesamos los datos personales en nuestra <a href='https://reservble.com/privacy-policy' target='_blank'>Política de privacidad</a>.</span></p>

<p><strong>Cookies necesarios</strong></p>
<p><span>Los cookies necesarios ayudan a hacer una página web utilizable, permitiendo funciones básicas como la navegación por la página y el acceso a áreas seguras de la misma. La página web no puede funcionar adecuadamente sin estos archivos cookies.</span></p>

<p><strong>Cookies de preferencia</strong></p>
<p><span>Los cookies de preferencia permiten a una página web recordar información que cambia la forma en que se comporta o se ve la página, como su idioma preferido o la región en la que se encuentra.</span></p>

<p><strong>Cookies estadísticos</strong></p>
<p><span>Los cookies estadísticos ayudan a los propietarios de las páginas web a comprender cómo interactúan los visitantes con las páginas web recopilando y reportando información de manera anónima.</span></p>

<p><strong>Cookies de marketing</strong></p>
<p><span>Los cookies de marketing se utilizan para rastrear a los visitantes en las páginas web. La intención es mostrar anuncios que sean relevantes y atractivos para el usuario individual y, por lo tanto, más valiosos para los editores y anunciantes externos.</span></p>

<p><strong>Cookies no clasificados</strong></p>
<p><span>Los cookies no clasificados son cookies que estamos en proceso de clasificar, junto con los proveedores de cookies individuales.</span></p>
`;
