import React, { memo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Image from '@components/display/Image/Image';
import { Marker, Tooltip } from 'react-leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';
import { getPlaceCoordinates } from '@utils/place/placeUtils';
import SvgIcon from '../../SvgIcon/SvgIcon';

function PlaceMarker({ place, small, onClick }) {
  const { avatarUrl, name, donates } = place;

  const marker = small ?
    <SvgIcon type={donates?.length > 0 ? 'donatePin' : 'pin'} className='custom-marker__small' /> :
    <Image src={avatarUrl} alt={name} className='custom-marker__avatar' cover />;

  const iconMarkup = renderToStaticMarkup(marker);

  const customMarkerIcon = divIcon({
    html: iconMarkup,
    className: `leaflet-marker ${donates?.length > 0 ? 'leaflet-marker--priority' : ''}`,
  });

  const tooltipRef = useRef();

  const markerMouseOver = useCallback(() => {
    if (tooltipRef.current._container) {
      tooltipRef.current._container.classList.add('leaflet-tooltip--hovered');
    }
  }, [tooltipRef]);
  const markerMouseOut = useCallback(() => {
    if (tooltipRef.current._container) {
      tooltipRef.current._container.classList.remove('leaflet-tooltip--hovered');
    }
  }, []);

  const tooltipKey = place.id + (small ? 's' : '');

  return (
    <Marker
      icon={customMarkerIcon}
      className={`custom-marker custom-marker--place ${small && 'custom-marker--small'}`}
      position={getPlaceCoordinates(place)}
      eventHandlers={{
        click: onClick,
        mouseover: markerMouseOver,
        mouseout: markerMouseOut
      }}
    >
      <Tooltip key={tooltipKey} className={`place-tooltip ${small ? 'place-tooltip--small' : ''}`} ref={tooltipRef}>
        {place.name}
      </Tooltip>
    </Marker>
  );
}

PlaceMarker.propTypes = {
  place: PropTypes.object.isRequired,
  small: PropTypes.bool,
  onClick: PropTypes.func,
};

PlaceMarker.defaultProps = {
  small: false,
  onClick: undefined,
};

export default memo(PlaceMarker);
