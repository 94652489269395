import { connect } from 'react-redux';
import { closeModal } from '@actions/modal';
import PlaceFeedbackModal from './PlaceFeedbackModal';

const mapStateToProps = ({ modal }) => ({
  place: modal.params
});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceFeedbackModal);
