/* eslint-disable */

export default `
<h2>Визначення термінів</h2>
    <p><strong>Гість</strong> – фізична особа, користувач сервісу, що здійснює бронювання столів онлайн.</p>
    <p><strong>Виконавець</strong> – фізична особа-підприємець Носенко Володимир, що є власником прав на веб-ресурс <a href="https://reservble.com">https://reservble.com</a>, що надає послуги з обробки та розміщення інформації на веб-ресурсі.</p>
    <p><strong>Партнер</strong> – фізична особа-підприємець, або юридична особа, що підключена до веб-сервісу <a href="https://reservble.com">https://reservble.com</a>, приймає та обробляє резерви столів у власному кабінеті онлайн.</p>

    <h2>1. Послуги</h2>
    <h3>1.1. Оплата рахунку</h3>
    <p>Оплата рахунку, або проведення розрахунків на веб-ресурсах Виконавця, де Виконавець виступає посередником між Партнером та Portmone. Партнер зазначає вручну, або автоматично, за допомогою інтеграції по API з POS системою ресторану, суму рахунку, яка виставляється як рахунок до сплати Гостю. У разі зазначення вручну, сума рахунку вводиться при закритті резерву натисканням кнопки «Завершити резерв».</p>

    <h3>1.2. Оплата депозиту</h3>
    <p>Партнер, політика якого відповідає бронюванню столів виключно, або частково зі сплатою депозиту гостем, може скористатись послугою «Оплата депозиту» від Виконавця підключивши її в особистому кабінеті в розділі «Налаштування» - «Оплати».</p>
    <p>1.2.1. Послуга надає можливість Гостю, що планує завітати до Партнера при оформленні онлайн бронювання стола або після підтвердження онлайн бронювання стола адміністратором, сплатити Депозит, в розмірі, який зазначає Партнер у своїй політиці бронювання.</p>
    <p>1.2.2. Сума Депозиту у Партнера, що користується даною послугою, зазначається на сторінці профілю в Політиці Бронювання, щоб Гість, перед тим, як бронювати стіл, ознайомився з Політикою Бронювання і сумою Депозиту.</p>

    <h3>1.3. Політика повернення Депозиту</h3>
    <p>1.3.1. Загальна політика повернення Депозиту для всіх ресторанів, що підключені до веб-сервісу Виконавця:</p>
    <ul>
        <li>У разі відхилення резерву стола, що був попередньо підтверджений представником Партнера, менше ніж за 24 години до зазначеного часу в резерві стола, Виконавець утримує 100% суми Депозиту, що компенсує збитки Партнера.</li>
        <li>У разі відхилення резерву стола за 48-24 годин до зазначеного часу в резерві столу, Виконавець утримує 50% від Депозиту. 50% від Депозиту, що гарантовано повертаються Гостю, будуть надіслані протягом 7 робочих днів з дня відхилення резерву стола на картку, з якою була проведена оплата.</li>
    </ul>
    <p>1.3.2. Партнер залишає за собою право вносити зміни в загальну політику повернення Депозиту для збільшення або зменшення періоду, за який Депозит утримується в повному обсязі. У разі внесення змін в політику повернення Депозиту, нова політика буде розміщена на сторінці профілю ресторану. Строки утримання та повернення Депозиту зазначаються Партнером у власному кабінеті.</p>
    <p>1.3.3.Останнє вирішальне слово стосовно повернення Депозиту приймає Партнер. Виконавець не несе відповідальність за рішення Партнера стосовно утримання, або повернення Депозиту Гостю. При частковому або повному утриманні Депозиту Партнер зобов’язаний надати аргументовану причину свого рішення, яка буде надіслана Гостю.</p>

    <h3>1.4. Оплата рахунків</h3>
    <p>1.4.1. Дана послуга має на меті надати Гостю закладу харчування Партнера можливість сплатити в повному розмірі або частково рахунок, який Партнер передає Виконавцю через API, або вводить представник Партнера вручну, що відповідає замовленню Гостя в закладі Партнера.</p>

    <h3>1.5. Політика повернення Оплати рахунку</h3>
    <p>1.5.1. Загальна політика повернення грошей для всіх Партнерів, що підключені до Виконавця:</p>
    <ul>
        <li>У разі помилково виставленого рахунку Партнером, Гість може надіслати запит на повернення коштів;</li>
        <li>Кожен запит на повернення коштів гостем повинен бути підтверджений Виконавцем, а потім Партнером, тільки після цього кошти можуть бути зараховані на рахунок Гостя.</li>
    </ul>
    <p>1.5.2. Гість, що підписався на дану послугу від Виконавця, погоджується з правилами участі в подальших заходах, на які буде запрошений.</p>
    <p>1.5.3. Гість заповнює анкету, тим самим погоджується на обробку своїх персональних даних для подальшого підбору інших Гостей для загальної зустрічі.</p>
    <p>1.5.4. Гість гарантує свою присутність, на заходах, запрошення на які йому приходять в СМС повідомленні або електронною поштою зазначеною при реєстрації.</p>

    <h3>1.6. Оплата підписки на регулярні зустрічі Гостей</h3>
    <p>1.6.1. Підписка на регулярні зустрічі надає можливість Гостю взяти участь в 4 (чотирьох) зустрічах в місяць з 7 (сьома) незнайомцями підібраними завдяки вивченю приватних даних, індивідуальних характеристик кожного з Гостей.</p>
    <p>1.6.2. Підписуючись на дану послугу Гість погоджується на передачу Виконавцю своїх персональних даних відповідно до умов даного Договору в обсязі, необхідному для оформлення, оплати Підписки та надання послуг з підбору компанії на вечерю.</p>
    <p>1.6.3. Гість підтверджує надання Виконавцю згоди на обробку його персональних даних відповідно до Закону України «Про захист персональних даних» від 01.06.2010 р. № 2297–VІ та інших нормативно — правових актів України, які стосуються захисту персональних даних, в базі персональних даних Reservble, власником бази даних є Фізична Особа-Підприємець Носенко Володимир Миколайович.</p>
    <p>1.6.4. Гость надає Виконавцю право без попередньої згоди Гостя на передачу (поширення) своїх персональних даних розпорядникам та/або третім особам. Гость надає згоду на обробку своїх персональних даних у будь-яких комп’ютерних та/або інформаційних системах для здійснення діяльності відповідно до мети обробки, зазначеної в даному Договорі, а також погоджується на отримання від Виконавця інформації щодо рекламних заходів та акцій, що проводяться веб-сервісом.</p>
    <p>1.6.5. Ціна і умови оплати</p>
    <p>1.6.5.1. Перелік послуг Виконавця, їх ціна та строк виконання вказані на сайті Reservble.com, який може бути змінений Виконавцем в односторонньому порядку.</p>
    <p>1.6.5.2. Виконавець не має права змінювати ціну оплаченої послуги після того, як Замовлення було прийнято Виконавцем, а Гість отримав sms-повідомлення на вказаний Гостем номер телефону та/або електронне повідомлення, яке підтверджує прийняття Замовлення Виконавцем.</p>
    <p>1.6.5.3. Вартість послуг сплачується в національній валюті України — гривні, шляхом здійснення безготівкового платежу під час оформлення Замовлення перед наданням послуги.</p>
    <p>1.6.5.4. Якщо Гість сплатив послугу через сайт, але з певних причин не захотів отримати послугу, повернення необхідно Гостю провести самостійно на сайті. Виконавець протягом двох днів після отримання інформації з сайту про відмову від послуги, проводить операцію повернення в системі Portmone. Кошти на рахунок пацієнта зараховуються в термін від 5 до 30 днів. Якщо Гість самостійно не вдалося оформити повернення на сайті, він може звернутися до технічної підтримки за електронною адресою <a href="mailto:support@reservble.com">support@reservble.com</a>. Виконавець на підставі такої Заяви протягом двох днів проводить операцію повернення в системі Portmone. Кошти на рахунок пацієнта зараховуються в термін від 5 до 30 днів.</p>
    <p>1.6.5.5. Якщо Гість сплатив послугу через сайт, встиг хоча б частково скористатись, відвідав одне з чотирьох доступних заходів на місяць, гроші Гостю в такому випадку не повертаються.</p>

    <h2>2. Відповідальність сторін</h2>
    <p>2.1. Виконавець не несе відповідальність за коректність введених Партнером даних про грошові суми Гостя у власному кабінеті Партнера на веб-ресурсі Виконавця.</p>
    <p>2.2. Виконавець не несе відповідальність за якість роботи провайдера послуг з проведення платежів Portmone. У разі виникнення проблем Виконавець зобов’язується надати запит на роз’яснення до провайдера послуг Portmone та публічно, або в приватному листуванні надати роз’яснення Партнерам.</p>
    <p>2.3. Виконавець не відповідає за терміни проведення розрахунків, які гарантує Portmone, але гарантує повідомляти Партнера у разі внесення змін, щодо строків проведення оплат.</p>
    <p>2.4. Виконавець не несе відповідальність за коректність наданих даних по API, лише за якісну обробку таких даних своїми ресурсами. Сервіс гарантує, що суми рахунків відповідають заданим столам, наданій інформації по API або введеній вручну представником Партнера у власному кабінеті на веб-ресурсі Виконавця.</p>
    <p>2.5. Виконавець не несе відповідальність за будь-які конфлікти та їх наслідки, що виникли під час щотижневих зустрічей, на які підписався Гість.</p>
    <p>2.6. Підписуючись на послуги Виконавця, Гість або Партнер погоджується з умовами використання сервісу, умовами проведення та повернення оплат.</p>
`;
