import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import PersonsSelector from '@components/inputs/PersonsSelector/PersonsSelector';
import Calendar from '@components/inputs/Calendar/Calendar';
import TimeSelector from '@components/inputs/TimeSelector/TimeSelector';
import Formsy from 'formsy-react';
import './SearchBar.scss';
import ViewSelector from '@containers/ViewSelector';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { connect } from 'react-redux';
import { searchPlaces } from '@actions/search';
import { updateQuickReservationParams } from '@actions/reservation';
import useMedia from 'utils/hooks/useMedia';
import { MAP_MOBILE_VIEW_WIDTH } from 'utils/constants';

function SearchBar({ searchPlaces, location, history, mapRef, setPage, updateQuickReservationParams }) {
  const queryParams = queryString.parse(location.search);
  const nearest15Mins = moment(Math.ceil((moment()) / moment.duration(15, 'minutes')) * moment.duration(15, 'minutes')).toDate();
  const { q, time: stringTime = nearest15Mins } = queryParams;
  const time = new Date(stringTime);
  const showMapMobileVersion = useMedia([`(max-width: ${MAP_MOBILE_VIEW_WIDTH})`], [true], false);

  const setSeats = (newSeats) => {
    queryParams.seats = newSeats;
    history.push(`${window.location.pathname}?${queryString.stringify(queryParams)}`);
  };

  const setTime = (newTime) => {
    queryParams.time = moment(newTime).toISOString();
    history.push(`${window.location.pathname}?${queryString.stringify(queryParams)}`);
  };

  if (time < new Date()) {
    setTimeout(() => {
      setTime(nearest15Mins);
    }, 100);
  }

  useEffect(() => {
    setPage(0);
    updateQuickReservationParams();
    searchPlaces(location.search, (result) => {
      if (!mapRef.current) {
        return;
      }

      if (result.length === 1) {
        mapRef.current.setCenter(result[0].location.coordinates, 17);
      } else {
        mapRef.current.resetCenter();
      }
    });
  }, [location.search, location.pathname, searchPlaces, q, mapRef, setPage, updateQuickReservationParams]);
  // location.pathname is required to watch for city change in URL

  // const showMobileVersion = useMedia([`(max-width: ${MOBILE_VIEW_WIDTH})`], [true], false);

  return (
    <Formsy className='search-bar'>
      <Calendar
        onChange={setTime}
        value={time}
        className='search-bar__calendar'
      />
      <TimeSelector
        value={time}
        onChange={setTime}
        className='search-bar__time'
      />
      <PersonsSelector className='search-bar__persons' onChange={setSeats} />
      {!showMapMobileVersion && <ViewSelector className='search-bar__view-selector' />}
    </Formsy>
  );
}

SearchBar.propTypes = {
  searchPlaces: PropTypes.func.isRequired,
  updateQuickReservationParams: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  mapRef: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {};

const mapDispatchToProps = {
  searchPlaces,
  updateQuickReservationParams
};

export default memo(withRouter(connect(null, mapDispatchToProps)(SearchBar)));
