import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FinishReservation from './FinishReservation';
import { getReservation, confirmReservation } from '@actions/reservation';
import { getPlace, getDeposits } from '@actions/place';
import { ASK_PHONE_MODAL } from '@constants/modal';
import { openModal, closeModal } from '@actions/modal';
import { saveRegistrationData } from '@actions/user';
import { get } from '@utils/lo/lo';
import { findCertificate } from "@actions/billing";

const mapStateToProps = ({ user, place, reservation }) => ({
  place: place.details.data,
  deposits: (place.deposits.data || []).filter(d => d.active),
  reservation: reservation.details.data,
  loading: reservation.details.isLoading ||
    (!reservation.details.data && !reservation.details.error) ||
    place.details.isLoading,
  user: get(user, 'details.data.user'),
});

const mapDispatchToProps = {
  getPlace,
  getReservation,
  getDeposits,
  confirmReservation,
  openAskPhoneModal: () => openModal(ASK_PHONE_MODAL),
  saveRegistrationData: (data) => saveRegistrationData(data),
  closeModal,
  findCertificate,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FinishReservation));
