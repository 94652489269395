import React, { useEffect } from 'react';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import './ToastrMessage.scss';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';

function ToastrMessage() {
  useEffect(() => {
    const successFunction = toastr.success;
    const infoFunction = toastr.info;
    const errorFunction = toastr.error;

    toastr.success = function (...args) { // eslint-disable-line func-names
      successFunction.call(this, ...args, { icon: <SvgIcon type='confirm' /> });
    };

    toastr.info = function (...args) { // eslint-disable-line func-names
      infoFunction.call(this, ...args, { icon: <SvgIcon type='info' /> });
    };

    toastr.error = function (...args) { // eslint-disable-line func-names
      errorFunction.call(this, ...args, { icon: <SvgIcon type='cancel' /> });
    };
  }, []);

  return (
    <div>
      <ReduxToastr
        timeOut={10000}
        newestOnTop
        position='top-center'
        transitionIn='fadeIn'
        transitionOut='fadeOut'
      />
    </div>
  );
}

export default ToastrMessage;
