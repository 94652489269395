import React, { memo, useMemo } from 'react';
import './PlaceContacts.scss';
import PropTypes from 'prop-types';
import mova from 'mova';
import ContactItem from './ContactItem/ContactItem';
import Map from '@components/display/Map/Map';

const t = mova.ns('components.PlaceContacts');

function PlaceContacts(props) {
  const { place, className } = props;
  const { address, contacts } = place;

  const contactItems = useMemo(() => (contacts || []).map(contact => (
    <div className='place-contacts__data' key={contact.id}><ContactItem contact={contact} /></div>
  )), [contacts]);

  return (
    <div className={`place-contacts ${className}`}>
      {
        contactItems.length > 0 && (
          <div className='place-contacts__item'>
            <div className='place-contacts__heading'>{t('contacts')}</div>
            {contactItems}
          </div>
        )
      }

      <div className='place-contacts__item'>
        <div className='place-contacts__heading'>{t('address')}</div>
        <div className='place-contacts__data'>{address}</div>
      </div>

      <div className='place-contacts__item'>
        <Map places={[place]} placePage />
      </div>
    </div>
  );
}

PlaceContacts.propTypes = {
  place: PropTypes.object.isRequired,
  className: PropTypes.string
};

PlaceContacts.defaultProps = {
  className: ''
};

export default memo(PlaceContacts);
