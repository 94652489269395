import { connect } from 'react-redux';
import MyReservations from './Favorites';
import { getFavoritePlaces } from '@actions/user';

const mapStateToProps = ({ user }) => ({
  favoritePlaces: user.favoritePlaces.data,
  loading: user.favoritePlaces.isLoading || (!user.favoritePlaces.data && !user.favoritePlaces.error)
});

const mapDispatchToProps = {
  getFavoritePlaces
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReservations);
