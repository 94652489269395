import React from 'react';
import mova from 'mova';
import './ErrorPage.scss';
import TextLink from '@components/navigation/TextLink/TextLink';
import { baseUrlPath } from '@utils/url/urlUtils';

const t = mova.ns('pages.Error');

export default function ReservationNotDraft() {
  return (
    <div className='error-page'>
      <div className='error-page__message'>{t('reservationNotDraft')}</div>
      <div className='error-page__link'>
        <TextLink to={`${baseUrlPath()}/reservations`} type='text' size='large'>{t('goToReservations')}</TextLink>
      </div>
    </div>
  );
}
