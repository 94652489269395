import deepFreeze from 'deep-freeze';
import deepMerge from 'deepmerge';
import { isDev } from '@utils/env';
import { set as loSet } from '@utils/lo/lo';

if (isDev) {
  console.log('DEVELOPMENT MODE ENABLED'); // eslint-disable-line no-console
}

const Immutable = (value) => {
  if (!isDev || value === null || value === undefined) {
    return value;
  }
  return deepFreeze(value);
};

const List = (array) => {
  if (!array) {
    return Immutable([]);
  }
  if (!Array.isArray(array)) {
    throw new Error('the argument is not an array');
  }
  return Immutable(array);
};

const merge = (a, b, options) => Immutable(deepMerge(a, b, options));


const _freeze = (value) => {
  if (!isDev) {
    return value;
  }
  return Object.freeze(value);
};

const _thawArray = array => [...array];

const _arrayApply = (array, methodName, ...args) => {
  if (!array || !Array.isArray(array)) {
    throw new Error('The first arguments must be an array.');
  }
  const next = _thawArray(array);
  next[methodName](...args);
  return _freeze(next);
};


const push = (array, ...args) => _arrayApply(array, 'push', ...args);
const pop = (array, ...args) => _arrayApply(array, 'pop', ...args);
const shift = (array, ...args) => _arrayApply(array, 'shift', ...args);
const unshift = (array, ...args) => _arrayApply(array, 'unshift', ...args);
const reverse = (array, ...args) => _arrayApply(array, 'reverse', ...args);
const sort = (array, ...args) => _arrayApply(array, 'sort', ...args);
const splice = (array, ...args) => _arrayApply(array, 'splice', ...args);


const _isImmutable = (o, recursivityCounter) => {
  if (recursivityCounter > 100) {
    throw new Error('The object is too deep or there is a cycle');
  }
  const properties = Object.getOwnPropertyNames(o).filter(prop =>
  // eslint-disable-next-line
    o.hasOwnProperty(prop) && o[prop] !== null && (typeof o[prop] === 'object' || typeof o[prop] === 'function'));
  const oneIsMutable = properties
    .find(prop => !Object.isFrozen(o[prop]) || !_isImmutable(o[prop], recursivityCounter + 1));
  return !oneIsMutable;
};

const isImmutable = (object, recursive) => {
  if (!isDev) {
    return true;
  }
  if (!recursive) {
    return Object.isFrozen(object);
  }
  return Object.isFrozen(object) && _isImmutable(object, 0);
};

const set = (object, fieldPath, value) => Immutable(loSet(object, fieldPath, value));

export default Immutable;
export { List, merge, push, pop, shift, unshift, reverse, sort, splice, set, isImmutable };
