import React from 'react';
import Colors from '@data/enums/Color.enum';

export function money({ fill = Colors.TEXT_DEFAULT }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 8.20833C10.1774 8.20833 8.7 9.90578 8.7 12C8.7 14.0939 10.1774 15.7917 12 15.7917C13.8219 15.7917 15.3 14.0946 15.3 12C15.3 9.90578 13.8226 8.20833 12 8.20833ZM21.9 5.5H2.1C1.49259 5.5 1 5.98513 1 6.58333V17.4167C1 18.0149 1.49259 18.5 2.1 18.5H21.9C22.5074 18.5 23 18.0149 23 17.4167V6.58333C23 5.98513 22.5074 5.5 21.9 5.5ZM21.35 14.7083C20.1348 14.7083 19.15 15.6783 19.15 16.875H4.85C4.85 15.6783 3.86516 14.7083 2.65 14.7083V9.29167C3.86516 9.29167 4.85 8.32174 4.85 7.125H19.15C19.15 8.32174 20.1348 9.29167 21.35 9.29167V14.7083Z'
        fill={fill}
      />
    </svg>
  );
}
