import React, { Fragment, useEffect } from 'react';
import './ReservationHistory.scss';
import PropTypes from 'prop-types';
import PlaceCard from '@components/display/PlaceCard/PlaceCard';
import Loading from '@components/feedback/Loading/Loading';
import mova from 'mova';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import { groupBy } from '@utils/lo/lo';
import moment from 'moment';
import { STATUS_FINISHED } from '@constants/reservation';
import Div100vh from 'react-div-100vh';
import { HEADER_HEIGHT, SUMMARY_HEIGHT, FOOTER_HEIGHT } from '@utils/constants';

const t = mova.ns('pages.ReservationHistory');

export default function ReservationHistory(props) {
  const { reservations, loading, getOldReservations, timezone } = props;

  useEffect(() => {
    getOldReservations();
  }, [getOldReservations]);

  if (loading) {
    return <Loading />;
  }

  const empty = reservations.length === 0;

  const groupedReservations = groupBy(reservations.map(r => ({
    ...r,
    dateFrom: moment.tz(r.from, timezone).format('DD.MM.YYYY')
  })), 'dateFrom');

  const placesVisited = reservations.filter(r => r.status === STATUS_FINISHED).length;
  const reservationDates = Object.keys(groupedReservations);
  const reservationItems = reservationDates.map(date => (
    <div className='content-container' key={date}>
      <div className='reservation-history__date'>{date}</div>
      <div className='reservation-history__cards'>
        {groupedReservations[date].map(r => <PlaceCard place={r.place} reservation={r} key={r.id} />)}
      </div>
    </div>
  ));

  return (
    <div className='reservation-history'>
      <div className='reservation-history__summary'>
        <div className='content-container'>
          {empty && t('empty')}
          {
            !empty && (
              <Fragment>
                <span>{t('summary.visits')}</span>&nbsp;
                <span>{placesVisited}</span>&nbsp;
                <span>{t(`summary.reservations.${getPluralsFactor(placesVisited)}`)}</span>&nbsp;
              </Fragment>
            )
          }
        </div>
      </div>
      <Div100vh
        style={{ minHeight: `calc(100rvh - ${HEADER_HEIGHT} - ${SUMMARY_HEIGHT} - ${FOOTER_HEIGHT})` }}
        className='reservation-history__cards-container'
      >
        {reservationItems}
      </Div100vh>
    </div>
  );
}

ReservationHistory.propTypes = {
  reservations: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  getOldReservations: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired
};

ReservationHistory.defaultProps = {
  reservations: []
};
