import React, { useEffect } from 'react';
import './Favorites.scss';
import PropTypes from 'prop-types';
import Loading from '@components/feedback/Loading/Loading';
import mova from 'mova';
import PlaceCard from '@components/display/PlaceCard/PlaceCard';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import Div100vh from 'react-div-100vh';
import { HEADER_HEIGHT, SUMMARY_HEIGHT, FOOTER_HEIGHT } from '@utils/constants';

const t = mova.ns('pages.Favorites');

export default function Favorites(props) {
  const { favoritePlaces, loading, getFavoritePlaces } = props;

  useEffect(() => {
    getFavoritePlaces();
  }, [getFavoritePlaces]);

  if (loading) {
    return <Loading />;
  }

  const places = favoritePlaces.rows || [];
  const placeList = places.map(data => <PlaceCard place={data.place} key={data.place.id} />);
  const empty = places.length === 0;
  const text = empty ?
    t('empty') :
    `${t('favorites')} ${favoritePlaces.count} ${t(`places.${getPluralsFactor(places.length)}`)}`;

  return (
    <div className='favorite-places'>
      <div className='favorite-places__summary'>
        <div className='content-container'>
          {text}
        </div>
      </div>
      <Div100vh
        className='favorite-places__cards'
        style={{ minHeight: `calc(100rvh - ${HEADER_HEIGHT} - ${SUMMARY_HEIGHT} - ${FOOTER_HEIGHT})` }}
      >
        <div className='content-container favorite-places__cards-container'>
          {placeList}
        </div>
      </Div100vh>
    </div>
  );
}

Favorites.propTypes = {
  favoritePlaces: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  getFavoritePlaces: PropTypes.func.isRequired
};

Favorites.defaultProps = {
  favoritePlaces: {}
};
