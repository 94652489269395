import { get, isDefined } from '@utils/lo/lo';

/**
 * Performs a shallow equals between the two specified objects.
 * If the fields parameter is specified the equality will be chacked only on the specified fields.
 *
 * @param objA the first object to compare.
 * @param objB the second object to compare.
 * @param fields (optional) the fields to check.
 *
 * @return true if the shallow equality procedure does not find any difference.
 */
const shallowEquals = (objA, objB, fields) => {
  if (!objA && !objB) {
    return true;
  }
  if (!objA || !objB) {
    return false;
  }
  const keys = fields || Object.keys(objA);
  if (!fields && keys.length !== Object.keys(objB).length) {
    return false;
  }
  return !keys.some(key => get(objA, key) !== get(objB, key));
};

/**
 * Safe way to cast a value as string. If the value is not defined null will be returned.
 *
 * @param value The value to cast.
 * @return the value as string on null.
 */
const stringify = value => (isDefined(value) ? String(value) : null);

/**
 * Gets a property value as string. This method is null safe.
 *
 * @param object the object to that contains the value.
 * @param fieldPath the field's path.
 * @param defaultValue the default value.
 * @return the value as string
 */
const getStr = (object, fieldPath, defaultValue) => stringify(get(object, fieldPath, defaultValue));

const arrayfy = (value) => {
  if (!isDefined(value)) {
    return null;
  }
  return Array.isArray(value) ? value : [value];
};

const preventDefault = (e) => e.preventDefault();

export { getStr, stringify, arrayfy, shallowEquals, preventDefault };
