/* eslint-disable quote-props */
import axios from 'axios';
import mova from 'mova';
import { toastr } from 'react-redux-toastr';
import getMessageFromError from '@utils/error/errorMessageUtils';

const errT = mova.ns('errors');

export function createApiClient(clientConfig = {}) {
  const client = axios.create({
    timeout: 60000,
    ...clientConfig,
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate, proxy-revalidate',
      'Pragma': 'no-cache',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...clientConfig.headers,
    },

    validateStatus: (status) => {
      if (clientConfig.validateStatus) {
        const outerResult = clientConfig.validateStatus(status);

        // If custom validateStatus returns a value - stop standard flow
        if (outerResult) {
          return outerResult;
        }
      }

      return status >= 200 && status < 300;
    },
  });

  client.setToken = function setToken(token) {
    const headers = client.defaults.headers.common;

    if (!token) {
      delete headers.Authorization;
      return;
    }

    headers.Authorization = `Bearer ${token}`;
  };

  client.setToken(clientConfig.token);

  client.interceptors.response.use(
    response => response,
    error => {
      toastr.error(errT('error'), getMessageFromError(error));

      return Promise.reject(error);
    }
  );

  return client;
}
