/* eslint-disable */

export default `<p><strong>Загальні положення</strong></p>
<p><span>Дана політика обробки персональних даних складена відповідно до вимог закону України від 01.06.2010. №2297 &laquo;Про захист персональних даних&raquo; та визначає порядок обробки персональних даних та заходи щодо забезпечення безпеки персональних даних Reservble (далі - Оператор).</span></p>
<ol>
<li><span>Оператор ставить своєю найважливішою метою і умовою здійснення своєї діяльності дотримання прав і свобод людини і громадянина при обробці його персональних даних, в тому числі захисту прав на недоторканність приватного життя, особисту і сімейну таємницю.</span></li>
<li><span>Ця політика Оператора щодо обробки персональних даних (далі - Політика) застосовується до всієї інформації, яку Оператор може отримати про відвідувачів веб-сайту https://reservble.com.</span></li>
</ol>
<p><strong>Основні поняття, що використовуються в Політиці</strong></p>
<ol>
<li><span>Автоматизована обробка персональних даних - обробка персональних даних за допомогою засобів обчислювальної техніки;</span></li>
<li><span>Блокування персональних даних - тимчасове припинення обробки персональних даних (за винятком випадків, якщо обробка необхідна для уточнення персональних даних);</span></li>
<li><span>Веб-сайт - сукупність графічних і інформаційних матеріалів, а також програм для ЕОМ і баз даних, що забезпечують їх доступність в мережі інтернет за мережевою адресою</span><a href="https://reservble.com/"> <span>https://reservble.com</span></a><span>;</span></li>
<li><span>Інформаційна система персональних даних - сукупність даних що містяться в базах персональних даних, і забезпечуючих їх обробку інформаційних технологій і технічних засобів;</span></li>
<li><span>Знеособлювання персональних даних - дії, в результаті яких неможливо визначити без використання додаткової інформації приналежність персональних даних конкретному Користувачеві чи іншому суб'єкту персональних даних;</span></li>
<li><span>Обробка персональних даних - будь-яка дія (операція) або сукупність дій (операцій), що здійснюються з використанням засобів автоматизації або без використання таких засобів з персональними даними, включаючи збір, запис, систематизацію, накопичення, зберігання, уточнення (оновлення, зміна), витяг, використання, передачу (поширення, надання, доступ), знеособлення, блокування, видалення, знищення персональних даних;</span></li>
<li><span>Оператор - державний орган, муніципальний орган, юридична чи фізична особа, яка самостійно або спільно з іншими особами організують і (або) здійснюють обробку персональних даних, а також визначають цілі обробки персональних даних, склад персональних даних, що підлягають обробці, дії (операції) , що здійснюються з персональними даними;</span></li>
<li><span>Персональні дані - будь-яка інформація, що відноситься прямо або побічно до певного або визначається Користувачеві веб-сайту</span><a href="https://reservble.com/"> <span>https://reservble.com</span></a><span>;</span></li>
<li><span>Користувач - будь-який відвідувач веб-сайту</span><a href="https://reservble.com/"> <span>https://reservble.com</span></a><span>;</span></li>
<li><span>Надання персональних даних - дії, спрямовані на розкриття персональних даних певній особі або певного кола осіб;</span></li>
<li><span>Поширення персональних даних - будь-які дії, спрямовані на розкриття персональних даних невизначеному колу осіб (передача персональних даних) або на ознайомлення з персональними даними необмеженого кола осіб, в тому числі оприлюднення персональних даних в засобах масової інформації, розміщення в інформаційно-телекомунікаційних мережах або надання доступу до персональних даних будь-яким іншим способом;</span></li>
<li><span>Транскордонна передача персональних даних - передача персональних даних на територію іноземної держави органу влади іноземної держави, іноземної фізичній або іноземній юридичній особі;</span></li>
<li><span>Знищення персональних даних - будь-які дії, в результаті яких персональні дані знищуються безповоротно з неможливістю подальшого відновлення змісту персональних даних в інформаційній системі персональних даних і (або) внаслідок яких знищуються матеріальні носії персональних даних.</span><strong><strong>&nbsp;</strong></strong></li>
</ol>
<p><strong> Оператор може обробляти такі персональні дані Користувача</strong></p>
<ol>
<li>Прізвище, ім'я, по батькові;</li>
<li>Електронна адреса;</li>
<li>Номери телефонів;</li>
<li>Рік, місяць, дата і місце народження;</li>
<li>Фотографії;</li>
<li>Також на сайті відбувається збір і обробка знеособлених даних про відвідувачів (в т.ч. файлів &laquo;cookie&raquo;) за допомогою сервісів інтернет-статистики (Гугл Аналітика та інших).</li>
<li>Перераховані вище дані далі по тексту Політики об'єднані загальним поняттям Персональні дані.</li>
</ol>
<p><strong> Цілі обробки персональних даних</strong></p>
<ol>
<li>Мета обробки персональних даних Користувача - надання доступу Користувачеві до сервісів, інформації та / або матеріалами, що містяться на веб-сайті.</li>
<li>Також Оператор має право направляти Користувачеві повідомлення про нові продукти та послуги, спеціальні пропозиції та різні події. Користувач завжди може відмовитися від отримання інформаційних повідомлень, направивши Оператору лист на адресу електронної пошти support@reservble.com з позначкою &laquo;Відмова від повідомленнях про нові продукти і послуги та спеціальні пропозиції&raquo;.</li>
<li>Знеособлені дані користувачів, що збираються за допомогою сервісів інтернет-статистики, служать для збору інформації про дії користувачів на сайті, поліпшення якості сайту та його змісту.&nbsp;</li>
</ol>
<p><strong>Правові підстави обробки персональних даних</strong></p>
<ol>
<li><span> Оператор обробляє персональні дані Користувача тільки в разі їх заповнення та / або відправки Користувачем самостійно через спеціальні форми, розташовані на сайті https://reservble.com. Заповнюючи відповідні форми і / або відправляючи свої персональні дані Оператору, Користувач висловлює свою згоду з цією політикою.</span></li>
<li><span> Оператор обробляє знеособлені дані про Користувача в разі, якщо це дозволено в настройках браузера Користувача (включено збереження файлів &laquo;cookie&raquo; і використання технології JavaScript).</span>&nbsp;</li>
</ol>
<p><strong>Порядок збору, зберігання, передачі та інших видів обробки персональних даних</strong></p>
<p><span>Безпека персональних даних, які обробляються Оператором, забезпечується шляхом реалізації правових, організаційних і технічних заходів, необхідних для виконання в повному обсязі вимог чинного законодавства у сфері захисту персональних даних.</span></p>
<ol>
<li><span>Оператор забезпечує збереження персональних даних і вживає всіх можливих заходів, що виключають доступ до особистих даних неуповноважених осіб.</span></li>
<li><span>Персональні дані Користувача ніколи, ні за яких умов не будуть передані третім особам, за винятком випадків, пов'язаних з виконанням чинного законодавства.</span></li>
<li><span>У разі виявлення неточностей в персональних даних, Користувач може актуалізувати їх самостійно, шляхом подання відповідного Оператору повідомлення на адресу електронної пошти Оператора support@reservble.com з позначкою &laquo;Актуалізація персональних даних&raquo;.</span></li>
<li><span>Термін обробки персональних даних є необмеженим. Користувач може в будь-який момент відкликати свою згоду на обробку персональних даних, направивши Оператору повідомлення за допомогою електронної пошти на електронну адресу Оператора support@reservble.com з позначкою &laquo;Відкликання згоди на обробку персональних даних&raquo;.</span></li>
<li><span> Транскордонна передача персональних даних</span></li>
<li><span> Оператор до початку здійснення транскордонної передачі персональних даних зобов'язаний переконатися в тому, що іноземною державою, на територію якого передбачається здійснювати передачу персональних даних, забезпечується надійний захист прав суб'єктів персональних даних.&nbsp;</span></li>
<li><span> Транскордонна передача персональних даних на території іноземних держав, які не відповідають зазначеним вище вимогам, може здійснюватися тільки в разі наявності згоди в письмовій формі суб'єкта персональних даних на транскордонну передачу його персональних даних та / або виконання договору, стороною якого є суб'єкт персональних даних</span></li>
</ol>
<p><strong>Заключні положення</strong></p>
<ol>
<li><span>Користувач може отримати будь-які роз'яснення з потрібних питань, що стосуються обробки його персональних даних, звернувшись до Оператору за допомогою електронної пошти support@reservble.com.</span></li>
<li><span>У даному документі будуть відображені будь-які зміни політики обробки персональних даних Оператором. Політика діє безстроково до заміни її новою версією</span></li>
<li><span>Актуальна версія Політики у вільному доступі розташована в мережі Інтернет за адресою https://reservble.com/privacy-policy.</span></li>
</ol>`;
