import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './AllowGeolocation.scss';
import mova from 'mova';
import Button from '@components/inputs/Button/Button';
import Modal from '@components/feedback/Modal/Modal';
import { withRouter } from 'react-router-dom';
import { setGeolocationModalSeen } from '@utils/geolocation/geolocation';

const t = mova.ns('containers.AllowGeolocationModal');

function AllowGeolocationModal({ closeModal, getUserLocation }) {
  const allowGeo = () => {
    closeModal();
    setGeolocationModalSeen();
    getUserLocation();
  };

  return (
    <Modal title={t('title')} className='allow-geolocation-modal' closeOnOverlayClick={false}>
      <div className='allow-geolocation-modal__text'>{t('text')}</div>
      <div className='allow-geolocation-modal__actions'>
        <Button size='medium' behavior='submit' onClick={allowGeo}>{t('ok')}</Button>
      </div>
    </Modal>
  );
}

AllowGeolocationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getUserLocation: PropTypes.func.isRequired,
};

AllowGeolocationModal.defaultProps = {};

export default memo(withRouter(AllowGeolocationModal));
