import { useCallback, useState } from 'react';

export default function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = useCallback(x => new Promise(resolve => {
    setValue(x);
    resolve(x);
  }), []);
  return [value, setter];
}
