import React, { memo } from 'react';
import mova from 'mova';
import './ErrorPage.scss';
import TextLink from '@components/navigation/TextLink/TextLink';
import { baseUrlPath } from '@utils/url/urlUtils';

const t = mova.ns('pages.Error');

function ApplicationError() {
  return (
    <div className='error-page'>
      <h1 className='error-page__status'>500</h1>
      <h5 className='error-page__message'>{t('error500.message')}</h5>
      <h5 className='error-page__details'>{t('error500.details')}</h5>

      <div className='error-page__link'>
        <TextLink to={baseUrlPath()} type='text' size='large'>{t('goHome')}</TextLink>
      </div>
    </div>
  );
}

export default memo(ApplicationError);
