import React from 'react';
import Colors from '@data/enums/Color.enum';

export function processing() {
  return (
    <svg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='7' cy='7' r='7' fill={Colors.COLOR_LINK_MAIN} />
      <path
        d='M6.625 3.58073L6.625 7.29427C6.625 7.33867 6.66719 7.375 6.71875 7.375L7.28125 7.375C7.33281 7.375 7.375 7.33867 7.375 7.29427L7.375 3.58073C7.375 3.53633 7.33281 3.5 7.28125 3.5L6.71875 3.5C6.66719 3.5 6.625 3.53633 6.625 3.58073Z'
        fill='white'
      />
      <path
        d='M6.6764 7.4264L9.04076 9.79076C9.06903 9.81903 9.12199 9.81233 9.15845 9.77587L9.5562 9.37812C9.59266 9.34166 9.59936 9.2887 9.57109 9.26043L7.20673 6.89607C7.17846 6.8678 7.1255 6.8745 7.08904 6.91096L6.69129 7.30871C6.65483 7.34517 6.64813 7.39813 6.6764 7.4264Z'
        fill='white'
      />
      <path
        d='M7 1.75C4.10078 1.75 1.75 4.10078 1.75 7C1.75 9.89922 4.10078 12.25 7 12.25C9.89922 12.25 12.25 9.89922 12.25 7C12.25 4.10078 9.89922 1.75 7 1.75ZM7 11.3594C4.59297 11.3594 2.64062 9.40703 2.64062 7C2.64062 4.59297 4.59297 2.64062 7 2.64062C9.40703 2.64062 11.3594 4.59297 11.3594 7C11.3594 9.40703 9.40703 11.3594 7 11.3594Z'
        fill='white'
      />
    </svg>
  );
}
