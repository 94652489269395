import LocalStorageService from '@services/LocalStorage.service';
import {
  CUISINES_LOADING,
  INITIAL_LOADING_FINISHED,
  SAVE_CUISINES,
  SAVE_TAGS,
  SAVE_TIMEZONE_INFO,
  SHOW_TOASTR,
  TAGS_LOADING,
  TIMEZONE_INFO_LOADING,
  TOGGLE_VIEW
} from '@constants/app';
// import { ALLOW_GEOLOCATION_MODAL } from '@constants/modal';
import api from '@api';
import { getCurrentUser } from './user';
import { get, isDefined } from '@utils/lo/lo';
import { loadData } from '@utils/redux/actionUtils';
import { SAVE_QUICK_DATE } from '@constants/reservation';
import { prettifyTime } from '@utils/date/dateUtils';
import { countUnreadNotifications } from './notification';
import { countActiveReservations } from './reservation';
import { webSocketAuth } from '@data/socket/socket';
// import { geolocationModalSeen } from '@utils/geolocation/geolocation';
import { toastr } from 'react-redux-toastr';
import mova from 'mova';

const t = mova.ns('types');
const errT = mova.ns('errors');

export const getCurrentTime = () => loadData('app.getTimeInfo')(TIMEZONE_INFO_LOADING, SAVE_TIMEZONE_INFO);
export const getTags = () => loadData('app.getTags')(TAGS_LOADING, SAVE_TAGS);

export const initialLoading = () => async dispatch => {
  const token = LocalStorageService.get(LocalStorageService.Keys.TOKEN);
  api.setToken(token);

  // toDO: Reactivate fetching user geolocation later upon user request
  // if (geolocationModalSeen()) {
  //   dispatch(getUserLocation());
  // } else {
  // dispatch(getUserLocationByIp());
  // dispatch(openModal(ALLOW_GEOLOCATION_MODAL));
  // }

  if (token) {
    const user = await getCurrentUser()(dispatch);

    if (get(user, 'response.status') === 401) {
      LocalStorageService.set(LocalStorageService.Keys.TOKEN, null);
    }

    if (isDefined(user, 'user')) {
      dispatch(countUnreadNotifications());
      dispatch(countActiveReservations());
      webSocketAuth(token);
    }
  }

  const timeData = await getCurrentTime()(dispatch);
  dispatch({ type: SAVE_QUICK_DATE, payload: prettifyTime(new Date(timeData.current), timeData.DEFAULT_TZ) });

  await getTags()(dispatch);

  dispatch({ type: INITIAL_LOADING_FINISHED });
};

export const showToastr = options => (dispatch) => {
  dispatch({ type: SHOW_TOASTR, payload: options });
};

export const logError = (renderError, errorInfo) => () => {
  const body = {
    data: {
      renderError,
      errorInfo
    }
  };

  const apiRequest = get(api, 'app.logError');
  apiRequest(body);
};

export const toggleView = () => (dispatch) => {
  dispatch({ type: TOGGLE_VIEW });
};

export const getCuisines = (land) => loadData('places.getAllCuisines', land)(CUISINES_LOADING, SAVE_CUISINES);

export const sendJoinRequest = (data, formRef) => () => {
  const apiRequest = get(api, 'app.joinRequest');
  apiRequest(data).then(res => {
    if (res.data.telegram || res.data.email) {
      toastr.success(t('join.thankYou'));

      if (formRef && formRef.current) {
        formRef.current.reset();
        formRef.current.setFormPristine(true);
      }
    } else {
      toastr.error(errT('join'));
    }
  });
};
