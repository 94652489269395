import React, { memo } from 'react';
import Select, { components } from 'react-select';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import './MultiSelect.scss';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <SvgIcon type='chevronDown' className='react-select__chevron' />
  </components.DropdownIndicator>
);

function MultiSelect(props) {
  const { options, isMulti, placeholder, label, className, value, setValue, name } = props;

  const handleSelect = values => {
    if (!values) return;
    setValue(values);
  };

  return (
    <label
      className={`multi-select ${className} }`}
    >
      {label && <div className='multi-select__label'>{label}</div>}
      <Select
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        className='react-select-container'
        classNamePrefix='react-select'
        components={{ DropdownIndicator }}
        onChange={handleSelect}
        name={name}
        value={value}
      />
    </label>
  );
}

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string,
};

MultiSelect.defaultProps = {
  isMulti: false,
  placeholder: '',
  label: '',
  className: null,
  name: undefined,
};

export default memo(withFormsy(MultiSelect));
