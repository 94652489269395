import React, { memo } from 'react';
import './Policy.scss';
import mova from 'mova';
import UaPolicy from './Langs/UaPaymentsPolicy';

const t = mova.ns('pages.Policy');

function PaymentsPolicy() {
  return (
    <div>
      <div className='policy'>
        <div className='policy__content'>
          <div className='policy__heading'>{t('payments')}</div>
          <div className='policy__text'>
            <div dangerouslySetInnerHTML={{ __html: UaPolicy }} />
            {/* eslint-disable-line react/no-danger */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PaymentsPolicy);
