import React from 'react';
import Colors from '@data/enums/Color.enum';

export function star({ fill, rating }) {
  if (rating >= 1) {
    return (
      <svg viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14.1891 6.37042L10.2219 5.79385L8.44847 2.19854C8.40003 2.1001 8.32034 2.02042 8.2219 1.97198C7.97503 1.8501 7.67503 1.95167 7.55159 2.19854L5.77815 5.79385L1.81097 6.37042C1.70159 6.38604 1.60159 6.4376 1.52503 6.51573C1.43247 6.61086 1.38146 6.73885 1.38322 6.87157C1.38498 7.0043 1.43936 7.13089 1.5344 7.22354L4.40472 10.022L3.72659 13.9735C3.71069 14.0655 3.72086 14.16 3.75595 14.2464C3.79105 14.3329 3.84966 14.4077 3.92514 14.4626C4.00062 14.5174 4.08995 14.55 4.183 14.5566C4.27605 14.5632 4.3691 14.5437 4.45159 14.5001L8.00003 12.6345L11.5485 14.5001C11.6453 14.5517 11.7578 14.5689 11.8657 14.5501C12.1375 14.5032 12.3203 14.2454 12.2735 13.9735L11.5953 10.022L14.4657 7.22354C14.5438 7.14698 14.5953 7.04698 14.611 6.9376C14.6532 6.66417 14.4625 6.41104 14.1891 6.37042Z'
          fill={Colors.COLOR_YELLOW}
        />
      </svg>
    );
  }

  return (
    <svg viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.1891 6.37042L10.2219 5.79385L8.44847 2.19854C8.40003 2.1001 8.32034 2.02042 8.2219 1.97198C7.97503 1.8501 7.67503 1.95167 7.55159 2.19854L5.77815 5.79385L1.81097 6.37042C1.70159 6.38604 1.60159 6.4376 1.52503 6.51573C1.43247 6.61086 1.38146 6.73885 1.38322 6.87157C1.38498 7.0043 1.43936 7.13089 1.5344 7.22354L4.40472 10.022L3.72659 13.9735C3.71069 14.0655 3.72086 14.16 3.75595 14.2464C3.79105 14.3329 3.84966 14.4077 3.92514 14.4626C4.00062 14.5174 4.08995 14.55 4.183 14.5566C4.27605 14.5632 4.3691 14.5437 4.45159 14.5001L8.00003 12.6345L11.5485 14.5001C11.6453 14.5517 11.7578 14.5689 11.8657 14.5501C12.1375 14.5032 12.3203 14.2454 12.2735 13.9735L11.5953 10.022L14.4657 7.22354C14.5438 7.14698 14.5953 7.04698 14.611 6.9376C14.6532 6.66417 14.4625 6.41104 14.1891 6.37042Z'
        fill={fill || Colors.TEXT_GREY}
      />

      {
        rating >= 0.1 && (
          <path
            d='M1.81268 6.37034C1.70334 6.38597 1.60336 6.43754 1.52682 6.51568C1.43429 6.61083 1.3833 6.73885 1.38505 6.8716C1.38681 7.00435 1.44117 7.13097 1.53619 7.22363L2.70801 8.36665V6.24015L1.81268 6.37034Z'
            fill={Colors.COLOR_YELLOW}
          />
        )
      }

      {
        rating >= 0.2 && rating < 0.3 && (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.03101 14.5224V12.2072L3.72778 13.975C3.71188 14.0669 3.72205 14.1615 3.75713 14.2479C3.79222 14.3344 3.85081 14.4093 3.92627 14.4641C3.95887 14.4878 3.99406 14.5073 4.03101 14.5224ZM4.03101 9.65714V6.04779L2.70801 6.24015V8.36665L4.03101 9.65714Z'
            fill={Colors.COLOR_YELLOW}
          />
        )
      }

      {
        rating >= 0.3 && rating < 0.4 && (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.35401 5.85542V14.0275L4.45258 14.5017C4.37011 14.5452 4.27708 14.5648 4.18406 14.5582C4.13122 14.5544 4.07959 14.5423 4.03101 14.5224C3.99406 14.5073 3.95887 14.4878 3.92627 14.4641C3.85081 14.4093 3.79222 14.3344 3.75713 14.2479C3.72205 14.1615 3.71188 14.0669 3.72778 13.975L4.03101 12.2072L4.40572 10.0226L4.03101 9.65714L2.70801 8.36665V6.24015L4.03101 6.04779L5.35401 5.85542Z'
            fill={Colors.COLOR_YELLOW}
          />
        )
      }

      {
        rating >= 0.4 && rating < 0.5 && (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.67703 13.3316L6.66525 3.99564L5.77878 5.79366L5.35401 5.85542L4.03101 6.04779L2.70801 6.24015V8.36665L4.03101 9.65714L4.40572 10.0226L4.03101 12.2072L3.72778 13.975C3.71188 14.0669 3.72205 14.1615 3.75713 14.2479C3.79222 14.3344 3.85081 14.4093 3.92627 14.4641C3.95887 14.4878 3.99406 14.5073 4.03101 14.5224C4.07959 14.5423 4.13122 14.5544 4.18406 14.5582C4.27708 14.5648 4.37011 14.5452 4.45258 14.5017L5.35401 14.0275L6.67703 13.3316Z'
            fill={Colors.COLOR_YELLOW}
          />
        )
      }

      {
        rating >= 0.5 && rating < 0.6 && (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.00004 12.6357V1.91944C7.81639 1.91999 7.63946 2.02208 7.55173 2.19762L6.66525 3.99564L5.77878 5.79366L5.35401 5.85542L4.03101 6.04779L2.70801 6.24015V8.36665L4.03101 9.65714L4.40572 10.0226L4.03101 12.2072L3.72778 13.975C3.71188 14.0669 3.72205 14.1615 3.75713 14.2479C3.79222 14.3344 3.85081 14.4093 3.92627 14.4641C3.95887 14.4878 3.99406 14.5073 4.03101 14.5224C4.07959 14.5423 4.13122 14.5544 4.18406 14.5582C4.27708 14.5648 4.37011 14.5452 4.45258 14.5017L5.35401 14.0275L6.67703 13.3316L8.00004 12.6357Z'
            fill={Colors.COLOR_YELLOW}
          />
        )
      }

      {
        rating >= 0.6 && rating < 0.7 && (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.00004 12.6357L9.33483 13.3378V3.99564L8.44835 2.19762C8.39993 2.09916 8.32026 2.01946 8.22185 1.97101C8.15051 1.93577 8.07472 1.91921 8.00004 1.91944C7.81639 1.91999 7.63946 2.02208 7.55173 2.19762L6.66525 3.99564L5.77878 5.79366L5.35401 5.85542L4.03101 6.04779L2.70801 6.24015V8.36665L4.03101 9.65714L4.40572 10.0226L4.03101 12.2072L3.72778 13.975C3.71188 14.0669 3.72205 14.1615 3.75713 14.2479C3.79222 14.3344 3.85081 14.4093 3.92627 14.4641C3.95887 14.4878 3.99406 14.5073 4.03101 14.5224C4.07959 14.5423 4.13122 14.5544 4.18406 14.5582C4.27708 14.5648 4.37011 14.5452 4.45258 14.5017L5.35401 14.0275L6.67703 13.3316L8.00004 12.6357Z'
            fill={Colors.COLOR_YELLOW}
          />
        )
      }

      {
        rating >= 0.7 && rating < 0.8 && (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.6461 5.85542V14.0275L9.33483 13.3378L8.00004 12.6357L6.67703 13.3316L5.35401 14.0275L4.45258 14.5017C4.37011 14.5452 4.27708 14.5648 4.18406 14.5582C4.13122 14.5544 4.07959 14.5423 4.03101 14.5224C3.99406 14.5073 3.95887 14.4878 3.92627 14.4641C3.85081 14.4093 3.79222 14.3344 3.75713 14.2479C3.72205 14.1615 3.71188 14.0669 3.72778 13.975L4.03101 12.2072L4.40572 10.0226L4.03101 9.65714L2.70801 8.36665V6.24015L4.03101 6.04779L5.35401 5.85542L5.77878 5.79366L6.66525 3.99564L7.55173 2.19762C7.63946 2.02208 7.81639 1.91999 8.00004 1.91944C8.07472 1.91921 8.15051 1.93577 8.22185 1.97101C8.32026 2.01946 8.39993 2.09916 8.44835 2.19762L9.33483 3.99564L10.2213 5.79366L10.6461 5.85542Z'
            fill={Colors.COLOR_YELLOW}
          />
        )
      }

      {
        rating >= 0.8 && rating < 0.9 && (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.9691 6.04779V9.65714L11.5944 10.0226L11.9675 12.1981V14.5224C11.935 14.5356 11.9006 14.5455 11.8646 14.5517C11.7568 14.5704 11.6444 14.5532 11.5475 14.5017L10.6461 14.0275L9.33483 13.3378L8.00004 12.6357L6.67703 13.3316L5.35401 14.0275L4.45258 14.5017C4.37011 14.5452 4.27708 14.5648 4.18406 14.5582C4.13122 14.5544 4.07959 14.5423 4.03101 14.5224C3.99406 14.5073 3.95887 14.4878 3.92627 14.4641C3.85081 14.4093 3.79222 14.3344 3.75713 14.2479C3.72205 14.1615 3.71188 14.0669 3.72778 13.975L4.03101 12.2072L4.40572 10.0226L4.03101 9.65714L2.70801 8.36665V6.24015L4.03101 6.04779L5.35401 5.85542L5.77878 5.79366L6.66525 3.99564L7.55173 2.19762C7.63946 2.02208 7.81639 1.91999 8.00004 1.91944C8.07472 1.91921 8.15051 1.93577 8.22185 1.97101C8.32026 2.01946 8.39993 2.09916 8.44835 2.19762L9.33483 3.99564L10.2213 5.79366L10.6461 5.85542L11.9691 6.04779Z'
            fill={Colors.COLOR_YELLOW}
          />
        )
      }

      {
        rating >= 0.9 && rating < 1 && (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.2921 6.24015L11.9691 6.04779L10.6461 5.85542L10.2213 5.79366L9.33483 3.99564L8.44835 2.19762C8.39993 2.09916 8.32026 2.01946 8.22185 1.97101C8.15051 1.93577 8.07472 1.91921 8.00004 1.91944C7.81639 1.91999 7.63946 2.02208 7.55173 2.19762L6.66525 3.99564L5.77878 5.79366L5.35401 5.85542L4.03101 6.04779L2.70801 6.24015V8.36665L4.03101 9.65714L4.40572 10.0226L4.03101 12.2072L3.72778 13.975C3.71188 14.0669 3.72205 14.1615 3.75713 14.2479C3.79222 14.3344 3.85081 14.4093 3.92627 14.4641C3.95887 14.4878 3.99406 14.5073 4.03101 14.5224C4.07959 14.5423 4.13122 14.5544 4.18406 14.5582C4.27708 14.5648 4.37011 14.5452 4.45258 14.5017L5.35401 14.0275L6.67703 13.3316L8.00004 12.6357L9.33483 13.3378L10.6461 14.0275L11.5475 14.5017C11.6444 14.5532 11.7568 14.5704 11.8646 14.5517C11.9006 14.5455 11.935 14.5356 11.9675 14.5224C12.1808 14.4363 12.313 14.2109 12.2723 13.975L11.9675 12.1981L11.5944 10.0226L11.9691 9.65714L13.2921 8.36665V6.24015Z'
            fill={Colors.COLOR_YELLOW}
          />
        )
      }
    </svg>
  );
}
