import React, { memo } from 'react';
import './DishQuantity.scss';
import PropTypes from 'prop-types';
import { addDish, removeDish } from '@store/actions/menu';
import { connect } from 'react-redux';

const DishQuantity = ({ place, orders, dish, addDish, removeDish }) => {
  const placeOrders = (orders[place.uri] || []);
  const dishOrder = placeOrders.find(o => o.id === dish.id);
  const ordered = dishOrder?.quantity || 0;

  return (
    <div>
      {ordered === 0 && <div className='dish-quantity__empty' onClick={() => addDish(dish)}>+</div>}
      {ordered > 0 && (
        <div className='dish-quantity__select'>
          <div className='dish-quantity__select--left' onClick={() => removeDish(dish)}>-</div>
          <div className='dish-quantity__select--quantity'>{ordered}</div>
          <div className='dish-quantity__select--right' onClick={() => addDish(dish)}>+</div>
        </div>
      )}
    </div>
  );
};

DishQuantity.propTypes = {
  place: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  dish: PropTypes.object.isRequired,
  addDish: PropTypes.func.isRequired,
  removeDish: PropTypes.func.isRequired,
};

const mapStateToProps = ({ place }) => ({
  place: place.details.data,
  orders: place.menu,
});

const mapDispatchToProps = {
  addDish,
  removeDish
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(DishQuantity));
