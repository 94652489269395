import React from 'react';
import { connect } from 'react-redux';
import { get } from '@utils/lo/lo';
import './UserMailingPreferences.scss';
import mova from 'mova';
import Checkbox from '@components/inputs/Checkbox/Checkbox';
import Button from '@components/inputs/Button/Button';
import Form from '@components/inputs/Form/Form';
import PropTypes from 'prop-types';
import { updateProfile } from '@actions/user';

const t = mova.ns('containers.MailingPreferences');

function UserMailingPreferences(props) {
  const { user, updateUserProfile } = props;
  const { subscribe, subscribeNews, subscribePlaces } = user;

  const submitForm = (model) => {
    updateUserProfile(model);
  };

  return (
    <div className='mailing-preferences'>
      <div className='profile-page__form-container'>
        <Form onSubmit={submitForm}>
          <div className='profile-page__row'>
            <Checkbox value={subscribe} name='subscribe'>{t('notifications')}</Checkbox>
          </div>
          <div className='profile-page__row'>
            <Checkbox value={subscribeNews} name='subscribeNews'>{t('discounts')}</Checkbox>
          </div>
          <div className='profile-page__row'>
            <Checkbox value={subscribePlaces} name='subscribePlaces'>{t('selections')}</Checkbox>
          </div>
          <div className='profile-page__row'>
            <Button behavior='submit' size='medium'>{t('save')}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

UserMailingPreferences.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user: get(user, 'details.data.user'),
});

const mapDispatchToProps = {
  updateUserProfile: user => updateProfile(user)
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMailingPreferences);
