import { connect } from 'react-redux';
import { initialLoading, logError } from '@actions/app';
import PageWrapper from './PageWrapper';

const mapStateToProps = ({ app, modal }) => ({
  loading: app.general.initialLoading,
  modal: modal.current
});

const mapDispatchToProps = {
  loadInitialData: initialLoading,
  logError
};

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper);
