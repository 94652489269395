import React from 'react';
import Colors from '@data/enums/Color.enum';

export function user({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.1215 17.8969C19.6794 16.8496 19.0378 15.8984 18.2325 15.0961C17.4296 14.2915 16.4785 13.65 15.4317 13.207C15.4223 13.2023 15.4129 13.2 15.4036 13.1953C16.8637 12.1406 17.8129 10.4227 17.8129 8.48438C17.8129 5.27344 15.2114 2.67188 12.0004 2.67188C8.78949 2.67188 6.18793 5.27344 6.18793 8.48438C6.18793 10.4227 7.13714 12.1406 8.5973 13.1977C8.58792 13.2023 8.57855 13.2047 8.56918 13.2094C7.51918 13.6523 6.57699 14.2875 5.76839 15.0984C4.96381 15.9013 4.32231 16.8524 3.87933 17.8992C3.44415 18.924 3.20944 20.0228 3.18792 21.1359C3.1873 21.161 3.19169 21.1858 3.20083 21.2091C3.20997 21.2324 3.22368 21.2537 3.24116 21.2716C3.25863 21.2895 3.27951 21.3037 3.30257 21.3134C3.32563 21.3231 3.3504 21.3281 3.37542 21.3281H4.78167C4.8848 21.3281 4.96683 21.2461 4.96917 21.1453C5.01605 19.3359 5.74261 17.6414 7.02699 16.357C8.35589 15.0281 10.1207 14.2969 12.0004 14.2969C13.8801 14.2969 15.645 15.0281 16.9739 16.357C18.2582 17.6414 18.9848 19.3359 19.0317 21.1453C19.034 21.2484 19.1161 21.3281 19.2192 21.3281H20.6254C20.6504 21.3281 20.6752 21.3231 20.6983 21.3134C20.7213 21.3037 20.7422 21.2895 20.7597 21.2716C20.7772 21.2537 20.7909 21.2324 20.8 21.2091C20.8092 21.1858 20.8136 21.161 20.8129 21.1359C20.7895 20.0156 20.5575 18.9258 20.1215 17.8969ZM12.0004 12.5156C10.9246 12.5156 9.91214 12.0961 9.15042 11.3344C8.38871 10.5727 7.96918 9.56016 7.96918 8.48438C7.96918 7.40859 8.38871 6.39609 9.15042 5.63437C9.91214 4.87266 10.9246 4.45312 12.0004 4.45312C13.0762 4.45312 14.0887 4.87266 14.8504 5.63437C15.6121 6.39609 16.0317 7.40859 16.0317 8.48438C16.0317 9.56016 15.6121 10.5727 14.8504 11.3344C14.0887 12.0961 13.0762 12.5156 12.0004 12.5156Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
