export const HEADER_HEIGHT = '94px';
export const GPT_PROMO_HEIGHT = '64px';
export const FOOTER_HEIGHT = '208px';
export const SUMMARY_HEIGHT = '44px';
export const MOBILE_VIEW_WIDTH = '600px';
export const MAP_MOBILE_VIEW_WIDTH = '1265px';
export const FULL_SIZE_CARD_WIDTH = '1060px';

export const DEFAULT_PAGE_SIZE = 10;

export const COOKIE_POLICY_ACCEPTED = 'COOKIE_POLICY_ACCEPTED';

export const USER_LAST_LANG = 'USER_LAST_LANG';
export const USER_LAST_CITY = 'USER_LAST_CITY';

export const ACTIVE_PLACE_PROMO = 'ACTIVE_PLACE_PROMO';
export const PROMO_SUCCESS_PARAM = 'promo_success';
export const CHANNEL_ID_QUERY_PARAM = 'utm_content';
