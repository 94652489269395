import { loadData } from '@utils/redux/actionUtils';
import {
  NOTIFICATIONS_LOADING,
  SAVE_NOTIFICATIONS,
  SAVE_UNREAD_NOTIFICATIONS_COUNT,
  UNREAD_NOTIFICATIONS_COUNT_LOADING
} from '@constants/notification';
import { get } from '@utils/lo/lo';
import api from '@api';
import store from '@store/Store';

export const getNotifications = () => loadData('notifications.getAll')(NOTIFICATIONS_LOADING, SAVE_NOTIFICATIONS);

export const countUnreadNotifications = () => loadData('notifications.countUnread')(UNREAD_NOTIFICATIONS_COUNT_LOADING, SAVE_UNREAD_NOTIFICATIONS_COUNT);

export const markAllAsRead = () => (dispatch) => {
  const apiRequest = get(api, 'notifications.markRead');

  apiRequest().then(() => {
    const currentState = store.getState();

    const readNotifications = (get(currentState, 'notification.details.data') || [])
      .map(note => ({ ...note, tempRead: true }));

    dispatch({ type: SAVE_NOTIFICATIONS, payload: readNotifications });
    dispatch({ type: SAVE_UNREAD_NOTIFICATIONS_COUNT, payload: { count: 0 } });
  });
};
