import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './Popup.scss';
import Animate from '../Animate/Animate';

function Popup({ children, className, open, closing, onClose, ...rest }) {
  const animation = closing ? 'transition.slideUpOut' : 'transition.slideDownIn';
  const duration = closing ? 300 : 500;

  return (
    <>
      {open && (
        <div className='popup__wrapper' onClick={onClose}>
          <Animate animation={animation} duration={duration} delay={0}>
            <div className={`popup ${className}`} {...rest} onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          </Animate>
        </div>
      )}
    </>
  );
}

Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  closing: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Popup.defaultProps = {
  className: '',
  closing: false,
};

export default memo(Popup);
