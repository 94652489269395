import React, { memo } from 'react';
import './DishCard.scss';
import SvgIcon from '../SvgIcon/SvgIcon';
import PropTypes from 'prop-types';
import mova from 'mova';
import DishQuantity from '../DishQuantity/DishQuantity';
import { connect } from 'react-redux';
import { DISH_DETAILS_MODAL } from '@store/constants/modal';
import { openModal } from '@store/actions/modal';
import { addDish, removeDish, toggleLikeDish } from '@store/actions/menu';
import Colors from '@data/enums/Color.enum';
// import CloudinaryImage from "../CloudinaryImage/CloudinaryImage";

const t = mova.ns('components.DishCard');

const DishCard = ({ dish, short = false, className = '', showDishDetails, toggleLikeDish }) => {
  return (
    <div className={`dish-card ${className}`}>
      {dish.photo && !short && (
        <div className='dish-card__photo-wrapper' onClick={() => showDishDetails(dish)}>
          <img className='dish-card__photo' src={dish.photoUrl} alt={dish.name} />
          {/* <CloudinaryImage width={50} height={50} src={dish.photo} alt={dish.name} className='dish-card__photo' /> */}
        </div>
      )}
      <div className='dish-card__content'>
        <div className='dish-card__name-row'>
          {dish.variables?.top && <SvgIcon type='top' />}
          <div className='dish-card__name' onClick={() => showDishDetails(dish)}>{dish.name}</div>
        </div>
        <div className='dish-card__description'>{dish.description}</div>
        {!short && (
          <div className='dish-card__tags'>
            {dish.weight && <div className='dish-card__weight'>{dish.weight}</div>}
            {dish.variables?.hot && <div className='dish-card__tag'><SvgIcon type='hot' /></div>}
            {dish.variables?.vegetarian && <div className='dish-card__tag'><SvgIcon type='vegetarian' /></div>}
          </div>
        )}
      </div>
      <div className='dish-card__actions'>
        <div className='dish-card__price'>{dish.price} {t('uah')}</div>
        {!short && (
          <div
            className={`dish-card__like-wrapper ${dish.isLiked ? 'dish-card__like-wrapper--liked' : ''}`}
            onClick={() => toggleLikeDish(dish)}
          >
            <SvgIcon
              type='heart'
              filled={dish.isLiked}
              fill={dish.isLiked ? 'white' : Colors.COLOR_PRIMARY}
              iconProps={{ size: 16 }}
            />
          </div>
        )}
        <DishQuantity dish={dish} />
      </div>
    </div>
  );
};

DishCard.propTypes = {
  dish: PropTypes.object.isRequired,
  className: PropTypes.string,
  showDishDetails: PropTypes.func.isRequired,
  toggleLikeDish: PropTypes.func.isRequired,
  short: PropTypes.bool,
};

const mapStateToProps = ({ place }) => ({
  place: place.details.data,
});

const mapDispatchToProps = {
  showDishDetails: (meta) => openModal(DISH_DETAILS_MODAL, meta),
  addDish,
  removeDish,
  toggleLikeDish,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(DishCard));
