import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './AskPhoneModal.scss';
import Button from '@components/inputs/Button/Button';
import Modal from '@components/feedback/Modal/Modal';
import { withRouter } from 'react-router-dom';
import Form from '@components/inputs/Form/Form';
import PhoneInput from '@components/inputs/PhoneInput/PhoneInput';
import { phoneRegexp } from '@utils/validation/validationUtils';

const t = mova.ns('containers.AskPhoneModal');
const errorsT = mova.ns('errors.validation');

function AskPhoneModal({ savePhoneAndFinishReservation, reservation, location }) {
  const placeUri = useMemo(
    () => location.pathname.substring(location.pathname.slice(1).indexOf('/') + 2, location.pathname.lastIndexOf('/')),
    [location.pathname]
  );

  const submitForm = (model) => savePhoneAndFinishReservation(placeUri, model, reservation);

  return (
    <Modal title={t('lastStep')} className='ask-phone-modal'>
      <Form onValidSubmit={submitForm}>
        <div className='ask-phone-modal__text'>
          {t('text')}
        </div>

        <div className='ask-phone-modal__row'>
          <PhoneInput
            name='phone'
            fullWidth
            validations={{
              matchRegexp: phoneRegexp
            }}
            validationErrors={{
              matchRegexp: errorsT('phone'),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='ask-phone-modal__actions'>
          <Button fullWidth size='medium' behavior='submit'>{t('continue')}</Button>
        </div>
      </Form>
    </Modal>
  );
}

AskPhoneModal.propTypes = {
  savePhoneAndFinishReservation: PropTypes.func.isRequired,
  reservation: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

AskPhoneModal.defaultProps = {};

export default memo(withRouter(AskPhoneModal));
