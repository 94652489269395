import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import measureElements from '@utils/measure/measureElements';
import ReactResizeDetector from 'react-resize-detector';
import InfoTag from './InfoTag';
import './InfoTags.scss';
import { isDefined } from '@utils/lo/lo';
import { connect } from 'react-redux';
import { getRenderedTagRows } from '@utils/place/placeUtils';
import mova from 'mova';
import TextLink from '@components/navigation/TextLink/TextLink';
import { withRouter } from 'react-router-dom';
import { baseUrlPath } from '@utils/url/urlUtils';

const t = mova.ns('components.InfoTags');

const TAG_MARGIN = 8;

function InfoTags(props) {
  const { tags, rows, checked, className, tagTranslations, placeUri, clickable, history } = props;

  const [width, setWidth] = useState(0);
  const [measuredTags, setMeasuredTags] = useState([]);

  const searchByTag = (name) => {
    history.push(`${baseUrlPath()}/?q=${name}`);
  };

  const wrappedTags = (tags || []).map(tag => (
    <InfoTag
      title={tagTranslations[tag.name] || tag.name}
      key={tag.id}
      checked={checked}
      clickable={clickable}
      onClick={clickable ? () => searchByTag(tagTranslations[tag.name] || tag.name) : undefined}
    />
  ));

  useEffect(() => {
    measureElements(wrappedTags, (output) => {
      setMeasuredTags(output);
    });
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isDefined(tags) || !Array.isArray(tags)) {
    return null;
  }

  let renderedTags = wrappedTags;

  if (isDefined(rows)) {
    renderedTags = getRenderedTagRows(width, rows, measuredTags, TAG_MARGIN);

    if (renderedTags.length < tags.length) {
      renderedTags.pop();
      renderedTags.push(
        <TextLink
          className='info-tags__more'
          type='text'
          key='more'
          size='medium'
          to={`${baseUrlPath()}/places/${placeUri}`}
        >
          {t('more')}
        </TextLink>
      );
    }
  }

  const onContainerResize = (newWidth) => {
    setWidth(newWidth);
  };

  return (
    <div className={`info-tags ${!isDefined(rows) || rows > 1 ? 'info-tags--multirow' : ''} ${className}`}>
      <ReactResizeDetector handleWidth onResize={onContainerResize} />
      {renderedTags}
    </div>
  );
}

InfoTags.propTypes = {
  tags: PropTypes.array,
  rows: PropTypes.number,
  checked: PropTypes.bool,
  className: PropTypes.string,
  tagTranslations: PropTypes.object.isRequired,
  placeUri: PropTypes.string,
  clickable: PropTypes.bool,
  history: PropTypes.object.isRequired
};

InfoTags.defaultProps = {
  tags: [],
  rows: undefined,
  checked: false,
  className: '',
  placeUri: undefined,
  clickable: false
};

const mapStateToProps = ({ app }) => ({
  tagTranslations: app.tags.data
});

export default memo(withRouter(connect(mapStateToProps)(InfoTags)));
