import React from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './Distance.scss';

const t = mova.ns('components.Distance');

const getUiDistance = distance => {
  if (distance >= 1000) {
    return (distance / 1000).toFixed(1);
  }

  if (distance >= 100) {
    return Math.round(distance / 50) * 50;
  }

  return Math.round(distance);
};

function Distance(props) {
  const { distance, className, ...rest } = props;

  if (!distance) {
    return null;
  }

  const units = distance >= 1000 ? 'km' : 'm';

  return <span className={`distance ${className}`} {...rest}>{getUiDistance(distance)} {t(units)}</span>;
}

Distance.propTypes = {
  distance: PropTypes.number,
  className: PropTypes.string
};

Distance.defaultProps = {
  className: '',
  distance: undefined,
};

export default Distance;
