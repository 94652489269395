import React from 'react';
import Colors from '@data/enums/Color.enum';

export function chartArrow({ fill = Colors.TEXT_DEFAULT }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.5 7C17.1406 6.99479 16.8021 7.18229 16.6198 7.49479C16.4427 7.80729 16.4427 8.19271 16.6198 8.50521C16.8021 8.81771 17.1406 9.00521 17.5 9H19.5834L14.75 13.8333L9.45836 8.54167C9.06773 8.15104 8.43232 8.15104 8.04169 8.54167L1.29169 15.2917C1.03127 15.5417 0.927106 15.9167 1.01565 16.2656C1.1094 16.6146 1.38544 16.8906 1.7344 16.9844C2.08336 17.0729 2.45836 16.9687 2.70836 16.7083L8.75002 10.6667L14.0417 15.9583C14.4323 16.349 15.0677 16.349 15.4584 15.9583L21 10.4167V12.5C20.9948 12.8594 21.1823 13.1979 21.4948 13.3802C21.8073 13.5573 22.1927 13.5573 22.5052 13.3802C22.8177 13.1979 23.0052 12.8594 23 12.5V8C23 7.44792 22.5521 7 22 7H17.5Z'
        fill={fill}
      />
    </svg>
  );
}
