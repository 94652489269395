import { connect } from 'react-redux';
import ReservationHistory from './ReservationHistory';
import { getOldReservations } from '@actions/reservation';
import { get } from '@utils/lo/lo';

const mapStateToProps = ({ reservation, app }) => ({
  reservations: get(reservation, 'old.data.rows') || [],
  loading: reservation.old.isLoading || (!reservation.old.data && !reservation.old.error),
  timezone: app.time.data.DEFAULT_TZ
});

const mapDispatchToProps = {
  getOldReservations
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationHistory);
