import React, { memo } from 'react';
import './Logo.scss';
import logo from '@theme/images/reservble.png';
import logoWhite from '@theme/images/reservble-white.png';
import uaFlag from '@theme/images/ukraine_flag.jpg';
import { Link } from 'react-router-dom';
import Image from '@components/display/Image/Image';
import { baseUrlPath } from '@utils/url/urlUtils';
import PropTypes from 'prop-types';

function Logo({ className, white }) {
  return (
    <Link to={baseUrlPath()} className={`app-logo ${className}`}>
      <div className='app-logo__img'>
        <Image src={white ? logoWhite : logo} alt='RESERVBLE' />
      </div>
      {!white && (
        <div className='app-logo__ua'>
          <Image src={uaFlag} alt='Ukraine' />
        </div>
      )}
    </Link>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  white: PropTypes.bool,
};

Logo.defaultProps = {
  className: '',
  white: false,
};

export default memo(Logo);
