import React from 'react';
import Colors from '@data/enums/Color.enum';

export function check({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.3744 4.45312H19.7361C19.5064 4.45312 19.2885 4.55859 19.1478 4.73906L9.48456 16.9805L4.85097 11.1094C4.78087 11.0204 4.69153 10.9484 4.58964 10.8989C4.48775 10.8494 4.37597 10.8236 4.26268 10.8234H2.6244C2.46737 10.8234 2.38065 11.0039 2.47675 11.1258L8.89628 19.2586C9.19628 19.6383 9.77284 19.6383 10.0752 19.2586L21.5221 4.75313C21.6182 4.63359 21.5314 4.45312 21.3744 4.45312Z'
        fill={fill || Colors.TEXT_DEFAULT}
        className='fill'
      />
    </svg>
  );
}
