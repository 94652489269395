import React, { memo } from 'react';
import './InputGroup.scss';
import PropTypes from 'prop-types';

/**
 * Can wrap several <Input>, <Button>, <Select> or similar components into one grouped item
 */
function InputGroup(props) {
  const { children, className, error, errorPresent, fullWidth } = props;

  return (
    <div
      className={
        `input-group ${className}
        ${errorPresent && 'input-group--has-error'}
        ${fullWidth && 'input-group--full-width'}`
      }
    >
      {children}
      {errorPresent && <div className='input-group__error'>{error}</div>}
    </div>
  );
}

InputGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  errorPresent: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

InputGroup.defaultProps = {
  className: '',
  error: undefined,
  errorPresent: false,
  fullWidth: false
};

export default memo(InputGroup);
