import React from 'react';
import Colors from '@data/enums/Color.enum';

export function logo() {
  return (
    <svg viewBox='0 0 242 143' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M194.922 118.963C188.235 118.963 181.728 117.517 175.583 114.806C152.631 104.324 128.413 84.2628 121.545 78.4795C119.557 76.8529 118.473 74.5034 118.473 71.9732C118.473 69.4429 119.557 67.0935 121.545 65.4669C128.413 59.5028 152.45 39.6225 175.583 29.1402C181.547 26.4292 188.054 24.9834 194.922 24.9834C220.947 24.9834 241.911 46.1288 241.911 71.9732C241.911 97.8176 220.766 118.963 194.922 118.963ZM133.473 71.7924C142.691 79.3831 162.39 94.7451 180.825 103.239C185.162 105.227 189.861 106.312 194.741 106.312C213.717 106.312 229.079 90.9498 229.079 71.9732C229.079 52.9965 213.717 37.6345 194.741 37.6345C189.861 37.6345 185.162 38.7189 180.825 40.7069C162.39 48.8397 142.691 64.2018 133.473 71.7924Z'
        fill={Colors.COLOR_PRIMARY}
      />
      <path
        d='M196.729 82.2748C202.518 82.2748 207.211 77.5817 207.211 71.7925C207.211 66.0033 202.518 61.3102 196.729 61.3102C190.939 61.3102 186.246 66.0033 186.246 71.7925C186.246 77.5817 190.939 82.2748 196.729 82.2748Z'
        fill='black'
      />
      <path
        d='M129.497 104.143L93.1704 71.7924L129.497 39.4417C129.678 39.261 129.678 39.261 129.859 39.0803C131.666 37.0922 132.208 34.2006 130.943 31.8511C130.581 31.3089 130.22 30.9474 129.859 30.4052C116.846 12.3322 95.7006 0.584778 72.025 0.584778C32.6258 0.584778 0.998108 32.574 0.998108 71.7924C0.998108 111.192 32.6258 143 72.025 143C95.7006 143 117.027 131.253 129.859 113.18C130.22 112.637 130.581 112.276 130.943 111.734C132.208 109.384 131.847 106.493 129.859 104.505C129.678 104.324 129.497 104.324 129.497 104.143ZM71.6635 128.903C40.2165 128.903 14.7336 103.42 14.9143 71.9731C14.9143 40.5261 40.2165 15.0432 71.6635 15.0432C89.1943 15.0432 104.918 22.9953 115.219 35.4657L88.4714 59.1413L88.2907 59.322L79.6156 67.0934C76.5432 69.8044 76.5432 74.5034 79.6156 77.2143L88.2907 84.9857L88.4714 85.1664L115.219 108.842C104.918 120.951 89.1943 128.903 71.6635 128.903Z'
        fill='black'
      />
    </svg>
  );
}
