import { createBrowserHistory } from 'history';
import store from '@store/Store';

const browserHistory = createBrowserHistory();

browserHistory.pushBack = () => {
  const historyList = store.getState().history.list;
  const historyLength = historyList.length;
  if (historyLength >= 2) {
    browserHistory.push(historyList[1].pathname);
  } else if (historyLength === 1) {
    browserHistory.goBack();
  } else {
    browserHistory.push('/');
  }
};

export default browserHistory;
