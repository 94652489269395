import React, { useMemo } from 'react';
import './Policy.scss';
import mova from 'mova';
import RuPolicy from './Langs/RuCookiePolicy';
import UaPolicy from './Langs/UaCookiePolicy';
import EnPolicy from './Langs/EnCookiePolicy';
import EsPolicy from './Langs/EsCookiePolicy';

const langName = mova.ns('name');
const t = mova.ns('pages.Policy');

function CookiePolicy() {
  const policy = useMemo(() => {
    switch (langName()) {
      case 'ru': {
        return RuPolicy;
      }
      case 'en': {
        return EnPolicy;
      }
      case 'es': {
        return EsPolicy;
      }
      default:
        return UaPolicy;
    }
  }, []);

  return (
    <div>
      <div className='policy'>
        <div className='policy__content'>
          <div className='policy__heading'>{t('cookies')}</div>
          <div className='policy__text'>
            <div dangerouslySetInnerHTML={{ __html: policy }} />
            {/* eslint-disable-line react/no-danger */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
