import React from 'react';
import PropTypes from 'prop-types';
import './StarRating.scss';
import { withFormsy } from 'formsy-react';
import StarRating from './StarRating';

function StarRatingFormsy(props) {
  const { value, setValue, ...rest } = props;

  const newValueClicked = (newValue) => {
    setValue(newValue);
  };

  return <StarRating setRating={newValueClicked} rating={value} {...rest} />;
}

StarRatingFormsy.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired
};

StarRatingFormsy.defaultProps = {
  value: undefined,
};

export default withFormsy(StarRatingFormsy);
