import { useMapEvents } from 'react-leaflet';
import { distanceBetweenPoints } from '@utils/geolocation/geolocation';

function MapEventTracker({ positionUpdated, handleZoom }) {
  const newBoundsHandler = (e) => {
    if (typeof positionUpdated === 'function') {
      positionUpdated(
        e.target.getCenter(),
        distanceBetweenPoints(e.target.getBounds()._northEast, e.target.getCenter())
      );
    }
  };

  useMapEvents({
    dragend: newBoundsHandler,
    load: newBoundsHandler,
    resize: newBoundsHandler,
    zoomend: (e) => {
      newBoundsHandler(e);
      handleZoom(e);
    },
  });
  return null;
}

export default MapEventTracker;
