import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';

import Immutable from '@utils/immutable/Immutable';
import reducers from '@store/reducers';

const initialState = Immutable({});

const middleware = [
  reduxThunk
];

let enhance = null;

let devTool = f => f;
if (typeof window !== 'undefined') {
  devTool = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;
}

enhance = compose(
  applyMiddleware(...middleware),
  devTool
);

const store = createStore(reducers, initialState, enhance);
export default store;
