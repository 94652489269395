import React from 'react';
import Colors from '@data/enums/Color.enum';

export function guests({ fill = Colors.TEXT_BLACK }) {
  return (
    <svg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 2.5C6.07813 2.5 4.5 4.07813 4.5 6C4.5 7.92188 6.07813 9.5 8 9.5C9.92188 9.5 11.5 7.92188 11.5 6C11.5 4.07813 9.92188 2.5 8 2.5ZM16 2.5C14.0781 2.5 12.5 4.07813 12.5 6C12.5 7.92188 14.0781 9.5 16 9.5C17.9219 9.5 19.5 7.92188 19.5 6C19.5 4.07813 17.9219 2.5 16 2.5ZM8 4C9.11458 4 10 4.88542 10 6C10 7.11458 9.11458 8 8 8C6.88542 8 6 7.11458 6 6C6 4.88542 6.88542 4 8 4ZM16 4C17.1146 4 18 4.88542 18 6C18 7.11458 17.1146 8 16 8C14.8854 8 14 7.11458 14 6C14 4.88542 14.8854 4 16 4ZM4.75 10.5C3.78646 10.5 3 11.2865 3 12.25V17C3 19.7552 5.24479 22 8 22C9.27083 22 10.4271 21.5208 11.3125 20.7344C10.9948 20.3385 10.724 19.8958 10.5156 19.4271C9.88021 20.0885 8.98958 20.5 8 20.5C6.06771 20.5 4.5 18.9323 4.5 17V12.25C4.5 12.1146 4.61458 12 4.75 12H10.0104C10.0625 11.4323 10.2865 10.9167 10.6302 10.5H4.75ZM12.75 10.5C11.7917 10.5 11 11.2917 11 12.25V17C11 19.75 13.25 22 16 22C18.75 22 21 19.75 21 17V12.25C21 11.2917 20.2083 10.5 19.25 10.5H12.75ZM12.75 12H19.25C19.3958 12 19.5 12.1042 19.5 12.25V17C19.5 18.9427 17.9427 20.5 16 20.5C14.0573 20.5 12.5 18.9427 12.5 17V12.25C12.5 12.1042 12.6042 12 12.75 12Z'
        fill={fill}
      />
    </svg>
  );
}
