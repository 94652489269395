import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './ChangeCityPopup.scss';
import mova from 'mova';
import Popup from '@components/feedback/Popup/Popup';
import Button from '@components/inputs/Button/Button';
import { getCityFromUrl, urlWithNewCity } from '@utils/url/urlUtils';
import PropTypes from 'prop-types';
import TextLink from '@components/navigation/TextLink/TextLink';
import { useHistory } from 'react-router-dom';
import LocalStorageService from '@services/LocalStorage.service';
import { USER_LAST_CITY } from '@utils/constants';

const t = mova.ns('containers.ChangeCityPopup');

const ChangeCityPopup = ({ userCity }) => {
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const urlCity = getCityFromUrl();
  const history = useHistory();

  useEffect(() => {
    if (userCity && userCity !== urlCity) {
      setOpen(true);
    }
  }, [userCity]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeCity = () => {
    LocalStorageService.set(USER_LAST_CITY, userCity);
    history.push(urlWithNewCity(userCity));
    setClosing(true);
  };

  return (
    <Popup className='change-city-popup' open={open} closing={closing} onClose={() => setClosing(true)}>
      <div className='change-city-popup__guess'>
        {t('yourCity')} - {t(`cities.${userCity}`)}?
      </div>
      <div className='change-city-popup__options'>
        <Button className='change-city-popup__yes' onClick={changeCity}>
          {t('yes')}
        </Button>
        <TextLink className='change-city-popup__no' size='medium' onClick={() => setClosing(true)}>
          {t('no')} {t(`cities.${urlCity}`)}
        </TextLink>
      </div>
    </Popup>
  );
};

ChangeCityPopup.propTypes = {
  userCity: PropTypes.string,
};

ChangeCityPopup.defaultProps = {
  userCity: null,
};

const mapStateToProps = ({ user }) => ({
  userCity: user.location.differentCity
});

export default connect(mapStateToProps)(ChangeCityPopup);
