import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';

function UserMarker(props) {
  const { location } = props;

  const marker = (
    <div className='custom-marker custom-marker--user'>
      <SvgIcon fill='#fff' type='user' />
    </div>
  );

  const iconMarkup = renderToStaticMarkup(marker);

  const customMarkerIcon = divIcon({
    html: iconMarkup,
    className: 'leaflet-marker',
  });

  return (
    <Marker
      icon={customMarkerIcon}
      position={location}
    />
  );
}

UserMarker.propTypes = {
  location: PropTypes.object.isRequired
};

UserMarker.defaultProps = {};

export default memo(UserMarker);
