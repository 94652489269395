import Immutable from '@utils/immutable/Immutable';
import { PLACE_LOADING, SAVE_PLACE } from '../../constants/place';
import { REPLACE_DISH } from '../../constants/menu';
import { clone } from '@utils/lo/lo';

const initialState = { data: null, isLoading: true, error: null };

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case PLACE_LOADING: {
      const next = { ...state, isLoading: true, error: null };
      return Immutable(next);
    }
    case SAVE_PLACE: {
      if (error) {
        return Immutable({ ...state, error, isLoading: false });
      }
      return Immutable({ ...state, isLoading: false, data: payload, error: null });
    }
    case REPLACE_DISH: {
      const newDish = action.payload;
      const dishes = clone(state.data?.dishes) || [];
      const dishIndex = dishes.findIndex(d => d.id === newDish?.id);

      if (dishIndex > -1) {
        dishes.splice(dishIndex, 1, newDish);
        return Immutable({ ...state, data: { ...state.data, dishes } });
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};
