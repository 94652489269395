import React from 'react';
import Colors from '@data/enums/Color.enum';

export function read({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.75 3.77344H16.3875C15.2367 3.77344 14.1117 4.10391 13.1437 4.72734L12 5.46094L10.8563 4.72734C9.88924 4.10403 8.76299 3.77282 7.6125 3.77344H2.25C1.83516 3.77344 1.5 4.10859 1.5 4.52344V17.8359C1.5 18.2508 1.83516 18.5859 2.25 18.5859H7.6125C8.76328 18.5859 9.88828 18.9164 10.8563 19.5398L11.8969 20.2102C11.9273 20.2289 11.9625 20.2406 11.9977 20.2406C12.0328 20.2406 12.068 20.2313 12.0984 20.2102L13.1391 19.5398C14.1094 18.9164 15.2367 18.5859 16.3875 18.5859H21.75C22.1648 18.5859 22.5 18.2508 22.5 17.8359V4.52344C22.5 4.10859 22.1648 3.77344 21.75 3.77344ZM7.6125 16.8984H3.1875V5.46094H7.6125C8.44219 5.46094 9.24844 5.69766 9.94453 6.14531L11.0883 6.87891L11.25 6.98438V17.8125C10.1344 17.2125 8.8875 16.8984 7.6125 16.8984ZM20.8125 16.8984H16.3875C15.1125 16.8984 13.8656 17.2125 12.75 17.8125V6.98438L12.9117 6.87891L14.0555 6.14531C14.7516 5.69766 15.5578 5.46094 16.3875 5.46094H20.8125V16.8984ZM9.30234 8.46094H4.94766C4.85625 8.46094 4.78125 8.54063 4.78125 8.63672V9.69141C4.78125 9.7875 4.85625 9.86719 4.94766 9.86719H9.3C9.39141 9.86719 9.46641 9.7875 9.46641 9.69141V8.63672C9.46875 8.54063 9.39375 8.46094 9.30234 8.46094ZM14.5312 8.63672V9.69141C14.5312 9.7875 14.6062 9.86719 14.6977 9.86719H19.05C19.1414 9.86719 19.2164 9.7875 19.2164 9.69141V8.63672C19.2164 8.54063 19.1414 8.46094 19.05 8.46094H14.6977C14.6062 8.46094 14.5312 8.54063 14.5312 8.63672ZM9.30234 11.7422H4.94766C4.85625 11.7422 4.78125 11.8219 4.78125 11.918V12.9727C4.78125 13.0688 4.85625 13.1484 4.94766 13.1484H9.3C9.39141 13.1484 9.46641 13.0688 9.46641 12.9727V11.918C9.46875 11.8219 9.39375 11.7422 9.30234 11.7422ZM19.0523 11.7422H14.6977C14.6062 11.7422 14.5312 11.8219 14.5312 11.918V12.9727C14.5312 13.0688 14.6062 13.1484 14.6977 13.1484H19.05C19.1414 13.1484 19.2164 13.0688 19.2164 12.9727V11.918C19.2188 11.8219 19.1438 11.7422 19.0523 11.7422Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
