import { loadData } from '@utils/redux/actionUtils';
import {
  PLACE_LOADING,
  PLACE_REVIEWS_LOADING,
  PLACE_SLOTS_LOADING,
  SAVE_PLACE,
  SAVE_PLACE_REVIEWS,
  SAVE_PLACE_SLOTS,
  PLACE_DEPOSITS_LOADING,
  SAVE_PLACE_DEPOSITS,
  PLACE_CERTIFICATES_LOADING,
  SAVE_PLACE_CERTIFICATES
} from '@constants/place';
import store from '@store/Store';
import moment from 'moment';
import { openModal } from './modal';
import { PROMO_SUCCESS_MODAL } from '@constants/modal';
import promoUtils from '@utils/place/promoUtils';
import { get } from '@utils/lo/lo';
import api from '@api';

export const getPlace = (id) => loadData('places.getOne', id)(PLACE_LOADING, SAVE_PLACE);

export const getDeposits = (id) => loadData('places.getDeposits', id)(PLACE_DEPOSITS_LOADING, SAVE_PLACE_DEPOSITS);

export const getCertificates = (id) => loadData('places.getCertificates', id)(PLACE_CERTIFICATES_LOADING, SAVE_PLACE_CERTIFICATES);

export const getAvailableSlots = (placeId) => {
  if (!placeId) {
    return;
  }

  const currentState = store.getState();
  const { seats, date } = currentState.reservation.quick;
  const formattedDate = moment(date).format('YYYY-MM-DDTHH:mm');
  return loadData('places.getSlots', {
    placeId: Math.ceil(placeId),
    seats,
    time: formattedDate
  })(PLACE_SLOTS_LOADING, SAVE_PLACE_SLOTS);
};

export const getReviews = (placeUri, page, pageSize) => loadData('places.getReviews', {
  placeUri,
  page,
  pageSize
})(PLACE_REVIEWS_LOADING, SAVE_PLACE_REVIEWS);

export const registerInPromo = (place, channelId) => dispatch => {
  const apiRequest = get(api, 'places.registerInPromo');
  apiRequest(place.uri, channelId)
    .then(() => {
      dispatch(openModal(PROMO_SUCCESS_MODAL, { place, channelId }));
      promoUtils.clearPromo();
    });
};

export const prepareAndRegisterInPromo = () => dispatch => {
  const promoData = promoUtils.getPromoData();
  const apiRequest = get(api, 'places.getOne');
  apiRequest(promoData.uri)
    .then((res) => {
      dispatch(registerInPromo(res.data, promoData.channelId));
    });
};
