import moment from 'moment-timezone';
import memoize from 'memoize-one';
import store from '@store/Store';

export const worksUntil = memoize(({ openHours, isOpen }) => {
  const { current, DEFAULT_TZ } = store.getState().app.time.data;
  const weekDay = Math.ceil(moment.tz(current, DEFAULT_TZ).format('E'));
  const todayInfo = openHours.find(item => item.dayOfWeek === weekDay.toString());
  const tomorrowInfo = openHours.find(item => item.dayOfWeek === ((+weekDay % 7) + 1).toString());

  return isOpen
    ? `${(`0${todayInfo.closedH}`).slice(-2)}:${(`0${todayInfo.closedM}`).slice(-2)}`
    : `${(`0${tomorrowInfo.openH}`).slice(-2)}:${(`0${tomorrowInfo.openM}`).slice(-2)}`;
});

export const getRenderedTagRows = memoize((width, rows, measuredTags, tagMargin, showMax) => {
  const renderedTags = [];
  let tagIndex = 0;

  if (width > 0 && measuredTags.length > 0) {
    outer: // eslint-disable-line
    for (let i = 0; i < rows; i++) {
      let freeSpace = width;

      for (let j = tagIndex; j < measuredTags.length; j++) {
        const currentTag = measuredTags[j];

        if (currentTag.width + tagMargin < freeSpace) {
          renderedTags.push(currentTag.element);
          tagIndex++;
          freeSpace -= (currentTag.width + tagMargin);
        } else {
          break;
        }

        if (showMax !== undefined && renderedTags.length >= showMax) {
          break outer;
        }
      }
    }
  }

  return renderedTags;
});

export const getPlaceCoordinates = place => ({
  lat: place.location.coordinates[0],
  lng: place.location.coordinates[1]
});
