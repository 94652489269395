import React, { useEffect, useState } from 'react';
import mova from 'mova';
import './BuyCertificate.scss';
import Loading from '@components/feedback/Loading/Loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SvgIcon from '../../components/display/SvgIcon/SvgIcon';
import { getCertificates } from '@store/actions/place';
import PropTypes from 'prop-types';
import CertificateCard from '../../components/display/CertificateCard/CertificateCard';
import { baseUrlPath } from '@utils/url/urlUtils';
import Button from '../../components/inputs/Button/Button';
import TextInput from '../../components/inputs/TextInput/TextInput';
import PhoneInput from '../../components/inputs/PhoneInput/PhoneInput';
import Form from '../../components/inputs/Form/Form';
import { phoneRegexp } from '../../../utils/validation/validationUtils';
import api from '@api';
import { get } from '@utils/lo/lo';
import Colors from '@data/enums/Color.enum';

const t = mova.ns('pages.BuyCertificate');

const BuyCertificate = ({ history, match, getCertificates, certificates, user }) => {
  const [certificate, setCertificate] = useState(null);

  const { placeUri, certificateId } = match.params;

  const goBackToPlace = () => {
    history.push(`${baseUrlPath()}/places/${placeUri}`);
  };

  useEffect(() => {
    if (placeUri) {
      getCertificates(placeUri);
    }
  }, [placeUri, getCertificates]);

  useEffect(() => {
    if (certificates.length) {
      setCertificate(certificates.find(c => c.id === +certificateId));
    }
  }, [certificates, certificateId]);

  if (!certificate) {
    return <Loading />;
  }

  const goToPayment = (data) => {
    const body = { ...data, certificateId };
    api.billing.buyCertificate(placeUri, body)
      .then(res => {
        const data = {
          paymentTypes: {
            card: 'Y',
            portmone: 'Y',
            clicktopay: 'Y',
            gpay: 'Y',
            applepay: 'Y',
          },
          priorityPaymentTypes: {
            applepay: '1',
            gpay: '2',
            card: '3',
            portmone: '4',
            clicktopay: '5',
          },
          payee: {
            payeeId: res.data.portmonePayeeId,
          },
          order: {
            description: '',
            shopOrderNumber: `${res.data.id}`,
            billAmount: `${certificate.price}`,
            successUrl: '',
            failureUrl: '',
            expTime: '1800',
          },
          style: {
            type: 'light',
            logo: '',
            backgroundColorHeader: '',
            backgroundColorButtons: Colors.COLOR_PRIMARY_STRONG,
            colorTextAndIcons: Colors.TEXT_BLACK,
            borderColorList: Colors.TEXT_DISABLED,
            bcMain: '',
          }
        };

        const form = document.createElement('form');
        form.action = 'https://www.portmone.com.ua/gateway/';
        form.method = 'post';
        form.target = '_blank';

        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'bodyRequest';
        input.value = JSON.stringify(data);
        form.appendChild(input);

        const inputTypeRequest = document.createElement('input');
        inputTypeRequest.type = 'hidden';
        inputTypeRequest.name = 'typeRequest';
        inputTypeRequest.value = 'json';
        form.appendChild(inputTypeRequest);

        document.body.appendChild(form);
        form.submit();
      });
  };

  return (
    <div className='buy-certificate'>
      <div className='buy-certificate__container'>
        <div className='buy-certificate__title'>
          <SvgIcon type='chevronDown' className='buy-certificate__title-icon' onClick={goBackToPlace} />
          <div>{t('title')}</div>
        </div>
        <CertificateCard certificate={certificate} />
        <Form onValidSubmit={goToPayment} className='buy-certificate__form'>
          <TextInput
            placeholder={t('fields.name')}
            name='firstName'
            fullWidth
            fontSize={16}
            value={user?.firstName}
            required
            validationErrors={{
              isDefaultRequiredValue: t('errors.required')
            }}
          />
          <TextInput
            placeholder={t('fields.surname')}
            name='lastName'
            value={user?.lastName}
            fullWidth
            fontSize={16}
          />
          <PhoneInput
            name='phone'
            fullWidth
            required
            value={user?.phone}
            validations={{
              matchRegexp: phoneRegexp
            }}
            validationErrors={{
              matchRegexp: t('errors.phone'),
              isDefaultRequiredValue: t('errors.required')
            }}
          />
          <div className='buy-certificate__check-phone'>{t('checkPhone')}</div>
          <TextInput
            name='email'
            placeholder={t('fields.email')}
            fullWidth
            fontSize={16}
            value={user?.email}
            validations='isEmail'
            validationErrors={{
              isEmail: t('errors.email'),
            }}
          />
          <div>
            <Button fullWidth size='large' behavior='submit'>{t('payWithPortmone')}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

BuyCertificate.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getCertificates: PropTypes.func.isRequired,
  certificates: PropTypes.array.isRequired,
  user: PropTypes.object,
};

BuyCertificate.defaultProps = {
  user: null,
};

const mapStateToProps = ({ place, user }) => ({
  certificates: (place.certificates.data || []).filter(c => c.active),
  user: get(user, 'details.data.user'),
});

const mapDispatchToProps = {
  getCertificates,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BuyCertificate));
