import React, { memo } from 'react';
import './PriceIndicator.scss';
import PropTypes from 'prop-types';

function PriceIndicator(props) {
  const { price, className } = props;

  return (
    <div className={`price-indicator ${className}`}>
      <div className='item--filled'>$</div>
      <div className={`${price >= 2 ? 'item--filled' : 'item--empty'}`}>$</div>
      <div className={`${price >= 3 ? 'item--filled' : 'item--empty'}`}>$</div>
      <div className={`${price >= 4 ? 'item--filled' : 'item--empty'}`}>$</div>
    </div>
  );
}

PriceIndicator.propTypes = {
  price: PropTypes.number.isRequired,
  className: PropTypes.string
};

PriceIndicator.defaultProps = {
  className: ''
};

export default memo(PriceIndicator);
