import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.scss';
import PropTypes from 'prop-types';
import PlaceMarker from './CustomMarker/PlaceMarker';
import { getPlaceCoordinates } from '@utils/place/placeUtils';
import UserMarker from './CustomMarker/UserMarker';
import { getCityObjectFromUrl, baseUrlPath, getCityFromUrl } from '@utils/url/urlUtils';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MapEventTracker from './MapEventTracker/MapEventTracker';
import City from '@data/enums/City.enum';

const setTooltipPermanent = (mapRef, val) => {
  mapRef.current.eachLayer((l) => {
    if (l.getTooltip()) {
      const tooltip = l.getTooltip();
      l.unbindTooltip().bindTooltip(tooltip, {
        permanent: val
      });
    }
  });
};

const Map = forwardRef(
  ({ userLocation, fullHeight, places, placePage, differentCity }, ref) => {
    const currentCity = getCityFromUrl();
    const cityConfig = Object.values(City).find(c => c.name === currentCity);
    const initialZoom = placePage ? 16 : cityConfig?.zoom || 13;
    const history = useHistory();
    const mapRef = useRef(null);

    useEffect(() => {
      // Leaflet map container takes 100% of parent height only upon resize, otherwise it supports only fixed values
      window.dispatchEvent(new Event('resize'));
    }, []);

    const defaultCenter = placePage ? getPlaceCoordinates(places[0]) :
      userLocation?.lat && !differentCity ? userLocation : getCityObjectFromUrl().center;

    useImperativeHandle(ref, () => ({
      resetCenter() {
        mapRef.current.flyTo(defaultCenter, initialZoom);
      },
      setCenter(val, zoom) {
        mapRef.current.flyTo(val, zoom);
      }
    }));

    const goToPlace = (place) => {
      if (!placePage) {
        history.push(`${baseUrlPath()}/places/${place.uri}`);
      }
    };

    const handleZoom = (e) => {
      setTooltipPermanent(mapRef, e.target._zoom >= 17);
    };

    const markerList = places.map(item => {
      const coordinates = getPlaceCoordinates(item);
      return (
        <PlaceMarker
          key={item.id}
          place={item}
          coordinates={coordinates}
          small={places.length > 1}
          onClick={() => goToPlace(item)}
        />
      );
    });

    return (
      <div className={`map ${fullHeight && 'map--full-height'}`}>
        <MapContainer
          center={defaultCenter}
          zoom={initialZoom}
          zoomControl={false}
          whenCreated={map => {
            mapRef.current = map;
          }}
        >
          <MapEventTracker handleZoom={handleZoom} />
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
          />
          {userLocation && <UserMarker location={userLocation} />}
          {markerList}
          <ZoomControl position='bottomright' />
        </MapContainer>
      </div>
    );
  }
);

Map.propTypes = {
  places: PropTypes.array.isRequired,
  fullHeight: PropTypes.bool,
  userLocation: PropTypes.object,
  placePage: PropTypes.bool,
  differentCity: PropTypes.string
};

Map.defaultProps = {
  userLocation: undefined,
  fullHeight: false,
  placePage: false,
  differentCity: null
};

const mapStateToProps = ({ user }) => ({
  differentCity: user.location.differentCity
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Map);
