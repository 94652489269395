import React from 'react';

export function back({ fill = '#fff' }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.60889 17.9957C9.9789 17.9582 10.2914 17.7107 10.4139 17.3607C10.5339 17.0107 10.4414 16.6207 10.1714 16.3657L7.10889 13.3032L19.0089 13.3032C19.0364 13.3057 19.0664 13.3057 19.0964 13.3032C19.6214 13.2782 20.0239 12.8357 19.9989 12.3107C19.9764 11.7882 19.5314 11.3857 19.0089 11.4082L7.10889 11.4082L10.1864 8.34568C10.5589 7.97318 10.5589 7.37068 10.1864 6.99818C9.81639 6.62568 9.21139 6.62568 8.84139 6.99818L4.16389 11.6907L3.48389 12.3557L4.16389 13.0207L8.84139 17.7132C9.03889 17.9182 9.32389 18.0232 9.60889 17.9957Z'
        fill={fill}
      />
    </svg>
  );
}
