import React from 'react';
import Colors from '@data/enums/Color.enum';

export function location({ fill, outerFill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3883 20.2534C10.7108 20.6731 10.9798 21.0136 11.1686 21.2496C11.3257 21.446 11.4855 21.6403 11.6453 21.8346C11.8273 22.0551 12.1727 22.0551 12.3547 21.8346C12.5148 21.6405 12.6743 21.446 12.8314 21.2496C13.0202 21.0136 13.2892 20.6731 13.6117 20.2534C14.2564 19.4145 15.1171 18.256 15.9791 16.9801C16.8396 15.7065 17.7097 14.3036 18.3671 12.9779C19.0127 11.676 19.5 10.3585 19.5 9.28485C19.5 5.26154 16.1421 2 12 2C7.85786 2 4.5 5.26154 4.5 9.28485C4.5 10.3585 4.98732 11.676 5.63288 12.9779C6.29032 14.3036 7.16042 15.7065 8.02087 16.9801C8.88288 18.256 9.74365 19.4145 10.3883 20.2534ZM12 3.32452C8.61098 3.32452 5.86364 5.99305 5.86364 9.28485C5.86364 10.0399 6.22859 11.1269 6.86144 12.4031C7.48241 13.6553 8.31685 15.004 9.16095 16.2534C10.0035 17.5005 10.8473 18.6363 11.4811 19.4611C11.6763 19.7151 11.8513 19.9393 12 20.1279C12.1487 19.9393 12.3237 19.7151 12.5189 19.4611C13.1527 18.6363 13.9965 17.5005 14.8391 16.2534C15.6832 15.004 16.5176 13.6553 17.1386 12.4031C17.7714 11.1269 18.1364 10.0399 18.1364 9.28485C18.1364 5.99305 15.389 3.32452 12 3.32452Z'
        fill={outerFill || fill || Colors.TEXT_DEFAULT}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 5.53205C9.86617 5.53205 8.13636 7.21223 8.13636 9.28485C8.13636 11.3575 9.86617 13.0376 12 13.0376C14.1338 13.0376 15.8636 11.3575 15.8636 9.28485C15.8636 7.21223 14.1338 5.53205 12 5.53205ZM12 6.85657C10.6193 6.85657 9.5 7.94375 9.5 9.28485C9.5 10.626 10.6193 11.7131 12 11.7131C13.3807 11.7131 14.5 10.626 14.5 9.28485C14.5 7.94375 13.3807 6.85657 12 6.85657Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
