import { ADD_DISH, CLEAR_ORDER, REMOVE_DISH, REPLACE_DISH } from '../constants/menu';
import { clone, get } from '@utils/lo/lo';
import api from '@api';
import { toastr } from 'react-redux-toastr';
import mova from 'mova';
import store from '@store/Store';
import { openModal } from './modal';
import { LOGIN_MODAL } from '../constants/modal';

const likeT = mova.ns('types.likes');

export const addDish = (dish) => dispatch => {
  const place = store.getState().place.details?.data;
  dispatch({ type: ADD_DISH, payload: dish, place });
};

export const removeDish = (dish) => dispatch => {
  const place = store.getState().place.details?.data;
  dispatch({ type: REMOVE_DISH, payload: dish, place });
};

export const clearOrder = () => dispatch => {
  const place = store.getState().place.details?.data;
  dispatch({ type: CLEAR_ORDER, payload: place });
};

export const toggleLikeDish = (dish) => dispatch => {
  const isLoggedIn = !!store.getState().user.details.data?.user?.id;

  if (!isLoggedIn) {
    dispatch(openModal(LOGIN_MODAL));
    return;
  }

  const like = {
    targetId: dish.id,
    type: 'dish'
  };

  const apiRequest = get(api, 'places.toggleLike');
  apiRequest(like)
    .then((res) => {
      const updatedDish = clone(dish);
      updatedDish.isLiked = res.data.isLiked;
      dispatch({ type: REPLACE_DISH, payload: updatedDish });

      const text = res.data.isLiked ? likeT('addDish') : likeT('deleteDish');
      toastr.info(text);
    });
};
