import Immutable from '@utils/immutable/Immutable';

const loadDataReducer = (
  startActionType,
  endActionType,
  keepDataAtStart = () => false
) => (state = { data: undefined, isLoading: true, error: null }, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case startActionType: {
      const next = { ...state, isLoading: true, error: null };
      if (!keepDataAtStart({ state, type, payload, error })) {
        next.data = null;
      }
      return Immutable(next);
    }
    case endActionType: {
      if (error) {
        return Immutable({ ...state, error, isLoading: false });
      }
      return Immutable({ ...state, isLoading: false, data: payload, error: null });
    }
    default: {
      return state;
    }
  }
};

const truthful = () => true;

export { loadDataReducer, truthful };
