import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '@components/inputs/TextInput/TextInput';
import Formsy from 'formsy-react';
import './MainPageSearchInput.scss';
import mova from 'mova';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { searchPlaces } from '@actions/search';
import { updateQuickReservationParams } from '@actions/reservation';
import Button from '../../components/inputs/Button/Button';
import SvgIcon from '../../components/display/SvgIcon/SvgIcon';
import { baseUrlPath } from '@utils/url/urlUtils';
import useMedia from 'utils/hooks/useMedia';

const t = mova.ns('containers.SearchBar');

function MainPageSearchInput({
  searchPlaces,
  location,
  history,
  mapRef,
  setPage,
  updateQuickReservationParams
}) {
  const [tempQ, setTempQ] = useState('');

  const queryParams = queryString.parse(location.search);
  const { q } = queryParams;
  const showAiHint = useMedia(['(min-width: 900px)'], [true], false);

  const setQ = (search) => {
    setTempQ(search);

    if (!search) {
      delete queryParams.q;
    } else {
      queryParams.q = search;
    }

    history.push(`${window.location.pathname}?${queryString.stringify(queryParams)}`);
  };

  useEffect(() => {
    setTempQ(q);
    setPage(0);
    updateQuickReservationParams();
    searchPlaces(location.search, (result) => {
      if (!mapRef.current) {
        return;
      }

      if (result.length === 1) {
        mapRef.current.setCenter(result[0].location.coordinates, 17);
      } else {
        mapRef.current.resetCenter();
      }
    });
  }, [location.search, location.pathname, searchPlaces, q, mapRef, setPage, updateQuickReservationParams]);
  // location.pathname is required to watch for city change in URL

  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      setQ(tempQ);
    }
  };

  const clearInput = () => {
    setQ('');
  };

  const goToAiSearch = () => {
    history.push(`${baseUrlPath()}/search${tempQ ? `?start=${tempQ}` : ''}`);
  };

  return (
    <Formsy className='main-page-search-input'>
      <TextInput
        className='main-page-search-input__input-wrapper'
        inputClass='main-page-search-input__input'
        fontSize={18}
        placeholder={t('placeholder')}
        icon='search'
        endIcon={tempQ ? 'close' : ''}
        endIconClick={clearInput}
        name='text-input'
        onChange={(e) => setTempQ(e.target.value)}
        value={tempQ}
        fullWidth
        onKeyDown={handlePressEnter}
      />
      <Button inputClass='main-page-search-input__ai-search' onClick={goToAiSearch}>
        <SvgIcon type='magic' />
      </Button>
      {showAiHint && (
        <div className='main-page-search-input__ai-hint'>
          <img src='/img/ai_hand.png' className='main-page-search-input__hint-image' alt={t('useAi')} />
          <span className='main-page-search-input__hint-label'>{t('useAi')}</span>
        </div>
      )}
    </Formsy>
  );
}

MainPageSearchInput.propTypes = {
  searchPlaces: PropTypes.func.isRequired,
  updateQuickReservationParams: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  mapRef: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
};

MainPageSearchInput.defaultProps = {};

const mapDispatchToProps = {
  searchPlaces,
  updateQuickReservationParams
};

export default memo(withRouter(connect(null, mapDispatchToProps)(MainPageSearchInput)));
