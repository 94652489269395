import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './Loading.scss';

function Loading(props) {
  const { inline, className } = props;

  return (
    <div className={`loader ${inline ? 'loader--inline' : 'loader--absolute'} ${className}`}>
      <div className='lds-ripple'><div /><div /></div>
    </div>
  );
}

Loading.propTypes = {
  inline: PropTypes.bool,
  className: PropTypes.string
};

Loading.defaultProps = {
  inline: false,
  className: ''
};

export default memo(Loading);
