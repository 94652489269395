import React from 'react';
import './TextLink.scss';
import PropTypes from 'prop-types';
import RouterLink from '@components/navigation/RouterLink/RouterLink';
import { isFunction } from '@utils/lo/lo';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';

function TextLink(props) {
  const { to, onClick, children, size, type, className, disabled, icon, external, newPage, title, ...rest } = props;

  const textLinkClicked = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  const buttonLinkClicked = () => {
    if (!disabled && isFunction(onClick)) {
      onClick();
    }
  };

  const classes = `text-link text-link--${size} text-link--${type} ${className}`;

  let view;
  const target = newPage ? '_blank' : '_self';

  if (to) {
    if (external) {
      view = (
        <a
          className={classes}
          href={to}
          target={target}
          title={title}
          {...rest}
          rel='noopener noreferrer'
        >
          {icon && <SvgIcon className='text-link__icon' type={icon} />}
          {children}
        </a>
      );
    } else {
      view = (
        <RouterLink
          className={classes}
          to={to}
          onClick={textLinkClicked}
          disabled={disabled}
          target={target}
          title={title}
          {...rest}
        >
          {icon && <SvgIcon className='text-link__icon' type={icon} />}
          {children}
        </RouterLink>
      );
    }
  } else {
    view = (
      <div
        className={classes}
        onClick={buttonLinkClicked}
        onKeyPress={buttonLinkClicked}
        role='button'
        disabled={disabled}
        title={title}
      >
        {icon && <SvgIcon className='text-link__icon' type={icon} />}
        {children}
      </div>
    );
  }

  return view;
}

TextLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  external: PropTypes.bool,
  newPage: PropTypes.bool,
  title: PropTypes.string,
};

TextLink.defaultProps = {
  to: undefined,
  onClick: undefined,
  size: 'small', // small, medium, large
  type: 'default', // default, text, success, danger
  className: '',
  disabled: false,
  icon: undefined,
  external: false,
  newPage: false,
  title: undefined
};

export default TextLink;
