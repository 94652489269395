import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import './SideTabs.scss';
import PropTypes from 'prop-types';
import Tab from './Tab';

function SideTabs(props) {
  const { tabs, className } = props;

  const tabsView = tabs.map(({ name, path, ...rest }) => (
    <Tab
      name={name}
      path={path}
      selected={path === props.location.pathname}
      key={name}
      {...rest}
    />
  ));

  return (
    <div className={`side-tabs ${className}`}>
      {tabsView}
    </div>
  );
}

SideTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  className: PropTypes.string
};

SideTabs.defaultProps = {
  className: ''
};

export default memo(withRouter(SideTabs));
