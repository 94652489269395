import React from 'react';

export function donatePin() {
  return (
    <svg viewBox='0 0 25 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M25 12.5C24.3421 24.3421 13.898 22.6974 12.5 29.852C11.4309 22.6974 0 24.0132 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z' fill='url(#paint0_linear_357_3800)' />
      <path fillRule='evenodd' clipRule='evenodd' d='M12.4998 21C17.1942 21 20.9998 17.1944 20.9998 12.5C20.9998 7.80558 17.1942 4 12.4998 4C7.82411 4 4.03017 7.7753 4 12.4439H7.23252C7.89669 12.4612 8.56674 12.7029 9.08397 13.2095L12.1932 16.2544C12.5165 16.5825 12.5165 17.1005 12.1932 17.4171C11.8582 17.7337 11.3292 17.7337 11.006 17.4171L8.50796 14.9708C8.39629 14.8614 8.23171 14.8614 8.12004 14.9708C8.00836 15.0802 8.00836 15.2413 8.12004 15.3507L10.6004 17.7797C10.8649 18.0387 11.2117 18.1769 11.5878 18.1769C11.964 18.1769 12.3108 18.0387 12.5753 17.7797C12.7046 17.6531 12.7986 17.5034 12.8692 17.3538H16.3957C16.8542 17.3538 17.2304 17.7337 17.2304 18.1826C17.2304 18.6316 16.8542 19 16.3957 19H10.6651C9.98325 19 9.24267 18.7525 8.71368 18.2345L6.55071 16.1162C6.27446 15.8572 5.92768 15.7191 5.56327 15.7191H4.63054C5.89929 18.8174 8.94467 21 12.4998 21ZM18.3765 8.85792C19.3052 9.90551 19.1759 11.4827 18.1238 12.3864L14.3503 16.0587L10.6944 12.3864C9.64235 11.4827 9.51304 9.88824 10.4417 8.85792C11.3645 7.82759 12.9926 7.70672 14.0447 8.61041L14.4091 8.92124L14.7735 8.61041C15.8432 7.70096 17.4537 7.82759 18.3765 8.85792Z' fill='white' />
      <defs>
        <linearGradient id='paint0_linear_357_3800' x1='12.5' y1='0' x2='12.5' y2='29.852' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#FFCA3A' />
          <stop offset='1' stopColor='#E6B634' />
        </linearGradient>
      </defs>
    </svg>

  );
}
