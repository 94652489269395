import { connect } from 'react-redux';
import AskPhoneModal from './AskPhoneModal';
import { savePhoneAndFinishReservation } from '@actions/user';
import { get } from '@utils/lo/lo';

const mapStateToProps = ({ reservation, user }) => ({
  reservation: reservation.details.data,
  user: get(user, 'details.data.user'),
});

const mapDispatchToProps = {
  savePhoneAndFinishReservation
};

export default connect(mapStateToProps, mapDispatchToProps)(AskPhoneModal);
