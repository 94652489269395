import React, { memo } from 'react';
import './TextInput.scss';
import PropTypes from 'prop-types';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import { get, isFunction } from '@utils/lo/lo';
import { withFormsy } from 'formsy-react';

function TextInput({
  icon, placeholder, onChange, type, name, label, fullWidth, fontSize, value, setValue, errorMessage,
  onBlur, onFocus, onMouseDown, isPristine, autoComplete, className, onKeyDown, endIcon, endIconClick, inputClass,
  children
}) {
  const inputProps = { onBlur, onFocus, onMouseDown, onKeyDown, type, name, placeholder, autoComplete };
  const errorPresent = !isPristine && Boolean(errorMessage);

  const inputChanged = (e) => {
    if (isFunction(onChange)) {
      onChange(e);
    } else {
      setValue(get(e, 'currentTarget.value'));
    }
  };

  return (
    <label
      className={
        `text-input__wrapper
        input-group-item__wrapper
        ${label && 'text-input--has-label'}
        ${className}
        ${fullWidth && 'text-input--full-width'}`
      }
    >
      {label && <div className='text-input__label'>{label}</div>}
      {icon && <SvgIcon type={icon} className='text-input__start-icon' />}
      {
        endIcon && (
          <SvgIcon
            type={endIcon}
            onClick={endIconClick}
            className={`text-input__end-icon ${endIconClick ? 'clickable' : ''}`}
          />
        )
      }
      <input
        className={
          `text-input
          input-group-item
          ${icon && 'text-input--has-icon'}
          ${fullWidth && 'text-input--full-width'}
          ${errorPresent && 'text-input--has-error'}
          ${inputClass}`
        }
        onChange={inputChanged}
        style={{ fontSize }}
        value={value || ''}
        autoComplete={name}
        {...inputProps}
      />
      {errorPresent && <div className='text-input__error'>{errorMessage}</div>}
      {children}
    </label>
  );
}

TextInput.propTypes = {
  icon: PropTypes.string,
  endIcon: PropTypes.string,
  endIconClick: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  fontSize: PropTypes.number,
  required: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isPristine: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.string,
  inputClass: PropTypes.string,
};

TextInput.defaultProps = {
  icon: null,
  endIcon: null,
  endIconClick: undefined,
  placeholder: null,
  onChange: null,
  type: 'text',
  name: undefined,
  label: undefined,
  fullWidth: false,
  fontSize: 16,
  required: false,
  onBlur: undefined,
  onFocus: undefined,
  onMouseDown: undefined,
  onKeyDown: undefined,
  className: '',
  autoComplete: undefined,
  errorMessage: null,
  value: '',
  inputClass: '',
};

export default memo(withFormsy(TextInput));
