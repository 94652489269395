import React, { Fragment, memo, useState } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './CancelReservationModal.scss';
import Button from '@components/inputs/Button/Button';
import Modal from '@components/feedback/Modal/Modal';
import Form from '@components/inputs/Form/Form';
import RadioButton from '@components/inputs/RadioButton/RadioButton';
import TextArea from '@components/inputs/TextArea/TextArea';
import RadioGroup from '@components/inputs/RadioButton/RadioGroup';
import { withRouter } from 'react-router-dom';

const t = mova.ns('containers.CancelReservationModal');
const errorsT = mova.ns('errors.validation');

const feedback = {
  CHANGE_OF_PLANS: 'changeOfPlans',
  MISTAKE: 'mistake',
  LATE: 'late',
  CHANGE_TIME: 'changeTime',
  OTHER: 'other'
};

function CancelReservationModal(props) {
  const { confirmCancelReservation, reservationId, placeUri, history } = props;

  const [formState, setFormState] = useState({});

  const submitForm = (model) => {
    const body = { feedback: model.feedback === feedback.OTHER ? model.feedbackText : model.feedback };
    confirmCancelReservation(placeUri, reservationId, body);

    if (model.feedback === feedback.CHANGE_TIME) {
      setTimeout(() => history.push(`/places/${placeUri}`), 500);
    }
  };

  return (
    <Modal title={t('title')} className='cancel-reservation-modal'>
      <div className='cancel-reservation-modal__question'>
        {t('why')}
      </div>

      <Form onValidSubmit={submitForm} onChange={model => setFormState(model)}>
        <RadioGroup
          name='feedback'
          required
          validationErrors={{
            isDefaultRequiredValue: errorsT('requiredOption')
          }}
        >
          {(setValue, selected) => (
            <Fragment>
              <div className='cancel-reservation-modal__row'>
                <RadioButton
                  name='feedback'
                  value={feedback.CHANGE_OF_PLANS}
                  selected={selected}
                  setValue={setValue}
                  required
                >
                  {t('changeOfPlans')}
                </RadioButton>
              </div>

              <div className='cancel-reservation-modal__row'>
                <RadioButton
                  name='feedback'
                  value={feedback.MISTAKE}
                  selected={selected}
                  setValue={setValue}
                  required
                >
                  {t('mistake')}
                </RadioButton>
              </div>

              <div className='cancel-reservation-modal__row'>
                <RadioButton
                  name='feedback'
                  value={feedback.LATE}
                  selected={selected}
                  setValue={setValue}
                  required
                >
                  {t('late')}
                </RadioButton>
              </div>

              <div className='cancel-reservation-modal__row'>
                <RadioButton
                  name='feedback'
                  value={feedback.CHANGE_TIME}
                  selected={selected}
                  setValue={setValue}
                  required
                >
                  {t('changeTime')}
                </RadioButton>
              </div>

              <div className='cancel-reservation-modal__row'>
                <RadioButton
                  name='feedback'
                  value={feedback.OTHER}
                  selected={selected}
                  setValue={setValue}
                  required
                >
                  {t('other')}
                </RadioButton>
              </div>
            </Fragment>
          )}
        </RadioGroup>

        <div className='login-register-modal__row'>
          <TextArea
            name='feedbackText'
            fullWidth
            fontSize={14}
            disabled={formState.feedback !== feedback.OTHER}
            required={formState.feedback === feedback.OTHER}
          />
        </div>

        <div className='cancel-reservation-modal__actions'>
          <Button fullWidth size='medium' behavior='submit' type='danger'>{t('cancel')}</Button>
        </div>
      </Form>
    </Modal>
  );
}

CancelReservationModal.propTypes = {
  confirmCancelReservation: PropTypes.func.isRequired,
  reservationId: PropTypes.number.isRequired,
  placeUri: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

CancelReservationModal.defaultProps = {};

export default memo(withRouter(CancelReservationModal));
