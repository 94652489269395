import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import { connect } from 'react-redux';
import { postFeedback } from '@actions/user';
import './FeedbackForm.scss';
import TextLink from '@components/navigation/TextLink/TextLink';
import StarRatingFormsy from '@components/display/StarRating/StarRatingFormsy';
import Form from '@components/inputs/Form/Form';
import Button from '@components/inputs/Button/Button';
import TextArea from '@components/inputs/TextArea/TextArea';
import useMedia from '@utils/hooks/useMedia';
import { MOBILE_VIEW_WIDTH } from '@utils/constants';

const ratingT = mova.ns('components.DetailedRating');
const feedbackT = mova.ns('containers.FeedbackForm');

function FeedbackForm(props) {
  const { place, createUserFeedback, closeForm, review = {}, className } = props;

  const { kitchen, interior, service, mood, comment } = review;

  const [isFormValid, setIsFormValid] = useState(false);

  const showMobileView = useMedia([`(max-width: ${MOBILE_VIEW_WIDTH})`], [true], false);

  const enableButton = () => setIsFormValid(true);
  const disableButton = () => setIsFormValid(false);

  const submitForm = (model) => {
    createUserFeedback(place.uri, model);
    if (closeForm) {
      closeForm();
    }
  };

  return (
    <Form
      onSubmit={submitForm}
      onValid={enableButton}
      onInvalid={disableButton}
    >
      <div className={`feedback-form ${className}`}>
        <div className='feedback-form__heading'>{feedbackT('whatAbout')}</div>
        <div className='feedback-form__rating'>
          <div className='feedback-form__left'>
            <div className='feedback-form__stat'>
              <div className='feedback-form__stat--name'>{ratingT('kitchen')}</div>
              <div className='feedback-form__stat--value'>
                <StarRatingFormsy name='kitchen' value={kitchen} required />
              </div>
            </div>
            <div className='feedback-form__stat'>
              <div className='feedback-form__stat--name'>{ratingT('interior')}</div>
              <div className='feedback-form__stat--value'>
                <StarRatingFormsy name='interior' value={interior} required />
              </div>
            </div>
          </div>
          <div className='feedback-form__right'>
            <div className='feedback-form__stat'>
              <div className='feedback-form__stat--name'>{ratingT('service')}</div>
              <div className='feedback-form__stat--value'>
                <StarRatingFormsy name='service' value={service} required />
              </div>
            </div>
            <div className='feedback-form__stat'>
              <div className='feedback-form__stat--name'>{ratingT('atmosphere')}</div>
              <div className='feedback-form__stat--value'>
                <StarRatingFormsy name='mood' value={mood} required />
              </div>
            </div>
          </div>
        </div>
        <div className='feedback-form__heading'>{feedbackT('comment')}</div>
        <TextArea
          name='comment'
          placeholder={feedbackT('commentPlaceholder')}
          fullWidth
          value={comment}
          fontSize={16}
        />
        <div className='feedback-form__buttons'>
          <TextLink
            type='danger'
            size={showMobileView ? 'medium' : 'small'}
            className='feedback-form__cancel'
            onClick={closeForm}
          >
            {feedbackT('cancel')}
          </TextLink>
          <Button
            behavior='submit'
            disabled={!isFormValid && Object.keys(review).length === 0}
            fullWidth={showMobileView}
            size={showMobileView ? 'medium' : 'small'}
          >
            {feedbackT('send')}
          </Button>
        </div>
      </div>
    </Form>
  );
}

FeedbackForm.propTypes = {
  place: PropTypes.object,
  createUserFeedback: PropTypes.func.isRequired,
  closeForm: PropTypes.func,
  review: PropTypes.object,
  className: PropTypes.string
};

FeedbackForm.defaultProps = {
  place: null,
  review: {},
  className: '',
  closeForm: null,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createUserFeedback: (url, body) => postFeedback(url, body)
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(FeedbackForm));
