import LocalStorageService from '@services/LocalStorage.service';

export const setGeolocationModalSeen = () => {
  LocalStorageService.set('geolocationModalSeen', 'true');
};

export const geolocationModalSeen = () => LocalStorageService.get('geolocationModalSeen') === 'true';

export const distanceBetweenPoints = ({ lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 }) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lng2 - lng1) * p)) / 2;

  return Math.round(12742 * Math.asin(Math.sqrt(a)) * 1000); // 2 * R; R = 6371 km
};
