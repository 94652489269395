import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './ReservationSuccessModal.scss';
import Button from '@components/inputs/Button/Button';
import Modal from '@components/feedback/Modal/Modal';
import { withRouter } from 'react-router-dom';
import { openModal } from '@actions/modal';
import { REGISTRATION_MODAL } from '@constants/modal';
import { connect } from 'react-redux';

const t = mova.ns('containers.ReservationSuccessModal');

function ReservationSuccessModal({ openRegistrationModal, place }) {
  const smsConfirmationOn = place.smsConfirmation;

  return (
    <Modal title={smsConfirmationOn ? t('title') : undefined} className='reservation-success-modal'>
      <p>{smsConfirmationOn ? t('p1') : t('p1noSms')}</p>
      <p>{t('p2')}</p>
      <Button onClick={openRegistrationModal} className='reservation-success-modal__action'>
        {t('register')}
      </Button>
    </Modal>
  );
}

ReservationSuccessModal.propTypes = {
  openRegistrationModal: PropTypes.func.isRequired,
  place: PropTypes.object.isRequired,
};

ReservationSuccessModal.defaultProps = {};

const mapStateToProps = ({ place }) => ({
  place: place.details.data,
});

const mapDispatchToProps = {
  openRegistrationModal: () => openModal(REGISTRATION_MODAL)
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(withRouter(ReservationSuccessModal)));
