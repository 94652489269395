import React from 'react';
import Colors from '@data/enums/Color.enum';

export function cancel({ fill = Colors.COLOR_DANGER }) {
  return (
    <svg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='7' cy='7' r='7' fill={fill} />
      <path
        d='M7.60694 7L10.6831 3.3332C10.7347 3.27227 10.6913 3.17969 10.6116 3.17969H9.67647C9.6214 3.17969 9.56866 3.2043 9.53233 3.24648L6.99522 6.27109L4.45811 3.24648C4.42296 3.2043 4.37022 3.17969 4.31397 3.17969H3.37882C3.29913 3.17969 3.25577 3.27227 3.30733 3.3332L6.3835 7L3.30733 10.6668C3.29578 10.6804 3.28837 10.697 3.28598 10.7147C3.28359 10.7323 3.28632 10.7503 3.29385 10.7665C3.30137 10.7826 3.31338 10.7963 3.32844 10.8058C3.3435 10.8154 3.36099 10.8204 3.37882 10.8203H4.31397C4.36905 10.8203 4.42179 10.7957 4.45811 10.7535L6.99522 7.72891L9.53233 10.7535C9.56749 10.7957 9.62022 10.8203 9.67647 10.8203H10.6116C10.6913 10.8203 10.7347 10.7277 10.6831 10.6668L7.60694 7Z'
        fill='white'
      />
    </svg>
  );
}
