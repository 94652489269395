import React from 'react';
import Colors from '@data/enums/Color.enum';

export function users({ fill = Colors.TEXT_BLACK, size = 16 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.75 8C5.99264 8 7 6.99264 7 5.75C7 4.50736 5.99264 3.5 4.75 3.5C3.50736 3.5 2.5 4.50736 2.5 5.75C2.5 6.99264 3.50736 8 4.75 8Z'
        fill={fill}
      />
      <path
        d='M7.3125 9.25C6.4325 8.80313 5.46125 8.625 4.75 8.625C3.35687 8.625 0.5 9.47938 0.5 11.1875V12.5H5.1875V11.9978C5.1875 11.4041 5.4375 10.8088 5.875 10.3125C6.22406 9.91625 6.71281 9.54844 7.3125 9.25Z'
        fill={fill}
      />
      <path
        d='M10.625 8.99902C8.99781 8.99902 5.75 10.004 5.75 11.999V13.499H15.5V11.999C15.5 10.004 12.2522 8.99902 10.625 8.99902Z'
        fill={fill}
      />
      <path
        d='M10.625 7.99902C12.1438 7.99902 13.375 6.76781 13.375 5.24902C13.375 3.73024 12.1438 2.49902 10.625 2.49902C9.10622 2.49902 7.875 3.73024 7.875 5.24902C7.875 6.76781 9.10622 7.99902 10.625 7.99902Z'
        fill={fill}
      />
    </svg>
  );
}
