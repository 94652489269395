import React, { memo } from 'react';
import './ViewSelector.scss';
import PropTypes from 'prop-types';
import mova from 'mova';
import { VIEW_MAP } from '@constants/app';
import Button from '@components/inputs/Button/Button';

const t = mova.ns('components.ViewSelector');

function ViewSelector({ view, toggleView, className, small }) {
  const mapIsOpen = view === VIEW_MAP;

  return (
    <Button
      className={`view-selector ${className} ${small ? 'view-selector--small' : ''}`}
      type='flat'
      onClick={toggleView}
      icon={mapIsOpen ? 'tiles' : 'map'}
      size='large'
    >
      {small ? '' : mapIsOpen ? t('tiles') : t('map')}
    </Button>
  );
}

ViewSelector.propTypes = {
  view: PropTypes.string.isRequired,
  toggleView: PropTypes.func.isRequired,
  className: PropTypes.string,
  small: PropTypes.bool,
};

ViewSelector.defaultProps = {
  className: '',
  small: false,
};

export default memo(ViewSelector);
