import React from 'react';
import PropTypes from 'prop-types';
import './ProfilePage.scss';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import UserProfile from '@containers/Profile/UserProfile';
import UserPreferences from '@containers/Profile/UserPreferences/UserPreferences';
import UserFeedbacks from '@containers/Profile/UserFeedbacks/UserFeedbacks';
import UserMailingPreferences from '@containers/Profile/UserMailingPreferences/UserMailingPreferences';
import Page404 from '../Error/Page404';

function ProfileRouter(props) {
  const { match } = props;

  return (
    <Switch>
      <Route exact path={`${match.url}/profile`} component={UserProfile} />
      <Route exact path={`${match.url}/preferences`} component={UserPreferences} />
      <Route exact path={`${match.url}/feedback`} component={UserFeedbacks} />
      <Route exact path={`${match.url}/mailing`} component={UserMailingPreferences} />
      <Redirect from={`${match.url}`} to={`${match.url}/profile`} />
      <Route path='*' component={Page404} />
    </Switch>
  );
}

ProfileRouter.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ProfileRouter);
