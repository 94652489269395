import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TextInput from '@components/inputs/TextInput/TextInput';
import NumberFormat from 'react-number-format';

function ReactNumberFormat(props) {
  const { phoneChanged, ...rest } = props;

  return (
    <NumberFormat
      onValueChange={phoneChanged}
      customInput={TextInput}
      {...rest}
    />
  );
}

ReactNumberFormat.propTypes = {
  phoneChanged: PropTypes.func.isRequired,
};

ReactNumberFormat.defaultProps = {};

export default memo(ReactNumberFormat);
