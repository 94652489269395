import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Notifications from './Notifications';
import { getNotifications, markAllAsRead } from '@actions/notification';

const mapStateToProps = ({ notification }) => ({
  notifications: notification.details.data,
  loading: notification.details.isLoading || (!notification.details.data && !notification.details.error)
});

const mapDispatchToProps = {
  getNotifications,
  markAllAsRead
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifications));
