import { connect } from 'react-redux';
import { toggleUserSubmenu, userSignOut } from '@actions/user';
import TopUserMenu from './TopUserMenu';
import { withRouter } from 'react-router-dom';
import { get } from '@utils/lo/lo';

const mapStateToProps = ({ user }) => ({
  user: get(user, 'details.data.user'),
  submenuOpen: user.submenu
});

const mapDispatchToProps = {
  onSignOutClick: userSignOut,
  toggleUserSubmenu
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopUserMenu));
