/* eslint-disable */

export default `
<p><strong>Ця веб-сторінка використовує файли &laquo;кукі&raquo;</strong></p>
<p><span>Ми використовуємо файли cookie, щоб персоналізувати вміст і рекламу, інтегрувати функції соціальних мереж та аналізувати наш трафік. Ми також передаємо нашим партнерам із соціальних мереж, реклами й аналітики інформацію про те, як ви користуєтеся нашим сайтом. Вони можуть поєднувати її з іншою інформацією, яку ви їм надали або яку вони зібрали під час вашого користування їхніми службами. Ви погоджуєтеся з нашим використанням файлів cookie, якщо продовжите користуватись цим веб-сайтом.</span></p>

<p><strong>Загальна інформація про файли &laquo;кукі&raquo;</strong></p>
<p><span>Файли кукі - це невеликі текстові файли, які можуть використовуватись веб-сторінками для підвищення ефективності роботи користувача.</span></p>
<p><span>Згідно Закону, ми можемо зберігати файли кукі на вашому пристрої, якщо вони справді необхідні для повноцінної роботи цієї веб-сторінки. Для всіх інших типів кукі нам необхідно отримати дозвіл від вас.</span></p>
<p><span>Ця веб-сторінка використовує різні типи кукі. Деякі кукі розміщуються сервісами сторонніх компаній, які використовуються на наших сторінках.</span></p>
<p><span>Ви можете будь-коли змінити або скасувати свою згоду з Політикою використання файлів cookie на нашому веб-сайті.</span></p>
<p><span>Дізнайтеся більше про нас, нашу контактну інформацію та як ми обробляємо персональні дані у розділі про <a href='https://reservble.com/privacy-policy' target='_blank'>Політику конфіденційності</a>.</span></p>

<p><strong>Необхідні файли &laquo;кукі&raquo;</strong></p>
<p><span>Необхідні кукі допомагають зробити веб-сторінку корисною, дозволяючи використовувати її основні функції, такі як навігацію по веб-сторінці і доступ до її захищених розділів. Веб-сторінка не може нормально функціонувати без цих файлів кукі.</span></p>

<p><strong>Привілейовані файли &laquo;кукі&raquo;</strong></p>
<p><span>Привілейовані кукі дозволяють веб-сторінці запам'ятовувати інформацію, яка змінює спосіб поводження веб-сторінки і її вигляд, наприклад, мову, яку ви обрали, або регіон, де ви знаходитесь.</span></p>

<p><strong>Статистичні файли &laquo;кукі&raquo;</strong></p>
<p><span>На основі зібраної анонімної інформації та звітів, статистичні файли кукі допомагають власникам веб-сторінок зрозуміти, яким чином відвідувачі взаємодіють із веб-сторінками.</span></p>

<p><strong>Маркетингові файли &laquo;кукі&raquo;</strong></p>
<p><span>Маркетингові кукі використовуються для відстежування відвідувачів на різних веб-сторінках. Це робиться для того, щоб показувати рекламу, яка відноситься і підходить до окремого користувача, і тим самим є більш цінною для видавців і сторонніх рекламодавців.</span></p>

<p><strong>Некласифіковані файли &laquo;кукі&raquo;</strong></p>
<p><span>Некласифіковані кукі - це кукі-файли, які знаходяться ще в процесі класифікації разом із постачальниками окремих видів кукі.</span></p>
`;
