import React, { PureComponent, memo } from 'react';
import PropTypes from 'prop-types';

class CalendarCustomInput extends PureComponent {
  render() {
    const { onClick, value, className } = this.props;

    return (
      <input
        onClick={onClick}
        value={value}
        type='text'
        readOnly
        className={className}
      />
    );
  }
}

CalendarCustomInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  className: PropTypes.string
};

CalendarCustomInput.defaultProps = {
  value: null,
  className: null
};

export default memo(CalendarCustomInput);
