import React from 'react';

export function top() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15.7656 11.5061H15.4102V12.7691H15.7657C16.6525 12.7373 16.6518 11.5376 15.7656 11.5061Z' fill='#D94375' />
      <path d='M21.9713 9.83363C21.916 9.67289 21.7857 9.54484 21.6178 9.48609L16.106 7.55785L12.4595 3.20772C12.2393 2.93076 11.7607 2.93076 11.5404 3.20772L7.89396 7.55785L2.38224 9.48609C2.21427 9.54484 2.08404 9.67289 2.02869 9.83363C1.97333 9.99438 1.99892 10.1702 2.09822 10.3109L5.35607 14.9278L5.5963 20.4697C5.60364 20.6386 5.69243 20.795 5.83736 20.8944C5.98228 20.9938 6.16743 21.0251 6.33989 20.9795L12 19.4824L17.66 20.9795C17.8325 21.0251 18.0177 20.9938 18.1626 20.8944C18.3075 20.795 18.3963 20.6386 18.4036 20.4697L18.6439 14.9278L21.9017 10.3109C22.0011 10.1702 22.0267 9.99441 21.9713 9.83363ZM8.862 11.5061H8.50649V14.5064C8.47766 15.2392 7.36317 15.2386 7.33462 14.5064V11.5061H6.97915C6.20255 11.4789 6.20314 10.4272 6.97915 10.4003H8.86196C9.6386 10.4275 9.63802 11.4791 8.862 11.5061ZM13.5273 13.322C13.435 15.6247 9.93673 15.6231 9.84505 13.322V12.1376C9.93466 9.83382 13.4397 9.83747 13.5273 12.1376V13.322ZM15.7656 13.8749H15.4102V14.5064C15.3813 15.2392 14.2668 15.2386 14.2383 14.5064V10.9532C14.2383 10.6478 14.5006 10.4003 14.8242 10.4003H15.7656C18.2054 10.4879 18.2036 13.7881 15.7656 13.8749Z' fill='#D94375' />
      <path d='M11.6862 11.5061C11.3159 11.5061 11.0169 11.7886 11.0169 12.1376V13.322C11.0509 14.159 12.322 14.1583 12.3555 13.322V12.1376C12.3555 11.7875 12.0553 11.5061 11.6862 11.5061Z' fill='#D94375' />
    </svg>
  );
}
