import Immutable from '@utils/immutable/Immutable';
import { PLACE_REVIEWS_LOADING, SAVE_PLACE_REVIEWS } from '@constants/place';

const initialState = {
  count: 0,
  page: 1,
  pageSize: 10,
  rows: [],
  isLoading: false,
  totalPages: 1
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PLACE_REVIEWS_LOADING: {
      return Immutable({ ...state, isLoading: true });
    }
    case SAVE_PLACE_REVIEWS: {
      if (!payload) {
        return state;
      }

      const currentPage = payload?.page;
      const updatedRows = currentPage === 1 ? payload.rows : [...state.rows, ...payload.rows];

      return Immutable({
        ...state,
        ...payload,
        rows: updatedRows,
        totalPages: payload.count > 0 ? Math.floor((payload.count - 1) / payload.pageSize) + 1 : 0,
        isLoading: false
      });
    }
    default:
      return state;
  }
};
