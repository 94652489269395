/* eslint-disable */

export default `
<p><strong>Этот сайт использует файлы &laquo;куки&raquo;</strong></p>
<p><span>Мы используем файлы cookie, чтобы анализировать трафик, подбирать для вас подходящий контент и рекламу, а также дать вам возможность делиться информацией в социальных сетях. Мы передаем информацию о ваших действиях на сайте партнерам Google: социальным сетям и компаниям, занимающимся рекламой и веб-аналитикой. Наши партнеры могут комбинировать эти сведения с предоставленной вами информацией, а также данными, которые они получили при использовании вами их сервисов. Продолжая использовать наш сайт, вы соглашаетесь на использование нами куки-файлов.</span></p>

<p><strong>Общая информация о файлах &laquo;куки&raquo;</strong></p>
<p><span>&laquo;Куки&raquo; — это небольшие текстовые файлы, которые могут использоваться сайтами для повышения эффективности работы пользователей.</span></p>
<p><span>В законе говорится, что мы можем хранить файлы &laquo;куки&raquo; на вашем устройстве, если они абсолютно необходимы для функционирования этого сайта. В отношении всех остальных типов файлов &laquo;куки&raquo; необходимо получить ваше разрешение.</span></p>
<p><span>Этот сайт использует разные типы файлов &laquo;куки&raquo;. Некоторые файлы &laquo;куки&raquo;размещаются сторонними сервисами, отображаемыми на наших страницах.</span></p>
<p><span>Вы можете в любое время изменить или отозвать свое согласие с Политикой использования файлов куки на нашем сайте.</span></p>
<p><span>Узнайте подробнее из нашей <a href='https://reservble.com/privacy-policy' target='_blank'>Политики конфиденциальности</a> о том, кто мы такие, как вы можете связаться с нами и как мы обрабатываем личные данные.</span></p>

<p><strong>Необходимые файлы &laquo;куки&raquo;</strong></p>
<p><span>Необходимые файлы &laquo;куки&raquo; помогают сделать сайт удобным, позволяя реализовать основные функции, такие как навигация по странице и доступ к защищенным областям сайта. Сайт не может нормально функционировать без этих файлов &laquo;куки&raquo;.</span></p>

<p><strong>Настроечные файлы &laquo;куки&raquo;</strong></p>
<p><span>Настроечные файлы &laquo;куки&raquo; позволяют сайту запоминать информацию, которая изменяет способ работы или вид сайта, например, с учетом вашего предпочтительного языка и региона, в котором вы находитесь.</span></p>

<p><strong>Статистические файлы &laquo;куки&raquo;</strong></p>
<p><span>Статистические &laquo;куки&raquo; помогают владельцам сайтов понять, как посетители взаимодействуют с сайтами, путем сбора и представления анонимной информации.</span></p>

<p><strong>Маркетинговые файлы &laquo;куки&raquo;</strong></p>
<p><span>Маркетинговые &laquo;куки&raquo; используются для отслеживания перемещения посетителей по сайтам. Цель этого отслеживания — показ рекламных объявлений, которые актуальны и интересны для конкретного пользователя и тем самым более ценны для издателей и сторонних рекламодателей.</span></p>

<p><strong>Файлы &laquo;куки&raquo; без категории</strong></p>
<p><span>Файлы &laquo;куки&raquo; без категории — это файлы &laquo;куки&raquo;, которые мы сейчас пытаемся классифицировать вместе с поставщиками конкретных файлов &laquo;куки&raquo;.</span></p>
`;
