import React, { memo } from 'react';
import './TextArea.scss';
import PropTypes from 'prop-types';
import { get, isFunction } from '@utils/lo/lo';
import { withFormsy } from 'formsy-react';

function TextArea(props) {
  const {
    name, onChange, className, placeholder, fullWidth, cols, rows, fontSize, value, setValue, required, disabled
  } = props;

  const textChanged = (e) => {
    if (isFunction(onChange)) {
      onChange(get(e, 'currentTarget.value'));
    }
    setValue(get(e, 'currentTarget.value'));
  };

  return (
    <textarea
      name={name}
      onChange={textChanged}
      value={value || ''}
      placeholder={placeholder}
      className={`textarea ${className} ${fullWidth && 'textarea--full-width'} ${disabled && 'textarea--disabled'}`}
      cols={cols}
      rows={rows}
      style={{ fontSize }}
      required={required}
      disabled={disabled}
    />
  );
}

TextArea.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  cols: PropTypes.number,
  rows: PropTypes.number,
  fontSize: PropTypes.number,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

TextArea.defaultProps = {
  onChange: undefined,
  placeholder: '',
  className: '',
  fullWidth: false,
  cols: 20,
  rows: 2,
  fontSize: 12,
  required: false,
  disabled: false,
  value: '',
};

export default memo(withFormsy(TextArea));
