import React from 'react';
import './PromoLabel.scss';
import SvgIcon from '../SvgIcon/SvgIcon';
import PropTypes from 'prop-types';

const PromoLabel = ({ donate, className, iconWidth, scalable }) => {
  return (
    <div className={`promo-label ${className} ${scalable ? 'promo-label--scalable' : ''}`}>
      <SvgIcon type='badge' className='promo-label__badge'>
        <div className='promo-label__badge-content'>
          {donate.PlaceDonate?.count && <div className='promo-label__percent'>{donate.PlaceDonate.count}%</div>}
          <SvgIcon type={donate.name} width={iconWidth} style={{ width: iconWidth }} />
        </div>
      </SvgIcon>
    </div>
  );
};

PromoLabel.propTypes = {
  donate: PropTypes.object.isRequired,
  className: PropTypes.string,
  iconWidth: PropTypes.number,
  scalable: PropTypes.bool,
};

PromoLabel.defaultProps = {
  className: '',
  iconWidth: 16,
  scalable: false,
};

export default PromoLabel;
