import React, { Fragment, memo, useCallback, useState } from 'react';
import './Header.scss';
import Language from '@data/enums/Language.enum';
import Logo from '@components/display/Logo/Logo';
import Select from '@components/inputs/Select';
import Button from '@components/inputs/Button/Button';
import CitySelect from '@components/inputs/CitySelect/CitySelect';
import mova from 'mova';
import TextLink from '@components/navigation/TextLink/TextLink';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import SlidingMenu from '@components/display/SlidingMenu/SlidingMenu';
import { DEFAULT_LANG } from '@constants/app';
import ToggleButtons from '@components/inputs/ToggleButtons/ToggleButtons';
import PropTypes from 'prop-types';
import TopUserMenu from '../TopUserMenu';
import { isDefined } from '@utils/lo/lo';
import { disableBodyScroll, enableBodyScroll, SIDEBAR_OPEN_CLASS } from '@utils/scroll/scrollUtils';
import useMedia from '@utils/hooks/useMedia';
import Avatar from '@components/display/Avatar/Avatar';
import { MOBILE_VIEW_WIDTH, USER_LAST_LANG, MAP_MOBILE_VIEW_WIDTH } from '@utils/constants';
import ViewSelector from '@containers/ViewSelector';
import { useLocation } from 'react-router-dom';
import { getUserLang, replaceLangInUrl, baseUrlPath } from '@utils/url/urlUtils';
import LocalStorageService from '@services/LocalStorage.service';

const t = mova.ns('containers.Header');

const languageOptions = Object.values(Language);

function Header({ signIn, signUp, user, submenuOpen, unreadNotifications, reservationsCount, saveLangSelection }) {
  const isAuthenticated = isDefined(user);

  const [menuOpen, setMenuOpen] = useState(false);

  const userLanguage = getUserLang();

  const openMenu = useCallback(() => {
    setMenuOpen(true);
    disableBodyScroll(SIDEBAR_OPEN_CLASS);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
    enableBodyScroll(SIDEBAR_OPEN_CLASS);
  }, []);

  const languageSelected = useCallback((newLanguage) => {
    LocalStorageService.set(USER_LAST_LANG, newLanguage.toLowerCase());
    if (isAuthenticated && user.lang !== newLanguage.toLowerCase()) {
      saveLangSelection(newLanguage.toLowerCase())
        .then(() => {
          if (newLanguage.toLowerCase() !== userLanguage) {
            replaceLangInUrl(newLanguage.toLowerCase());
          }
        });
    } else if (newLanguage.toLowerCase() !== userLanguage) {
      replaceLangInUrl(newLanguage.toLowerCase());
    }
  }, [userLanguage, isAuthenticated, user, saveLangSelection]);

  const hideViewSelectorText = useMedia(['(max-width: 470px)'], [true], false);
  const showMobileVersion = useMedia([`(max-width: ${MOBILE_VIEW_WIDTH})`], [true], false);
  const showMapMobileVersion = useMedia([`(max-width: ${MAP_MOBILE_VIEW_WIDTH})`], [true], false);
  const location = useLocation();
  const showViewSelector = showMobileVersion && !showMapMobileVersion &&
    (location.pathname === baseUrlPath() || location.pathname === `${baseUrlPath()}/`);
  const allNotifications = unreadNotifications + reservationsCount;

  return (
    <div className='app-header'>
      <div className='app-header__top-bar'>
        <div className='app-header__top-bar-content content-container'>
          <a
            className='app-header__top-bar-link'
            href='https://restaurant.reservble.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('placeOwner')}
          </a>
        </div>
      </div>
      <div className='app-header__content content-container'>
        <div className='app-header__left'>
          <Logo />
          <CitySelect />
        </div>

        {
          !showMobileVersion && (
            <div className='app-header__right'>
              <Select
                defaultValue={userLanguage.toUpperCase()}
                options={languageOptions}
                onSelect={languageSelected}
              />
              {
                !isAuthenticated && (
                  <Fragment>
                    <TextLink
                      className='app-header__sign-in'
                      type='success'
                      onClick={signIn}
                      size='medium'
                    >
                      {t('signIn')}
                    </TextLink>
                    <Button
                      onClick={signUp}
                    >
                      {t('signUp')}
                    </Button>
                  </Fragment>
                )
              }
              {
                isAuthenticated &&
                <TopUserMenu newNotifications={unreadNotifications} reservationNotifications={reservationsCount} />
              }
            </div>
          )
        }

        {
          showMobileVersion && (
            <div className='app-header__right'>
              {
                showViewSelector && <ViewSelector className='app-header__view-selector' small={hideViewSelectorText} />
              }
              <div className='app-header__burger-container'>
                <SvgIcon className='app-header__burger' type='menu' onClick={openMenu} />
                {
                  allNotifications > 0 && (
                    <div className='app-header__burger-notifications'>
                      {allNotifications <= 99 ? allNotifications : '99+'}
                    </div>
                  )
                }
              </div>
              <SlidingMenu open={menuOpen} onClose={closeMenu} submenuOpen={submenuOpen}>
                {
                  isAuthenticated && (
                    <div className='app-header__burger-row app-header__user-row'>
                      <Avatar
                        user={user}
                        size={60}
                      >
                        {t('signIn')}
                      </Avatar>

                      <div className='app-header__username' title={user.displayedName}>{user.displayedName}</div>
                    </div>
                  )
                }

                {
                  !isAuthenticated && (
                    <Fragment>
                      <div className='app-header__burger-row'>
                        <Button
                          onClick={signIn}
                          size='medium'
                          type='flat'
                          fullWidth
                        >
                          {t('signIn')}
                        </Button>
                      </div>
                      <div className='app-header__burger-row'>
                        <Button
                          onClick={signUp}
                          size='medium'
                          fullWidth
                        >
                          {t('signUp')}
                        </Button>
                      </div>
                    </Fragment>
                  )
                }
                {
                  isAuthenticated && (
                    <TopUserMenu
                      newNotifications={unreadNotifications}
                      reservationNotifications={reservationsCount}
                      mobile
                    />
                  )
                }
                <ToggleButtons
                  className='app-header__lang-mobile'
                  items={languageOptions}
                  onSelect={languageSelected}
                  defaultValue={(userLanguage && userLanguage.toUpperCase()) || DEFAULT_LANG.toUpperCase()}
                />
              </SlidingMenu>
            </div>
          )
        }
      </div>
    </div>
  );
}

Header.propTypes = {
  signIn: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  saveLangSelection: PropTypes.func.isRequired,
  user: PropTypes.object,
  submenuOpen: PropTypes.bool,
  unreadNotifications: PropTypes.number.isRequired,
  reservationsCount: PropTypes.number,
};

Header.defaultProps = {
  user: null,
  submenuOpen: false,
  reservationsCount: 0,
};

export default memo(Header);
