import React from 'react';
import mova from 'mova';
import PropTypes from 'prop-types';
import './Payment.scss';
import { baseUrlPath } from '@utils/url/urlUtils';
import { withRouter } from 'react-router-dom';
import successIcon from '../../theme/icons/payment/success.svg';
import Button from '@components/inputs/Button/Button';

const t = mova.ns('pages.Payment');

function Payment({ billing, history }) {
  const { amount, reservation } = billing;
  const { place } = reservation;

  const goToPlace = () => {
    history.push(`${baseUrlPath()}/places/${place?.uri}`);
  };

  return (
    <div className='payment__reservation-info reservation-info'>
      <div className='payment-success'>
        <img src={successIcon} width={80} height={80} alt='' />
        <div className='payment-success__message'>{t('success')}</div>
        <div className='payment-success__amount'>{amount}{t('uah')}</div>
        <div className='payment-success__divider' />
        {billing.data?.receipt?.shopOrderNumber && (
          <div className='payment-success__data-row'>
            <div className='payment-success__label'>{t('orderNumber')}</div>
            <div className='payment-success__value'>{billing.data.receipt.shopOrderNumber}</div>
          </div>
        )}
        {/*<div className='payment-success__data-row'>*/}
        {/*  <div className='payment-success__label'>{t('paymentTime')}</div>*/}
        {/*  <div className='payment-success__value'></div>*/}
        {/*</div>*/}
        <div className='payment-success__divider' />
        <div className='payment-success__data-row'>
          <div className='payment-success__label'>{t('amount')}</div>
          <div className='payment-success__value'>{amount}{t('uah')}</div>
        </div>
      </div>
      <Button onClick={goToPlace} className='payment-success__back'>{t('back')}</Button>
    </div>
  );
}

Payment.propTypes = {
  billing: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Payment);
