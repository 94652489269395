import React from 'react';
import Colors from '@data/enums/Color.enum';

export function filter({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.6273 3.60938H3.37263C2.79841 3.60938 2.43982 4.23516 2.7281 4.73438L8.17966 14.0016V19.6406C8.17966 20.0555 8.51247 20.3906 8.92497 20.3906H15.075C15.4875 20.3906 15.8203 20.0555 15.8203 19.6406V14.0016L21.2742 4.73438C21.5601 4.23516 21.2015 3.60938 20.6273 3.60938ZM14.1422 18.7031H9.85778V15.0469H14.1445V18.7031H14.1422ZM14.3672 13.1578L14.1445 13.5469H9.85544L9.63278 13.1578L4.98513 5.29688H19.0148L14.3672 13.1578Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
