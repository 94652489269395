import { DEFAULT_LANG, DEFAULT_CITY } from '@constants/app';
import Language from '@data/enums/Language.enum';
import City from '@data/enums/City.enum';

export const getUserLang = () => {
  if (window.location.pathname.startsWith('/en')) {
    return Language.EN.toLowerCase();
  } else if (window.location.pathname.startsWith('/ru')) {
    return Language.RU.toLowerCase();
  } else if (window.location.pathname.startsWith('/es')) {
    return Language.ES.toLowerCase();
  }

  return DEFAULT_LANG;
};

const urlWithoutLang = () =>
  getUserLang() === DEFAULT_LANG ? window.location.pathname : window.location.pathname.substring(3);

export const getCityObjectFromUrl = () => {
  const cities = Object.values(City).filter(c => c.enabled);
  const url = urlWithoutLang();
  for (let i = 0; i < cities.length; i++) {
    if (url.startsWith(`/${cities[i].name}`)) {
      return cities[i];
    }
  }

  return cities.find(c => c.name === DEFAULT_CITY);
};

export const getCityFromUrl = () => getCityObjectFromUrl().name;

export const replaceLangInUrl = (lang) => {
  let newUrl = window.location.pathname + window.location.search;

  Object.values(Language).map(l => l.toLowerCase()).forEach(l => {
    if (newUrl.startsWith(`/${l}`)) {
      newUrl = newUrl.substring(3);
    }
  });

  if (lang !== DEFAULT_LANG) {
    newUrl = `/${lang}${newUrl}`;
  }

  window.location.assign(newUrl);
};

export const urlWithNewCity = (newCity) => {
  const currentUrl = window.location.pathname + window.location.search;
  const currentCity = getCityFromUrl();

  if (newCity === currentCity) {
    return currentUrl;
  }

  const currentLang = getUserLang();

  if (newCity === DEFAULT_CITY) {
    return currentLang === DEFAULT_LANG ? '/' : `/${currentLang}`;
  } else {
    return currentLang === DEFAULT_LANG ? `/${newCity}` : `/${currentLang}/${newCity}`;
  }
};

export const baseUrlPath = () => {
  let baseUrl = '';

  if (getUserLang() !== DEFAULT_LANG) {
    baseUrl += `/${getUserLang()}`;
  }

  if (getCityFromUrl() !== DEFAULT_CITY) {
    baseUrl += `/${getCityFromUrl()}`;
  }

  return baseUrl;
};

const getDistanceBetweenPoints = (point1, point2) => (
  Math.sqrt(Math.abs(((point1.lat - point2.lat) ** 2) - ((point1.lng - point2.lng) ** 2)))
);

export const findClosestCity = (coordinates) => Object.values(City)
  .map(c => ({ ...c, distance: getDistanceBetweenPoints(coordinates, c.center) }))
  .reduce((prev, curr) => prev.distance < curr.distance ? prev : curr);
