import React, { memo, useMemo } from 'react';
import Modal from '../../../components/feedback/Modal/Modal';
import SlickSlider from 'react-slick';
import './PaidServicesModal.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PaidServicesModal = ({ paidServices }) => {
  const imageCards = useMemo(() => {
    return paidServices.imagesUrl.map(image => (
      <div key={image}>
        <div
          style={{
            background: `url(${image}) center center no-repeat`,
            backgroundSize: 'contain',
            width: '100%',
            height: 'calc(90vh - 100px)',
          }}
        />
      </div>
    ));
  }, []); // eslint-disable-line

  const settings = {
    dots: true,
    customPaging: (i) => {
      return (
        <img
          src={paidServices.imagesUrl[i]}
          alt={`Preview ${i}`}
          style={{ width: '76px', height: '76px', objectFit: 'cover' }}
        />
      );
    }
  };

  return (
    <Modal className='paid-services-modal'>
      <SlickSlider className='paid-services-modal__slider' {...settings}>
        {imageCards}
      </SlickSlider>
    </Modal>
  );
};

PaidServicesModal.propTypes = {
  paidServices: PropTypes.object.isRequired,
};

const mapStateToProps = ({ modal }) => ({
  paidServices: modal.params
});

export default memo(connect(mapStateToProps)(PaidServicesModal));
