import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import Animate from '@components/feedback/Animate/Animate';

function TopMenuOption(props) {
  const { option, onSelect, inline, selected, submenuOpen, toggleUserSubmenu, currentPath } = props;
  const { label, icon, notifications, options } = option;

  const optionSelected = (e, o) => {
    if (inline && options) {
      toggleUserSubmenu();
      e.stopPropagation();
    } else {
      onSelect(o);
    }
  };

  const submenuOptionSelected = (e, o) => {
    onSelect(o);
  };

  return (
    <Fragment>
      <div
        className={`top-menu__option
                  ${icon && 'top-menu__option--has-icon'}
                  ${selected && 'top-menu__option--selected'}
                  ${notifications > 0 && 'top-menu__option--has-notifications'}
                  ${inline && options && 'top-menu__option--has-submenu'}
                `}
        onClick={(e) => optionSelected(e, option)}
        onKeyPress={(e) => optionSelected(e, option)}
        role='menuitem'
        tabIndex={0}
      >
        <SvgIcon className='top-menu__icon' type={icon} style={{ width: 16 }} iconProps={{ size: 16 }} />
        {label}
        {
          notifications > 0 && (
            <div className='top-menu__notifications'>{notifications <= 99 ? notifications : '99+'}</div>
          )
        }
        {
          inline && options && (
            <SvgIcon type='chevronDown' className='top-menu__chevron' />
          )
        }
      </div>
      {
        inline && submenuOpen && options && options.map(o => (
          <Animate animation='transition.slideDownIn' key={o.label}>
            <div
              onClick={(e) => submenuOptionSelected(e, o)}
              onKeyPress={(e) => submenuOptionSelected(e, o)}
              role='menuitem'
              tabIndex={0}
              className={`top-menu__submenu-option ${currentPath === o.path && 'top-menu__submenu-option--selected'}`}
            >
              {o.label}
            </div>
          </Animate>
        ))
      }
    </Fragment>
  );
}

TopMenuOption.propTypes = {
  option: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  selected: PropTypes.bool,
  submenuOpen: PropTypes.bool,
  toggleUserSubmenu: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired
};

TopMenuOption.defaultProps = {
  inline: false,
  selected: false,
  submenuOpen: false
};

export default memo(TopMenuOption);
