import React from 'react';
import PropTypes from 'prop-types';
import './Image.scss';
import { isDefined } from '@utils/lo/lo';

function Image(props) {
  const { src, alt, cover, className, ...rest } = props;

  if (!isDefined(src)) {
    return null;
  }

  return (
    <img className={`image ${className} ${cover && 'image--cover'}`} src={src} alt={alt} {...rest} />
  );
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  cover: PropTypes.bool,
  className: PropTypes.string
};

Image.defaultProps = {
  cover: false,
  className: '',
  src: undefined
};

export default Image;
