import React, { memo } from 'react';
import './SideTabs.scss';
import PropTypes from 'prop-types';
import TextLink from '@components/navigation/TextLink/TextLink';

function Tab(props) {
  const { name, path, selected } = props;

  return (
    <TextLink to={path} type='text' className={`side-tabs__tab ${selected && 'side-tabs__tab--selected'}`}>
      {name}
    </TextLink>
  );
}

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  selected: PropTypes.bool
};

Tab.defaultProps = {
  selected: false
};

export default memo(Tab);
