import React, { memo, useMemo } from 'react';
import mova from 'mova';
import Select from '@components/inputs/Select';
import City from '@data/enums/City.enum';
import { getCityFromUrl, urlWithNewCity } from '@utils/url/urlUtils';
import { useHistory, useLocation } from 'react-router-dom';
import './CitySelect.scss';
import LocalStorageService from '@services/LocalStorage.service';
import { USER_LAST_CITY } from '@utils/constants';

const t = mova.ns('cities');

const CitySelect = () => {
  // we need to re-render this component if location is updated to check for city changes
  const location = useLocation(); // eslint-disable-line

  const cityOptions = useMemo(
    () => Object.values(City).filter(v => v.enabled).map(c => ({ ...c, label: t(c.name) })),
    []
  );

  const defaultValue = cityOptions.find(c => c.name === getCityFromUrl());
  const history = useHistory();

  if (cityOptions.length === 1) {
    return <span className='city-select__ml'>{cityOptions[0].label}</span>;
  }

  const changeCity = (newCity) => {
    LocalStorageService.set(USER_LAST_CITY, newCity.name);
    history.push(urlWithNewCity(newCity.name));
  };

  return (
    <Select
      defaultValue={defaultValue}
      labelPath='label'
      options={cityOptions}
      onSelect={changeCity}
      className='app-header__city-select'
    />
  );
};

export default memo(CitySelect);
