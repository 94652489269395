import mova from 'mova';
import { loadData } from '@utils/redux/actionUtils';
import LocalStorageService from '@services/LocalStorage.service';
import api from '@api';
import store from '@store/Store';
import { confirmReservation, getActiveReservations } from './reservation';
import { get } from '@utils/lo/lo';
import { toastr } from 'react-redux-toastr';
import { closeModal } from './modal';
import { getNotifications } from './notification';
import { webSocketAuth } from '@data/socket/socket';
import { SAVE_PLACE } from '@constants/place';
import { prepareAndRegisterInPromo, registerInPromo } from './place';
import promoUtils from '@utils/place/promoUtils';
import { findClosestCity, getCityFromUrl } from '@utils/url/urlUtils';
import {
  FAVORITE_PLACES_LOADING,
  GEOLOCATION_PERMISSION_DENIED,
  LOAD_AUTH,
  LOAD_AUTH_START,
  PLACE_LIKE_LOADING,
  SAVE_FAVORITE_PLACES,
  SAVE_LIKED_PLACES,
  SAVE_REGISTRATION_DATA,
  SAVE_USER,
  SAVE_USER_FEEDBACKS,
  SAVE_USER_LOCATION,
  SET_DIFFERENT_CITY,
  TOGGLE_USER_SUBMENU,
  USER_FEEDBACKS_LOADING,
  USER_LOADING,
} from '@constants/user';
import { initialLoading } from './app';
import { OPEN_MODAL, REGISTRATION_MODAL } from '../constants/modal';

const profileT = mova.ns('pages.Profile');
const likeT = mova.ns('types.likes');
const feedbackT = mova.ns('types.feedback');
const t = mova.ns('actions.user');

export const getCurrentUser = () => (dispatch) =>
  dispatch(loadData('user.current')(USER_LOADING, SAVE_USER));

export const getUserByToken = (token) => async (dispatch) => {
  const apiRequest = get(api, 'user.byToken');
  apiRequest({ token }).then((res) => {
    LocalStorageService.set(LocalStorageService.Keys.TOKEN, res.data.accessToken);
    api.setToken(res.data.accessToken);
    dispatch(getCurrentUser());
  });
};

export const saveLangSelection = (lang) => () => {
  const apiRequest = get(api, 'user.saveLang');
  return apiRequest({ lang });
};

export const updateProfile = (data) => (dispatch) => {
  const apiRequest = get(api, 'user.update');
  apiRequest(data).then((res) => {
    const currentUser = store.getState().user.details.data;
    dispatch({ type: SAVE_USER, payload: { ...currentUser, user: res.data } });
    toastr.success(profileT('update'));
  });
};

export const userSignIn = (data, meta) => async (dispatch) => {
  const response = await loadData('user.auth', data)(
    LOAD_AUTH_START,
    LOAD_AUTH
  )(dispatch);

  if (typeof response === 'object' && response.isAxiosError) {
    return response;
  }

  LocalStorageService.set(LocalStorageService.Keys.TOKEN, response.accessToken);
  api.setToken(response.accessToken);
  dispatch({ type: SAVE_USER, payload: response });
  dispatch(closeModal());
  dispatch(getNotifications());
  dispatch(getActiveReservations());
  webSocketAuth(response.accessToken);

  if (meta?.place && meta?.channelId) {
    dispatch(registerInPromo(meta.place, meta.channelId));
  } else if (promoUtils.hasActivePromo()) {
    dispatch(prepareAndRegisterInPromo());
  }

  return response;
};

export const userSignUp = (data, place) => async (dispatch) => {
  const response = await loadData('user.create', data)(
    LOAD_AUTH_START,
    LOAD_AUTH
  )(dispatch);
  if (typeof response === 'object' && response.isAxiosError) {
    return response;
  }

  await userSignIn(data, place)(dispatch);
  return response;
};

export const userSignOut = () => () => {
  LocalStorageService.delete(LocalStorageService.Keys.TOKEN);
  api.setToken(null);
  window.location.reload();
};

export const saveRegistrationData = (data) => (dispatch) => {
  dispatch({ type: SAVE_REGISTRATION_DATA, payload: data });
};

export const finishRegistration = (placeUri, data, reservation) => async (
  dispatch
) => {
  const currentState = store.getState();

  dispatch(closeModal());
  const user = await userSignUp({
    ...currentState.user.registration.data,
    ...data,
    comment: undefined,
  })(dispatch);

  if (!user.isAxiosError) {
    await dispatch(
      confirmReservation(
        placeUri,
        reservation,
        currentState.user.registration.data.comment
      )
    );
  }
};

export const savePhoneAndFinishReservation = (
  placeUri,
  data,
  reservation
) => async (dispatch) => {
  const currentState = store.getState();

  const updateUserRequest = get(api, 'user.update');

  try {
    await updateUserRequest(data);
    await dispatch(
      confirmReservation(
        placeUri,
        reservation,
        currentState.user.registration.data.comment
      )
    );
  } finally {
    dispatch(closeModal());
  }
};

export const sendForgotPasswordRequest = (data) => (dispatch) => {
  const apiRequest = get(api, 'user.forgotPassword');
  apiRequest(data).then(() => {
    toastr.info(t('forgotPasswordSent'));
    dispatch(closeModal());
  });
};

export const sendForgotPasswordSms = (data) => () => {
  const apiRequest = get(api, 'user.forgotSms');
  apiRequest(data).then(() => {
    toastr.info(t('forgotSmsSent'));
  });
};

export const verifySmsCode = (data, callback) => () => {
  const apiRequest = get(api, 'user.smsValidateCode');
  apiRequest(data).then((response) => {
    LocalStorageService.set(
      LocalStorageService.Keys.TOKEN,
      response.data.accessToken
    );
    api.setToken(response.data.accessToken);
    callback();
  });
};

export const changePasswordSms = (data) => (dispatch) => {
  const apiRequest = get(api, 'user.changePasswordSms');
  apiRequest(data).then(() => {
    toastr.info(t('passwordChanged'));
    dispatch(closeModal());
    dispatch(initialLoading());
  });
};

const postLocation = (data) => {
  const apiRequest = get(api, 'app.postLocation');
  apiRequest(data);
};

export const changePassword = (data) => () => {
  const apiRequest = get(api, 'user.updatePassword');
  return apiRequest(data).then(() => toastr.success(profileT('update')));
};

export const updateAvatar = (data) => (dispatch) => {
  const apiRequest = get(api, 'user.updateAvatar');
  apiRequest(data).then((res) => {
    const currentUser = store.getState().user.details.data;
    dispatch({ type: SAVE_USER, payload: { ...currentUser, user: res.data } });
    toastr.success(profileT('update'));
  });
};

export const deleteAvatar = () => (dispatch) => {
  const apiRequest = get(api, 'user.deleteAvatar');
  apiRequest().then(() => {
    dispatch(getCurrentUser());
  });
};

export const checkUserCity = (coordinates) => (dispatch) => {
  const selectedCity = getCityFromUrl();
  const closestCity = findClosestCity(coordinates);
  if (closestCity.name !== selectedCity && closestCity.enabled) {
    dispatch({ type: SET_DIFFERENT_CITY, payload: closestCity.name });
  }
};

export const getUserLocationByIp = () => (dispatch) => {
  const apiRequest = get(api, 'user.locationByIp');

  apiRequest().then((response) => {
    if (!response || !response.data) {
      return;
    }

    const {
      time_zone: timezone,
      latitude: lat,
      longitude: lng,
    } = response.data;

    const payload = {
      timezone,
      lat,
      lng,
    };

    if (lat > 0) {
      dispatch(checkUserCity(payload));
    }
  });
};

// toDO: @zozich delete this function later if not needed
const storeLocationData = (data) => (dispatch, getState) => {
  if (!getState().user.location.data.lat) {
    // check user city only once, before first coordinates are set
    dispatch(checkUserCity(data));
  }
  dispatch({ type: SAVE_USER_LOCATION, payload: data });
};

const getPositionData = (position) => ({
  lat: position.coords.latitude,
  lng: position.coords.longitude,
});

// toDO: call is commented out, reactivate later
export const getUserLocation = () => async (dispatch) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const data = getPositionData(position);
        postLocation(data);
        dispatch(storeLocationData(data));

        navigator.geolocation.watchPosition((pos) => {
          dispatch(storeLocationData(getPositionData(pos)));
        });
      },
      (err) => {
        dispatch(getUserLocationByIp());

        if (err.code === 1) {
          dispatch({ type: GEOLOCATION_PERMISSION_DENIED });
        } else {
          navigator.geolocation.watchPosition((pos) => {
            dispatch(storeLocationData(getPositionData(pos)));
          });
        }
      },
      { timeout: 2000 }
    );
  } else {
    dispatch(getUserLocationByIp());
  }
};

export const getUserFeedbacks = (params) => {
  const location = store.getState().user.location.data;
  const { lat, lng } = location;
  const updatedParams = { ...params, lat, lng };

  return loadData('user.getUserFeedbacks', updatedParams)(
    USER_FEEDBACKS_LOADING,
    SAVE_USER_FEEDBACKS
  );
};

export const toggleUserSubmenu = () => (dispatch) => {
  dispatch({ type: TOGGLE_USER_SUBMENU });
};

export const getFavoritePlaces = () => {
  const { lat, lng } = get(store.getState(), 'user.location.data');

  const params = { lat, lng };

  return loadData('places.getFavorite', params)(
    FAVORITE_PLACES_LOADING,
    SAVE_FAVORITE_PLACES
  );
};

export const toggleLike = (like, likesList, place) => (dispatch, getState) => {
  const isLoggedIn = !!getState().user.details.data?.user?.id;

  if (isLoggedIn) {
    dispatch({ type: PLACE_LIKE_LOADING, payload: true });

    const apiRequest = get(api, 'places.toggleLike');
    apiRequest(like)
      .then((res) => {
        const newLike = {
          id: place.id,
          isLiked: res.data.isLiked,
        };

        const updatedLikedList = likesList.filter((item) => item.id !== place.id);
        updatedLikedList.push(newLike);

        dispatch({ type: SAVE_LIKED_PLACES, payload: updatedLikedList });

        const text = res.data.isLiked
          ? likeT('add', { place: place.name })
          : likeT('delete', { place: place.name });
        toastr.info(text);
      })
      .finally(() => {
        dispatch({ type: PLACE_LIKE_LOADING, payload: false });
      });
  } else {
    dispatch({ type: OPEN_MODAL, payload: REGISTRATION_MODAL });
  }
};

export const postFeedback = (placeUrl, body) => (dispatch) => {
  const apiRequest = get(api, 'places.postFeedback');
  apiRequest(placeUrl, body).then((res) => {
    dispatch({ type: SAVE_PLACE, payload: res.data });
    toastr.success(feedbackT('thankYou'));
  });
};
