import React from 'react';
import Colors from '@data/enums/Color.enum';

export function starOutline({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.2837 8.27559L15.3329 7.41075L12.6728 2.01778C12.6001 1.87013 12.4806 1.75059 12.3329 1.67794C11.9626 1.49513 11.5126 1.64747 11.3274 2.01778L8.66729 7.41075L2.71651 8.27559C2.55245 8.29903 2.40245 8.37638 2.2876 8.49356C2.14877 8.63627 2.07226 8.82825 2.0749 9.02733C2.07753 9.22642 2.1591 9.41631 2.30167 9.55528L6.60714 13.7529L5.58995 19.6803C5.5661 19.8182 5.58135 19.96 5.63399 20.0896C5.68663 20.2193 5.77455 20.3316 5.88777 20.4138C6.00099 20.496 6.13498 20.5449 6.27456 20.5549C6.41413 20.5648 6.55371 20.5355 6.67745 20.4701L12.0001 17.6717L17.3228 20.4701C17.4681 20.5475 17.6368 20.5732 17.7985 20.5451C18.2064 20.4748 18.4806 20.0881 18.4103 19.6803L17.3931 13.7529L21.6985 9.55528C21.8157 9.44044 21.8931 9.29044 21.9165 9.12638C21.9798 8.71622 21.6939 8.33653 21.2837 8.27559ZM15.5814 13.1623L16.4275 18.0912L12.0001 15.7662L7.57276 18.0936L8.41885 13.1647L4.8376 9.67247L9.7876 8.95294L12.0001 4.46934L14.2126 8.95294L19.1626 9.67247L15.5814 13.1623Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
