import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '@utils/lo/lo';
import './Timer.scss';

const oneMinuteMillis = 60 * 1000;

const formatMillis = (millis) => {
  if (millis <= 0) {
    return '00:00';
  }

  const minutes = Math.floor(millis / oneMinuteMillis);
  const seconds = Math.round((millis - minutes * oneMinuteMillis) / 1000);

  return `${(`0${minutes}`).slice(-2)}:${(`0${seconds}`).slice(-2)}`;
};

function Timer({ millis, className, onFinish }) {
  const [time, setTime] = useState(Date.now());
  const [deadline] = useState(Date.now() + millis);

  useEffect(() => {
    const timerId = setInterval(() => {
      const currentTimestamp = Date.now();
      setTime(currentTimestamp);

      if (currentTimestamp > deadline) {
        clearInterval(timerId);

        if (isFunction(onFinish)) {
          onFinish();
        }
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [deadline, onFinish]);

  return (
    <span className={`timer ${className}`}>{formatMillis(deadline - time)}</span>
  );
}

Timer.propTypes = {
  millis: PropTypes.number.isRequired,
  className: PropTypes.string,
  onFinish: PropTypes.func
};

Timer.defaultProps = {
  className: '',
  onFinish: undefined
};

export default Timer;
