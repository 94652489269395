import config from 'config';
import socketIOClient from 'socket.io-client';
import store from '@store/Store';
import { SAVE_NOTIFICATIONS } from '@constants/notification';
import { get } from '@utils/lo/lo';
import { toastr } from 'react-redux-toastr';
import { getMessageText } from '@pages/Notifications/components/ReservationNotification/ReservationNotification';
import { getActiveReservations, getOldReservations } from '@actions/reservation';

const { dispatch } = store;

const socket = socketIOClient(`${config.apiDirect}/`, {
  transports: ['websocket'],
  timeout: 60000,
  pingTimout: 60000,
  rejectUnauthorized: false,
  allowUpgrades: false,
});

export const webSocketAuth = (accessToken) => {
  socket.emit('initial', { accessToken });
};

socket.on('changed_reservation', () => {
  dispatch(getActiveReservations());
  dispatch(getOldReservations());
});

socket.on('new_notification', notification => {
  const existingNotifications = get(store.getState(), 'notification.details.data');

  dispatch({ type: SAVE_NOTIFICATIONS, payload: [notification, ...existingNotifications] });

  const { type } = notification;

  if (type === 'reservation') {
    const message = getMessageText(notification);
    toastr.info(message);
  }
});
