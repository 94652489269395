import Immutable from '@utils/immutable/Immutable';
import { get } from '@utils/lo/lo';
import api from '@api';
import getMessageFromError from '@utils/error/errorMessageUtils';

const _getData = response => {
  const data = response.data || {};
  return (data && data.result !== undefined) ? data.result : data;
};

const dispatchError = (dispatch, type) => error => {
  dispatch({
    type,
    payload: null,
    error: Immutable(getMessageFromError(error)),
    status: get(error, 'response.status')
  });

  return Immutable(error);
};

const loadData = (apiPath, data, callback) => (startActionType, endActionType) => dispatch => {
  dispatch({ type: startActionType });
  const apiRequest = get(api, apiPath);
  if (!apiRequest) {
    throw new Error(`Wrong API path ${apiPath}`);
  }

  return apiRequest(data)
    .then(async response => {
      const payload = _getData(response);
      dispatch({ type: endActionType, payload: Immutable(payload), error: false });

      if (callback) {
        setTimeout(() => {
          callback(payload);
        }, 0);
      }

      return payload;
    })
    .catch(dispatchError(dispatch, endActionType));
};

export { loadData, dispatchError };
