import Immutable from '@utils/immutable/Immutable';
import { SHOW_TOASTR, INITIAL_LOADING_FINISHED } from '@constants/app';

const initialState = {
  toastrOptions: {},
  initialLoading: true
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_TOASTR: {
      return Immutable({ ...state, toastrOptions: payload });
    }
    case INITIAL_LOADING_FINISHED: {
      return Immutable({ ...state, initialLoading: false });
    }
    default:
      return state;
  }
};
