import React, { memo, useCallback, useEffect } from 'react';
import './QuickReservation.scss';
import PropTypes from 'prop-types';
import mova from 'mova';
import PersonsSelector from '@components/inputs/PersonsSelector/PersonsSelector';
import Calendar from '@components/inputs/Calendar/Calendar';
import TimeSelector from '@components/inputs/TimeSelector/TimeSelector';
import TimeTags from '@components/display/TimeTags/TimeTags';
import Loading from '@components/feedback/Loading/Loading';
import TextLink from '@components/navigation/TextLink/TextLink';
import PromoLabel from '../../components/display/PromoLabel/PromoLabel';

const t = mova.ns('containers.QuickReservation');

function QuickReservation({
  slots, slotsLoading, className, makeReservation, getAvailableSlots, match,
  seatsUpdated, dateUpdated, selectedSeats, selectedDate, place, selectNextDay, showSpotSelection
}) {
  const { placeUri } = match.params;
  const optionsAvailable = (slots || []).length > 0;

  useEffect(() => {
    getAvailableSlots(place?.id);
  }, [place.id, getAvailableSlots]);

  const handleUpdatingDate = useCallback((value) => dateUpdated(value, place.id), [place.id, dateUpdated]);
  const handleUpdatingSeats = useCallback((value) => seatsUpdated(value, place.id), [place.id, seatsUpdated]);
  const handleMakeReservation = useCallback((time) => makeReservation(placeUri, time), [placeUri, makeReservation]);
  const handleSelectNextDay = useCallback(() => selectNextDay(place.id), [place.id, selectNextDay]);

  const handleTimeClick = (time) => {
    if (place.showSpots && !place.ourManagment) {
      showSpotSelection({ time });
    } else {
      handleMakeReservation(time);
    }
  }

  return (
    <div className={`quick-reservation ${className}`}>
      <div className='quick-reservation__heading quick-reservation__heading--book'>{t('book')}</div>
      {
        place.donates.length > 0 && (
          <PromoLabel
            donate={place.donates[0]}
            className='quick-reservation__promo-label'
            iconWidth={place.donates[0].name === 'PRITULA' ? 16 : 24}
          />
        )}
      <div className='quick-reservation__selects'>
        <Calendar
          className='quick-reservation__select'
          onChange={handleUpdatingDate}
          place={place}
          value={selectedDate}
        />
        <TimeSelector
          className='quick-reservation__select'
          onChange={handleUpdatingDate}
          value={selectedDate}
          place={place}
        />
        <PersonsSelector
          className='quick-reservation__select quick-reservation__persons'
          onChange={handleUpdatingSeats}
          value={selectedSeats}
          place={place}
        />
      </div>
      <div className='quick-reservation__heading quick-reservation__heading--time'>{t('selectTime')}</div>
      {slotsLoading && <div className='quick-reservation__loading-wrapper'><Loading inline /></div>}
      {
        !slotsLoading && optionsAvailable &&
        <TimeTags tags={slots} rows={2} makeReservation={handleTimeClick} sortFrom={selectedDate} />
      }
      {
        !slotsLoading && !optionsAvailable && (
          <div className='quick-reservation__no-options'>
            <span>{t('noOptions')}</span>.&nbsp;
            <TextLink onClick={handleSelectNextDay} size='medium'>{t('nextDay')}</TextLink>
          </div>
        )
      }
    </div>
  );
}

QuickReservation.propTypes = {
  className: PropTypes.string,
  makeReservation: PropTypes.func.isRequired,
  getAvailableSlots: PropTypes.func.isRequired,
  seatsUpdated: PropTypes.func.isRequired,
  dateUpdated: PropTypes.func.isRequired,
  selectNextDay: PropTypes.func.isRequired,
  showSpotSelection: PropTypes.func.isRequired,
  slots: PropTypes.array,
  slotsLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  selectedSeats: PropTypes.number.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  place: PropTypes.object.isRequired,
};

QuickReservation.defaultProps = {
  className: '',
  slots: [],
};

export default memo(QuickReservation);
