import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import './DishDetailsModal.scss';
import Modal from '@components/feedback/Modal/Modal';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import DishQuantity from '@components/display/DishQuantity/DishQuantity';
import { connect } from 'react-redux';
import mova from 'mova';
import { toggleLikeDish } from '@store/actions/menu';
// import CloudinaryImage from '../../../components/display/CloudinaryImage/CloudinaryImage';
import Colors from '@data/enums/Color.enum';

const t = mova.ns('components.DishCard');

const DishDetailsModal = ({ meta, toggleLikeDish }) => {
  const likeRef = useRef();
  const dish = meta;

  const handleLikeClick = () => {
    toggleLikeDish(dish);
    likeRef.current.classList.toggle('dish-details-modal__like-wrapper--liked');
  };

  return (
    <Modal className='dish-details-modal'>
      {dish.photo && (
        <div>
          <img className='dish-details-modal__photo' src={dish.photoUrl} alt={dish.name} />
          {/* <CloudinaryImage
            height={340}
            src={dish.photo}
            alt={dish.name}
            className='dish-details-modal__photo'
          /> */}
        </div>
      )}
      <div className='dish-details-modal__title-row'>
      <div className='dish-details-modal__name-section'>
          {dish.variables?.top && <SvgIcon type='top' />}
          <div className='dish-details-modal__name'>{dish.name}</div>
        </div>
        <div className='dish-details-modal__price'>{dish.price} {t('uah')}</div>
      </div>
      <div className='dish-details-modal__description-row'>
        <div className='dish-details-modal__description'>{dish.description}</div>
        <div
          ref={likeRef}
          className={`dish-details-modal__like-wrapper ${dish.isLiked ? 'dish-details-modal__like-wrapper--liked' : ''}`}
          onClick={handleLikeClick}
        >
          <SvgIcon
            type='heart'
            filled={dish.isLiked}
            fill={dish.isLiked ? 'white' : Colors.COLOR_PRIMARY}
            iconProps={{ size: 28 }}
          />
        </div>
      </div>
      <div className='dish-details-modal__actions-row'>
        <div className='dish-details-modal__tags'>
          {dish.weight && <div className='dish-details-modal__weight'>{dish.weight}</div>}
          {dish.variables?.hot && <div className='dish-details-modal__tag'><SvgIcon type='hot' /></div>}
          {dish.variables?.vegetarian && <div className='dish-details-modal__tag'><SvgIcon type='vegetarian' /></div>}
        </div>
        <DishQuantity dish={dish} />
      </div>
    </Modal>
  );
};

DishDetailsModal.propTypes = {
  meta: PropTypes.object.isRequired,
  toggleLikeDish: PropTypes.func.isRequired,
};

const mapStateToProps = ({ modal }) => ({
  meta: modal.params
});

const mapDispatchToProps = {
  toggleLikeDish,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(DishDetailsModal));
