import Immutable from '@utils/immutable/Immutable';
import { SAVE_USER_LOCATION, GEOLOCATION_PERMISSION_DENIED, SET_DIFFERENT_CITY } from '@constants/user';

const initialState = {
  data: {
    country: null,
    city: null,
    lat: null,
    lng: null,
    timezone: null
  },
  blocked: false,
  isLoading: true,
  differentCity: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_USER_LOCATION: {
      return Immutable({
        ...state,
        data: { ...state.data, ...payload },
        isLoading: false
      });
    }
    case GEOLOCATION_PERMISSION_DENIED: {
      return Immutable({
        ...state,
        blocked: true
      });
    }
    case SET_DIFFERENT_CITY: {
      return Immutable({
        ...state,
        differentCity: action.payload
      });
    }
    default:
      return state;
  }
};
