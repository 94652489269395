export default {
  placeTitle: {
    ua: 'Reservble – Забронювати столик у ресторані ',
    ru: 'Reservble – Забронировать столик в ресторане ',
    en: 'Reservble – Make a reservation at ',
    es: 'Reservble – Reservar una mesa en el restaurante ',
  },
  title: {
    ua: 'Reservble – пошук та резерв стола у закладах міста',
    ru: 'Reservble – поиск и резерв стола в ресторанах города',
    en: 'Reservble – table search and reservation in city restaurants',
    es: 'Reservble – búsqueda y reserva de mesa en los restaurantes de la ciudad',
  },
};
