import React from 'react';
import Colors from '@data/enums/Color.enum';

export function confirm() {
  return (
    <svg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='7' cy='7' r='7' fill={Colors.COLOR_PRIMARY} />
      <path
        d='M11.6872 3.22656H10.8681C10.7532 3.22656 10.6442 3.2793 10.5739 3.36953L5.74228 9.49023L3.42548 6.55469C3.39044 6.51019 3.34576 6.47421 3.29482 6.44945C3.24388 6.42469 3.18798 6.41179 3.13134 6.41172H2.3122C2.23369 6.41172 2.19033 6.50195 2.23837 6.56289L5.44814 10.6293C5.59814 10.8191 5.88642 10.8191 6.03759 10.6293L11.761 3.37656C11.8091 3.3168 11.7657 3.22656 11.6872 3.22656Z'
        fill='white'
      />
    </svg>
  );
}
