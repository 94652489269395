import React, { memo } from 'react';
import Modal from '../../../components/feedback/Modal/Modal';
import './CertificatesModal.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import mova from 'mova';
import { withRouter } from 'react-router-dom';
import { baseUrlPath } from '@utils/url/urlUtils';
import { closeModal } from '@store/actions/modal';
import CertificateCard from '../../../components/display/CertificateCard/CertificateCard';

const t = mova.ns('components.CertificatesModal');

const CertificatesModal = ({ certificates, history, place, closeModal }) => {
  const buyCertificate = (certificate) => {
    history.push(`${baseUrlPath()}/places/${place.uri}/certificates/${certificate.id}`);
    closeModal();
  };

  const certificateCards = certificates.map(certificate =>
    <CertificateCard key={certificate.id} certificate={certificate} buy={() => buyCertificate(certificate)} />);

  return (
    <Modal className='certificates-modal' title={t('title')}>
      {certificateCards}
    </Modal>
  );
};

CertificatesModal.propTypes = {
  certificates: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  place: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ modal, place }) => ({
  certificates: modal.params,
  place: place.details.data,
});

const mapDispatchToProps = {
  closeModal,
};

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(CertificatesModal)));
