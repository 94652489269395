import { combineReducers } from 'redux';

import { loadDataReducer, truthful } from '@utils/redux/reducerUtils';
import {
  RESERVATION_LOADING,
  SAVE_RESERVATION,
  ACTIVE_RESERVATIONS_LOADING,
  OLD_RESERVATIONS_LOADING,
  SAVE_ACTIVE_RESERVATIONS,
  SAVE_OLD_RESERVATIONS,
} from '@constants/reservation';
import {
  LOAD_AUTH,
  LOAD_AUTH_START,
  SAVE_USER,
  USER_LOADING,
  FAVORITE_PLACES_LOADING,
  SAVE_FAVORITE_PLACES,
} from '@constants/user';
import {
  PLACE_SLOTS_LOADING,
  SAVE_PLACE_SLOTS,
  PLACE_DEPOSITS_LOADING,
  SAVE_PLACE_DEPOSITS
} from '@constants/place';
import {
  SAVE_TIMEZONE_INFO,
  TIMEZONE_INFO_LOADING,
  TAGS_LOADING,
  SAVE_TAGS,
  CUISINES_LOADING,
  SAVE_CUISINES
} from '@constants/app';
import {
  SAVE_NOTIFICATIONS,
  NOTIFICATIONS_LOADING,
  SAVE_UNREAD_NOTIFICATIONS_COUNT,
  UNREAD_NOTIFICATIONS_COUNT_LOADING
} from '@constants/notification';
import { BILLING_LOADING, SAVE_BILLING } from '@constants/billing';

import general from '@store/reducers/app/generalReducer';
import view from '@store/reducers/app/viewReducer';
import modal from '@store/reducers/modal/modalReducer';
import reservation from '@store/reducers/reservation/reservationReducer';
import user from '@store/reducers/user/userReducer';
import likes from '@store/reducers/user/likesReducer';
import reviews from '@store/reducers/place/reviewsReducer';
import details from '@store/reducers/place/detailsReducer';
import menu from '@store/reducers/place/menuReducer';
import location from '@store/reducers/user/locationReducer';
import submenu from '@store/reducers/user/submenuReducer';
import feedbacks from '@store/reducers/user/feedbacksReducer';
import search from '@store/reducers/search/searchReducer';
import chatbot from '@store/reducers/chatbot/chatbotReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { PLACE_CERTIFICATES_LOADING, SAVE_PLACE_CERTIFICATES } from "../constants/place";

const rootReducer = combineReducers({
  app: combineReducers({
    general,
    view,
    time: loadDataReducer(TIMEZONE_INFO_LOADING, SAVE_TIMEZONE_INFO, truthful),
    tags: loadDataReducer(TAGS_LOADING, SAVE_TAGS, truthful),
    cuisines: loadDataReducer(CUISINES_LOADING, SAVE_CUISINES, truthful)
  }),
  modal,
  place: combineReducers({
    details,
    slots: loadDataReducer(PLACE_SLOTS_LOADING, SAVE_PLACE_SLOTS, truthful),
    reviews,
    menu,
    deposits: loadDataReducer(PLACE_DEPOSITS_LOADING, SAVE_PLACE_DEPOSITS, truthful),
    certificates: loadDataReducer(PLACE_CERTIFICATES_LOADING, SAVE_PLACE_CERTIFICATES, truthful),
  }),
  notification: combineReducers({
    details: loadDataReducer(NOTIFICATIONS_LOADING, SAVE_NOTIFICATIONS, truthful),
    unreadCount: loadDataReducer(UNREAD_NOTIFICATIONS_COUNT_LOADING, SAVE_UNREAD_NOTIFICATIONS_COUNT, truthful),
  }),
  reservation: combineReducers({
    details: loadDataReducer(RESERVATION_LOADING, SAVE_RESERVATION, truthful),
    active: loadDataReducer(ACTIVE_RESERVATIONS_LOADING, SAVE_ACTIVE_RESERVATIONS, truthful),
    old: loadDataReducer(OLD_RESERVATIONS_LOADING, SAVE_OLD_RESERVATIONS, truthful),
    quick: reservation,
  }),
  user: combineReducers({
    details: loadDataReducer(USER_LOADING, SAVE_USER, truthful),
    registration: user,
    location,
    submenu,
    feedbacks,
    favoritePlaces: loadDataReducer(FAVORITE_PLACES_LOADING, SAVE_FAVORITE_PLACES, truthful),
    likes,
    auth: loadDataReducer(LOAD_AUTH_START, LOAD_AUTH, truthful),
  }),
  billing: loadDataReducer(BILLING_LOADING, SAVE_BILLING, truthful),
  search,
  chatbot,
  toastr: toastrReducer
});

export default rootReducer;
