import React, { memo } from 'react';
import TextLink from "@components/navigation/TextLink/TextLink";
import ReservationDetails from "@components/display/ReservationDetails/ReservationDetails";
import mova from 'mova';
import { baseUrlPath } from "utils/url/urlUtils";
import Image from '@components/display/Image/Image';

const t = mova.ns('pages.Payment');

const PlaceReservationPaymentInfo = ({ place, reservation }) => {
  return (
    <div>
      <div className='reservation-info__title'>{t('title')}</div>
      <div className='reservation-info__details'>
        {place.avatarUrl && (
          <div className='reservation-info__photo'>
            <Image src={place.avatarUrl} alt={place.name} cover />
          </div>
        )}
        <div className='reservation-info__right'>
          <TextLink to={`${baseUrlPath()}/places/${place.uri}`} className='reservation-info__resto-name'>
            {place.name}
          </TextLink>
          <ReservationDetails reservation={reservation} place={place} />
        </div>
      </div>
    </div>
  );
};

export default memo(PlaceReservationPaymentInfo);
