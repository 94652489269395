export default {
  TEXT_BLACK: '#303633',
  TEXT_DEFAULT: '#555D59',
  TEXT_SECONDARY: '#87918C',
  TEXT_DISABLED: '#BCC2BF',
  TEXT_GREY: '#D7DBD9',
  TEXT_LIGHT_GREY:  '#F8F8F8',
  COLOR_PRIMARY_VERY_DARK: '#293D33',
  COLOR_PRIMARY_DARK: '#41764C',
  COLOR_PRIMARY_STRONG: '#5EA66C',
  COLOR_PRIMARY: '#90C29A',
  COLOR_PRIMARY_MIDDLE:  '#A1D3AB',
  COLOR_PRIMARY_LIGHT:  '#D2EAD7',
  COLOR_DANGER_LIGHT: '#F79B9E',
  COLOR_DANGER: '#EC6061',
  COLOR_DANGER_PRESSED: '#AE3D46',
  COLOR_LINK_MAIN: '#338FE4',
  COLOR_LINK_LIGHT: '#E6F7FF',
  COLOR_MENU_HOVER: '#EFF6F0',
  COLOR_YELLOW: '#E2C15C'
};
