import Immutable from '@utils/immutable/Immutable';
import {
  SAVE_QUICK_SEATS,
  SAVE_QUICK_DATE,
  SELECT_NEXT_DAY
} from '@constants/reservation';
import { isSameDate } from '@utils/date/dateUtils';
import moment from 'moment';

const initialState = {
  seats: 2,
  date: new Date()
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SAVE_QUICK_SEATS: {
      return Immutable({ ...state, seats: action.payload });
    }
    case SAVE_QUICK_DATE: {
      const isTodayDate = isSameDate(action.payload, new Date());
      const isSame = isSameDate(state.date, action.payload);
      const date = !isSame && isTodayDate ? new Date() : action.payload;
      return Immutable({ ...state, date });
    }
    case SELECT_NEXT_DAY: {
      const tomorrow = moment(state.date).add(1, 'day').startOf('day').toDate();
      return Immutable({ ...state, date: tomorrow });
    }
    default:
      return state;
  }
};
