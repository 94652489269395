import React from 'react';
import PropTypes from 'prop-types';
import './SlidingMenu.scss';
import { useSwipeable } from 'react-swipeable';
import Div100vh from 'react-div-100vh';

function SlidingMenu({ open, onClose, className, children, submenuOpen }) {
  const menuSwiped = (params) => {
    if (params.dir === 'Right') {
      onClose();
    }
  };

  const handlers = useSwipeable({
    onSwiped: menuSwiped
  });

  return (
    <Div100vh
      className={`sliding-menu-overlay ${open && 'sliding-menu-overlay--visible'}`}
      role='presentation'
      tabIndex='-1'
      onClick={onClose}
    >
      <div
        {...handlers}
        className={`sliding-menu ${className} ${open && 'sliding-menu--visible'}`}
        role='presentation'
        tabIndex='-1'
      >
        <Div100vh className={`sliding-menu__content ${submenuOpen && 'sliding-menu__content--expanded'}`}>
          {children}
        </Div100vh>
      </div>
    </Div100vh>
  );
}

SlidingMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.array.isRequired,
  submenuOpen: PropTypes.bool
};

SlidingMenu.defaultProps = {
  className: '',
  submenuOpen: false
};

export default SlidingMenu;
