import Immutable from '@utils/immutable/Immutable';
import { ADD_MESSAGE, REPLACE_HISTORY } from '@constants/chatbot';

const initialState = {
  history: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_MESSAGE: {
      return Immutable({ ...state, history: [...state.history, payload] });
    }
    case REPLACE_HISTORY: {
      return Immutable({ ...state, history: payload });
    }
    default:
      return state;
  }
};
