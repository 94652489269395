export const SHOW_TOASTR = '@@reservble/app/SHOW_TOASTR';

export const TIMEZONE_INFO_LOADING = '@@reservble/app/TIMEZONE_INFO_LOADING';
export const SAVE_TIMEZONE_INFO = '@@reservble/app/SAVE_TIMEZONE_INFO';

export const TAGS_LOADING = '@@reservble/app/TAGS_LOADING';
export const SAVE_TAGS = '@@reservble/app/SAVE_TAGS';

export const INITIAL_LOADING_FINISHED = '@@reservble/app/INITIAL_LOADING_FINISHED';

export const DEFAULT_LANG = 'ua';
export const DEFAULT_CITY = 'kyiv';

export const PROVIDER_FACEBOOK = 'facebook';
export const PROVIDER_GOOGLE = 'google';

export const CUISINES_LOADING = '@@reservble/app/CUISINES_LOADING';
export const SAVE_CUISINES = '@@reservble/app/SAVE_CUISINES';

export const TOGGLE_VIEW = '@@reservble/view/TOGGLE_VIEW';
export const VIEW_MAP = '@@reservble/view/VIEW_MAP';
export const VIEW_TILES = '@@reservble/view/VIEW_TILES';
