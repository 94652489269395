import React from 'react';
import Colors from '@data/enums/Color.enum';

export function edit({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.1875 12H19.875C19.7719 12 19.6875 12.0844 19.6875 12.1875V19.6875H4.3125V4.3125H11.8125C11.9156 4.3125 12 4.22812 12 4.125V2.8125C12 2.70937 11.9156 2.625 11.8125 2.625H3.375C2.96016 2.625 2.625 2.96016 2.625 3.375V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V12.1875C21.375 12.0844 21.2906 12 21.1875 12Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
      <path
        d='M8.34131 12.5367L8.29678 15.3234C8.29443 15.532 8.46318 15.7031 8.67178 15.7031H8.68115L11.4468 15.6352C11.4937 15.6328 11.5405 15.6141 11.5733 15.5812L21.321 5.85469C21.3937 5.78203 21.3937 5.6625 21.321 5.58984L18.4077 2.67891C18.3702 2.64141 18.3233 2.625 18.2741 2.625C18.2249 2.625 18.178 2.64375 18.1405 2.67891L8.39521 12.4055C8.36136 12.4409 8.34211 12.4877 8.34131 12.5367ZM9.82959 13.0898L18.2741 4.66406L19.3335 5.72109L10.8843 14.1516L9.81318 14.1773L9.82959 13.0898Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
