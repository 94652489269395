import moment from 'moment';

const momentFromTimeString = (str, breakpoint) => {
  const hours = parseInt(str.substring(0, 2), 10);
  const minutes = parseInt(str.substring(3, 5), 10);

  const dateBreakpoint = breakpoint ? moment(breakpoint) : moment();
  const hoursBreakpoint = dateBreakpoint.get('hours');
  const minutesBreakpoint = dateBreakpoint.get('minutes');

  const date = moment().set({ hour: hours, minute: minutes });

  if (hours < hoursBreakpoint || (hours === hoursBreakpoint && minutes < minutesBreakpoint)) {
    date.add(1, 'days');
  }

  return date;
};

export const fromTime = (date) => (a, b) => {
  const momentA = momentFromTimeString(a, date);
  const momentB = momentFromTimeString(b, date);
  return momentA.diff(momentB, 'minutes');
};
