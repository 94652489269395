import api from '@api';
import { get } from '@utils/lo/lo';
import { ADD_MESSAGE, REPLACE_HISTORY } from '../constants/chatbot';
import { toastr } from 'react-redux-toastr';

export const sendQuestion = (message, token, cityId) => dispatch => {
  const body = {
    userToken: token,
    message,
    cityId,
  };

  dispatch({ type: ADD_MESSAGE, payload: { role: 'user', content: message } });
  setTimeout(() => {
    dispatch({ type: ADD_MESSAGE, payload: { role: 'assistant', content: '...' } });
  }, 1000);

  const apiRequest = get(api, 'chatbot.ask');
  apiRequest(body)
    .then((res) => {
      dispatch({ type: REPLACE_HISTORY, payload: res.data.history });
    })
    .catch(() => {
      toastr.error('У нас виникла якась проблема на сервері');
    });
};
