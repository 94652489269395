import React from 'react';

export function basket() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_5_1235)'>
        <path d='M17.1922 5.14064C17.1425 5.07008 17.0766 5.01249 17 4.97271C16.9234 4.93294 16.8384 4.91215 16.7521 4.91209H5.25629L4.38611 1.91126C4.04486 0.729613 3.23396 0.602051 2.90138 0.602051H0.581151C0.283759 0.602051 0.0432587 0.842822 0.0432587 1.13993C0.0432587 1.43703 0.28403 1.67778 0.581134 1.67778H2.90109C2.97449 1.67778 3.19847 1.67778 3.35123 2.20564L6.34476 13.2072C6.40976 13.4393 6.62128 13.5996 6.86257 13.5996H14.1745C14.4015 13.5996 14.6041 13.4574 14.6807 13.2437L17.258 5.63193C17.3173 5.467 17.2927 5.28337 17.1922 5.14064H17.1922ZM13.796 12.5241H7.27084L5.55863 5.98811H15.9873L13.796 12.5241ZM12.7292 14.6897C11.9812 14.6897 11.375 15.2958 11.375 16.0439C11.375 16.7919 11.9812 17.3981 12.7292 17.3981C13.4773 17.3981 14.0834 16.7919 14.0834 16.0439C14.0834 15.2958 13.4773 14.6897 12.7292 14.6897ZM7.85421 14.6897C7.10617 14.6897 6.50004 15.2958 6.50004 16.0439C6.50004 16.7919 7.10617 17.3981 7.85421 17.3981C8.60225 17.3981 9.20838 16.7919 9.20838 16.0439C9.20838 15.2958 8.60225 14.6897 7.85421 14.6897Z' fill='#65AC0B' />
      </g>
      <defs>
        <clipPath id='clip0_5_1235'>
          <rect width='17.3333' height='17.3333' fill='white' transform='translate(0 0.333374)' />
        </clipPath>
      </defs>
    </svg>
  );
}
