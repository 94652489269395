import React, { useEffect } from 'react';
import mova from 'mova';
import PropTypes from 'prop-types';
import './PlaceFeedback.scss';
import Loading from '@components/feedback/Loading/Loading';
import { get } from '@utils/lo/lo';
import Entity404 from '../Error/Entity404';
import FeedbackForm from '@containers/FeedbackForm/FeedbackForm';
import { useHistory, useParams } from 'react-router-dom';
import seo from '@utils/seo';
import { Helmet } from 'react-helmet';
import { baseUrlPath, getUserLang } from '@utils/url/urlUtils';
import { connect } from 'react-redux';
import { getPlace } from '@actions/place';
import { getUserByToken } from '@actions/user';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@utils/constants';
import Div100vh from 'react-div-100vh';

const t = mova.ns('pages.PlaceFeedback');

const PlaceFeedback = ({ place, getPlace, loading, user, getUserByToken }) => {
  const { placeUri, token } = useParams();
  const history = useHistory();

  useEffect(() => {
    getPlace(placeUri);
  }, [placeUri, getPlace, user]);

  useEffect(() => {
    if (!user) {
      getUserByToken(token);
    }
  }, [user, token, getUserByToken]);

  const feedbackLeft = () => {
    history.push(`${baseUrlPath()}/places/${placeUri}`);
  };

  if (loading) {
    return <Loading />;
  }

  if (!place) {
    return <Entity404 entity={t('place')} />;
  }

  if (!user) {
    return <Entity404 entity={t('user')} />;
  }

  const language = getUserLang();

  return (
    <div className='place-feedback'>
      <Helmet>
        <title>{seo.placeTitle[language] + place.name}</title>
      </Helmet>
      <Div100vh
        className='place-feedback__content'
        style={{ minHeight: `calc(100rvh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})` }}
      >
        <div className='place-feedback__text'>
          {t('leaveFeedback')} {place.name}
        </div>
        <FeedbackForm
          className='place-feedback__form'
          user={user}
          place={place}
          review={place.currentReview || {}}
          closeForm={feedbackLeft}
        />
      </Div100vh>
    </div>
  );
};

PlaceFeedback.propTypes = {
  place: PropTypes.object,
  getPlace: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getUserByToken: PropTypes.func.isRequired,
  user: PropTypes.object
};

PlaceFeedback.defaultProps = {
  place: null,
  user: undefined
};

const mapStateToProps = ({ place, user }) => ({
  place: place.details.data,
  loading: place.details.isLoading || user.details.isLoading || (!place.details.data && !place.details.error),
  user: get(user, 'details.data.user'),
});

const mapDispatchToProps = {
  getPlace,
  getUserByToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceFeedback);
