import React, { memo, useEffect, useMemo, useState } from 'react';
import './TopUserMenu.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import mova from 'mova';
import { isFunction } from '@utils/lo/lo';
import Avatar from '@components/display/Avatar/Avatar';
import TopMenuOption from './TopMenuOption';
import { baseUrlPath } from '@utils/url/urlUtils';

const t = mova.ns('containers.TopUserMenu');
const profileT = mova.ns('pages.Profile');

function TopUserMenu(props) {
  const {
    user, mobile, location, newNotifications, reservationNotifications, history, onSignOutClick, submenuOpen,
    toggleUserSubmenu
  } = props;

  const [open, setOpen] = useState(mobile);

  const toggleOptions = (e) => {
    setOpen(!open);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const closeOptions = () => setOpen(false);

  useEffect(() => {
    document.addEventListener('click', closeOptions);
    return () => document.removeEventListener('click', closeOptions);
  }, []);

  const itemSelected = item => {
    if (isFunction(item.onClick)) {
      item.onClick();
    }

    if (item.path) {
      history.push(item.path);
    }
  };

  const allNotifications = newNotifications + reservationNotifications;

  const basePath = baseUrlPath();
  const selectOptions = useMemo(() => ([
    {
      label: t('notifications'),
      icon: 'bell',
      path: `${basePath}/notifications`,
      notifications: newNotifications
    },
    {
      label: t('currentReserve'),
      icon: 'safety',
      path: `${basePath}/reservations`,
      notifications: reservationNotifications
    },
    {
      label: t('history'),
      icon: 'history',
      path: `${basePath}/history`
    },
    {
      label: t('favorites'),
      icon: 'heart',
      path: `${basePath}/favorites`
    },
    {
      label: t('profile'),
      icon: 'settings',
      path: `${basePath}/profile/profile`,
      options: [
        {
          label: profileT('profile'),
          path: `${basePath}/profile/profile`
        },
        {
          label: profileT('preferences'),
          path: `${basePath}/profile/preferences`
        },
        {
          label: profileT('feedback'),
          path: `${basePath}/profile/feedback`
        },
        {
          label: profileT('mailing'),
          path: `${basePath}/profile/mailing`
        },
      ]
    },
    {
      label: t('logout'),
      icon: 'logout',
      onClick: onSignOutClick
    },
  ]), [basePath, newNotifications, onSignOutClick, reservationNotifications]);

  const selectItems = selectOptions
    .map(option => (
      <TopMenuOption
        key={option.label}
        option={option}
        onSelect={itemSelected}
        inline={mobile}
        selected={option.path === location.pathname && !(mobile && option.options)}
        submenuOpen={submenuOpen}
        toggleUserSubmenu={toggleUserSubmenu}
        currentPath={location.pathname}
      />
    ));

  return (
    <div className={`top-menu ${mobile && 'top-menu--inline'}`}>
      {
        !mobile && (
          <Avatar
            user={user}
            notifications={allNotifications}
            onClick={toggleOptions}
            size={40}
            clickable
          />
        )
      }
      {
        (open || mobile) && (
          <div className='top-menu__options'>
            {selectItems}
          </div>
        )
      }
    </div>
  );
}

TopUserMenu.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSignOutClick: PropTypes.func.isRequired, // eslint-disable-line
  mobile: PropTypes.bool,
  location: PropTypes.object.isRequired,
  newNotifications: PropTypes.number,
  reservationNotifications: PropTypes.number,
  submenuOpen: PropTypes.bool,
  toggleUserSubmenu: PropTypes.func.isRequired
};

TopUserMenu.defaultProps = {
  mobile: false,
  newNotifications: 0,
  reservationNotifications: 0,
  submenuOpen: false,
};

export default memo(withRouter(TopUserMenu));
