export const LOAD_AUTH_START = '@@reservble/user/LOAD_AUTH_START';
export const LOAD_AUTH = '@@reservble/user/LOAD_AUTH';

export const USER_LOADING = '@@reservble/user/USER_LOADING';
export const SAVE_USER = '@@reservble/user/SAVE_USER';

export const SAVE_USER_LOCATION = '@@reservble/user/SAVE_USER_LOCATION';
export const GEOLOCATION_PERMISSION_DENIED = '@@reservble/user/GEOLOCATION_PERMISSION_DENIED';

export const UPDATE_PROFILE_START = '@@reservble/user/UPDATE_PROFILE_START';
export const UPDATE_PROFILE = '@@reservble/user/UPDATE_PROFILE';

export const SAVE_REGISTRATION_DATA = '@@reservble/user/SAVE_REGISTRATION_DATA';

export const USER_FEEDBACKS_LOADING = '@@reservble/user/USER_FEEDBACKS_LOADING';
export const SAVE_USER_FEEDBACKS = '@@reservble/user/SAVE_USER_FEEDBACKS';

export const TOGGLE_USER_SUBMENU = '@@reservble/user/TOGGLE_USER_SUBMENU';

export const FAVORITE_PLACES_LOADING = '@@reservble/user/FAVORITE_PLACES_LOADING';
export const SAVE_FAVORITE_PLACES = '@@reservble/user/SAVE_FAVORITE_PLACES';

export const PLACE_LIKE_LOADING = '@@reservble/user/PLACE_LIKE_LOADING';
export const SAVE_LIKED_PLACES = '@@reservble/user/SAVE_LIKED_PLACES';

export const SET_DIFFERENT_CITY = '@@reservble/user/SET_DIFFERENT_CITY';
