import Immutable from '@utils/immutable/Immutable';
import { TOGGLE_VIEW, VIEW_MAP, VIEW_TILES } from '@constants/app';

const initialState = VIEW_MAP;

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case TOGGLE_VIEW: {
      return Immutable(state === VIEW_TILES ? VIEW_MAP : VIEW_TILES);
    }
    default:
      return state;
  }
};
