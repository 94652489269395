import Immutable from '@utils/immutable/Immutable';
import {
  ADD_DISH,
  REMOVE_DISH,
  CLEAR_ORDER
} from '@constants/menu';
import { clone } from '@utils/lo/lo';
import LocalStorageService from '@services/LocalStorage.service';

const initialState = LocalStorageService.get(LocalStorageService.Keys.ORDERS) || {};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case CLEAR_ORDER: {
      const place = action.payload;
      const newState = { ...state, [place.uri]: undefined };
      LocalStorageService.set(LocalStorageService.Keys.ORDERS, newState);
      return Immutable(newState);
    }
    case ADD_DISH: {
      const dish = action.payload;
      const { place } = action;
      const placeOrders = clone(state[place.uri] || []);
      const dishOrder = placeOrders.find(o => o.id === dish.id);

      if (dishOrder) {
        dishOrder.quantity += 1;
      } else {
        placeOrders.push({
          id: dish.id,
          quantity: 1,
        });
      }

      const newState = { ...state, [place.uri]: placeOrders };
      LocalStorageService.set(LocalStorageService.Keys.ORDERS, newState);
      return Immutable(newState);
    }
    case REMOVE_DISH: {
      const dish = action.payload;
      const { place } = action;
      const placeOrders = clone(state[place.uri] || []);
      const dishOrder = placeOrders.find(o => o.id === dish.id);

      if (dishOrder) {
        if (dishOrder.quantity <= 1) {
          placeOrders.splice(placeOrders.indexOf(dishOrder), 1);
        } else {
          dishOrder.quantity -= 1;
        }
      }

      const newState = { ...state, [place.uri]: placeOrders };
      LocalStorageService.set(LocalStorageService.Keys.ORDERS, newState);
      return Immutable(newState);
    }
    default:
      return state;
  }
};
