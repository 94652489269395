import React from 'react';

export function google() {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.9933 8.13097C14.9933 7.5574 14.9457 7.13884 14.8426 6.70479H8.13947V9.29361H12.0741C11.9948 9.93697 11.5664 10.9059 10.6145 11.5569L10.6011 11.6436L12.7205 13.2479L12.8674 13.2622C14.2159 12.0453 14.9933 10.2547 14.9933 8.13097'
        fill='#4285f4'
      />
      <path
        d='M8.13947 14.9519C10.0671 14.9519 11.6853 14.3318 12.8674 13.2622L10.6145 11.5569C10.0116 11.9677 9.20243 12.2545 8.13947 12.2545C6.25149 12.2545 4.64909 11.0376 4.07788 9.35565L3.99415 9.3626L1.79036 11.0291L1.76154 11.1074C2.93557 13.3862 5.34712 14.9519 8.13947 14.9519Z'
        fill='#34a853'
      />
      <path
        d='M4.07787 9.35565C3.92715 8.9216 3.83992 8.4565 3.83992 7.97595C3.83992 7.49536 3.92715 7.03031 4.06994 6.59626L4.06595 6.50382L1.83453 4.81056L1.76152 4.8445C1.27765 5.79014 1 6.85205 1 7.97595C1 9.09986 1.27765 10.1617 1.76152 11.1074L4.07787 9.35565'
        fill='#fbbc05'
      />
      <path
        d='M8.13947 3.69735C9.48008 3.69735 10.3844 4.26318 10.9 4.73602L12.9149 2.81375C11.6775 1.68985 10.0671 1 8.13947 1C5.34712 1 2.93557 2.5657 1.76154 4.8445L4.06995 6.59626C4.64909 4.91428 6.25149 3.69735 8.13947 3.69735'
        fill='#eb4335'
      />
    </svg>
  );
}
