import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './PromoSuccessModal.scss';
import { connect } from 'react-redux';
import Modal from '@components/feedback/Modal/Modal';
import LocaleService from '@services/Locale.service';

const t = mova.ns('containers.PromoSuccessModal');

function PromoSuccessModal({ meta }) {
  const { place, channelId } = meta;
  const congratsMessage = place.translate?.channels?.[channelId]?.promoSuccess?.[LocaleService.getLocale()];

  return (
    <Modal title={t('congrats')} className='promo-success-modal'>
      <div className='promo-success-modal__message'>
        {congratsMessage || t('defaultMessage')}
      </div>
    </Modal>
  );
}

PromoSuccessModal.propTypes = {
  meta: PropTypes.object
};

PromoSuccessModal.defaultProps = {
  meta: {}
};

const mapStateToProps = ({ modal }) => ({
  meta: modal.params
});

export default memo(connect(mapStateToProps)(PromoSuccessModal));
