import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './UserProfile.scss';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
// import Colors from '@data/enums/Color.enum';
import mova from 'mova';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import Button from '@components/inputs/Button/Button';
import TextInput from '@components/inputs/TextInput/TextInput';
import PhoneInput from '@components/inputs/PhoneInput/PhoneInput';
import TextLink from '@components/navigation/TextLink/TextLink';
import Form from '@components/inputs/Form/Form';
import { phoneRegexp } from '@utils/validation/validationUtils';
import AvatarEditor from 'react-avatar-editor';
import Avatar from '@components/display/Avatar/Avatar';
import EXIF from 'exif-js';

const t = mova.ns('containers.UserProfile');
const errorsT = mova.ns('errors.validation');

const mergeFilledProps = (user, model) => Object.keys(model).reduce((obj, key) => {
  obj[key] = model[key] || user[key];
  return obj;
}, {});

function UserProfile(props) {
  const { user, updateProfile, changePassword, updateAvatar, deleteAvatar } = props;
  const { countReservations, countStars, countComments, countCities } = user;

  const [userPhoto, setUserPhoto] = useState(null);
  const [avatarEditorOpen, toggleAvatarEditor] = useState(false);

  const showUserStats = countCities || countReservations || countStars || countComments;
  const [rotate, setRotate] = useState(0);

  const setEditorRef = useRef();

  const submitForm = (model) => {
    updateProfile(mergeFilledProps(user, model));
  };

  const submitChangePassword = (model) => {
    const { newPassword, oldPassword } = model;
    changePassword({ newPassword, oldPassword });
  };

  const uploadPhoto = (e) => {
    const file = e.target.files[0];

    // Fixes rotation of some images on iOS
    EXIF.getData(file, () => {
      const orientation = EXIF.getTag(file, 'Orientation');

      let rotatePic = 0;

      switch (orientation) {
        case 8:
          rotatePic = 270;
          break;
        case 6:
          rotatePic = 90;
          break;
        case 3:
          rotatePic = 180;
          break;
        default:
          rotatePic = 0;
      }

      setRotate(rotatePic);
    });

    setUserPhoto(file);

    if (setEditorRef.current) {
      const canvas = setEditorRef.current.getImageScaledToCanvas();
      const context = canvas.getContext('2d');
      context.globalCompositeOperation = 'destination-over';
      context.fillRect(0, 0, canvas.width, canvas.height);
    }

    toggleAvatarEditor(true);
  };

  const savePhoto = () => {
    if (setEditorRef.current) {
      const canvas = setEditorRef.current.getImageScaledToCanvas();
      if (canvas) {
        canvas.toBlob(
          blob => {
            const image = new File([blob], `${`${user.name}profile`}.jpg`, { type: 'image/jpeg' });
            const fd = new FormData();
            fd.append('avatar', image);
            updateAvatar({ file: fd });

            setTimeout(() => toggleAvatarEditor(false), 100);
          },
          'image/jpeg',
          1
        );
      }
    }
  };

  const deletePhoto = () => {
    deleteAvatar();
  };

  return (
    <div className='user-profile'>
      <div className='profile-page__form-container statistics'>
        <div>
          <div className='avatar'>
            <div className='avatar__img-wrapper'>
              {
                !avatarEditorOpen && (
                  <label htmlFor={user.avatarUrl ? undefined : 'avatar'}>
                    <Avatar
                      user={user}
                      size={80}
                      fontSize={28}
                      clickable={!user.avatarUrl}
                    />
                  </label>
                )}
              {
                avatarEditorOpen && (
                  <AvatarEditor
                    ref={setEditorRef}
                    image={userPhoto}
                    width={80}
                    height={80}
                    border={0}
                    rotate={rotate}
                  />
                )}
            </div>

            <div className='avatar__controls'>
              <div className='avatar__title'>{user.title}</div>
              <div className='avatar__button-wrapper'>
                {
                  user.avatarUrl && (
                    <TextLink icon='close' className='avatar__action-button avatar__delete' onClick={deletePhoto}>
                      {t('photo.delete')}
                    </TextLink>
                  )}
              </div>
              <div className='avatar__button-wrapper'>
                <input type='file' name='file' id='avatar' hidden onChange={uploadPhoto} />
                <label htmlFor='avatar'>
                  <TextLink icon='download' className='avatar__action-button avatar__new'>
                    {t('photo.new')}
                  </TextLink>
                </label>
              </div>
            </div>
          </div>
          {!!showUserStats && <div className='info'>{t('info')}</div>}
          {
            !!showUserStats && (
              <div className='stats'>
                {
                  !!countCities && (
                    <div className='stats__item'>
                      <SvgIcon
                        className='stats__icon'
                        type='location'
                      />
                      {countCities} {t(`stats.cities.${getPluralsFactor(countCities)}`)}
                    </div>
                  )
                }
                {
                  !!countReservations && (
                    <div className='stats__item'>
                      <SvgIcon
                        className='stats__icon'
                        type='forkKnife'
                      />
                      {countReservations} {t(`stats.visits.${getPluralsFactor(countReservations)}`)}
                    </div>
                  )
                }
                {
                  !!countStars && (
                    <div className='stats__item'>
                      <SvgIcon
                        className='stats__icon'
                        type='starOutline'
                      />
                      {countStars} {t(`stats.rates.${getPluralsFactor(countStars)}`)}
                    </div>
                  )
                }
                {
                  !!countComments && (
                    <div className='stats__item'>
                      <SvgIcon
                        className='stats__icon'
                        type='comment'
                      />
                      {countComments} {t(`stats.feedbacks.${getPluralsFactor(countComments)}`)}
                    </div>
                  )
                }
              </div>
            )
          }
          {/* <div className='divider' /> */}
          {/* <div className='achievements'> */}
          {/* <div className='achievements__item'> */}
          {/* <SvgIcon className='achievements__icon' type='trophy' fill={Colors.COLOR_PRIMARY_STRONG} /> */}
          {/* 50 {t('achievement.rates')} */}
          {/* </div> */}
          {/* <div className='achievements__item'> */}
          {/* <SvgIcon className='achievements__icon' type='trophy' fill={Colors.COLOR_YELLOW} /> */}
          {/* 100 {t('achievement.bookings')} */}
          {/* </div> */}
          {/* </div> */}
          <div className='avatar__button-container'>
            <Button size='medium' onClick={savePhoto} behavior='submit'>{t('save')}</Button>
          </div>
        </div>
      </div>
      <Form
        onValidSubmit={submitForm}
        className='user-profile__form'
      >
        <div className='profile-page__form-container user-profile__user-data user-data'>
          <div className='user-data__row'>
            <div className='user-data__column'>
              <TextInput
                label={t('data.name')}
                placeholder={t('data.namePlaceholder')}
                fullWidth
                name='firstName'
                value={user.firstName}
                validations={{
                  minLength: 2,
                }}
                validationErrors={{
                  minLength: errorsT(`minLength.${getPluralsFactor(2)}`, { symbols: 2 }),
                  isDefaultRequiredValue: errorsT('required')
                }}
                required
              />
            </div>
            <div className='user-data__column'>
              <TextInput
                label={t('data.surname')}
                placeholder={t('data.surnamePlaceholder')}
                fullWidth
                name='lastName'
                value={user.lastName}
                validations={{
                  minLength: 2,
                }}
                validationErrors={{
                  minLength: errorsT(`minLength.${getPluralsFactor(2)}`, { symbols: 2 }),
                  isDefaultRequiredValue: errorsT('required')
                }}
                required
              />
            </div>
          </div>
          <div className='user-data__row'>
            <div className='user-data__column'>
              <PhoneInput
                label={t('data.phone')}
                name='phone'
                fullWidth
                fontSize={12}
                value={user.phone}
                validations={{
                  matchRegexp: phoneRegexp
                }}
                validationErrors={{
                  matchRegexp: errorsT('phone'),
                  isDefaultRequiredValue: errorsT('required')
                }}
                required
              />
            </div>
            <div className='user-data__column'>
              <TextInput
                label={t('data.email')}
                placeholder={t('data.emailPlaceholder')}
                fullWidth
                name='email'
                value={user.email}
                validations='isEmail'
                validationErrors={{
                  isEmail: errorsT('email'),
                  isDefaultRequiredValue: errorsT('required')
                }}
                required
              />
            </div>
          </div>
          <div className='user-data__row user-data__row--center'>
            <Button size='medium' behavior='submit'>{t('save')}</Button>
          </div>
        </div>
      </Form>
      <Form onValidSubmit={submitChangePassword} className='user-profile__form'>
        <div className='profile-page__form-container user-profile__user-data user-data'>
          <div className='user-data__row'>
            <div className='user-data__column'>
              <TextInput
                type='password'
                label={t('data.password')}
                placeholder={t('data.passwordPlaceholder')}
                fullWidth
                name='oldPassword'
                validationErrors={{
                  isDefaultRequiredValue: errorsT('required')
                }}
                required
              />
            </div>
          </div>
          <div className='user-data__row'>
            <div className='user-data__column'>
              <TextInput
                type='password'
                label={t('data.newPassword')}
                placeholder={t('data.newPasswordPlaceholder')}
                fullWidth
                name='newPassword'
                validations={{
                  minLength: 4,
                }}
                validationErrors={{
                  minLength: errorsT(`minLength.${getPluralsFactor(4)}`, { symbols: 4 }),
                  isDefaultRequiredValue: errorsT('required')
                }}
                required
              />
            </div>
            <div className='user-data__column'>
              <TextInput
                type='password'
                label={t('data.confirmPassword')}
                placeholder={t('data.confirmPasswordPlaceholder')}
                fullWidth
                name='repeatPassword'
                validations={{
                  repeatPassword: (values, value) => values.newPassword === value
                }}
                validationErrors={{
                  repeatPassword: errorsT('repeatPassword'),
                  isDefaultRequiredValue: errorsT('required')
                }}
                required
              />
            </div>
          </div>
          <div className='user-data__row user-data__row--center'>
            <Button size='medium' behavior='submit'>{t('save')}</Button>
          </div>
        </div>
      </Form>
      {/* <div className='profile-page__form-container user-profile__social social'> */}
      {/* <div className='social__link'>{t('social.link')}</div> */}
      {/* <div className='social__item'> */}
      {/* <span>Facebook {t('social.notLinked')}</span> */}
      {/* <Button size='medium'>{t('social.linkButton')}</Button> */}
      {/* </div> */}
      {/* <div className='social__item'> */}
      {/* <span>Google {t('social.linked')}</span> */}
      {/* <TextLink size='medium' className='social__unlink'>{t('social.unlinkButton')}</TextLink> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}

UserProfile.propTypes = {
  user: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  updateAvatar: PropTypes.func.isRequired,
  deleteAvatar: PropTypes.func.isRequired,
};

export default UserProfile;
