import React, { memo } from 'react';
import './ViewOrder.scss';
import mova from 'mova';
import SvgIcon from '../SvgIcon/SvgIcon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CART_MODAL } from '@store/constants/modal';
import { openModal } from '@store/actions/modal';
import { getOrderAmount } from '@utils/menu/menu';

const t = mova.ns('components.ViewOrder');

const ViewOrder = ({ className = '', showCart, ...rest }) => {
  const total = getOrderAmount();

  return (
    <div className={`view-order ${className}`} onClick={showCart} {...rest}>
      <div className='view-order__left'>
        <div className='view-order__icon'>
          <SvgIcon type='basket' />
        </div>
        <div className='view-order__text'>{t('view')}</div>
      </div>
      <div className='view-order__price'>{total} {t('uah')}</div>
    </div>
  );
};

ViewOrder.propTypes = {
  className: PropTypes.string,
  showCart: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
  // place: place.details.data,
  // orders: place.menu,
});

const mapDispatchToProps = {
  showCart: () => openModal(CART_MODAL),
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(ViewOrder));
