import React from 'react';
import Colors from '@data/enums/Color.enum';

export function additional({ fill = Colors.TEXT_DEFAULT, size = 16 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_420_1189)'>
        <path d='M10 5.33301H16V6.66634H10V5.33301Z' fill={fill} />
        <path d='M10 8H16V9.33333H10V8Z' fill={fill} />
        <path d='M10 10.667H16V12.0003H10V10.667Z' fill={fill} />
        <path d='M10 13.333H16V14.6663H10V13.333Z' fill={fill} />
        <path d='M10 16H16V17.3333H10V16Z' fill={fill} />
        <path d='M7.33398 5.33301H8.66732V6.66634H7.33398V5.33301Z' fill={fill} />
        <path d='M7.33398 8H8.66732V9.33333H7.33398V8Z' fill={fill} />
        <path d='M7.33398 10.667H8.66732V12.0003H7.33398V10.667Z' fill={fill} />
        <path d='M7.33398 13.333H8.66732V14.6663H7.33398V13.333Z' fill={fill} />
        <path d='M7.33398 16H8.66732V17.3333H7.33398V16Z' fill={fill} />
        <path
          d='M18.6667 1.33301H5.33333C4.97971 1.33301 4.64057 1.47348 4.39052 1.72353C4.14048 1.97358 4 2.31272 4 2.66634V21.333C4 21.6866 4.14048 22.0258 4.39052 22.2758C4.64057 22.5259 4.97971 22.6663 5.33333 22.6663H18.6667C19.0203 22.6663 19.3594 22.5259 19.6095 22.2758C19.8595 22.0258 20 21.6866 20 21.333V2.66634C20 2.31272 19.8595 1.97358 19.6095 1.72353C19.3594 1.47348 19.0203 1.33301 18.6667 1.33301ZM18.6667 21.333H5.33333V2.66634H18.6667V21.333Z'
          fill={fill}
        />
      </g>
    </svg>
  );
}
