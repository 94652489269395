import React, { useEffect } from 'react';
import './UserFeedbacks.scss';
import { connect } from 'react-redux';
import Feedback from '@components/display/Feedback/Feedback';
import PlaceCard from '@components/display/PlaceCard/PlaceCard';
import PropTypes from 'prop-types';
import { getUserFeedbacks } from '@actions/user';
import { get } from '@utils/lo/lo';
import Loading from '@components/feedback/Loading/Loading';
import Button from '@components/inputs/Button/Button';
import mova from 'mova';
import useMedia from '@utils/hooks/useMedia';
import { DEFAULT_PAGE_SIZE } from '@utils/constants';

const FEEDBACK_PAGE_SIZE = DEFAULT_PAGE_SIZE;
const t = mova.ns('containers.UserFeedbacks');

function UserFeedbacks(props) {
  const { feedbacks, getFeedbacks, loading, user } = props;
  const pageSize = FEEDBACK_PAGE_SIZE;

  const { rows = [], page, totalPages } = feedbacks;
  useEffect(() => {
    getFeedbacks({ page: 1, pageSize });
  }, [getFeedbacks, pageSize]);

  const fetchMoreReviews = () => {
    getFeedbacks({ page: page + 1, pageSize });
  };

  const showSmallPlaceCard = useMedia(['(max-width: 850px)'], [true], false);

  const feedbackCards = rows.map(feedback => (
    <div className='profile-page__form-container' key={feedback.id}>
      <PlaceCard className='user-feedbacks__place-card' place={feedback.place} small={showSmallPlaceCard} />
      <div className='user-feedbacks__divider' />
      <Feedback className='user-feedbacks__feedback' feedback={feedback} user={user} />
    </div>
  ));

  const fetchMoreButton = (
    <div className='user-feedbacks__more-button-container'>
      <Button
        inputClass='user-feedbacks__more-reviews'
        onClick={fetchMoreReviews}
      >
        {t('moreReviews', { pageSize })}
      </Button>
    </div>
  );

  const noFeedbacksMessage = <div className='user-feedbacks__empty'>{t('empty')}</div>;

  return (
    <div className='user-feedbacks'>
      {feedbackCards}
      {feedbackCards.length === 0 && !loading && noFeedbacksMessage}
      {!loading && page < totalPages && fetchMoreButton}
      {loading && <Loading />}
    </div>
  );
}

UserFeedbacks.propTypes = {
  feedbacks: PropTypes.object,
  getFeedbacks: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

UserFeedbacks.defaultProps = {
  feedbacks: {}
};

const mapStateToProps = ({ user }) => ({
  feedbacks: user.feedbacks,
  user: get(user, 'details.data.user'),
  loading: user.feedbacks.isLoading
});

const mapDispatchToProps = {
  getFeedbacks: params => getUserFeedbacks(params)
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFeedbacks);
