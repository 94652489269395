import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import config from 'config'; // eslint-disable-line
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import './SocialLoginButton.scss';
import { PROVIDER_FACEBOOK, PROVIDER_GOOGLE } from '@constants/app';
import LocalStorageService from '@services/LocalStorage.service';

const t = mova.ns('components.SocialLoginButton');

const providerLoginUrl = {
  [PROVIDER_FACEBOOK]: 'auth/ui/facebook',
  [PROVIDER_GOOGLE]: 'auth/ui/google'
};

function SocialLoginButton(props) {
  const { provider, className, fullWidth } = props;

  const handleLogin = () => {
    LocalStorageService.set(LocalStorageService.Keys.OAUTH_REDIRECTION, window.location.pathname);
    window.location = `${config.apiRoot || ''}/${providerLoginUrl[provider]}`;
  };

  return (
    <button
      type='button'
      className={`social-button ${className} ${fullWidth && 'social-button--full-width'}`}
      onClick={handleLogin}
    >
      {provider && <SvgIcon className='social-button__icon' type={provider} />}
      {t('text', { provider: t(provider) })}
    </button>
  );
}

SocialLoginButton.propTypes = {
  provider: PropTypes.string.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool
};

SocialLoginButton.defaultProps = {
  className: '',
  fullWidth: false
};

export default memo(SocialLoginButton);
