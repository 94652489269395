import moment from 'moment';
import memoize from 'memoize-one';

const STEP_MINUTES = 15;

export const isSameDate = memoize((date1, date2) => moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD'));

export const prettifyTime = (time, timezone) => {
  const dateObj = moment.tz(time, timezone);
  if (dateObj.minutes() % STEP_MINUTES === 0) {
    return moment(dateObj).toDate();
  }

  return moment(dateObj)
    .minutes(dateObj.minutes() + (STEP_MINUTES - (dateObj.minutes() % STEP_MINUTES)))
    .seconds(0)
    .milliseconds(0)
    .toDate();
};
