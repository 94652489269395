import memoize from 'memoize-one';

export const createInitials = memoize((name) => {
  const cleanName = (name || '').trim();
  const names = cleanName.split(' ');

  if (!names[0].length) {
    return 'NN';
  }

  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
});
