import React from 'react';
import Colors from '@data/enums/Color.enum';

export function description({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.125 6.1875C16.125 6.08437 16.0406 6 15.9375 6H6.9375C6.83437 6 6.75 6.08437 6.75 6.1875V7.3125C6.75 7.41563 6.83437 7.5 6.9375 7.5H15.9375C16.0406 7.5 16.125 7.41563 16.125 7.3125V6.1875ZM15.9375 9.375H6.9375C6.83437 9.375 6.75 9.45937 6.75 9.5625V10.6875C6.75 10.7906 6.83437 10.875 6.9375 10.875H15.9375C16.0406 10.875 16.125 10.7906 16.125 10.6875V9.5625C16.125 9.45937 16.0406 9.375 15.9375 9.375ZM11.25 12.75H6.9375C6.83437 12.75 6.75 12.8344 6.75 12.9375V14.0625C6.75 14.1656 6.83437 14.25 6.9375 14.25H11.25C11.3531 14.25 11.4375 14.1656 11.4375 14.0625V12.9375C11.4375 12.8344 11.3531 12.75 11.25 12.75Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.6875 2.53125C19.6875 2.11641 19.3523 1.78125 18.9375 1.78125H3.9375C3.52266 1.78125 3.1875 2.11641 3.1875 2.53125V20.9062C3.1875 21.3211 3.52266 21.6562 3.9375 21.6562H18.8875C19.3293 21.6562 19.6875 21.2981 19.6875 20.8563V2.53125ZM4.875 3.46875V19.9688H18V3.46875H4.875Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
