import React, { memo } from 'react';
import './Checkbox.scss';
import PropTypes from 'prop-types';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import { get, isDefined } from '@utils/lo/lo';
import { withFormsy } from 'formsy-react';

function Checkbox(props) {
  const { children, onClick, disabled, name, value, setValue } = props;

  const checkboxClicked = (e) => {
    if (isDefined(onClick)) {
      onClick(get(e, 'currentTarget.checked'));
    }
    setValue(get(e, 'currentTarget.checked'));
  };

  return (
    <label className='checkbox-container'>
      <input
        type='checkbox'
        className='checkbox'
        onChange={checkboxClicked}
        disabled={disabled}
        checked={!!value}
        name={name}
      />
      <div className='area'>
        <SvgIcon type='check' className='checkmark' />
      </div>
      <div className='text'>{children}</div>
    </label>
  );
}

Checkbox.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  disabled: false,
  name: undefined,
  onClick: undefined,
  value: false,
};

export default memo(withFormsy(Checkbox));
