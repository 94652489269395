import { connect } from 'react-redux';
import { closeModal } from '@actions/modal';
import AllowGeolocationModal from './AllowGeolocationModal';
import { getUserLocation } from '@actions/user';

const mapDispatchToProps = {
  closeModal,
  getUserLocation
};

export default connect(null, mapDispatchToProps)(AllowGeolocationModal);
