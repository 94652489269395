import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './PlaceFeedbackModal.scss';
import Modal from '@components/feedback/Modal/Modal';
import FeedbackForm from '../../FeedbackForm/FeedbackForm';

function PlaceFeedbackModal({ closeModal, place }) {
  return (
    <Modal className='place-feedback-modal'>
      <FeedbackForm
        place={place}
        review={place.currentReview}
        className='place-feedback-modal__form'
        closeForm={closeModal}
      />
    </Modal>
  );
}

PlaceFeedbackModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  place: PropTypes.object.isRequired
};

PlaceFeedbackModal.defaultProps = {};

export default memo(PlaceFeedbackModal);
