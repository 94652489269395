import React, { useEffect } from 'react';
import './UserPreferences.scss';
import mova from 'mova';
import Button from '@components/inputs/Button/Button';
import TextInput from '@components/inputs/TextInput/TextInput';
import Form from '@components/inputs/Form/Form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isDefined } from '@utils/lo/lo';
import { updateProfile } from '@actions/user';
import { getCuisines } from '@actions/app';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import MultiSelect from '@components/inputs/MultiSelect/MultiSelect';
import Loading from '@components/feedback/Loading/Loading';

const t = mova.ns('containers.UserPreferences');
const errorsT = mova.ns('errors.validation');

function UserPreferences(props) {
  const { user, updateUserProfile, allCuisines, cuisinesLoading, getAllCuisines } = props;
  const { lang, allergy, dishes, ingridients, cusines = [] } = user;

  useEffect(() => {
    if (!isDefined(allCuisines) || allCuisines.length === 0) {
      getAllCuisines(lang);
    }
  }, [getAllCuisines]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitForm = (model) => {
    const selectedCusines = model.cusines.map(val => val.item);

    updateUserProfile({ ...model, cusines: selectedCusines });
  };

  if (cuisinesLoading) {
    return <Loading />;
  }

  const cuisineOptions = allCuisines.map(item => ({ label: item.tr, value: item.name, item }));
  const cuisineValues = cusines.map(item => {
    const translatedItem = allCuisines.find(i => i.id === item.id);

    return { label: translatedItem.tr, value: translatedItem.name, item };
  });

  return (
    <div className='user-preferences'>
      <div className='profile-page__form-container'>
        <Form onValidSubmit={submitForm}>
          {/* <div className='profile-page__row'> */}
          {/* <Select */}
          {/* name='city' */}
          {/* options={['Киев', 'Одесса', 'Харьков', 'Львов']} */}
          {/* label={t('city')} */}
          {/* fullWidth */}
          {/* fontSize={14} */}
          {/* placeholder={t('cityPlaceholder')} */}
          {/* /> */}
          {/* </div> */}

          <div className='profile-page__row'>
            <TextInput
              name='allergy'
              label={t('allergy')}
              fullWidth
              fontSize={14}
              placeholder={t('allergyPlaceholder')}
              value={allergy}
              validations={{
                minLength: 2,
              }}
              validationErrors={{
                minLength: errorsT(`minLength.${getPluralsFactor(2)}`, { symbols: 2 }),
              }}
            />
          </div>

          <div className='profile-page__row'>
            <MultiSelect
              name='cusines'
              options={cuisineOptions}
              value={cuisineValues}
              label={t('favoriteCuisine')}
              placeholder={t('favoriteCuisinePlaceholder')}
              isMulti
            />
          </div>
          <div className='profile-page__row'>
            <TextInput
              name='dishes'
              label={t('favoriteDishes')}
              fullWidth
              fontSize={14}
              placeholder={t('favoriteDishesPlaceholder')}
              value={dishes}
              validations={{
                minLength: 2,
              }}
              validationErrors={{
                minLength: errorsT(`minLength.${getPluralsFactor(2)}`, { symbols: 2 }),
              }}
            />
          </div>

          <div className='profile-page__row'>
            <TextInput
              name='ingridients'
              label={t('unliked')}
              fullWidth
              fontSize={14}
              placeholder={t('unlikedPlaceholder')}
              value={ingridients}
              validations={{
                minLength: 2,
              }}
              validationErrors={{
                minLength: errorsT(`minLength.${getPluralsFactor(2)}`, { symbols: 2 }),
              }}
            />
          </div>

          <div className='profile-page__row'>
            <Button size='medium' behavior='submit'>{t('save')}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

UserPreferences.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  allCuisines: PropTypes.array.isRequired,
  cuisinesLoading: PropTypes.bool.isRequired,
  getAllCuisines: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user, app }) => ({
  user: get(user, 'details.data.user'),
  allCuisines: app.cuisines.data || [],
  cuisinesLoading: app.cuisines.isLoading
});

const mapDispatchToProps = {
  updateUserProfile: user => updateProfile(user),
  getAllCuisines: (lang) => getCuisines(lang)
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferences);
