import { v1 } from './common/clients';
import LocaleService from '@services/Locale.service';

export default {
  getTimeInfo: () => v1.get('/internal/timezone'),
  getTags: () => v1.get(`/dictionaries/i18n?lang=${LocaleService.getLocale()}`),
  postLocation: (body) => v1.post('/histories/location', body),
  logError: (body) => v1.post('/logs/client', body),
  joinRequest: (body) => v1.post('/users/request', body),
};
