import React from 'react';

export function magic({ fill = '#fff' }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.6927 13.5365L18.2344 11.2656L15.9635 5.80729C15.8073 5.4375 15.1927 5.4375 15.0365 5.80729L12.7656 11.2656L7.30729 13.5365C7.11979 13.6146 7 13.7969 7 14C7 14.2031 7.11979 14.3854 7.30729 14.4635L12.7656 16.7344L15.0365 22.1927C15.1146 22.3802 15.2969 22.5 15.5 22.5C15.7031 22.5 15.8854 22.3802 15.9635 22.1927L18.2344 16.7344L23.6927 14.4635C23.8802 14.3854 24 14.2031 24 14C24 13.7969 23.8802 13.6146 23.6927 13.5365ZM10.6563 6.52604L7.14583 5.35417L5.97396 1.84375C5.83854 1.43229 5.16146 1.43229 5.02604 1.84375L3.85417 5.35417L0.343749 6.52604C0.135416 6.59375 0 6.78646 0 7C0 7.21354 0.135416 7.40625 0.343749 7.47396L3.85417 8.64583L5.02604 12.1615C5.09375 12.3646 5.28646 12.5 5.5 12.5C5.71354 12.5 5.90625 12.3646 5.97396 12.1563L7.14583 8.64583L10.6615 7.47396C10.8646 7.40625 11 7.21354 11 7C11 6.78646 10.8646 6.59375 10.6563 6.52604ZM8.65625 19.526L6.64583 18.8542L5.97396 16.8385C5.83854 16.4323 5.16146 16.4323 5.02604 16.8385L4.35417 18.8542L2.33854 19.526C2.13542 19.5938 2 19.7865 2 20C2 20.2135 2.13542 20.4062 2.34375 20.474L4.35417 21.1458L5.02604 23.1615C5.09375 23.3646 5.28646 23.5 5.5 23.5C5.71354 23.5 5.90625 23.3646 5.97396 23.1562L6.64583 21.1458L8.66146 20.474C8.86458 20.4062 9 20.2135 9 20C9 19.7865 8.86458 19.5938 8.65625 19.526Z'
        fill={fill}
      />
    </svg>
  );
}
