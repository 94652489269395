import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import Image from '@components/display/Image/Image';
import { createInitials } from '@utils/user/userUtils';
import './Avatar.scss';

function Avatar(props) {
  const { src, user, className, notifications, size, fontSize, clickable, ...rest } = props;
  const { displayedName, avatarUrl } = user;
  const avatarSrc = src || avatarUrl;

  return (
    <Fragment>
      <div
        className={`avatar ${className} ${clickable && 'avatar--clickable'}`}
        style={{ width: size, height: size }}
        {...rest}
      >
        {
          avatarSrc && (
            <Image
              className='avatar__image'
              src={avatarSrc}
              alt={displayedName}
              cover
              style={{ width: size, height: size }}
            />
          )}
        {
          !avatarSrc && (
            <div className='avatar__generated' style={{ fontSize }}>
              {createInitials(displayedName)}
            </div>
          )
        }
      </div>
      {
        notifications > 0 && (
          <div className='avatar__notifications'>
            {notifications <= 99 ? notifications : '99+'}
          </div>
        )
      }
    </Fragment>
  );
}

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
  className: PropTypes.string,
  notifications: PropTypes.number,
  size: PropTypes.number,
  fontSize: PropTypes.number,
  clickable: PropTypes.bool,
  src: PropTypes.string,
};

Avatar.defaultProps = {
  className: '',
  notifications: 0,
  size: 40,
  fontSize: 18,
  clickable: false,
  src: null,
};

export default memo(Avatar);
