import React, { memo } from 'react';
import './SvgIcon.scss';
import PropTypes from 'prop-types';
import * as IconSet from '@theme/icons';

function SvgIcon(props) {
  const { type, fill, stroke, className, filled, iconProps, children, size, ...moreProps } = props;

  if (!type || !IconSet[type]) {
    return null;
  }

  const icon = IconSet[type];

  return (
    <div
      className={`svg-icon svg-icon__${type} ${className}`}
      {...moreProps}
    >
      {icon({ fill, stroke, filled, size, ...iconProps })}
      {children}
    </div>
  );
}

SvgIcon.propTypes = {
  type: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
  filled: PropTypes.bool,
  iconProps: PropTypes.object,
  children: PropTypes.node,
};

SvgIcon.defaultProps = {
  fill: undefined,
  stroke: undefined,
  className: '',
  filled: false,
  iconProps: {},
  children: null,
};

export default memo(SvgIcon);
