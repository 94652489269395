import React from 'react';
import Colors from '@data/enums/Color.enum';

export function history({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip-bell)'>
        <path
          d='M2.47452 10.8355C2.86342 8.07784 4.47186 5.51999 7.06994 4.01999C11.5537 1.43132 17.2726 2.96028 19.8674 7.44048C22.4656 11.9268 20.9327 17.667 16.4449 20.258C13.9037 21.7252 10.9626 21.867 8.41898 20.8972C8.32113 20.859 8.27354 20.7484 8.31177 20.6506L8.87507 19.2591C8.91244 19.1644 9.01893 19.1192 9.11444 19.1534C9.34332 19.2404 9.57884 19.3155 9.81896 19.3799C10.753 19.6319 11.7271 19.7 12.6871 19.5805C13.6817 19.4609 14.6306 19.1404 15.5074 18.6342C16.3843 18.1279 17.1363 17.4664 17.7403 16.6657C18.325 15.8924 18.7485 15.0228 19.0007 14.0815C19.2529 13.1402 19.321 12.1754 19.2013 11.2134C19.0774 10.2159 18.7569 9.26701 18.2506 8.39016C17.7444 7.51331 17.0828 6.76128 16.2821 6.15726C15.5089 5.5726 14.6392 5.14913 13.6979 4.89691C12.7567 4.6447 11.7918 4.57661 10.8298 4.6963C9.83436 4.81906 8.88546 5.13957 8.00861 5.64582C7.13176 6.15207 6.37973 6.81359 5.77572 7.61427C5.19219 8.38591 4.76414 9.26357 4.51537 10.1985C4.43559 10.4962 4.37384 10.7971 4.331 11.0979L5.86545 11.3134C5.90042 11.3182 5.93335 11.3327 5.96044 11.3553C5.98752 11.378 6.00768 11.4078 6.01858 11.4413C6.02948 11.4749 6.03068 11.5109 6.02205 11.5451C6.01342 11.5793 5.99531 11.6104 5.96979 11.6348L3.1416 14.3394C3.05418 14.4223 2.91019 14.4026 2.85042 14.2991L0.877152 10.9188C0.798637 10.7828 0.910397 10.6154 1.06516 10.6397L2.47452 10.8355Z'
          fill={fill || Colors.TEXT_DEFAULT}
        />
        <path
          d='M15.8445 15.4047L12.5023 12.9883V7.1875C12.5023 7.08437 12.418 7 12.3148 7H11.1875C11.0844 7 11 7.08437 11 7.1875V13.6422C11 13.7031 11.0281 13.7594 11.0773 13.7945L14.9539 16.6211C15.0383 16.682 15.1555 16.6633 15.2164 16.5812L15.8867 15.6672C15.9476 15.5804 15.9289 15.4633 15.8445 15.4047Z'
          fill={fill || Colors.TEXT_DEFAULT}
        />
      </g>
      <defs>
        <clipPath id='clip-bell'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
