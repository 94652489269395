import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './FinishRegistrationModal.scss';
import TextInput from '@components/inputs/TextInput/TextInput';
import Checkbox from '@components/inputs/Checkbox/Checkbox';
import Button from '@components/inputs/Button/Button';
import Modal from '@components/feedback/Modal/Modal';
import { withRouter } from 'react-router-dom';
import Form from '@components/inputs/Form/Form';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';

const t = mova.ns('containers.FinishRegistrationModal');
const errorsT = mova.ns('errors.validation');

function FinishRegistrationModal({ finishRegistration, reservation, location }) {
  const placeUri = useMemo(
    () => location.pathname.substring(location.pathname.slice(1).indexOf('/') + 2, location.pathname.lastIndexOf('/')),
    [location.pathname]
  );

  const submitForm = (model) => finishRegistration(placeUri, model, reservation);

  return (
    <Modal title={t('lastStep')} className='finish-registration-modal'>
      <Form onValidSubmit={submitForm}>
        <div className='finish-registration-modal__row'>
          <TextInput
            name='password'
            placeholder={t('password')}
            fullWidth
            fontSize={14}
            type='password'
            validations={{
              minLength: 4,
            }}
            validationErrors={{
              minLength: errorsT(`minLength.${getPluralsFactor(4)}`, { symbols: 4 }),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='finish-registration-modal__row'>
          <TextInput
            name='repeatPassword'
            placeholder={t('repeatPassword')}
            fullWidth
            fontSize={14}
            type='password'
            validations={{
              minLength: 4,
              repeatPassword: (values, value) => values.password === value
            }}
            validationErrors={{
              minLength: errorsT(`minLength.${getPluralsFactor(4)}`, { symbols: 4 }),
              repeatPassword: errorsT('repeatPassword'),
              isDefaultRequiredValue: errorsT('required')
            }}
            required
          />
        </div>
        <div className='finish-registration-modal__row'>
          <Checkbox name='rememberMe'>{t('rememberMe')}</Checkbox>
        </div>
        <div className='finish-registration-modal__actions'>
          <Button fullWidth size='medium' behavior='submit'>{t('createAccount')}</Button>
        </div>
      </Form>
    </Modal>
  );
}

FinishRegistrationModal.propTypes = {
  finishRegistration: PropTypes.func.isRequired,
  reservation: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

FinishRegistrationModal.defaultProps = {};

export default memo(withRouter(FinishRegistrationModal));
