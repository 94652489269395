import React from 'react';

export function botMessage() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='black' />
      <path
        d='M20.8737 13.5439C22.1991 12.0146 24.154 11.25 26.7384 11.25V17.8989C26.142 17.8103 25.5898 17.766 25.0817 17.766C22.5194 17.766 21.2382 19.0736 21.2382 21.6888V29.7673H13.75V11.5824H20.8737V13.5439Z'
        fill='white'
      />
    </svg>
  );
}
