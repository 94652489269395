import { v1 } from './common/clients';
import queryString from 'query-string';
import { activeReservationStatuses, oldReservationStatuses } from '@constants/reservation';

export default {
  getOne: (data) => v1.get(`/places/${data.placeUri}/reservations/${data.reservationId}`),
  add: (placeUri, body) => v1.post(`/places/${placeUri}/reservations`, body),
  confirm: (placeUri, reservationId, body) => v1.put(`/places/${placeUri}/reservations/${reservationId}`, body),
  active: (params) => v1.get(`/profile/reservations?${queryString.stringify({
    status: activeReservationStatuses,
    ...params
  })}`),
  countActive: () => v1.get(`/profile/reservations/count?${queryString.stringify({
    status: activeReservationStatuses
  })}`),
  old: (params) => v1.get(`/profile/reservations?${queryString.stringify({
    status: oldReservationStatuses,
    showSlots: true,
    ...params
  })}`),
  cancel: (uri, id, body) => v1.put(`places/${uri}/reservations/${id}/canceled`, body)
};
