import React, { memo } from 'react';
import './RadioButton.scss';
import PropTypes from 'prop-types';
import { get } from '@utils/lo/lo';

function RadioButton({ children, disabled, name, setValue, required, checked, value, ...rest }) {
  const radioClicked = (e) => {
    setValue && setValue(get(e, 'currentTarget.value'));
  };

  return (
    <label className='radiobutton-container' {...rest}>
      <input
        type='radio'
        className='radiobutton'
        onChange={radioClicked}
        disabled={disabled}
        defaultChecked={checked}
        name={name}
        value={value}
        required={required}
      />
      <div className='area'>
        <div className='checkmark' />
      </div>
      {children && <div className='text'>{children}</div>}
    </label>
  );
}

RadioButton.propTypes = {
  children: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.string
};

RadioButton.defaultProps = {
  disabled: false,
  name: undefined,
  children: undefined,
  required: false,
  checked: false,
  value: '',
  setValue: undefined,
};

export default memo(RadioButton);
