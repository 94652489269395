import React, { memo } from 'react';
import './QueryResult.scss';
import PropTypes from 'prop-types';
import mova from 'mova';
import { getPluralsFactor } from 'utils/i18n/i18nUtils';

const t = mova.ns('components.QueryResult');

function QueryResult({ results, className }) {
  return (
    <div className={`query-result ${className}`}>
      <span className='query-result__results'>{results}</span>
      <span className='query-result__label'>{t(`results.${getPluralsFactor(results)}`)}</span>
    </div>
  );
}

QueryResult.propTypes = {
  results: PropTypes.number.isRequired,
  className: PropTypes.string
};

QueryResult.defaultProps = {
  className: ''
};

export default memo(QueryResult);
