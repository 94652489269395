import Immutable from '@utils/immutable/Immutable';
import { TOGGLE_USER_SUBMENU } from '@constants/user';

export default (state = false, action) => {
  const { type } = action;
  switch (type) {
    case TOGGLE_USER_SUBMENU: {
      return Immutable(!state);
    }
    default:
      return state;
  }
};
