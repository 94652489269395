import React from 'react';
import Colors from '@data/enums/Color.enum';

export function map({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.4451 4.125C21.3771 4.125 21.3069 4.13801 21.2382 4.16578L15.3337 6.375H15.3333L9.36111 4.24031C9.13446 4.16403 8.89718 4.1251 8.65833 4.125C8.42604 4.125 8.1941 4.16191 7.97049 4.23539L2.69861 6.09199C2.49242 6.17549 2.31567 6.31962 2.19115 6.50579C2.06663 6.69197 2.00005 6.91164 2 7.13648V19.3118C2 19.6349 2.26146 19.875 2.55521 19.875C2.62326 19.875 2.69306 19.862 2.76215 19.8342L8.66667 17.625L14.6389 19.7593C15.0899 19.9117 15.5774 19.9136 16.0295 19.7646L21.3014 17.908C21.5076 17.8245 21.6844 17.6804 21.8089 17.4942C21.9334 17.3081 22 17.0884 22 16.8635V4.6882C22 4.36512 21.7385 4.125 21.4451 4.125ZM9.77778 6.17883L14.2222 7.76754V17.8212L9.77778 16.2325V6.17883ZM3.66667 17.6971V7.53762L8.11111 5.97211V16.0338L8.08889 16.0419L3.66667 17.6971ZM20.3333 16.462L15.8889 18.0275V7.96617L15.9111 7.95773L20.3333 6.30293V16.462Z'
        fill={fill || Colors.TEXT_DEFAULT}
        className='fill'
      />
    </svg>
  );
}
