export const PLACE_LOADING = '@@reservble/place/PLACE_LOADING';
export const SAVE_PLACE = '@@reservble/place/SAVE_PLACE';

export const PLACE_SLOTS_LOADING = '@@reservble/place/PLACE_SLOTS_LOADING';
export const SAVE_PLACE_SLOTS = '@@reservble/place/SAVE_PLACE_SLOTS';

export const PLACE_REVIEWS_LOADING = '@@reservble/place/PLACE_REVIEWS_LOADING';
export const SAVE_PLACE_REVIEWS = '@@reservble/place/SAVE_PLACE_REVIEWS';

export const PLACE_DEPOSITS_LOADING = '@@reservble/place/PLACE_DEPOSITS_LOADING';
export const SAVE_PLACE_DEPOSITS = '@@reservble/place/SAVE_PLACE_DEPOSITS';

export const PLACE_CERTIFICATES_LOADING = '@@reservble/place/PLACE_CERTIFICATES_LOADING';
export const SAVE_PLACE_CERTIFICATES = '@@reservble/place/SAVE_PLACE_CERTIFICATES';
