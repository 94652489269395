import Immutable from '@utils/immutable/Immutable';
import { CLOSE_MODAL, OPEN_MODAL, MODAL_CLOSING } from '@constants/modal';

const initialState = Immutable({
  current: null,
  params: null,
  closing: false
});

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case OPEN_MODAL: {
      return Immutable({ current: action.payload, params: action.params });
    }
    case CLOSE_MODAL: {
      return initialState;
    }
    case MODAL_CLOSING: {
      return Immutable({ ...state, closing: true });
    }
    default:
      return state;
  }
};
