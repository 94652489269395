import React, { Fragment } from 'react';
import './RadioButton.scss';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';

function RadioGroup(props) {
  const { children, value, setValue } = props;

  return (
    <Fragment>
      {children(setValue, value)}
    </Fragment>
  );
}

RadioGroup.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

RadioGroup.defaultProps = {
  value: undefined,
};

RadioGroup.defaultProps = {};

export default withFormsy(RadioGroup);
