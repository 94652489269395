import React, { Fragment, useEffect } from 'react';
import './MyReservations.scss';
import PropTypes from 'prop-types';
import PlaceCard from '@components/display/PlaceCard/PlaceCard';
import Loading from '@components/feedback/Loading/Loading';
import mova from 'mova';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import { STATUS_ACCEPTED, STATUS_REQUESTED } from '@constants/reservation';
import Div100vh from 'react-div-100vh';
import { HEADER_HEIGHT, SUMMARY_HEIGHT, FOOTER_HEIGHT } from '@utils/constants';

const t = mova.ns('pages.MyReservations');

export default function MyReservations(props) {
  const { reservations, loading, getActiveReservations } = props;

  useEffect(() => {
    getActiveReservations();
  }, [getActiveReservations]);

  if (loading) {
    return <Loading />;
  }

  const empty = reservations.length === 0;
  const confirmed = reservations.filter(r => r.status === STATUS_ACCEPTED).length;
  const pending = reservations.filter(r => r.status === STATUS_REQUESTED).length;

  return (
    <div className='my-reservations'>
      <div className='my-reservations__summary'>
        <div className='content-container'>
          {empty && t('empty')}
          {
            !empty && (
              <Fragment>
                <span>{t(`summary.booked.${getPluralsFactor(confirmed)}`)}</span>&nbsp;
                <span>{confirmed}</span>&nbsp;
                <span>{t(`summary.tables.${getPluralsFactor(confirmed)}`)}</span>
                <span>{t(`summary.pending.${getPluralsFactor(pending)}`)}</span>&nbsp;
                <span>{pending}</span>&nbsp;
                <span>{t(`summary.tables.${getPluralsFactor(pending)}`)}</span>
              </Fragment>
            )
          }
        </div>
      </div>
      <Div100vh
        style={{ minHeight: `calc(100rvh - ${HEADER_HEIGHT} - ${SUMMARY_HEIGHT} - ${FOOTER_HEIGHT})` }}
        className='my-reservations__cards'
      >
        <div className='content-container my-reservations__cards-container'>
          {reservations.map(res => <PlaceCard place={res.place} reservation={res} key={res.id} activeReservation />)}
        </div>
      </Div100vh>
    </div>
  );
}

MyReservations.propTypes = {
  reservations: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  getActiveReservations: PropTypes.func.isRequired
};

MyReservations.defaultProps = {
  reservations: []
};
