export const OPEN_MODAL = '@@reservble/modal/OPEN_MODAL';
export const CLOSE_MODAL = '@@reservble/modal/CLOSE_MODAL';
export const MODAL_CLOSING = '@@reservble/modal/MODAL_CLOSING';

export const LOGIN_MODAL = '@@reservble/modal/LOGIN_MODAL';
export const REGISTRATION_MODAL = '@@reservble/modal/REGISTRATION_MODAL';
export const FINISH_REGISTRATION_MODAL = '@@reservble/modal/FINISH_REGISTRATION_MODAL';
export const CANCEL_RESERVATION_MODAL = '@@reservble/modal/CANCEL_RESERVATION_MODAL';
export const ALLOW_GEOLOCATION_MODAL = '@@reservble/modal/ALLOW_GEOLOCATION_MODAL';
export const ASK_PHONE_MODAL = '@@reservble/modal/ASK_PHONE_MODAL';
export const FORGOT_PASSWORD_MODAL = '@@reservble/modal/FORGOT_PASSWORD_MODAL';
export const NEW_PASSWORD_MODAL = '@@reservble/modal/NEW_PASSWORD_MODAL';
export const PLACE_LOCATION_MODAL = '@@reservble/modal/PLACE_LOCATION_MODAL';
export const PLACE_FEEDBACK_MODAL = '@@reservble/modal/PLACE_FEEDBACK_MODAL';
export const PROMO_SUCCESS_MODAL = '@@reservble/modal/PROMO_SUCCESS_MODAL';
export const RESERVATION_SUCCESS_MODAL = '@@reservble/modal/RESERVATION_SUCCESS_MODAL';
export const CART_MODAL = '@@reservble/modal/CART_MODAL';
export const DISH_DETAILS_MODAL = '@@reservble/modal/DISH_DETAILS_MODAL';
export const PAID_SERVICES_MODAL = '@@reservble/modal/PAID_SERVICES_MODAL';
export const CERTIFICATES_MODAL = '@@reservble/modal/CERTIFICATES_MODAL';
export const SELECT_SPOT_MODAL = '@@reservble/modal/SELECT_SPOT_MODAL';

export const MODAL_TRANSITION_OUT_MS = 300;
