import React, { useEffect, useState } from 'react';
import './Select.scss';
import PropTypes from 'prop-types';
import { get, isDefined, isFunction } from '@utils/lo/lo';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import SelectOption from './SelectOption';

/**
 * Renders a select-like element that allows to select one of several items. Can act as a menu
 *
 * @param icon Name of the icon to be prepended before the value
 * @param placeholder Text shown when no value is selected
 * @param defaultValue Default value to be shown upon first render
 * @param onSelect Callback function
 * @param prefix Text to be prepended before the selected value
 * @param suffix Text to be appended after the selected value
 * @param className String to be appended to classes of root element
 * @param label If provided, shows text label above the input
 * @param fullWidth If true, rendered select will take full width of parent container
 * @param fontSize Defines font-size of all values inside the select
 * @param popupPosition Direction of popup with options relative to the parent container
 *  - parent - Options will take same width as parent container
 *  - left - Popup will be placed at the start of parent container, and will stretch right
 *  - right - Popup will be placed at the end of parent container, and will stretch left
 * @param options array of items to be shown in input
 * @param textAlign Alignment of the text in select options
 * @param labelPath Path inside passed object to a string value that will be shown as label on UI
 * @return React component
 */
function Select({
  icon, placeholder, defaultValue, options, labelPath, onSelect, prefix, suffix, textAlign, popupPosition,
  className, label, fullWidth, fontSize
}) {
  const [value, setValue] = useState(defaultValue);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const toggleOptions = (e, newState) => {
    if (isDefined(e)) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    if (newState !== undefined) {
      setOpen(newState);
    } else {
      setOpen(!open);
    }
  };

  const closeOptions = toggleOptions.bind(null, null, false);

  useEffect(() => {
    document.addEventListener('click', closeOptions);
    return () => document.removeEventListener('click', closeOptions);
  }, [closeOptions]);

  const optionSelected = (selectedId) => {
    if (selectedId === value) {
      return;
    }

    setValue(selectedId);
    closeOptions();

    if (isFunction(onSelect)) {
      onSelect(selectedId);
    }
  };

  const selectOptions = options.map(option => {
    const labelValue = labelPath ? get(option, labelPath) : option;

    return (
      <SelectOption
        option={option}
        label={labelValue}
        value={option}
        key={labelValue}
        onSelect={optionSelected}
        textAlign={textAlign}
        selected={value === labelValue}
      />
    );
  });

  const valueWithPrefixAndSuffix = (val) => `${prefix ? `${prefix} ` : ''}${labelPath ? val[labelPath] : val}${suffix ? ` ${suffix}` : ''}`;

  return (
    <label
      className={`select__wrapper input-group-item__wrapper ${className} ${label && 'select--has-label'} ${fullWidth && 'select--full-width'}`}
      style={{ fontSize }}
    >
      {label && <div className='select__label'>{label}</div>}
      {icon && <SvgIcon className='front-icon' type={icon} />}
      <div
        className={`input-group-item select ${icon && 'select--has-icon'}`}
        onClick={(e) => toggleOptions(e)}
        onKeyPress={(e) => toggleOptions(e)}
        role='button'
        tabIndex={0}
      >
        {!!value && <span className='select__value' style={{ fontSize }}>{valueWithPrefixAndSuffix(value)}</span>}
        {!value && <span className='select__placeholder'>{placeholder}</span>}
      </div>
      <SvgIcon type='chevronDown' className='select__chevron' />
      {
        open && (
          <div className={`select__options select__options--${popupPosition}`} style={{ fontSize }}>
            {selectOptions}
          </div>
        )
      }
    </label>
  );
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  icon: PropTypes.string,
  labelPath: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  textAlign: PropTypes.string,
  popupPosition: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  fontSize: PropTypes.number,
};

Select.defaultProps = {
  icon: null,
  placeholder: '\u00A0',
  defaultValue: null,
  labelPath: null,
  prefix: '',
  suffix: '',
  textAlign: 'center',
  popupPosition: 'parent', // left, right, parent
  className: null,
  label: undefined,
  fullWidth: false,
  fontSize: 18,
};

export default Select;
