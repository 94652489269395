import React from 'react';
import PropTypes from 'prop-types';
import Colors from '@data/enums/Color.enum';
import { connect } from 'react-redux';
import { startPayment } from '@actions/billing';
import mova from 'mova';
import Button from "../../../components/inputs/Button/Button";

const t = mova.ns('pages.Payment');

const PaymentForm = ({ amount, billing, startPayment, showAmount = true }) => {
  const secondsDiff = (new Date(billing.data.expirationTime).getTime() - new Date().getTime()) / 1000;
  const expTime = Math.round(Math.max(secondsDiff, 600));

  const { place, reservation } = billing || {};

  const handleSubmit = async (event) => {
    startPayment(billing.data.hash);
    event.preventDefault();
    const data = {
      paymentTypes: {
        card: 'Y',
        portmone: 'Y',
        clicktopay: 'Y',
        gpay: 'Y',
        applepay: 'Y',
      },
      priorityPaymentTypes: {
        applepay: '1',
        gpay: '2',
        card: '3',
        portmone: '4',
        clicktopay: '5',
      },
      payee: {
        payeeId: reservation?.place?.portmonePayeeId || place?.portmonePayeeId,
      },
      order: {
        description: '',
        shopOrderNumber: `${billing?.id}`,
        billAmount: `${amount}`,
        successUrl: '',
        failureUrl: '',
        expTime: `${expTime}`,
      },
      style: {
        type: 'light',
        logo: '',
        backgroundColorHeader: '',
        backgroundColorButtons: Colors.COLOR_PRIMARY_STRONG,
        colorTextAndIcons: Colors.TEXT_BLACK,
        borderColorList: Colors.TEXT_DISABLED,
        bcMain: '',
      }
    };

    const form = document.createElement('form');
    form.action = 'https://www.portmone.com.ua/gateway/';
    form.method = 'post';
    form.target = '_blank';

    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'bodyRequest';
    input.value = JSON.stringify(data);
    form.appendChild(input);
    console.log(data);

    const inputTypeRequest = document.createElement('input');
    inputTypeRequest.type = 'hidden';
    inputTypeRequest.name = 'typeRequest';
    inputTypeRequest.value = 'json';
    form.appendChild(inputTypeRequest);

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <form onSubmit={handleSubmit}>
      {showAmount && <div className='payment__amount'>{billing.amount} {t('uah')}</div>}
      <Button size='large' behavior='submit' style={{ width: '100%' }} className='payment__submit'>
        {t('payWithPortmone')}
      </Button>
    </form>
  );
};

PaymentForm.propTypes = {
  amount: PropTypes.number.isRequired,
  billing: PropTypes.object,
  startPayment: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  startPayment,
};

export default connect(null, mapDispatchToProps)(PaymentForm);
