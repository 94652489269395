export const CATEGORY_CERTIFICATE = "CERTIFICATE";
export const CATEGORY_SUBSCRIPTION = "SUBSCRIPTION";
export const TYPE_VISITS = "VISITS";
export const TYPE_AMOUNT = "AMOUNT";

export const getCertificateIcon = (certificate) => {
  if (certificate.category === CATEGORY_SUBSCRIPTION) {
    return 'calendar';
  } else if (certificate.category === CATEGORY_CERTIFICATE) {
    if (certificate.data.type === TYPE_VISITS) {
      return 'pedestrian';
    } else if (certificate.data.type === TYPE_AMOUNT) {
      return 'money';
    }
  }
}
