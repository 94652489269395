import React from 'react';

export function ZHITTELUB() {
  return (
    <svg viewBox='0 0 25 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22.088 0.577393H14.612V5.80293H22.088V0.577393Z' fill='white' />
      <path d='M10.4968 0.651611H3.02081V5.87715H10.4968V0.651611Z' fill='white' />
      <path
        d='M22.4824 2.15155V1.40469C22.4824 0.62939 21.8518 0 21.0778 0H15.4615C14.6874 0 14.0568 0.630626 14.0568 1.40469V1.59511C13.6722 1.28351 13.138 0.99169 12.4901 1.00529C11.8545 1.01889 11.3265 1.31071 10.9432 1.6149V1.40469C10.9432 0.62939 10.3126 0 9.53853 0H3.92225C3.14819 0 2.51756 0.630626 2.51756 1.40469V2.15155H0V2.88357H2.51756V5.09694C2.51756 5.87101 3.14819 6.50163 3.92225 6.50163H9.53853C10.3126 6.50163 10.9432 5.87101 10.9432 5.09694V2.63626C11.1386 2.39514 11.7309 1.75463 12.5062 1.73855C13.2778 1.71877 13.8713 2.39885 14.0568 2.6375V5.09694C14.0568 5.87101 14.6874 6.50163 15.4615 6.50163H21.0778C21.8518 6.50163 22.4824 5.87101 22.4824 5.09694V2.88357H25V2.15155H22.4824ZM10.2112 5.09694C10.2112 5.4679 9.90949 5.76838 9.53977 5.76838H8.53324C8.84855 5.60515 9.06247 5.29726 9.06247 4.94114C9.06247 4.41686 8.59754 3.99273 8.02379 3.99273C7.45005 3.99273 6.98511 4.41686 6.98511 4.94114C6.98511 5.29602 7.19903 5.60515 7.51435 5.76838H3.92101C3.55006 5.76838 3.24835 5.46666 3.24835 5.09694V1.40345C3.24835 1.0325 3.55006 0.730784 3.92101 0.730784H9.53729C9.90825 0.730784 10.2087 1.0325 10.2087 1.40345V5.09694H10.2112ZM21.7504 5.09694C21.7504 5.4679 21.4487 5.76838 21.079 5.76838H17.4857C17.801 5.60515 18.0149 5.29726 18.0149 4.94114C18.0149 4.41686 17.55 3.99273 16.9762 3.99273C16.4025 3.99273 15.9375 4.41686 15.9375 4.94114C15.9375 5.29602 16.1514 5.60515 16.4668 5.76838H15.4615C15.0905 5.76838 14.79 5.46666 14.79 5.09694V1.40345C14.79 1.0325 15.0918 0.730784 15.4615 0.730784H21.0778C21.4487 0.730784 21.7492 1.0325 21.7492 1.40345V5.09694H21.7504Z'
        fill='black'
      />
    </svg>
  );
}
