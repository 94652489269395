import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import './StarRating.scss';
import { isFunction, isDefined } from '@utils/lo/lo';

function StarRating({ rating, showRating, className, setRating, reviews, showReviews }) {
  const [currentRating, setCurrentRating] = useState(0);

  const setRateByUser = (value) => {
    if (isFunction(setRating)) {
      setRating(value);
    }
  };

  const mouseOver = (rate) => {
    if (isFunction(setRating)) {
      setCurrentRating(rate);
    }
  };

  const mouseOut = () => {
    if (isFunction(setRating)) {
      setCurrentRating(null);
    }
  };

  return (
    <div className={`star-rating ${className} ${setRating && 'star-rating--clickable'}`} title={rating}>
      {showRating && !!rating && <div className='star-rating__rating'>{rating.toFixed(1)}</div>}

      <SvgIcon
        onClick={() => setRateByUser(1)}
        type='star'
        iconProps={{ rating: currentRating || rating }}
        value={1}
        onMouseOver={() => mouseOver(1)}
        onMouseOut={mouseOut}
      />
      <SvgIcon
        onClick={() => setRateByUser(2)}
        type='star'
        iconProps={{ rating: Math.max((currentRating || rating) - 1, 0) }}
        onMouseOver={() => mouseOver(2)}
        onMouseOut={mouseOut}
      />
      <SvgIcon
        onClick={() => setRateByUser(3)}
        type='star'
        iconProps={{ rating: Math.max((currentRating || rating) - 2, 0) }}
        onMouseOver={() => mouseOver(3)}
        onMouseOut={mouseOut}
      />
      <SvgIcon
        onClick={() => setRateByUser(4)}
        type='star'
        iconProps={{ rating: Math.max((currentRating || rating) - 3, 0) }}
        onMouseOver={() => mouseOver(4)}
        onMouseOut={mouseOut}
      />
      <SvgIcon
        onClick={() => setRateByUser(5)}
        type='star'
        iconProps={{ rating: Math.max((currentRating || rating) - 4, 0) }}
        onMouseOver={() => mouseOver(5)}
        onMouseOut={mouseOut}
      />

      {showReviews && isDefined(reviews) && <div className='star-rating__reviews'>({reviews})</div>}
    </div>
  );
}

StarRating.propTypes = {
  rating: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  reviews: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  showRating: PropTypes.bool,
  showReviews: PropTypes.bool,
  className: PropTypes.string,
  setRating: PropTypes.func
};

StarRating.defaultProps = {
  rating: undefined,
  reviews: 0,
  showRating: false,
  showReviews: false,
  className: '',
  setRating: undefined
};

export default memo(StarRating);
