import React from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './DetailedRating.scss';
import StarRating from '@components/display/StarRating/StarRating';
import Button from '@components/inputs/Button/Button';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';

const t = mova.ns('components.DetailedRating');

function DetailedRating(props) {
  const { className, place, openFeedbackForm } = props;
  const { reviewsCount = 0, averageKitchen, averageInterior, averageService, averageMood, isVisitor } = place;
  const averageRating = (+averageKitchen + +averageInterior + +averageService + +averageMood) / 4 || 0;

  const mainRating = (
    <div className='detailed-rating__container'>
      <div className='detailed-rating__rating'>{averageRating.toFixed(1)}</div>
      <div className='detailed-rating__stars'>
        <StarRating rating={averageRating} reviews={reviewsCount} showReviews />
      </div>
    </div>
  );

  const ratingStats = (
    <div className='detailed-rating__stats-list'>
      <div className='detailed-rating__stat'>
        <div className='detailed-rating__stat--name'>{t('kitchen')}</div>
        <div className='detailed-rating__stat--value'><StarRating rating={averageKitchen} /></div>
      </div>
      <div className='detailed-rating__stat'>
        <div className='detailed-rating__stat--name'>{t('interior')}</div>
        <div className='detailed-rating__stat--value'><StarRating rating={averageInterior} /></div>
      </div>
      <div className='detailed-rating__stat'>
        <div className='detailed-rating__stat--name'>{t('service')}</div>
        <div className='detailed-rating__stat--value'><StarRating rating={averageService} /></div>
      </div>
      <div className='detailed-rating__stat'>
        <div className='detailed-rating__stat--name'>{t('atmosphere')}</div>
        <div className='detailed-rating__stat--value'><StarRating rating={averageMood} /></div>
      </div>
    </div>
  );

  const ratingEmpty = (
    <div className='detailed-rating__empty'>
      <div className='detailed-rating__text'>
        {t('noFeedbacks')}
        {isVisitor && t('beTheFirst')}
      </div>
      {
        isVisitor && (
          <Button size='medium' onClick={openFeedbackForm} inputClass='detailed-rating__button'>
            <SvgIcon className='detailed-rating__button-icon' type='edit' fill='#fff' />
            {t('leaveFeedback')}
          </Button>
        )
      }
    </div>
  );

  return (
    <div className={`detailed-rating ${className}`}>
      <div className='detailed-rating__heading'>{t('heading')}</div>
      {reviewsCount > 0 && mainRating}
      <div className='detailed-rating__divider' />
      {reviewsCount > 0 && ratingStats}
      {!(reviewsCount > 0) && ratingEmpty}
    </div>
  );
}

DetailedRating.propTypes = {
  place: PropTypes.object.isRequired,
  className: PropTypes.string,
  openFeedbackForm: PropTypes.func.isRequired
};

DetailedRating.defaultProps = {
  className: ''
};

export default DetailedRating;
