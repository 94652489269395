import React, { memo, useEffect, useRef, useState } from 'react';
import './GptPlaceSearch.scss';
import mova from 'mova';
import TextInput from '../../components/inputs/TextInput/TextInput';
import Form from '../../components/inputs/Form/Form';
import Button from '../../components/inputs/Button/Button';
import Div100vh from 'react-div-100vh';
import { HEADER_HEIGHT, SUMMARY_HEIGHT } from '@utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { sendQuestion } from '@store/actions/chatbot';
import { markdownToHtml } from '@utils/markdown/markdown';
import SvgIcon from '../../components/display/SvgIcon/SvgIcon';
import TextLink from '../../components/navigation/TextLink/TextLink';
import { baseUrlPath, getCityObjectFromUrl } from 'utils/url/urlUtils';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import empty from '@theme/images/empty-place-avatar.jpg';
import Image from '@components/display/Image/Image';
import queryString from 'query-string';

const t = mova.ns('pages.GptPlaceSearch');

const getPlaceAverageRating = (place) => {
  return (place.averageKitchen + place.averageInterior + place.averageService + place.averageMood) / 4 || 0;
};

const GptPlaceSearch = ({ history }) => {
  const dispatch = useDispatch();
  const [chatToken] = useState(Math.random());
  const chatHistory = useSelector((store) => store.chatbot.history);
  const resetButtonRef = useRef();
  const messagesRef = useRef();
  const city = getCityObjectFromUrl();
  const searchParams = queryString.parse(window.location.search);

  const submitQuestion = (values) => {
    if (values.question) {
      resetButtonRef.current.click();
      dispatch(sendQuestion(values.question, chatToken, city?.id));
    }
  };

  const goToPlace = (place) => {
    history.push(`${baseUrlPath()}/places/${place.uri}`);
  };

  const messageItems = chatHistory.map((m, index) => (
    <div key={index} className={`gpt-search__message-wrapper gpt-search__message-wrapper--${m.role}`}>
      {m.role === 'assistant' && <SvgIcon type='botMessage' />}
      <div style={{ minWidth: 0 }}>
        <div
          key={index}
          className={`gpt-search__message gpt-search__message--${m.role}`}
          dangerouslySetInnerHTML={{ __html: markdownToHtml(m.content) }}
        />
        {Array.isArray(m.places) && m.places.length > 0 && (
          <div className='gpt-search__place-cards'>
            {m.places.map(place => (
              <div key={place.id} className='place-card' onClick={() => goToPlace(place)}>
                <Image src={place.avatarUrl || empty} alt={place.name} cover className='place-card__image' />
                <div className='place-card__name-row'>
                  <div className='place-card__name'>{place.name}</div>
                  {getPlaceAverageRating(place) > 0 && (
                    <div className='place-card__rating'>
                      <span>{getPlaceAverageRating(place)}</span>
                      <SvgIcon />
                    </div>
                  )}
                </div>
                <div className='place-card__address-row'>
                  <SvgIcon type='location' />
                  <div className='place-card__address'>{place.address}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {m.role === 'user' && <SvgIcon type='userMessage' />}
    </div>
  ));

  useEffect(() => {
    if (chatHistory.length > 0) {
      setTimeout(() => {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight + 200;
      }, 100);
    }
  }, [chatHistory.length]);

  useEffect(() => {
    if (searchParams.start) {
      dispatch(sendQuestion(searchParams.start, chatToken, city?.id));
    }
  }, []); // eslint-disable-line

  return (
    <Div100vh
      className='gpt-search'
      style={{ minHeight: `calc(100rvh - ${HEADER_HEIGHT} - ${SUMMARY_HEIGHT})` }}
    >
      <div className='gpt-search__container'>
        <TextLink className='gpt-search__back' to={`${baseUrlPath() || '/'}`}>
          <SvgIcon type='back' />
          <span>{t('back')}</span>
        </TextLink>
        <div className='gpt-search__card'>
          <div className='gpt-search__title'>{t('title')}</div>
          <div style={{ minWidth: 0 }}>
            {messageItems.length > 0 && (
              <div className='gpt-search__messages' ref={messagesRef}>
                {messageItems}
              </div>
            )}
            <Form onValidSubmit={submitQuestion}>
              <div className='gpt-search__search-row'>
                <TextInput
                  className='gpt-search__input-wrapper'
                  inputClass='gpt-search__input'
                  name='question'
                  placeholder={t('placeholderMessages')}
                  fontSize={18}
                />
                <Button inputClass='gpt-search__send' behavior='submit'>
                  <SvgIcon type='magic' />
                </Button>
                <button type='reset' style={{ display: 'none' }} ref={resetButtonRef} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Div100vh>
  );
};

GptPlaceSearch.propTypes = {
  history: PropTypes.object.isRequired,
};

GptPlaceSearch.defaultProps = {};

export default memo(withRouter(GptPlaceSearch));
