import React from 'react';
import Colors from '@data/enums/Color.enum';

export function time({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.25 5.16146L11.25 12.5885C11.25 12.6773 11.3344 12.75 11.4375 12.75L12.5625 12.75C12.6656 12.75 12.75 12.6773 12.75 12.5885L12.75 5.16146C12.75 5.07266 12.6656 5 12.5625 5L11.4375 5C11.3344 5 11.25 5.07266 11.25 5.16146Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
      <path
        d='M11.3528 12.8528L16.0815 17.5815C16.1381 17.6381 16.244 17.6247 16.3169 17.5517L17.1124 16.7562C17.1853 16.6833 17.1987 16.5774 17.1422 16.5209L12.4135 11.7921C12.3569 11.7356 12.251 11.749 12.1781 11.8219L11.3826 12.6174C11.3097 12.6903 11.2963 12.7963 11.3528 12.8528Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
      <path
        d='M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
