import { CLOSE_MODAL, OPEN_MODAL, MODAL_CLOSING, MODAL_TRANSITION_OUT_MS } from '@constants/modal';
import { disableBodyScroll, enableBodyScroll, MODAL_OPEN_CLASS } from '@utils/scroll/scrollUtils';

export const openModal = (modal, params) => dispatch => {
  dispatch({ type: OPEN_MODAL, payload: modal, params });
  disableBodyScroll(MODAL_OPEN_CLASS);
};

export const closeModal = () => dispatch => {
  dispatch({ type: MODAL_CLOSING });
  setTimeout(() => {
    dispatch({ type: CLOSE_MODAL });
    enableBodyScroll(MODAL_OPEN_CLASS);
  }, MODAL_TRANSITION_OUT_MS);
};
