import React, { memo } from 'react';
import mova from 'mova';
import Popup from "@components/feedback/Popup/Popup";
import RadioButton from "@components/inputs/RadioButton/RadioButton";
import moment from 'moment';

const t = mova.ns('containers.SelectSpotModal');

const getTypePrice = (type, date) => type[moment(date).isoWeekday()];

const SelectDepositTypePopup = ({ data, typeSelected, onClose, date }) => {
  const { deposit, x, y } = data;

  return (
    <Popup className='deposit-type-popup' open closing={false} style={{ left: x, top: y }} onClose={onClose}>
      <div>{t('selectDepositType')}:</div>
      {deposit.types.map(type => (
        <div key={type.id} onClick={() => typeSelected(type)} className='deposit-type-popup__row'>
          <div className='deposit-type-popup__left'>
            <RadioButton style={{ flexShrink: 0 }} />
            <div className='deposit-type-popup__type-name'>{type.label}</div>
          </div>
          <div className='deposit-type-popup__price'>{getTypePrice(type, date)} {t('uah')}</div>
        </div>
      ))}
    </Popup>
  );
};

export default memo(SelectDepositTypePopup);
