export const RESERVATION_LOADING = '@@reservble/app/reservation/RESERVATION_LOADING';
export const SAVE_RESERVATION = '@@reservble/app/reservation/SAVE_RESERVATION';

export const SAVE_QUICK_SEATS = '@@reservble/app/reservation/SAVE_QUICK_SEATS';
export const SAVE_QUICK_DATE = '@@reservble/app/reservation/SAVE_QUICK_DATE';
export const SELECT_NEXT_DAY = '@@reservble/app/reservation/SELECT_NEXT_DAY';

export const ACTIVE_RESERVATIONS_LOADING = '@@reservble/app/reservation/ACTIVE_RESERVATIONS_LOADING';
export const SAVE_ACTIVE_RESERVATIONS = '@@reservble/app/reservation/SAVE_ACTIVE_RESERVATIONS';

export const OLD_RESERVATIONS_LOADING = '@@reservble/app/reservation/OLD_RESERVATIONS_LOADING';
export const SAVE_OLD_RESERVATIONS = '@@reservble/app/reservation/SAVE_OLD_RESERVATIONS';

export const STATUS_DRAFT = 'DRAFT';
export const STATUS_REQUESTED = 'REQUESTED';
export const STATUS_ACCEPTED = 'ACCEPTED';
export const STATUS_CANCELLED = 'CANCELED';
export const STATUS_FINISHED = 'FINISHED';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_SKIPPED = 'SKIPPED';

export const activeReservationStatuses = [STATUS_REQUESTED, STATUS_ACCEPTED];
export const oldReservationStatuses = [STATUS_FINISHED, STATUS_CANCELLED, STATUS_REJECTED, STATUS_SKIPPED];
