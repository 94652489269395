import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';

function InfoTag({ title, checked, clickable, onClick }) {
  return (
    <div
      className={`info-tags__tag ${checked && 'info-tags__tag--checked'} ${clickable && 'info-tags__tag--clickable'}`}
      onClick={onClick}
      onKeyPress={onClick}
      role='button'
      tabIndex={-1}
    >
      {title}
      {checked && <SvgIcon className='info-tags__icon' type='check' fill='#fff' />}
    </div>
  );
}

InfoTag.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  clickable: PropTypes.bool,
  onClick: PropTypes.func
};

InfoTag.defaultProps = {
  checked: false,
  clickable: false,
  onClick: () => {}
};

export default memo(InfoTag);
