import Immutable from '@utils/immutable/Immutable';
import { SAVE_USER_FEEDBACKS, USER_FEEDBACKS_LOADING } from '@constants/user';

const initialState = {
  count: 0,
  page: 1,
  pageSize: 10,
  rows: [],
  isLoading: false,
  totalPages: 1 // toDo: not used right now
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_FEEDBACKS_LOADING: {
      return Immutable({ ...state, isLoading: true });
    }
    case SAVE_USER_FEEDBACKS: {
      const currentPage = payload.page;
      const updatedRows = currentPage === 1 ? payload.rows : [...state.rows, ...payload.rows];

      return Immutable({
        ...state,
        ...payload,
        rows: updatedRows,
        isLoading: false
      });
    }
    default:
      return state;
  }
};
