import { loadData } from '@utils/redux/actionUtils';
import { BILLING_LOADING, SAVE_BILLING, } from '@constants/billing';
import api from '@api';
import { get } from '@utils/lo/lo';

export const getBillingByHash = (hash) => (
  loadData('billing.getByHash', hash)(BILLING_LOADING, SAVE_BILLING)
);

export const startPayment = (hash) => () => {
  get(api, 'billing.startPayment')(hash);
};

export const findCertificate = (placeUri, uuid) => () => {
  return get(api, 'billing.findCertificate')(placeUri, uuid);
};
