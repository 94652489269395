import React, { memo, useCallback } from 'react';
import './PlaceCard.scss';
import PropTypes from 'prop-types';
import mova from 'mova';
import Color from '@data/enums/Color.enum';
import StarRating from '@components/display/StarRating/StarRating';
import PriceIndicator from '@components/display/PriceIndicator/PriceIndicator';
import Distance from '@components/display/Distance/Distance';
import TextLink from '@components/navigation/TextLink/TextLink';
import InfoTags from '@components/display/InfoTags';
import TimeTags from '@components/display/TimeTags';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import Image from '@components/display/Image/Image';
import { get } from '@utils/lo/lo';
import ReservationDetails from '../ReservationDetails/ReservationDetails';
import Button from '@components/inputs/Button/Button';
import { toggleLike } from '@actions/user';
import {
  STATUS_ACCEPTED,
  STATUS_CANCELLED,
  STATUS_FINISHED,
  STATUS_REJECTED,
  STATUS_REQUESTED,
  STATUS_SKIPPED
} from '@constants/reservation';
import { connect } from 'react-redux';
import { openModal } from '@actions/modal';
import { CANCEL_RESERVATION_MODAL, PLACE_LOCATION_MODAL, SELECT_SPOT_MODAL } from '@constants/modal';
import { addReservation } from '@actions/reservation';
import useMedia from '@utils/hooks/useMedia';
import empty from '@theme/images/empty-place-avatar.jpg';
import { baseUrlPath } from '@utils/url/urlUtils';
import PromoLabel from '../PromoLabel/PromoLabel';
import { getPluralsFactor } from 'utils/i18n/i18nUtils';
import RouterLink from "../../navigation/RouterLink/RouterLink";
import { getPlace, getAvailableSlots } from "@actions/place";

const t = mova.ns('components.PlaceCard');
const statusT = mova.ns('types.reservation.status');

const statusIconType = {
  [STATUS_REQUESTED]: 'processing',
  [STATUS_ACCEPTED]: 'confirm',
  [STATUS_REJECTED]: 'cancel',
  [STATUS_FINISHED]: 'confirm',
  [STATUS_CANCELLED]: 'cancel',
  [STATUS_SKIPPED]: 'cancel'
};

function PlaceCard({
  reservation, place, activeReservation, cancel, makeReservation, likePlace, likes, className, inline,
  openLocationModal, small, sortTimeFrom, mapRef, showSpotSelection, getPlace, getAvailableSlots
}) {
  const {
    avatarUrl, name, slots, uri, averageKitchen, averageInterior, averageService, averageMood, reviewsCount,
    averageBill, distance
  } = place;

  const averageRating = (averageKitchen + averageInterior + averageService + averageMood) / 4 || 0;

  const showSmallCard = useMedia(['(max-width: 550px)'], [true], false) || small;

  const likeClicked = () => {
    likePlace({ targetId: place.id }, likes, place);
  };

  const likedPlace = likes.find(item => item.id === place.id);
  const isLiked = likedPlace ? likedPlace.isLiked : place.isLiked;

  const placeTags = [
    ...get(place, 'categories', []),
    ...get(place, 'mood', []),
    ...get(place, 'cusines', [])
  ];

  const handleMakeReservation = useCallback((time) => makeReservation(place.uri, time), [place.uri, makeReservation]);

  const handleTimeClick = (time) => {
    if (place.showSpots && !place.ourManagment) {
      Promise.all([getPlace(place.uri), getAvailableSlots(place.id)])
        .then(() => {
          showSpotSelection({ time });
        });
    } else {
      handleMakeReservation(time);
    }
  }

  const handleAddressClick = () => {
    if (mapRef?.current) {
      mapRef.current.setCenter(place.location.coordinates, 17);
    } else {
      openLocationModal(place);
    }
  };

  return (
    <div
      className={`place-card ${className} ${inline && 'place-card--inline'} ${showSmallCard && 'place-card--small'}`}
    >
      <div className='place-card__container'>
        <RouterLink className='place-card__image-wrapper' to={`${baseUrlPath()}/places/${uri}`}>
          {
            place.donates?.length > 0 && (
            <PromoLabel
              donate={place.donates[0]}
              className='place-card__promo-label'
              iconWidth={place.donates[0].name === 'PRITULA' ? 16 : 24}
              scalable
            />
          )}
          <TextLink to={`${baseUrlPath()}/places/${uri}`}>
            <Image src={avatarUrl || empty} alt={name} cover className='place-card__image' />
          </TextLink>
        </RouterLink>

        <div className='place-card__content'>
          <SvgIcon
            type='heart'
            filled={isLiked}
            fill={Color.TEXT_BLACK}
            className='place-card__like'
            onClick={likeClicked}
          />

          <div className='place-card__row place-card__name-row'>
            <TextLink to={`${baseUrlPath()}/places/${uri}`} className='place-card__name'>{name}</TextLink>
          </div>
          <div className='place-card__row'>
            <StarRating rating={averageRating} reviews={reviewsCount} showRating showReviews />
            <PriceIndicator price={get(averageBill, 'length')} className='place-card__price' />
          </div>

          <div className='place-card__row'>
            <SvgIcon className='place-card__map-icon' type='location' />
            <TextLink
              className='place-card__map-link text-overflow-ellipsis'
              onClick={handleAddressClick}
              type='text'
              size='medium'
            >
              {place.address}
            </TextLink>
            <Distance distance={distance} />
          </div>

          <div className='place-card__row place-card__row--growing'>
            <InfoTags tags={placeTags} rows={1} placeUri={uri} clickable />
          </div>

          {place.reservationsToday > 0 && (
            <div className='place-card__row place-card__row--growing place-card__reservations-today'>
              <SvgIcon type='chartArrow' />
              <span>{place.reservationsToday} {t(`reservationsToday.${getPluralsFactor(place.reservationsToday)}`)}</span>
            </div>
          )}

          {
            !activeReservation && (
              <div className='place-card__row place-card__row--growing'>
                <TimeTags
                  rows={1}
                  tags={slots}
                  makeReservation={handleTimeClick}
                  showMoreButton
                  placeUri={uri}
                  showMax={6}
                  sortFrom={sortTimeFrom}
                />
              </div>
            )
          }

          {
            activeReservation && (
              <div className='place-card__row place-card__row--growing'>
                <ReservationDetails reservation={reservation} place={place} />
              </div>
            )
          }
        </div>
      </div>
      {
        reservation && (
          <div className='place-card__controls'>
            <div className='place-card__status'>
              <SvgIcon type={statusIconType[reservation.status]} className='place-card__status-icon' />
              <span>{statusT(reservation.status)}</span>
            </div>

            {
              activeReservation && (
                <Button
                  onClick={() => cancel(reservation.place.uri, reservation.id)}
                  className='place-card__cancel-reservation'
                  type='danger'
                  size='medium'
                >
                  {t('cancelReservation')}
                </Button>
              )
            }
          </div>
        )
      }
    </div>
  );
}

PlaceCard.propTypes = {
  place: PropTypes.object.isRequired,
  reservation: PropTypes.object,
  activeReservation: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
  likes: PropTypes.array,
  likePlace: PropTypes.func.isRequired,
  makeReservation: PropTypes.func.isRequired,
  openLocationModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  inline: PropTypes.bool,
  small: PropTypes.bool,
  sortTimeFrom: PropTypes.instanceOf(Date),
  mapRef: PropTypes.object,
};

PlaceCard.defaultProps = {
  activeReservation: false,
  reservation: null,
  likes: [],
  className: '',
  inline: false,
  small: false,
  sortTimeFrom: undefined,
  mapRef: undefined,
};

const mapStateToProps = ({ user }) => ({
  likes: user.likes.data
});

const mapDispatchToProps = {
  cancel: (placeUri, reservationId) => openModal(CANCEL_RESERVATION_MODAL, { placeUri, reservationId }),
  makeReservation: addReservation,
  likePlace: (like, likes, place) => toggleLike(like, likes, place),
  openLocationModal: (place) => openModal(PLACE_LOCATION_MODAL, place),
  showSpotSelection: (meta) => openModal(SELECT_SPOT_MODAL, meta),
  getPlace,
  getAvailableSlots,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(PlaceCard));
