import React from 'react';
import Colors from '@data/enums/Color.enum';

export function safety({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 1.5L3 4.5V13.5C3 18.4711 7.02891 22.5 12 22.5C16.9711 22.5 21 18.4711 21 13.5V4.5L12 1.5ZM19.3125 13.5C19.3125 17.5383 16.0383 20.8125 12 20.8125C7.96172 20.8125 4.6875 17.5383 4.6875 13.5V5.76562L12 3.1875L19.3125 5.76562V13.5Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
      <path
        d='M8.86872 11.1351C8.79052 11.0566 8.69757 10.9942 8.59521 10.9517C8.49284 10.9091 8.38309 10.8872 8.27224 10.8872C8.16139 10.8872 8.05163 10.9091 7.94927 10.9517C7.84691 10.9942 7.75396 11.0566 7.67575 11.1351C7.59719 11.2133 7.53485 11.3063 7.49232 11.4086C7.44978 11.511 7.42788 11.6207 7.42788 11.7316C7.42788 11.8424 7.44978 11.9522 7.49232 12.0546C7.53485 12.1569 7.59719 12.2499 7.67575 12.3281L10.7086 15.3609L10.7578 15.4101C10.8318 15.4842 10.9196 15.543 11.0163 15.5831C11.1131 15.6232 11.2168 15.6439 11.3215 15.6439C11.4262 15.6439 11.5299 15.6232 11.6266 15.5831C11.7233 15.543 11.8112 15.4842 11.8851 15.4101L17.1234 10.1718C17.1975 10.0979 17.2563 10.01 17.2965 9.91328C17.3366 9.81656 17.3572 9.71288 17.3572 9.60817C17.3572 9.50346 17.3366 9.39978 17.2965 9.30305C17.2563 9.20633 17.1975 9.11847 17.1234 9.0445L17.0578 8.97888C16.9838 8.90476 16.896 8.84597 16.7993 8.80585C16.7025 8.76574 16.5988 8.74509 16.4941 8.74509C16.3894 8.74509 16.2857 8.76574 16.189 8.80585C16.0923 8.84597 16.0044 8.90476 15.9305 8.97888L11.3203 13.5867L8.86872 11.1351Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
