import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const Animate = ({ children, animation, duration, delay, easing, runOnMount }) => {
  const variants = {
    fadeIn: { opacity: 1, transition: { duration: duration / 1000, delay: delay / 1000, ease: easing } },
    fadeOut: { opacity: 0 },
    slideDownIn: { y: 0, opacity: 1, transition: { duration: duration / 1000, delay: delay / 1000, ease: easing } },
    slideUpOut: { y: -20, opacity: 0, transition: { duration: duration / 1000, ease: easing } }
  };

  return (
    <motion.div
      initial={runOnMount ? variants[animation === 'transition.fadeIn' ? 'fadeOut' : 'slideUpOut'] : 'visible'}
      animate={animation === 'transition.slideDownIn' ? 'slideDownIn' : 'fadeIn'}
      exit={animation === 'transition.slideUpOut' ? 'slideUpOut' : 'fadeOut'}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

Animate.propTypes = {
  children: PropTypes.element.isRequired,
  animation: PropTypes.string,
  runOnMount: PropTypes.bool,
  duration: PropTypes.number,
  delay: PropTypes.number,
  easing: PropTypes.array,
};

Animate.defaultProps = {
  animation: 'transition.fadeIn',
  runOnMount: true,
  duration: 300,
  delay: 50,
  easing: [0.4, 0.0, 0.2, 1]
};

export default Animate;
