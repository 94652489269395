import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';

function InfoTagsFormsy({ children, value, setValue }) {
  return (
    <Fragment>
      {children(setValue, value)}
    </Fragment>
  );
}

InfoTagsFormsy.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func.isRequired,
};

InfoTagsFormsy.defaultProps = {
  value: null,
};

InfoTagsFormsy.defaultProps = {};

export default withFormsy(InfoTagsFormsy);
