import React, { memo } from 'react';
import mova from 'mova';
import './ErrorPage.scss';
import TextLink from '@components/navigation/TextLink/TextLink';
import { baseUrlPath } from '@utils/url/urlUtils';

const t = mova.ns('pages.Error');

function Page404() {
  return (
    <div className='error-page'>
      <div className='error-page__status'>404</div>
      <div className='error-page__message'>{t('error404')}</div>

      <div className='error-page__link'>
        <TextLink to={`${baseUrlPath()}/`} type='text' size='large'>{t('goHome')}</TextLink>
      </div>
    </div>
  );
}

export default memo(Page404);
