import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LOGIN_MODAL, REGISTRATION_MODAL } from '@constants/modal';
import { openModal } from '@actions/modal';
import Header from './Header';
import { get } from '@utils/lo/lo';
import { saveLangSelection } from '@actions/user';

const mapStateToProps = ({ user, notification, reservation, }) => ({
  user: get(user, 'details.data.user'),
  submenuOpen: user.submenu,
  unreadNotifications: get(notification, 'unreadCount.data.count') || 0,
  reservationsCount: get(reservation, 'active.data.count'),
});

const mapDispatchToProps = {
  signIn: () => openModal(LOGIN_MODAL),
  signUp: () => openModal(REGISTRATION_MODAL),
  saveLangSelection
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
