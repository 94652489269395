import mova from 'mova';
import { get } from '@utils/lo/lo';

const errT = mova.ns('errors');

function getMessageFromError(error, t = null) {
  const errors = get(error, 'response.data.errors') || get(error, 'response.data.error');

  if (!errors) {
    return errT('server');
  }

  if (Array.isArray(errors)) {
    const errorObject = errors.reduce((accum, err) => {
      accum[err.field] = t ? t(err.message) : err.message; // eslint-disable-line no-param-reassign
      return accum;
    }, {});
    return errT(`fields.${Object.values(errorObject)[0]}`, { field: errT(`fields.${Object.keys(errorObject)[0]}`) });
  }

  try {
    return errT(`common.${error.response.data.error}`);
  } catch (e) {
    return error.message;
  }
}

export default getMessageFromError;
