import React, { memo } from 'react';
import { Group, Rect } from "react-konva";
import Colors from '@data/enums/Color.enum';

const LoungeTable = ({ table, selected }) => {
  return (
    <Group x={0} y={0}>
      <Rect
        width={table.width}
        height={table.height}
        fill='white'
        stroke={Colors.TEXT_BLACK}
        strokeWidth={selected.some(item => item.id === table.id) ? 1 : 0}
      />
    </Group>
  );
};

export default memo(LoungeTable);
