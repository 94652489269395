import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import { lineClamp } from '@utils/styles';
import './CertificateCard.scss';
import TextLink from '../../navigation/TextLink/TextLink';
import Button from '../../inputs/Button/Button';

const t = mova.ns('components.CertificatesModal');

const CATEGORY_CERTIFICATE = 'CERTIFICATE';
const CATEGORY_SUBSCRIPTION = 'SUBSCRIPTION';
const TYPE_VISITS = 'VISITS';
const TYPE_AMOUNT = 'AMOUNT';

const CertificateCard = ({ certificate, buy }) => {
  const [isClipped, setIsClipped] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const checkClipping = () => {
      if (descriptionRef.current) {
        setIsClipped(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
      }
    };

    checkClipping();
    window.addEventListener('resize', checkClipping);
    return () => window.removeEventListener('resize', checkClipping);
  }, [certificate.description]);

  return (
    <div className='certificate-card'>
      <div className='certificate-card__top'>
        {certificate.imageUrl && (
          <div className='certificate-card__image-wrapper'>
            <img src={certificate.imageUrl} className='certificate-card__image' alt={certificate.name} />
          </div>
        )}
        <div style={{ minWidth: 0 }}>
          <div className='certificate-card__name'>{certificate.name}</div>
          <div className='certificate-card__category'>
            {certificate.category === CATEGORY_SUBSCRIPTION && (
              <span>
                {t('subscription')} - {certificate.data.duration} {t(`days.${getPluralsFactor(certificate.data.duration)}`)}
              </span>
            )}
            <span />
            {certificate.category === CATEGORY_CERTIFICATE && certificate.data.type === TYPE_AMOUNT && (
              <span>{certificate.data.amount} {t('uah')}</span>
            )}
            {certificate.category === CATEGORY_CERTIFICATE && certificate.data.type === TYPE_VISITS && (
              <span>{t(`visits.${getPluralsFactor(certificate.data.visits)}`)}</span>
            )}
          </div>
          <div className='certificate-card__description' ref={descriptionRef} style={expanded ? null : lineClamp(2)}>
            {certificate.description}
          </div>
          {isClipped && !expanded && (
            <TextLink onClick={() => setExpanded(true)}>{t('expand')}</TextLink>
          )}
        </div>
      </div>
      <div className='certificate-card__bottom'>
        <div className='certificate-card__price'>{certificate.price} {t('uah')}</div>
        {buy && <Button onClick={buy}>{t('buy')}</Button>}
      </div>
    </div>
  );
};

CertificateCard.propTypes = {
  certificate: PropTypes.object.isRequired,
  buy: PropTypes.func,
};

export default memo(CertificateCard);
