import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TimeSelector.scss';
import LocaleService from '@services/Locale.service';
import usePlaceDate from '@utils/hooks/usePlaceDate';
import { preventDefault } from '@utils/utils';
import CalendarCustomInput from '../CalendarCustomInput/CalendarCustomInput';
import mova from 'mova';

const t = mova.ns('components.TimeSelector');

function TimeSelector({ className, stepMinutes, value, onChange, place }) {
  const [dateWithOpenHours, dateWithCloseHours] = usePlaceDate(value, place);

  return (
    <div className={`time-selector__wrapper input-group-item__wrapper ${className}`}>
      <SvgIcon className='front-icon' type='time' />
      <span>{t('time')}:</span>
      <label onClick={preventDefault}>
        <DatePicker
          portalId='time-selector-wrapper'
          onCalendarOpen={() => {
            const selectedItem = document.querySelector('#time-selector-wrapper .react-datepicker__time-list-item--selected');
            if (selectedItem) {
              selectedItem.scrollIntoView({ block: 'center' });
            }
          }}
          selected={value}
          minTime={dateWithOpenHours}
          maxTime={dateWithCloseHours}
          onChange={onChange}
          showTimeSelect
          showTimeSelectOnly
          locale={LocaleService.getLocaleObject()}
          timeIntervals={stepMinutes}
          timeCaption={null}
          timeFormat='HH:mm'
          dateFormat='HH:mm'
          tabIndex={-1}
          customInput={<CalendarCustomInput className='time-selector input-group-item' />}
        />
        <SvgIcon type='chevronDown' className='time-selector__chevron' />
      </label>
    </div>
  );
}

TimeSelector.propTypes = {
  className: PropTypes.string,
  stepMinutes: PropTypes.number,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  place: PropTypes.object,
};

TimeSelector.defaultProps = {
  className: '',
  stepMinutes: 15,
  value: undefined,
  place: undefined,
};

export default memo(TimeSelector);
