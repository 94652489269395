import LocalStorageKeysEnum from '@data/enums/LocalStorageKeys.enum';

const storage = window.localStorage;

export default {
  Keys: LocalStorageKeysEnum,

  get(key) {
    if (!(key in storage)) {
      return undefined;
    }

    try {
      return JSON.parse(storage.getItem(key));
    } catch (e) {
      return storage.getItem(key);
    }
  },

  set(key, value) {
    if (typeof value === 'undefined') {
      this.delete(key);
      return;
    }

    storage.setItem(key, JSON.stringify(value));
  },

  delete(key) {
    storage.removeItem(key);
  },

  clear() {
    storage.clear();
  },
};
