import React, { memo, useState } from 'react';
import './ToggleButtons.scss';
import PropTypes from 'prop-types';

function ToggleButtons(props) {
  const { items, onSelect, defaultValue, className } = props;

  const [value, setValue] = useState(defaultValue);

  const itemSelected = (val) => {
    setValue(val);
    onSelect(val);
  };

  const itemButtons = items.map(item => (
    <div
      className={`toggle-buttons__item ${value === item && 'toggle-buttons__item--selected'}`}
      role='button'
      onClick={() => itemSelected(item)}
      onKeyPress={() => itemSelected(item)}
      tabIndex={-1}
      key={item}
    >
      {item}
    </div>
  ));

  return (
    <div className={`toggle-buttons ${className}`}>
      {itemButtons}
    </div>
  );
}

ToggleButtons.propTypes = {
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.string
};

ToggleButtons.defaultProps = {
  className: '',
  defaultValue: undefined
};

export default memo(ToggleButtons);
