import React from 'react';
import Colors from '@data/enums/Color.enum';

export function outlink({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.625 3.375L2.625 20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V12.1875C21.375 12.0844 21.2906 12 21.1875 12H19.875C19.7719 12 19.6875 12.0844 19.6875 12.1875V19.6875H4.3125L4.3125 4.3125L11.8125 4.3125C11.9156 4.3125 12 4.22813 12 4.125V2.8125C12 2.70938 11.9156 2.625 11.8125 2.625L3.375 2.625C2.96016 2.625 2.625 2.96016 2.625 3.375Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
      <path
        d='M19.3393 5.92629L20.5627 7.14973C20.5877 7.17451 20.619 7.19181 20.6533 7.19965C20.6875 7.20749 20.7233 7.20556 20.7565 7.19409C20.7897 7.18261 20.819 7.16204 20.8411 7.13473C20.8632 7.10741 20.8772 7.07444 20.8815 7.03957L21.3737 2.83488C21.3877 2.71535 21.287 2.61223 21.1651 2.62629L16.9604 3.11848C16.8057 3.13723 16.7401 3.32707 16.8502 3.43723L18.0784 4.66535L12.0737 10.67C12.001 10.7427 12.001 10.8622 12.0737 10.9349L13.0674 11.9286C13.1401 12.0013 13.2596 12.0013 13.3323 11.9286L19.3393 5.92629Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
