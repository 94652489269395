import React, { Fragment, useEffect } from 'react';
import mova from 'mova';
import './Notifications.scss';
import { getPluralsFactor } from '@utils/i18n/i18nUtils';
import PropTypes from 'prop-types';
import Loading from '@components/feedback/Loading/Loading';
import NotificationItem from './components/NotificationItem/NotificationItem';
import Div100vh from 'react-div-100vh';
import { HEADER_HEIGHT, SUMMARY_HEIGHT, FOOTER_HEIGHT } from '@utils/constants';

const t = mova.ns('pages.Notifications');

function Notifications(props) {
  const { notifications, getNotifications, markAllAsRead, loading } = props;

  useEffect(() => {
    getNotifications();
    markAllAsRead();
  }, [getNotifications, markAllAsRead]);

  if (loading) {
    return <Loading />;
  }

  const newNotifications = notifications
    .filter(note => !note.read)
    .map(item => <NotificationItem key={item.id} note={item} />);
  const readNotifications = notifications
    .filter(note => note.read)
    .map(item => <NotificationItem key={item.id} note={item} />);

  return (
    <div className='notifications'>
      <div className='notifications__summary'>
        {t('youHave')} {newNotifications.length} {t(`notifications.${getPluralsFactor(newNotifications.length)}`)}
      </div>
      <Div100vh
        style={{ minHeight: `calc(100rvh - ${HEADER_HEIGHT} - ${SUMMARY_HEIGHT} - ${FOOTER_HEIGHT})` }}
        className='notifications__container'
      >
        <div className='content-container notifications__content'>
          {
            newNotifications.length > 0 && (
              <Fragment>
                <h3 className='notifications__title'>{t('new')}</h3>
                <ul className='notifications__list'>
                  {newNotifications}
                </ul>
              </Fragment>
            )
          }
          {
            readNotifications.length > 0 && (
              <Fragment>
                <h3 className='notifications__title'>{t('read')}</h3>
                <ul className='notifications__list'>
                  {readNotifications}
                </ul>
              </Fragment>
            )
          }
        </div>
      </Div100vh>
    </div>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.array,
  getNotifications: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

Notifications.defaultProps = {
  notifications: []
};

export default Notifications;
