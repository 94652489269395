import React from 'react';
import Colors from '@data/enums/Color.enum';

export function search({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.5859 20.3205L18.164 16.8986C21.0257 13.4088 20.8265 8.23845 17.5546 4.96892C14.0859 1.49783 8.45149 1.49783 4.96868 4.96892C1.49758 8.45173 1.49758 14.0861 4.96868 17.5549C8.23821 20.8267 13.4085 21.026 16.8984 18.1642L20.3202 21.5861C20.3952 21.6517 20.5148 21.6517 20.5781 21.5861L21.5859 20.5783C21.6515 20.515 21.6515 20.3955 21.5859 20.3205ZM16.3124 16.3127C13.5281 19.0947 9.01868 19.0947 6.2343 16.3127C3.45227 13.5283 3.45227 9.01892 6.2343 6.23455C9.01868 3.45252 13.5281 3.45252 16.3124 6.23455C19.0945 9.01892 19.0945 13.5283 16.3124 16.3127Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
