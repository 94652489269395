import React from 'react';
import Colors from '@data/enums/Color.enum';

export function chevronDown({ stroke }) {
  return (
    <svg viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 1L4.64645 4.64645C4.84171 4.84171 5.15829 4.84171 5.35355 4.64645L9 1'
        stroke={stroke || Colors.TEXT_DEFAULT}
        strokeLinecap='round'
      />
    </svg>
  );
}
