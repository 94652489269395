import { connect } from 'react-redux';
import FinishRegistrationModal from './FinishRegistrationModal';
import { finishRegistration } from '@actions/user';

const mapStateToProps = ({ reservation }) => ({
  reservation: reservation.details.data,
});

const mapDispatchToProps = {
  finishRegistration
};

export default connect(mapStateToProps, mapDispatchToProps)(FinishRegistrationModal);
