import Immutable from '@utils/immutable/Immutable';
import { SAVE_SEARCH_RESULTS, SEARCH_RESULTS_LOADING } from '@constants/search';

const initialState = {
  data: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_RESULTS_LOADING: {
      return Immutable({ ...state, isLoading: true });
    }
    case SAVE_SEARCH_RESULTS: {
      return Immutable({
        ...state,
        data: payload,
        isLoading: false,
      });
    }
    default:
      return state;
  }
};
