import moment from 'moment';
import store from '@store/Store';
import { SAVE_SEARCH_RESULTS, SEARCH_RESULTS_LOADING } from '@constants/search';
import { loadData } from '@utils/redux/actionUtils';
import queryString from 'query-string';
import { getCityObjectFromUrl } from '@utils/url/urlUtils';

export const searchPlaces = (queryParams, searchFinishedCallback) => {
  const nearest15Mins = moment(Math.ceil((moment()) / moment.duration(15, 'minutes')) * moment.duration(15, 'minutes')).toDate();
  const { q = '', seats = 2, time: stringTime = nearest15Mins, donate = '' } = queryString.parse(queryParams);

  const time = moment(stringTime).format('YYYY-MM-DD HH:mm');

  const searchParams = { q, seats, time, city: getCityObjectFromUrl().id, donate };

  const location = store.getState().user.location.data;
  const { differentCity } = store.getState().user.location;
  const { lat, lng } = location;

  if (!differentCity && lat && lng) {
    searchParams.lat = lat;
    searchParams.lng = lng;
  }

  return loadData('places.search', searchParams, searchFinishedCallback)(
    SEARCH_RESULTS_LOADING,
    SAVE_SEARCH_RESULTS
  );
};
