import React from 'react';
import PropTypes from 'prop-types';
import './ProfilePage.scss';
import { withRouter } from 'react-router-dom';
import mova from 'mova';
import SideTabs from '@components/navigation/SideTabs/SideTabs';
import ProfileRouter from './ProfileRouter';
import Div100vh from 'react-div-100vh';
import { HEADER_HEIGHT, FOOTER_HEIGHT } from '@utils/constants';

const t = mova.ns('pages.Profile');

const tabs = (match) => [{
  name: t('profile'),
  path: `${match.url}/profile`
}, {
  name: t('preferences'),
  path: `${match.url}/preferences`
}, {
  name: t('feedback'),
  path: `${match.url}/feedback`
}, {
  name: t('mailing'),
  path: `${match.url}/mailing`
}];

function ProfilePage(props) {
  const { match } = props;

  return (
    <div>
      <Div100vh className='profile-page' style={{ minHeight: `calc(100rvh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})` }}>
        <SideTabs tabs={tabs(match)} className='profile-page__tabs' />
        <div className='profile-page__content'>
          <ProfileRouter />
        </div>
      </Div100vh>
    </div>
  );
}

ProfilePage.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ProfilePage);
