import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';
import Animate from '../Animate/Animate';
import { isDefined } from '@utils/lo/lo';
import { connect } from 'react-redux';
import { MODAL_TRANSITION_OUT_MS } from '@constants/modal';
import Div100vh from 'react-div-100vh';
import { closeModal } from '@actions/modal';

function Modal({ children, title, className, onClose, closing, closeOnOverlayClick }) {
  const onWindowKeyPress = useCallback((e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    window.addEventListener('keyup', onWindowKeyPress);
    return () => window.removeEventListener('keyup', onWindowKeyPress);
  }, [onWindowKeyPress]);

  const animation = closing ? 'transition.slideUpOut' : 'transition.slideDownIn';
  const duration = closing ? MODAL_TRANSITION_OUT_MS : 500;

  return (
    <Div100vh
      className='modal-overlay'
      role='presentation'
      tabIndex='-1'
      onClick={closeOnOverlayClick ? onClose : undefined}
    >
      <Animate animation={animation} duration={duration} delay={0}>
        <div
          className={`modal ${className}`}
          role='presentation'
          tabIndex='-1'
          onClick={e => e.stopPropagation()}
        >
          {
            isDefined(title) && (
              <h3 className='modal__title'>
                {title}
              </h3>
            )
          }
          <div className='modal__content'>
            {children}
          </div>
        </div>
      </Animate>
    </Div100vh>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  closing: PropTypes.bool,
  closeOnOverlayClick: PropTypes.bool
};

Modal.defaultProps = {
  title: undefined,
  className: '',
  closing: false,
  closeOnOverlayClick: true
};

const mapStateToProps = ({ modal }) => ({
  closing: modal.closing
});

const mapDispatchToProps = {
  onClose: closeModal,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(Modal));
