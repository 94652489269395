import { connect } from 'react-redux';
import UserProfile from './UserProfile';
import { get } from '@utils/lo/lo';
import { updateProfile, changePassword, updateAvatar, deleteAvatar } from '@actions/user';

const mapStateToProps = ({ user }) => ({
  user: get(user, 'details.data.user'),
});

const mapDispatchToProps = {
  updateProfile,
  changePassword,
  updateAvatar,
  deleteAvatar
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
