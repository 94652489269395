import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import LocalStorageService from '@services/LocalStorage.service';
import Loading from '@components/feedback/Loading/Loading';
import promoUtils from '@utils/place/promoUtils';
import { PROMO_SUCCESS_PARAM } from '@utils/constants';

class SocialLogin extends PureComponent {
  componentDidMount() {
    const { location } = this.props;
    const { accessToken } = queryString.parse(location.search);
    LocalStorageService.set(LocalStorageService.Keys.TOKEN, accessToken);
    let redirectionPath = LocalStorageService.get(LocalStorageService.Keys.OAUTH_REDIRECTION) || '/';

    if (promoUtils.hasActivePromo()) {
      redirectionPath += `?${PROMO_SUCCESS_PARAM}=true`;
    }

    setTimeout(() => {
      window.location = redirectionPath || '/';
    }, 300);
  }

  render() {
    return <Loading />;
  }
}

SocialLogin.propTypes = {
  location: PropTypes.object.isRequired
};

export default SocialLogin;
