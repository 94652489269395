import uk from 'date-fns/locale/uk';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import mova from 'mova';
import { DEFAULT_LANG } from '@constants/app';

const customUaLocale = {
  ...uk,
  localize: {
    ...uk.localize,
    month: (monthIndex, options) => {
      const shortMonths = [
        'січ', 'лют', 'бер', 'кві', 'тра', 'чер',
        'лип', 'сер', 'вер', 'жов', 'лис', 'гру'
      ];

      if (options?.width === 'abbreviated') {
        return shortMonths[monthIndex];
      } else {
        return uk.localize.month(monthIndex, options);
      }
    }
  }
};

const locales = {
  ua: customUaLocale,
  ru,
  en,
  es,
};

export default {
  getLocaleObject: () => locales[mova.language.name] || locales[DEFAULT_LANG],
  getLocale: () => mova.language.name || DEFAULT_LANG,
};
