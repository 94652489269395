import React from 'react';
import Colors from '@data/enums/Color.enum';

export function forkKnife({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25 4C5.52614 4 5.75 4.22386 5.75 4.5V9.46667C5.75 10.157 6.30964 10.7167 7 10.7167H7.75V4.5C7.75 4.22386 7.97386 4 8.25 4H8.75C9.02614 4 9.25 4.22386 9.25 4.5V10.7167H10C10.6904 10.7167 11.25 10.157 11.25 9.46667V4.5C11.25 4.22386 11.4739 4 11.75 4H12.25C12.5261 4 12.75 4.22386 12.75 4.5V9.46667C12.75 10.9854 11.5188 12.2167 10 12.2167H9.25V19.5C9.25 19.7761 9.02614 20 8.75 20H8.25C7.97386 20 7.75 19.7761 7.75 19.5V12.2167H7C5.48122 12.2167 4.25 10.9854 4.25 9.46667V4.5C4.25 4.22386 4.47386 4 4.75 4H5.25Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 4.75C17.3096 4.75 16.75 5.30964 16.75 6V12.85H18.25V4.75H18ZM18.25 14.35V19.5C18.25 19.7761 18.4739 20 18.75 20H19.25C19.5261 20 19.75 19.7761 19.75 19.5V3.75C19.75 3.47386 19.5261 3.25 19.25 3.25H18C16.4812 3.25 15.25 4.48122 15.25 6V13.85C15.25 14.1261 15.4739 14.35 15.75 14.35H18.25Z'
        fill={fill || Colors.TEXT_DEFAULT}
      />
    </svg>
  );
}
