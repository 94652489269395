import React from 'react';
import Colors from '@data/enums/Color.enum';

export function close({ fill }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.2139 12L19.3662 4.66641C19.4694 4.54453 19.3826 4.35938 19.2233 4.35938H17.3529C17.2428 4.35938 17.1373 4.40859 17.0647 4.49297L11.9904 10.5422L6.91623 4.49297C6.84592 4.40859 6.74045 4.35938 6.62795 4.35938H4.75763C4.59826 4.35938 4.51154 4.54453 4.61467 4.66641L10.767 12L4.61467 19.3336C4.59156 19.3608 4.57674 19.394 4.57196 19.4293C4.56718 19.4647 4.57264 19.5006 4.5877 19.533C4.60275 19.5653 4.62676 19.5926 4.65689 19.6117C4.68701 19.6308 4.72197 19.6408 4.75763 19.6406H6.62795C6.7381 19.6406 6.84357 19.5914 6.91623 19.507L11.9904 13.4578L17.0647 19.507C17.135 19.5914 17.2404 19.6406 17.3529 19.6406H19.2233C19.3826 19.6406 19.4694 19.4555 19.3662 19.3336L13.2139 12Z'
        fill={fill || Colors.TEXT_DEFAULT}
        className='fill'
      />
    </svg>
  );
}
