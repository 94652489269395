import ReactDOM from 'react-dom';

const measureElements = (elements, callback) => {
  const elementParams = [];

  if (elements.length === 0) {
    callback([]);
    return;
  }

  (elements || []).forEach((element, index) => {
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.visibility = 'hidden';
    document.body.appendChild(container);

    ReactDOM.render(element, container, () => {
      const height = container.children[0].clientHeight;
      const width = container.children[0].clientWidth;

      ReactDOM.unmountComponentAtNode(container);

      elementParams.push({
        element,
        width,
        height
      });

      container.parentNode.removeChild(container);

      if (index === elements.length - 1) {
        callback(elementParams);
      }
    });
  });
};

export default measureElements;
