import React, { memo } from 'react';
import './Pagination.scss';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

function Pagination({ pageCount, onChange, currentPage = 0 }) {
  return (
    <div className='pagination'>
      <ReactPaginate
        previousLabel='&lt;'
        nextLabel='&gt;'
        breakLabel='...'
        breakClassName='break-me'
        pageCount={pageCount}
        forcePage={currentPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={data => onChange(data.selected)}
        containerClassName='pagination__list'
        activeClassName='pagination--active'
        pageLinkClassName='pagination__item'
        previousLinkClassName='pagination__item'
        nextLinkClassName='pagination__item'
      />
    </div>
  );
}

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number
};

Pagination.defaultProps = {
  currentPage: 0
};

export default memo(Pagination);
