import React from 'react';

export function PRITULA() {
  return (
    <svg viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_329_2530)'>
        <path d='M11.0602 11.9999V0.939697H6.21686V10.1566' stroke='#0E1D61' strokeWidth='2' />
        <path d='M12 6.28906H0.939758V11.0601H10.1566' stroke='#D68130' strokeWidth='2' />
      </g>
      <defs>
        <clipPath id='clip0_329_2530'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
