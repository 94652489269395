import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './ErrorPage.scss';
import TextLink from '@components/navigation/TextLink/TextLink';
import { baseUrlPath } from '@utils/url/urlUtils';

const t = mova.ns('pages.Error');

function Entity404(props) {
  const { entity } = props;

  return (
    <div className='error-page'>
      <div className='error-page__status'>404</div>
      <div className='error-page__message'>{t('entity404', { entity })}</div>

      <div className='error-page__link'>
        <TextLink to={`${baseUrlPath()}/`} type='text' size='large'>{t('goHome')}</TextLink>
      </div>
    </div>
  );
}

Entity404.propTypes = {
  entity: PropTypes.string.isRequired
};

export default memo(Entity404);
