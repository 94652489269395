import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import './PhoneInput.scss';
import InputGroup from '@components/layout/InputGroup/InputGroup';
import Select from '@components/inputs/Select';
import { isFunction, isDefined } from '@utils/lo/lo';
import { withFormsy } from 'formsy-react';
import ReactNumberFormat from '../ReactNumberFormat/ReactNumberFormat';
import { countryCodes } from '@utils/phone/phoneUtils';
import TextInput from '@components/inputs/TextInput/TextInput';

function PhoneInput(props) {
  const { label, fullWidth, fontSize, onChange, value, setValue, errorMessage, isPristine, defaultValue } = props;

  const [code, setCode] = useState('+380');
  const [phone, setPhone] = useState('');

  const errorPresent = !isPristine && Boolean(errorMessage);

  const codeSelected = (newCode) => {
    setCode(newCode);

    if (isFunction(onChange)) {
      onChange(`${newCode}${phone}`);
    }
  };

  const phoneChanged = (newValue) => {
    let newPhone = newValue.value;

    if (!isDefined(newPhone) && newValue?.target) {
      newPhone = newValue.target.value;
    }

    if (newPhone.length > 0 && !/^\d+$/.test(newPhone)) {
      return;
    }

    setPhone(newPhone);

    if (isFunction(onChange)) {
      onChange(`${code}${newPhone}`);
    }

    setValue(`${code}${newPhone}`);
  };

  return (
    <InputGroup
      className={`phone-input ${fullWidth && 'phone-input--full-width'}`}
      error={errorMessage}
      errorPresent={errorPresent}
    >
      <Select
        options={countryCodes}
        defaultValue='+380'
        label={label}
        className='phone-input__select'
        fontSize={fontSize}
        onSelect={codeSelected}
      />
      {code !== '+380' && (
        <TextInput
          name='phone-suffix'
          autoComplete='phone-suffix'
          placeholder='(##) ###-##-##'
          onChange={phoneChanged}
          value={phone}
        />
      )}
      {code === '+380' && (
        <ReactNumberFormat
          format='(##) ###-##-##'
          mask='_'
          type='tel'
          fontSize={fontSize}
          phoneChanged={phoneChanged}
          value={(value || defaultValue || '').substring(code.length)}
          name='phone-suffix'
          isNumericString
          allowEmptyFormatting
        />
      )}
    </InputGroup>
  );
}

PhoneInput.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  fullWidth: PropTypes.bool,
  fontSize: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isPristine: PropTypes.bool.isRequired,
};

PhoneInput.defaultProps = {
  label: undefined,
  defaultValue: undefined,
  fullWidth: false,
  fontSize: 16,
  onChange: undefined,
  errorMessage: null,
  value: '',
};

export default memo(withFormsy(PhoneInput));
