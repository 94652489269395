import React, { memo } from 'react';
import './Switch.scss';

const Switch = ({ left, right, onClick, leftActive, rightActive, className = '' }) => {
  return (
    <div className={`switch ${className}`} onClick={onClick}>
      <div className={`switch__left switch__item ${leftActive ? 'switch__active' : ''}`}>{left}</div>
      <div className={`switch__right switch__item ${rightActive ? 'switch__active' : ''}`}>{right}</div>
    </div>
  );
};

export default memo(Switch);
