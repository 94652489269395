import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReservationNotification from '../ReservationNotification/ReservationNotification';
import moment from 'moment';
import { connect } from 'react-redux';

const notificationTypes = {
  reservation: ReservationNotification
};

function NotificationItem({ note, timezone }) {
  const notificationDate = moment.tz(note.createdAt, timezone).format('HH:mm DD.MM.YYYY');
  const TypedNotification = notificationTypes[note.type];

  return (
    <li className='notification-item'>
      <TypedNotification note={note} />
      <div className='notification-item__date'>{notificationDate}</div>
    </li>
  );
}

NotificationItem.propTypes = {
  note: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

const mapStateToProps = ({ app }) => ({
  timezone: app.time.data.DEFAULT_TZ
});

export default memo(connect(mapStateToProps)(NotificationItem));
