import React from 'react';
import Colors from '@data/enums/Color.enum';

export function calendar({ fill = Colors.TEXT_DEFAULT }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.7633 11.2792H8.23472C7.88815 11.2792 7.60768 11.5708 7.60768 11.9313C7.60768 12.2917 7.88815 12.5833 8.23472 12.5833H15.7653C16.1119 12.5833 16.3923 12.2917 16.3923 11.9313C16.3903 11.5708 16.1098 11.2792 15.7633 11.2792ZM15.7633 14.8104H8.23472C7.88815 14.8104 7.60768 15.1021 7.60768 15.4625C7.60768 15.8229 7.88815 16.1146 8.23472 16.1146H15.7653C16.1119 16.1146 16.3923 15.8229 16.3923 15.4625C16.3903 15.1021 16.1098 14.8104 15.7633 14.8104ZM19.5556 5.16667H17.2397V4.65208C17.2397 4.29167 16.9593 4 16.6127 4C16.2661 4 15.9856 4.29167 15.9856 4.65208V5.16667H8.01235V4.65208C8.01235 4.29167 7.73189 4 7.38531 4C7.03873 4 6.75826 4.29167 6.75826 4.65208V5.16667H4.4424C3.64508 5.16667 3 5.8375 3 6.66667V18.5C3 19.3292 3.64508 20 4.4424 20H19.5576C20.3549 20 21 19.3292 21 18.5V6.66667C20.998 5.83958 20.3529 5.16667 19.5556 5.16667ZM4.4424 6.66667H6.76027V7.00417C6.76027 7.36458 7.04073 7.65625 7.38731 7.65625C7.73389 7.65625 8.01436 7.36458 8.01436 7.00417V6.66667H15.9876V7.00417C15.9876 7.36458 16.2681 7.65625 16.6147 7.65625C16.9613 7.65625 17.2417 7.36458 17.2417 7.00417V6.66667H19.5596V8.71042H4.4424V6.66667ZM19.5556 18.5H4.4424V10.0083H19.5576V18.5H19.5556Z'
        fill={fill}
      />
    </svg>
  );
}
