import { v1 } from './common/clients';
import queryString from 'query-string';

export default {
  current: () => v1.get('/profile'),
  byToken: (data) => v1.post('/profile/byToken', data),
  auth: credentials => v1.post('/auth/ui', credentials),
  create: data => v1.post('/users', data),
  saveLang: data => v1.put('/profile/lang', data),
  update: data => v1.put('/profile', data),
  forgotPassword: data => v1.put('/recovery', data),
  forgotSms: data => v1.put('/recovery/sms', data),
  smsValidateCode: data => v1.put('/recovery/sms/code', data),
  changePasswordSms: data => v1.put('/recovery/sms/password', data),
  updatePassword: data => v1.put('/profile/changePassword', data),
  updateAvatar: ({ file }) => v1.put('/profile/avatar', file, { headers: { 'Content-Type': 'multipart/form-data' } }),
  deleteAvatar: () => v1.delete('/profile/avatar'),
  locationByIp: () => v1.get('/internal/location'),
  getUserFeedbacks: (params) => v1.get(`/profile/places/reviewed?${queryString.stringify(params)}`)
};
