import moment from 'moment';

const usePlaceDate = (date, place) => {
  if (!place) {
    return [null, null];
  }

  const dayOfWeek = moment(date).format('E');
  const openHours = place.openHours.find(p => p.dayOfWeek === dayOfWeek);
  let withOpenHours = null;
  let withCloseHours = null;

  if (openHours.works24h) {
    withOpenHours = moment(date).startOf('day').toDate();
    withCloseHours = moment(date).endOf('day').toDate();
  } else {
    withOpenHours = moment(date)
      .isoWeekday(Math.ceil(openHours.dayOfWeek))
      .hours(openHours.openH)
      .minutes(openHours.openM)
      .toDate();

    if (withOpenHours < new Date()) {
      withOpenHours = new Date();
    }

    withCloseHours = moment(date)
      .isoWeekday(Math.ceil(openHours.dayOfWeek))
      .hours(openHours.closedH)
      .minutes(openHours.closedM)
      .subtract(2, 'hours')
      .toDate();
  }
  return [withOpenHours, withCloseHours];
};

export default usePlaceDate;
