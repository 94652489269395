import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import measureElements from '@utils/measure/measureElements';
import ReactResizeDetector from 'react-resize-detector';
import TimeTag from './TimeTag';
import './TimeTags.scss';
import { distinct, isDefined } from '@utils/lo/lo';
import { getRenderedTagRows } from '@utils/place/placeUtils';
import { withRouter } from 'react-router-dom';
import { fromTime } from '@utils/sort/sortUtils';
import { baseUrlPath } from '@utils/url/urlUtils';
import { connect } from 'react-redux';
import { updateQuickReservationParams } from '@actions/reservation';

const TAG_MARGIN = 8;

function TimeTags(
  { tags, rows, makeReservation, showMoreButton, placeUri, history, showMax, sortFrom, updateQuickReservationParams }
) {
  const [width, setWidth] = useState(0);
  const [measuredTags, setMeasuredTags] = useState([]);

  const goToPlacePage = () => {
    updateQuickReservationParams();
    history.push(`${baseUrlPath()}/places/${placeUri}`);
  };

  const availableSlots = [].concat(...(tags || []).map(t => t.slots || []))
    .map(s => s.from)
    .filter(distinct)
    .sort(fromTime(sortFrom));

  const wrappedTags = availableSlots.map(tag => (
    <TimeTag time={tag} key={tag} onClick={() => makeReservation(tag)} />
  ));

  useEffect(() => {
    measureElements(wrappedTags, (output) => {
      setMeasuredTags(output);
    });
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isDefined(tags) || !Array.isArray(tags)) {
    return null;
  }

  const renderedTags = getRenderedTagRows(width, rows, measuredTags, TAG_MARGIN, showMax);

  if (showMoreButton && !!showMax && measuredTags.length > showMax) {
    renderedTags.pop();
    renderedTags.push(
      <TimeTag more key='time-more' onClick={goToPlacePage} />
    );
  }

  const onContainerResize = (newWidth) => {
    setWidth(newWidth);
  };

  return (
    <div className='time-tags'>
      <ReactResizeDetector refreshMode='throttle' refreshRate={100} handleWidth onResize={onContainerResize} />
      {renderedTags}
    </div>
  );
}

TimeTags.propTypes = {
  tags: PropTypes.array.isRequired,
  rows: PropTypes.number,
  makeReservation: PropTypes.func.isRequired,
  updateQuickReservationParams: PropTypes.func.isRequired,
  showMoreButton: PropTypes.bool,
  placeUri: PropTypes.string,
  history: PropTypes.object.isRequired,
  showMax: PropTypes.number,
  sortFrom: PropTypes.instanceOf(Date)
};

TimeTags.defaultProps = {
  rows: 1,
  showMoreButton: false,
  placeUri: undefined,
  showMax: undefined,
  sortFrom: new Date()
};

const mapDispatchToProps = {
  updateQuickReservationParams,
};

export default memo(connect(null, mapDispatchToProps)(withRouter(TimeTags)));
