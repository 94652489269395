import Immutable from '@utils/immutable/Immutable';
import { PLACE_LIKE_LOADING, SAVE_LIKED_PLACES } from '@constants/user';

const initialState = {
  data: [],
  isLoading: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SAVE_LIKED_PLACES: {
      return Immutable({ ...state, data: payload });
    }
    case PLACE_LIKE_LOADING: {
      return Immutable({ ...state, isLoading: payload });
    }
    default:
      return state;
  }
};
