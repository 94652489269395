import React, { memo } from 'react';
import PropTypes from 'prop-types';

function SelectOption(props) {
  const { option, label, onSelect, textAlign, selected } = props;

  return (
    <div
      className={`select__option ${selected && 'select__option--selected'}`}
      onClick={() => onSelect(option, label)}
      onKeyPress={() => onSelect(option, label)}
      role='menuitem'
      tabIndex={0}
      style={{ textAlign }}
    >
      {label}
    </div>
  );
}

SelectOption.propTypes = {
  option: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onSelect: PropTypes.func.isRequired,
  textAlign: PropTypes.string,
  selected: PropTypes.bool,
};

SelectOption.defaultProps = {
  textAlign: 'center',
  selected: false,
};

export default memo(SelectOption);
